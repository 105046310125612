import { Category } from 'src/app/data/data';
import { Injectable, EventEmitter } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { AppConstants } from 'src/app/app.constants';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CouponServiceService {
  getUpdatedData = new EventEmitter();
  subsVar: Subscription;
  private CategoryId: Number;
  private CampaignId:number;
  constructor(
    private http: HttpClient,
  ) { }
  public setCategoryId(id) {
    this.CategoryId = id;
  }
  public getCategoryId() {
    return this.CategoryId;
  }

  public setCampaignId(id) {
    this.CampaignId = id;
  }
  public getCampaignId() {
    return this.CampaignId;
  }
  onChangeClaimComponent() {
    console.log('Inside Event Emitter...');
    this.getUpdatedData.emit();
  }

  getImage(path,basePath) {
    // console.log("fbwfwykegfdwuiefbwyiebfwbgibgibuigwruig",path);
    // console.log("fbwfwykegfdwuiefbwyiebfwbgibgibuigwruig",basePath);
    
    return (AppConstants.BASE_API_URL+'wifi-portal/getCampaignProfileImage/' +path +'/' + basePath);
  }
  public getImageC(data: number): string {
    //  public  getImage(data: string) {
    // const accessToken = AppConstants.ACCESS_TOKEN['access_token'];
    // return 'https://shop10x.app/Video' + path.split('Video')[1];
    // return 'http://localhost:8080/Video' + path.split('Video')[1];
    // return 'https://203.193.144.93:8080/' + path.split('Video')[1];
    return (AppConstants.BASE_API_URL1 + 'media/retrieveImage/' + data + '?moduleType=campaign');
  }

  public getImagee(data: number): string {
    //  public  getImage(data: string) {
    // const accessToken = AppConstants.ACCESS_TOKEN['access_token'];
    // return 'https://shop10x.app/Video' + path.split('Video')[1];
    // return 'http://localhost:8080/Video' + path.split('Video')[1];
    // return 'https://203.193.144.93:8080/' + path.split('Video')[1];
    return (AppConstants.BASE_API_URL1 + 'media/retrieveImage/' + data + '?moduleType=category');
  }
  public getImageprod(data: number): string {
    //  public  getImage(data: string) {
    // const accessToken = AppConstants.ACCESS_TOKEN['access_token'];
    // return 'https://shop10x.app/Video' + path.split('Video')[1];
    // return 'http://localhost:8080/Video' + path.split('Video')[1];
    // return 'https://203.193.144.93:8080/' + path.split('Video')[1];
    return (AppConstants.BASE_API_URL1 + 'media/retrieveImage/' + data + '?moduleType=product');
  }
  // public getImage(data: number): string {
  //       const accessToken = AppConstants.ACCESS_TOKEN['access_token'];
     
  //       return AppConstants.BASE_API_URL + 'media/retrieveImage/' + data + '?moduleType=product&access_token=' +accessToken;
  //     }
  public getAllProduct(): Observable<any> {
    // return this.http.get(AppConstants.BASE_API_URL + 'wifi-portal/getAllProduct');
    return this.http.get(AppConstants.BASE_API_URL1 +"/product/viewallproduct")

    // return this.http.get('http://192.168.0.82:8080/wifi10xapi/wifi-portal/getAllProduct');
  }
  public getSubProductByProdId(id: number): Observable<any> {
    return this.http.get(AppConstants.BASE_API_URL + 'wifi-portal/getSubProductByProdId/' + id);
    // return this.http.get('http://192.168.0.82:8080/wifi10xapi/wifi-portal/getSubProductByProdId/' + id);
  }
  public getProductByCategoryId(id: number): Observable<any> {
    // return this.http.get(AppConstants.BASE_API_URL + 'wifi-portal/getProductByCategoryId/' + id);
    // return this.http.get('http://192.168.0.82:8080/wifi10xapi/wifi-portal/getProductByCategoryId/' + id);
    // return this.http.get(AppConstants.BASE_API_URL + 'wifi-portal/getProductByCategoryId/' + id);
    return this.http.get(AppConstants.BASE_API_URL1 +"/product/viewallproduct?categoryId=" + id)
  }
  public getCampaignByCategoryId(id: number): Observable<any> {
    // return this.http.get(AppConstants.BASE_API_URL + 'wifi-portal/getProductByCategoryId/' + id);
    // return this.http.get('http://192.168.0.82:8080/wifi10xapi/wifi-portal/getProductByCategoryId/' + id);
    // return this.http.get(AppConstants.BASE_API_URL + 'campaign/getCampaignByCatId?categoryId=' + id);
    return this.http.get(AppConstants.BASE_API_URL1 +"/product/viewallproduct?categoryId=" + id)

  }
  public getAllSubProduct(): Observable<any> {
    return this.http.get(AppConstants.BASE_API_URL + 'wifi-portal/getAllSubProduct');
  }
  public getAllCategory(): Observable<any> {
    // return this.http.get(AppConstants.BASE_API_URL + 'wifi-portal/getAllCategory');
    // return this.http.get("http://192.168.0.82:8080/wifi10xapi/wifi-portal/getAllCategory");
    // return this.http.get(AppConstants.BASE_API_URL + 'wifi-portal/getAllCategory');
    return this.http.get(AppConstants.BASE_API_URL1 +"product/getAllCategories");

  }
  public getAllBrand(): Observable<any> {
    // return this.http.get(AppConstants.BASE_API_URL + 'wifi-portal/getAllBrand');
    return this.http.get(AppConstants.BASE_API_URL1  +"/pos/getAllBrand");

  }
  public getAllSubCategory(): Observable<any> {
    return this.http.get(AppConstants.BASE_API_URL + 'wifi-portal/getAllSubCategory');
  }
  public(id: number): Observable<any> {
    return this.http.get(AppConstants.BASE_API_URL + 'wifi-portal//' + id);
    // return this.http.get('http://192.168.0.82:8080/wifi10xapi/wifi-portal//' + id);
  }
  public getAllSubCategoryByCatId(id: number): Observable<any> {
    return this.http.get(AppConstants.BASE_API_URL + 'wifi-portal/getAllSubCategoryByCatId/' + id);
  }
  public getAllCart(): Observable<any> {
    return this.http.get(AppConstants.BASE_API_URL + 'wifi-portal/getAllCart');
  }
  public getProductBySubCategoryId(id: number): Observable<any> {
    return this.http.get(AppConstants.BASE_API_URL + 'wifi-portal/getProductBySubCategoryId/' + id);
  }
  public getProductByBrandId(id: number): Observable<any> {
    // return this.http.get(AppConstants.BASE_API_URL + 'wifi-portal/getProductByBrandId/' + id);
    return this.http.get(AppConstants.BASE_API_URL1  +"/product/viewallproduct?brandId="+id);

  }
  public getBanner(): Observable<any> {
    return this.http.get(AppConstants.BASE_API_URL + 'wifi-portal/getBanner');
  }
  public addToWallet(data) {
    return this.http.post(AppConstants.BASE_API_URL + 'wifi-portal/addToWallet', data);
    // return this.http.post("http://192.168.0.82:8080/wifi10xapi/wifi-portal/addToWallet", data);
  }
  public addToWallet1(data) {
    return this.http.post(AppConstants.BASE_API_URL1 + 'admin/addtowallet/'+data.productId+"/"+data.offerId+"/"+data.campaignId+"?mobile_no="+data.mobileNo,data);
  }
  public claimcoupon(data) {
    return this.http.get(AppConstants.BASE_API_URL1 + 'coupon/redeem?mobile_no='+data.mobileNo+ '&offerId='+data.offerId+'&campaignId='+data.campaignId);
  }
  public addToWishlist(data) {
    return this.http.post(AppConstants.BASE_API_URL + 'wifi-portal/addWish', data);
    // return this.http.post("http://192.168.0.82:8080/wifi10xapi/wifi-portal/addWish", data);
  }
  public getAllWallet(mobileNo): Observable<any> {
    return this.http.get(AppConstants.BASE_API_URL + 'wifi-portal/getAllWallet'+'/'+mobileNo);
  }
  public getAllWallet1(mobileNo): Observable<any> {
    return this.http.get(AppConstants.BASE_API_URL1 + 'admin/viewwallet?mobile_no='+mobileNo);
  }
  public getWishList(): Observable<any> {
    return this.http.get(AppConstants.BASE_API_URL + 'wifi-portal/getWishList');
  }
  public getLocationWiseCoupon(data): Observable<any> {
    return this.http.post(AppConstants.BASE_API_URL + 'wifi-portal/detailsfromStore', data);
  }
  public shareCoupon(data): Observable<any> {
    return this.http.post(AppConstants.BASE_API_URL + 'wifi-portal/shareCoupon', data);
  }
  public validatePinCode(data: Number): Observable<any> {
    // return this.http.get('https://shop10x.app/wifi10x/wifi10x/getVoucherDetailsByPin/' + data);
    return this.http.get(AppConstants.BASE_API_URL + 'wifi10x/getVoucherDetailsByPin/' + data);
  }
  public saveBrowserInfo(browserInfo): Observable<any> {
    return this.http.post(AppConstants.BASE_API_URL+'wifi-portal/saveBrowserInfo', browserInfo);
  }
  public getToken(): Observable<any> {
    // return this.http.get('http://192.168.0.82:8080/wifi10xapi/wifi-portal/generateAccessToken');
    return this.http.get(AppConstants.BASE_API_URL+'wifi-portal/generateAccessToken');
  }

  public getFrontPageBData(campaignMgmtId): Observable<any> {
    return this.http.get(AppConstants.BASE_API_URL + "campaign/getCampaignPageB?campaignMgmtId=" + campaignMgmtId);
    // return this.http.get('assets/mock/tiles_template.json',campaignMgmtId); 
  }

  public getAuthorizationLink(data): Observable<any> {
    return this.http.post(AppConstants.BASE_API_URL + "router/getAuthorizationLink", data);
  }
  public saveLater(id) {
    return this.http.post(AppConstants.BASE_API_URL + "savelater", id);
  }
  public getSubProductBySubId(id){
    return this.http.get(AppConstants.BASE_API_URL+'wifi-portal/getSubProductBySubId/'+id)
  }
  public getAllStores(id) {
    return this.http.get(AppConstants.BASE_API_URL+'wifi-portal/getAllStores/' +id);
  }
  public getAllCategoryById(id) {
    return this.http.get(AppConstants.BASE_API_URL+'wifi-portal/getAllCategory/' + id);
  }
  getAllCountries() {
    return this.http.get(AppConstants.BASE_API_URL+'newchanges/getAllLocation'); 
  }
  getAllAdnetwork(id) {
    return this.http.get(AppConstants.BASE_API_URL+'newchanges/getAlladNetworks/' + id); 
  }
  getAllMalls(id) {
    return this.http.get(AppConstants.BASE_API_URL+'newchanges/getAllSellerByAdNetwork/' + id); 
  }
  getCampaignByMallId(id) {
    return this.http.get(AppConstants.BASE_API_URL +'campaign/getCampaignBySellerId?sellerId='+ id);
  }
  getCampaignByStoreId(id) {
    // return this.http.get(AppConstants.BASE_API_URL + 'campaign/getCampaignByStoreId?storeId='+id);
    return this.http.get(AppConstants.BASE_API_URL1 +"/product/qrCode/viewallproduct?storeId="+id);
  }
  getCampaignByStoreId1(type,id) {
    // return this.http.get(AppConstants.BASE_API_URL + 'campaign/getCampaignByStoreId?storeId='+id);
    return this.http.get(AppConstants.BASE_API_URL1 +"campaign/getAllCampaigns?moduleType="+type+"&typeId="+id);
  }
  getCampaignByproductId(id) {
    // return this.http.get(AppConstants.BASE_API_URL + 'campaign/getCampaignByStoreId?storeId='+id);
    return this.http.get(AppConstants.BASE_API_URL1 +"product/viewproductbyid/"+id);
  }
  getProductByCampaignId(id) {
    // return this.http.get(AppConstants.BASE_API_URL + 'campaign/getCampaignByStoreId?storeId='+id);
    return this.http.get(AppConstants.BASE_API_URL1 +"product/viewallproduct?campaignId="+id);
  }
  getCountry(data:object): Observable<any> {
    return this.http.get(AppConstants.BASE_API_URL1 + 'admin/getallcountries',data);
  }
}
