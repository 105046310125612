<nav class="navbar">
    <a href="#" class="navbar-brand">MALL<span>10</span>X</a>
  </nav>
 
<div class="wrapper">
  <div class="container">
    <div class="col-left">
      <div class="login-form">
        <h2>Enter Details</h2>
        <form>
          <p>
           <!-- <input placeholder="Enter store id" type="text" id="storeId" name="storeId" required [(ngModel)]="storeId">  -->
            <label>enter storeID</label>
            <select name="storeId" id="storeId" class="shipping-select" required [(ngModel)]="storeId">
              <option value="">--Select--</option>
              <option value="1">papa john Bawadi mall</option>
              <option value="2784">monsoon Bawadi mall</option>
              <option value="2758">monsoon children Bawadi mall</option>
              <option value="51278">51278</option>
              <option value="51279">51279</option>
              <option value="51280">51280</option>
              <option value="51282">51282</option>
              <option value="51284">51284</option>
              <option value="51285">51285</option>
              <option value="51289">51289</option>
              <option value="51290">51290</option>
              <option value="51291">51291</option>
              <option value="53629">53629</option>
              <option value="53658">53658</option>
              <option value="53750">53750</option>
              <option value="54459">54459</option>
              <option value="54503">54503</option>
         
            </select>

          </p>
           <p>
            <!-- <input placeholder="enter year" type="number" id="year" name="year" min="2020" max="2023" required [(ngModel)]="year"> -->
            <label>Enter Year</label>
              <select name="year" class="shipping-select" id="year" required [(ngModel)]="year">
                <option value="">--Select--</option>
                <!-- <option value="2020">2020</option> -->
                <option value="2021">2021</option>
                <option value="2022">2022</option>
                <option value="2023">2023</option> 


              </select>
          </p>
          
           <p>
            <label>Start month</label>

            <select class="shipping-select" id="startMonth" name="startMonth" required [(ngModel)]="startMonth">
              <option value="">--Select--</option>
              <option value="01">January</option>
              <option value="02">February</option>
              <option value="03">March</option>
              <option value="04">April</option>
              <option value="05">May</option>
              <option value="06">June</option>
              <option value="07">July</option>
              <option value="08">August</option>
              <option value="09">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option>
            </select>
          </p>
          <p>
            <label>End month</label>
            <select class="shipping-select" id="endMonth" name="endMonth" required [(ngModel)]="endMonth">
              <option value="">--Select--</option>
              <option value="01">January</option>
              <option value="02">February</option>
              <option value="03">March</option>
              <option value="04">April</option>
              <option value="05">May</option>
              <option value="06">June</option>
              <option value="07">July</option>
              <option value="08">August</option>
              <option value="09">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option>
            </select>
          </p>
        
         
        </form>
      </div>
    </div>
    <div class="col-right">
      <div class="login-social">
        <h2>Download Here</h2>
        
        <button class="btn btn-go" (click)="downloadPdf()">Download PDF</button>
        <button class="btn btn-tw" (click)="downloadExcel()">Download Excel</button>
      </div>
    </div>
  </div>
 
</div> 