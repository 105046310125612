import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WifiRouterService } from 'src/app/services/wifi-router/wifi-router-service.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-wifi-router-component',
  templateUrl: './wifi-router-component.component.html',
  styleUrls: ['./wifi-router-component.component.scss']
})
export class WifiRouterComponent implements OnInit {
  paramData: any;
  public error: boolean = false;
  constructor(private route: ActivatedRoute,
    public router: Router,
    private service: WifiRouterService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(param => {
      console.log(param);
      this.paramData = param;
      this.saveAuthorization();
    });

  }

  saveAuthorization() {
    localStorage.setItem("userId",'10000001');
    localStorage.setItem('userType','0');
    
    console.log(this.paramData);
    console.log(this.paramData.loc);
    if (this.paramData.loc === "" || this.paramData.loc === undefined) {
      //this.router.navigate(['/banner']);
      //this.service.token = this.paramData.xaccessToken;
      //this.service.targetURL = this.paramData.targetUrl;
      //this.router.navigate(['/login']);
    } else {
      if (this.paramData.isdemo == "true") {
        window.localStorage.setItem("isTimerRequired", "false");
      } else {

        window.localStorage.setItem("isTimerRequired", "true");
      }
      const time = new Date().getTime();
      // tslint:disable-next-line: max-line-length
      const AuthorizationLink = this.paramData.loc || this.paramData.Routerip;
      const browserInfo: object = {
        userAgent: navigator.userAgent,
        appName: navigator.appName,
        appCodeName: navigator.appCodeName,
        appVersion: navigator.appVersion,
        platform: navigator.platform,
        cookieEnabled: '' + navigator.cookieEnabled,
        javaEnabled: '' + navigator.javaEnabled(),
        colorDepth: '' + window.screen.colorDepth,
        width: '' + window.screen.width,
        height: '' + window.screen.height,
        availWidth: '' + window.screen.availWidth,
        availHeight: '' + window.screen.availHeight,
        outerWidth: '' + window.outerWidth,
        outerHeight: '' + window.outerHeight,
        cpuClass: window.navigator.platform,
        referrer: document.referrer,
        localedate: (new Date().toLocaleString()),
        now: '' + Date.now(),
        language: navigator.language,
        browserLanguage: window.navigator.language,
        systemLanguage: window.navigator.language,
        userLanguage: navigator.language,
      };
      const authorization = {
        loc: AuthorizationLink,
        msisdn: '',
        redir: 'https://www.samsung.com/in/',
        forcetimeout: 30,
        maxtraffic: 180,
        tok: '123456',
      };
      /*window.localStorage.setItem("authorizationLink", AuthorizationLink);
      window.localStorage.setItem("msisdn", "7205388748");
      window.localStorage.setItem("redir", "https://www.apple.com/in/");
      window.localStorage.setItem("forcetimeout", "30");
      window.localStorage.setItem("maxtraffic", "180");
      window.localStorage.setItem("tok", this.paramData.tok);*/
      //alert(this.paramData.ap);
      localStorage.setItem('deviceId', this.paramData.id);
      localStorage.setItem('routerdeviceId', this.paramData.ap);
      // tslint:disable-next-line: max-line-length
      this.paramData = Object.assign({}, this.paramData, { t: time }, { nodeIp: this.paramData.nodeIp }, { terminalMac: this.paramData.umac }, { terminalIpV4: this.paramData.uaddress }, { deviceMac: this.paramData.apmac }, { ssid: this.paramData.ssid }, { browserInfo }, { authorization }, { fullUrl: window.location.href });
      this.service.saveClientData(this.paramData).subscribe(
        res => {
          console.log(res);

          const postData = {
            accessToekn: res.token
          };
          window.localStorage.setItem("wifi-token", res.token);
          window.localStorage.setItem("redirected-From", "WIFI");
          window.localStorage.setItem("redirection-time", "" + Date.now());
          if (res.defaultLang !== null || res.defaultLang === "") {
            this.translate.use("grk");
          } else {
            this.translate.use(res.defaultLang);
          }
          this.translate.use(res.defaultLang);
          /*this.service.checkSessionStatus(postData).subscribe(
            result => {
              console.log(result);
            }, error => {
              console.log(error);
            }
          );*/
          // document.location.href = 'https://wifi10x.com/AdSequence/?id=5&xaccessToken=' + res.xaccessToken;
          //document.location.href = 'http://localhost:4201/?id=5&xaccessToken=' + res.xaccessToken;
          // this.toastr.success('Details Saved Successfully');
          this.router.navigate(['/banner']);
        }, err => {
          this.error = true;
          console.log(err);
        }
      );
    }
  }


}
