import { EventEmitterService } from './../../services/EventEmitter/event-emitter.service';
import { Component, OnInit, TemplateRef, ElementRef, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Category, Brand, ViewList } from 'src/app/data/data';
import { EmitterVisitorContext } from '@angular/compiler';
import { CouponServiceService } from 'src/app/services/coupon/coupon-service.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit {

  public catgBtn = 'ΚΑΤΗΓΟΡΙΑ';
  public brandBtn = 'ΜΑΡΚΑ';
  public listBtn = 'ΕΙΔΟΣ';
  // public catgBtn = 'Category';
  // public brandBtn = 'Brand';
  // public listBtn = 'Sort';
  public show = false;
  searchText;
  public category: Category[];
  public brand: Brand[];
  public viewList: ViewList[];
  public selectedCatg = Category;
  public headerFix = false;
  public selectedBrand = Brand;
  public value = 400;
  public difference = 280;
  public coupons: any;
  // [
  //   {
  //     id: 9,
  //     name: 'Coach',
  //     desc: '125 AED discount',
  //     expDate: '11-06-2020',
  //     imgURL: 'assets/img/coach.png',
  //     btnText: 'Earn ! AED 300'
  //   }, {
  //     id: 10,
  //     name: 'denver',
  //     desc: '125 AED discount',
  //     expDate: '11-06-2020',
  //     imgURL: 'assets/img/denver.png',
  //     btnText: 'Earn ! AED 300'
  //   }, {
  //     id: 11,
  //     name: 'galaxyZ',
  //     desc: '125 AED discount',
  //     expDate: '11-06-2020',
  //     imgURL: 'assets/img/galaxyZ.png',
  //     btnText: 'Earn ! AED 300'
  //   }, {
  //     id: 12,
  //     name: 'abmh',
  //     desc: '125 AED discount',
  //     expDate: '11-06-2020',
  //     imgURL: 'assets/img/abmh.png',
  //     btnText: 'Earn ! AED 300'
  //   }, {
  //     id: 13,
  //     name: 'clymb',
  //     desc: '125 AED discount',
  //     expDate: '11-06-2020',
  //     imgURL: 'assets/img/clymb.png',
  //     btnText: 'Earn ! AED 300'
  //   }, {
  //     id: 14,
  //     name: 'zbra',
  //     desc: '125 AED discount',
  //     expDate: '11-06-2020',
  //     imgURL: 'assets/img/zbra.png',
  //     btnText: 'Earn ! AED 300'
  //   }, {
  //     id: 1,
  //     name: 'samsung S10',
  //     desc: '125 AED discount',
  //     expDate: '11-06-2020',
  //     imgURL: 'assets/img/samsung S10.png',
  //     btnText: 'Earn ! AED 300'
  //   }, {
  //     id: 2,
  //     name: 'adidas',
  //     desc: '10% off',
  //     expDate: '20-08-2020',
  //     imgURL: 'assets/img/adidas.jpg',
  //     btnText: 'Shop Now'
  //   }, {
  //     id: 3,
  //     name: 'Gucci Bag',
  //     desc: 'flat 20% off ',
  //     expDate: '19-08-2020',
  //     imgURL: 'assets/img/gucci.jpg',
  //     btnText: 'Shop Now'
  //   }, {
  //     id: 4,
  //     name: 'Gucci Deo',
  //     desc: '100 AED discount',
  //     expDate: '31-12-2020',
  //     imgURL: 'assets/img/couponimg1.jpg',
  //     btnText: 'Shop Now'
  //   }, {
  //     id: 5,
  //     name: 'huawei phones',
  //     desc: 'Flat 25% off',
  //     expDate: '31-05-2020',
  //     imgURL: 'assets/img/huawei.jpg',
  //     btnText: 'Shop Now'
  //   }, {
  //     id: 6,
  //     name: 'Oppo phones',
  //     desc: 'AED 250 off',
  //     expDate: '31-08-2020',
  //     imgURL: 'assets/img/oppo-mobile-phones.jpg',
  //     btnText: 'Shop Now'
  //   }, {
  //     id: 7,
  //     name: 'Nike Shoes',
  //     desc: 'Flat 10% off',
  //     expDate: '25-05-2020',
  //     imgURL: 'assets/img/nike.jpg',
  //     btnText: 'Shop Now'
  //   }, {
  //     id: 8,
  //     name: 'Dell Laptops',
  //     desc: 'Flat 25% off',
  //     expDate: '31-05-2020',
  //     imgURL: 'assets/img/dell.jpg',
  //     btnText: 'Shop Now'
  //   },
  // ];

  public prodCard = [
    {
      id: 1,
      name: 'samsung S10',
      desc: ' A premium denim brand thats earned acclaim for its fine fits, fabrics and finishes.',
      expDate: '11-06-2020',
      imgURL: 'assets/img/samsung S10.png',
      btnText: 'Claim'
    }, {
      id: 2,
      name: 'adidas',
      desc: ' A premium denim brand thats earned acclaim for its fine fits, fabrics and finishes.',
      expDate: '20-08-2020',
      imgURL: 'assets/img/adidas.jpg',
      btnText: 'Shop Now'
    }, {
      id: 3,
      name: 'Gucci Bag',
      desc: ' A premium denim brand thats earned acclaim for its fine fits, fabrics and finishes. ',
      expDate: '19-08-2020',
      imgURL: 'assets/img/gucci.jpg',
      btnText: 'Shop Now'
    }, {
      id: 4,
      name: 'Gucci Deo',
      desc: 'A premium denim brand thats earned acclaim for its fine fits, fabrics and finishes.',
      expDate: '31-12-2020',
      imgURL: 'assets/img/couponimg1.jpg',
      btnText: 'Shop Now'
    }, {
      id: 5,
      name: 'huawei phones',
      desc: 'A premium denim brand thats earned acclaim for its fine fits, fabrics and finishes.',
      expDate: '31-05-2020',
      imgURL: 'assets/img/huawei.jpg',
      btnText: 'Shop Now'
    }
  ];
  public categories: any;

  constructor(
    public router: Router,
    private couponService: CouponServiceService,
    private service: EventEmitterService,
    private translate: TranslateService
  ) {
    this.translate.get("translations").subscribe((data) => {
      console.log(data);
      this.catgBtn = data.searchFilterCatBtn;
      this.brandBtn = data.searchFilterBrandBtn;
      this.listBtn = data.searchFilterListBtn;

    });
  }

  ngOnInit() {
    this.category = [
      {
        id: 1,
        cName: "Women's Fashion",
      }, {
        id: 2,
        cName: "Men's Fashion",
      }, {
        id: 3,
        cName: 'Jewelry',
      }, {
        id: 4,
        cName: 'Beauty',
      }, {
        id: 5,
        cName: 'Mobiles and Digital',
      }, {
        id: 6,
        cName: 'Eat Out',
      }, {
        id: 7,
        cName: 'Fun',
      }, {
        id: 8,
        cName: 'Grocery',
      }
    ];
    this.brand = [
      {
        id: 1,
        bName: 'Coach',
      }, {
        id: 2,
        bName: 'Zbra',
      }, {
        id: 3,
        bName: 'Clymb',
      }, {
        id: 4,
        bName: 'Stanza',
      }, {
        id: 5,
        bName: 'Giovani',
      }, {
        id: 6,
        bName: 'Jimmy Choo',
      }, {
        id: 7,
        bName: 'Jobb',
      }, {
        id: 8,
        bName: 'Gryyp',
      }, {
        id: 9,
        bName: 'Moncet',
      }, {
        id: 10,
        bName: 'Venyc',
      }, {
        id: 11,
        bName: 'Fiola',
      }, {
        id: 12,
        bName: 'Lilaq',
      }, {
        id: 13,
        bName: 'Samsung',
      }, {
        id: 14,
        bName: 'Apple',
      }
    ];
    this.viewList = [
      {
        id: 1,
        vName: 'Earnings (high to low) '
      },
      {
        id: 2,
        vName: 'Earnings (low to high  ) '
      },
      {
        id: 3,
        vName: 'Price (high to low) '
      },
      {
        id: 4,
        vName: 'Price (low to high  ) '
      },
    ];
    // console.log();
    this.getAllProduct();
    this.getAllCategory();
    this.getAllBrand();
  }
  getAllCategory() {
    this.couponService.getAllCategory().subscribe(data => {
      this.categories = data.categoryResponses;
    }, error => {
      console.log(error);
      alert('Some Error Occured');
    });
  }
  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScrollEvent(event) {
    // console.log(event);
    const data = window.scrollY;
    this.service.emitScrollEvent(data);
    // console.log(data);
    if (data > 49) {
      this.headerFix = true;
    } else {
      this.headerFix = false;
    }
  }
  getAllProduct() {
    this.couponService.getAllProduct().subscribe(data => {
      // console.log(data);
      this.coupons = data.productresponses;
      console.log('AllProduct List:', this.coupons);
    }, error => {
      console.log(error);
    });
  }
  getAllBrand() {
    this.couponService.getAllBrand().subscribe(data => {
      // console.log(data);
      this.brand = data.brandResponses;
      // console.log('AllProduct List:', this.coupons);
    }, error => {
      console.log(error);
    });
  }
  cardListDisplay() {
    console.log('comiujsadf');
    this.show = !this.show;
  }
  shopNow(card) {
    localStorage.clear();
    localStorage.setItem('offer', JSON.stringify(card));
    this.router.navigate(['/offer']);
  }
  selectCatg(data) {
    console.log(data);
    this.selectedCatg = data;
    this.catgBtn = data.catName;
    this.couponService.getProductByCategoryId(data.id).subscribe(
      res => {
        console.log(res);
        this.coupons = res.productresponse;
      }, err => {
        console.log(err);
      }
    );
  }
  selectBrand(data) {
    console.log(data);
    this.selectedBrand = data;
    this.brandBtn = data.name;
    this.couponService.getProductByBrandId(data.id).subscribe(
      res => {
        console.log(res);
        this.coupons = res.productresponse;
      }, err => {
        console.log(err);
      }
    );
  }
  selectList(data) {
    console.log(data);
    this.viewList = data;
    this.listBtn = data.vName;
  }
}

