<div class="wrapper survey">
  <div class="heading">
    <h3>Client Survey</h3>
  </div>
  <div class="add-img">
    <img src="assets/img/gucci.jpg" class="ad-img" alt="">
  </div>
  <p class="statement text-center">* Complete the survey to win exciting coupons</p>
  <p class="text-right mr-2 m-0">{{questionNo-1}}/{{questionsArray.length}}</p>
  <div class="card question-card ml-3 mr-3 rounded-lg border border-success">
    <div class="card-body">
      <div class="carousel slide" data-ride="carousel" data-interval="false">
        <div class="carousel-inner">
          <div class="carousel-item" [ngClass]="item.active?'active':'inactive'"
            *ngFor="let item of questionsArray; let i = index">
            <div class="row m-0">
              <!-- <div class="col-2 p-0 sl-no">Q {{item.id}}.</div> -->
              <div class="col-12 p-0 qstn pr-0">{{item.ques}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row ml-3 mr-3 mt-4">
    <div class="col-6 pl-0">
      <button type="button" class="btn btn-danger w-100" (click)="nextQuestion()">No</button>
    </div>
    <div class="col-6 pr-0">
      <button type="button" class="btn btn-success w-100" (click)="nextQuestion()">Yes</button>
    </div>
  </div>
</div>
