<!-- <app-header></app-header>
<div [ngClass]="headerFix?'earn-header':''">
  <app-earn-header [showLogo]="headerFix"></app-earn-header>
</div> -->
<app-header></app-header>
<div class="search row">

    <!-- <div class="col-10">
        <input type="text" placeholder="Search Place10x.com">
    </div>

    <div class="col-2">
        <a> <img class="menuIcon2" src="../../../assets/search.jpeg" alt=""></a>
    </div> -->

</div>

<div class="bg" [ngStyle]="{'margin-top': headerFix?'55px':'0'}">

    <div class="headers">
        <div class="overflow">
            <div class="card">
                <div class="row row1">
                    <div class="col-12 disply1">
                        <button type="button" class="btnClr" data-toggle="modal" data-target="#categoryModal">
                            {{catgBtn}}&nbsp;&nbsp;
                            <!-- {{catgBtn}}&nbsp;&nbsp;<i class="fa fa-plus plusIcon1" aria-hidden="true"></i> -->
                                <!-- <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
                                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
                                </svg> -->
                        </button>
                        <button type="button" class="btnClr" data-toggle="modal" data-target="#brandModal">
                            {{brandBtn}}&nbsp;&nbsp;
                            <!-- {{brandBtn}}&nbsp;&nbsp;<i class="fa fa-plus plusIcon2" aria-hidden="true"></i> -->

                            
                        </button>
                        <button type="button" class="btnClr" data-toggle="modal" data-target="#viewListModal">
                            {{listBtn}}&nbsp;&nbsp;

                            <!-- {{listBtn}}&nbsp;&nbsp;<i class="fa fa-sliders plusIcon3" aria-hidden="true"></i> -->
                            
                        </button>
                    </div>

                </div>
            </div>

            <div class="modal categoryModal" id="categoryModal">
                <div class="modal-dialog modalDia">
                    <div class="bg"></div>
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">{{ "translations.catMenu" | translate }}</h4>
                            <button type="button" class="close clsbtn btn-light p-2"
                                data-dismiss="modal">&times;</button>
                        </div>
                        <!-- Modal body -->
                        <div class="modal-body">
                            <div class="search-div">


                            </div>
                            <ul class="list-unstyled" *ngFor="let item of categories">
                                <li class="list-group-item borderT" (click)="selectCatg(item)" data-dismiss="modal">
                                    {{item.categoryName}}</li>
                            </ul>
                        </div>


                    </div>
                </div>
            </div>

            <div class="modal brandModal" id="brandModal">
                <div class="modal-dialog modalDia">
                    <div class="bg"></div>
                    <div class="modal-content">
                        <!-- Modal Header -->
                        <div class="modal-header">
                            <h4 class="modal-title">{{ "translations.brandMenu" | translate }}</h4>
                            <!-- <h4 class="modal-title">Brand</h4> -->
                            <button type="button" class="close clsbtn btn-light p-2"
                                data-dismiss="modal">&times;</button>
                        </div>

                        <!-- Modal body -->
                        <div class="modal-body">
                            <div class="search-div">


                            </div>
                            <ul class="list-unstyled" *ngFor="let item of brand ">
                                <li class="list-group-item borderT" (click)="selectBrand(item)" data-dismiss="modal">
                                    {{item.brandName}}</li>
                            </ul>
                        </div>



                    </div>
                </div>
            </div>


            <div class="modal viewListModal" id="viewListModal">
                <div class="modal-dialog modalDia">
                    <div class="bg"></div>
                    <div class="modal-content">
                        <!-- Modal Header -->
                        <div class="modal-header">
                            <!-- <h4 class="modal-title">Sort</h4> -->
                            <h4 class="modal-title">{{ "translations.sortMenu" | translate }}</h4>
                            <button type="button" class="close clsbtn btn-light p-2"
                                data-dismiss="modal">&times;</button>
                        </div>

                        <div class="modal-body">

                            <ul class="list-unstyled" *ngFor="let item of viewList">
                                <li class="list-group-item borderT" (click)="selectList(item)" data-dismiss="modal">
                                    {{item.vName}}</li>
                            </ul>
                        </div>


                    </div>
                </div>
            </div>


            <ng-container>

                <app-tiles-templatep [campaignData]="coupons"></app-tiles-templatep>
            </ng-container>

        </div>

    </div>
</div>