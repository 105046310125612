<div class="deskBack">
  <img class="backImg" src="assets/img/backDubai2.jpg">
  <div class="headers">
    <!-- <app-header></app-header> -->
    <div class="card cardiv pt-4 pl pr marge1">
      <div class="mb-0">
        <p class="pSign mb-0">Share on Mobile</p>
      </div>
      <div class="formStyl pt-3">
     
        <div>
          <label class="text-uppercase input-label" for="phNo">Mobile Number<span class="">*</span>
          </label>
        
          <div class="row m-0 mobilePart">
            <a class="col-4 part_1 p-2" (click)="showCountryModal=true" data-toggle="modal"
              data-target="#countryDetails">
              <span class="pl-2 country-code"> + {{selectedCountry.CountryCode}}</span>&nbsp;
              <span class="drop-down">&#709;</span>
            
            </a>
            <div class="col-1 p-0"></div>
            <div class="col-7 part_2 p-2">
              <input class="mobile pl-2 ml-1" [(ngModel)]="mobileNo" autocomplete="off" placeholder="50 123 4567"
                (keyup)="validateNumber()" type="number" min="10" max="15"  autocomplete="off">
            </div>
           <!-- <span class="m-0 text-primary sendOtp" (click)="!disableSendOTP && sendOTP()">Send OTP</span>-->
          </div>
        </div>

        <div class="star mb-4">
          
        </div>
   
        <div class="row m-0">
          <button class="loginBtn" (click)="!isDisableShare && shareCoupon()" [disabled]="isDisableShare">
            <p class="lockP m-0">Share
              <!-- <i class="fa fa-lock lockIcon" aria-hidden="true"></i> -->
            </p>
          </button>
          <!-- <button class="btn"><i class="fa fa-lock lockIcon"></i> Home</button> -->
        </div>
        </div>
        
      <div class="modal countryModal" id="countryDetails" *ngIf="showCountryModal">
        <div class="modal-dialog m-0">
          <div class="bg"></div>
          <div class="modal-content">
            <!-- Modal Header -->
            <div class="modal-header">
              <h4 class="modal-title">Select Country</h4>
              <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
              <ul class="list-unstyled">
                <li class="mt-2 p-2 border" *ngFor="let item of country" (click)="clickCountry(item)"
                  data-dismiss="modal">
                  <div class="row m-0 text-center">
                    <div class="col-2 p-0">
                      <img [src]="item.CountryIcon" alt="">
                    </div>
                    <div class="col-6 p-0">
                      {{item.Country}}
                    </div>
                    <div class="col-4 p-0">
                      + {{item.CountryCode}}
                    </div>
                  </div>
                </li>
              </ul>
            </div>

            <!-- Modal footer -->
            <div class="modal-footer">
              <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
            </div>

          </div>
        </div>
      </div>  
        </div>
        </div>
        </div>

        