import { Component, OnInit, Input, HostListener, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CouponServiceService } from 'src/app/services/coupon/coupon-service.service';


@Component({
  selector: 'app-tiles-templatep',
  templateUrl: './tiles-templatep.component.html',
  styleUrls: ['./tiles-templatep.component.scss']
})
export class TilesTemplatepComponent implements OnInit {


  @Input() campaignData: any;
  @ViewChild('scrollLock')
  scrollLock!: ElementRef;
  public height = '30vh';
  public shopBtnText: string[] = [];
  public isShopBtnEnabled: boolean[] = [];
  public isSubProdDescToggleOn: boolean[] = [];
  private startTime = new Date();
  navigator = window.navigator as any;
  public couponCode = "WWWWWyyy";
  public claimCard: any;
  public claimModalOpen: boolean = false;
  public isClaimButton: boolean = true;
  public showCode: boolean = false;
  public shareModal: boolean = false;
  public showMoreText: boolean = true;
  public desc = "BY PROVIDING THIS MOBILE NUMBER YOU CONFIRM YOU HAVE THE RECIPIENTS APPROVAL TO SHARE THE SAME."
  imageUrl: string = "";
  public isaddButton: boolean = false;
  isDisable = false;
  card: any;
  public tempcard;
  addedToWallet: boolean;


  constructor(public router: Router, private toastr: ToastrService, private couponService: CouponServiceService,) { }

  ngOnInit(): void {
    let mobileNo = localStorage.getItem('mobNo');
    // const url = this.campaignData
    // url.forEach((data:any)=>{
    //   const newData = data.campaignId
    //   console.log(newData);
    //   this.imageUrl = newData;
    //   console.log(this.imageUrl);

    // })


    const data = window.scrollY;
    console.log("Scrollinggggggg", data);
    let startTime = new Date();
    // this.setDefaultValuesForProductElem(this.campaignData.length);
    let endTime = new Date();
    this.campaignData.forEach((element: any) => {

    });

  }

  public addtoWalletBtn(card) {
    let mob = localStorage.getItem('mobNo');
    if (mob == null || mob == '') {
      this.router.navigate(['login']);
    } else {
      this.addtoWallet(card);
    }

  }
  public addtoWallet(card) {
    let mobileNo = localStorage.getItem('mobNo');
    const data = {

      mobileNo: localStorage.getItem('mobNo'),
      productId: card.productId,
      offerId: card.offerId,
      campaignId:card.campaignId
    };
    this.couponService.addToWallet1(data).subscribe(result => {
      this.addedToWallet = true;
      console.log('AddToWallet Details: ', result);
      this.toastr.success("Added", "Successfull", { timeOut: 1600 });
      card.isaddButton = !card.isaddButton;

      // this.AddToWallet = result.reponseList;
      // console.log('AddToWallet issss .......', result.reponseList);
    }, error => {
      //console.log(error);
      this.toastr.error(error.error.statusDesc, "error");
    });
  }











  walletBtnclicked(card: any) {
    // this.toastr.success('Added to your STARs!', 'Added');
    this.addtoWalletBtn(card);

    // this.addtoWalletBtn(card.productId)
    // window.alert(`Product added to star`);

    // this.router.navigate(["../wallet"]);


  }
  walletBtnclickedAgain(card: any) {
    // this.toastr.warning('Removed from your STARs!', 'Removed');

    // if (confirm("Are u sure u want to remove that from your star!")) {
    //   this.toastr.warning('Removed from your STARs!', 'Removed');
    //   card.isaddButton=true;
    // } else {  

    // }
    card.isaddButton = true;


  }





  clBtnzClicked(claimCard: any) {

    this.isClaimButton = !this.isClaimButton;

  }
  claimBTNClicked(card: { id: any; }) {
    localStorage.setItem("cardDetails", JSON.stringify(card));
    localStorage.removeItem("subProdDetails");
    this.router.navigate(["/offer"], { queryParams: { id: card.id } });

    this.claimModalOpen = true;
    console.log("temppppppp", card);
    this.claimCard = card;
  }
  claimsubproductBTNClicked(pcard: { id: any; }) {
    localStorage.setItem("subProdDetails", JSON.stringify(pcard));
    localStorage.removeItem("cardDetails");
    this.router.navigate(["/offer"], { queryParams: { id: pcard.id } });
  }
  clBtnzClickedAgain(card: any) {
    this.showCode = true;

  }
  claimModalDismiss() {
    this.showCode = false;
    this.isClaimButton = true;
  }

  @HostListener("window:scroll", [])
  onScroll(): void {
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {

    }
  }
  onScroll1(event: any) {
    // console.log('scrolling');
  }
  clickMoreText() {
    this.desc = '';
    this.showMoreText = !this.showMoreText
    if (!this.showMoreText) {
      this.desc = "BY PROVIDING THIS MOBILE NUMBER YOU CONFIRM YOU HAVE THE RECIPIENTS APPROVAL TO SHARE THE SAME. BY ACCEPTING OUR TOS YOU AGREE TO HAVE READ AND UNDERSTAND HOW THE SERVICE IS PROVIDED."
    } else {
      this.desc = "BY PROVIDING THIS MOBILE NUMBER YOU CONFIRM YOU HAVE THE RECIPIENTS APPROVAL TO SHARE THE SAME."
    }
  }
  shareApion(card) {
    console.log(card, "card first");
    this.tempcard = card;


  }
  shareApi(card) {
    // let endTime = new Date();
    console.log(card, "share card");
    console.log(this.tempcard, "temp in share");



    // Shop button clicked
    // this.shopBtnText[index] = "Earn AED";
    // let userActivity: Logging[] = [];
    // let campaignId = card.campainMgmtId;
    // userActivity.push({
    //   advId: '',
    //   longitute:localStorage.getItem("Longitude"),
    //   latitude:localStorage.getItem("Latitude"),
    //   campaignId: JSON.stringify(card.campainMgmtId),
    //   categoryId:JSON.stringify(card.catId),
    //   productId: JSON.stringify(card.productId),
    //   subProductId: '',
    //   userId: localStorage.getItem("userId"),
    //   // userAction: UserActivity[UserActivity.USER_SHARED],
    //   startTime: this.startTime.getTime(),
    //   endTime:  endTime.getTime(),
    //   duration: endTime.getTime() - this.startTime.getTime()
    // });
    // console.log("UserActivity",userActivity);



    // this.loggingService.saveUserActivity(userActivity).subscribe();



    if (this.navigator.share) {
      this.navigator
        .share({
          title: "place10x.com",
          url: "https://place10x.com/#/home" + "/product/" + this.tempcard.productId,

          text:
            "Hey i found this interesting offer.Visit this link to check the offer"
        })
        .then(() => {
          console.log("Thanks for sharing!");
        })
        .catch(console.error);
    } else {

      this.toastr.error("", "Something went wrong", { timeOut: 1600 });
    }
  }
  public renderImage(categoryId: number): string {
    return this.couponService.getImageprod(categoryId);
  }
}
