import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-login-header',
  templateUrl: './login-header.component.html',
  styleUrls: ['./login-header.component.scss']
})
export class LoginHeaderComponent implements OnInit {

  public loginActive = true;
  public registerActive = false;

  constructor(public route: Router,private location:Location) {
    console.log(route.url.split('/'));
    route.events.subscribe((val) => {
      // see also
      // console.log(val instanceof NavigationEnd);
      this.routingChanges();
    });
  }

  ngOnInit() {
  }
  routingChanges() {
    const path = this.route.url.split('/')[2];
    if (path === 'register') {
      this.registerClick();
    } else {
      this.loginClick();
    }
  }
  registerClick() {
    this.loginActive = false;
    this.registerActive = true;
  }
  loginClick() {
    this.loginActive = true;
    this.registerActive = false;
  }
  buttonClosedG () {
    this.location.back();
  }
}
