import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-survey-new',
  templateUrl: './survey-new.component.html',
  styleUrls: ['./survey-new.component.scss']
})
export class SurveyNewComponent implements OnInit {

  public questionsArray = [
    { id: 1, ques: 'Have you checked out our new collection at the Dubai Mall store?', active: true },
    { id: 2, ques: 'Did you know Gucci is the most followed brand in the Arab world?', active: false },
    { id: 3, ques: 'Would you recommend Gucci to your friends?', active: false },
  ];
  public questionNo = 2;
  constructor(
    public router: Router,
  ) { }

  ngOnInit() {
  }
  nextQuestion() {
    // document.getElementById('qstnCarousel').hide("slide", { direction: "left" }, 1000);
    // this.dataInterval = true;
    if (this.questionNo <= this.questionsArray.length) {
      for (const iterator of this.questionsArray) {
        if (iterator.id === this.questionNo) {
          iterator.active = true;
        } else {
          iterator.active = false;
        }
      }
      // this.question = this.questionsArray[this.questionNo];
      this.questionNo++;
    } else {
      this.router.navigate(['/offer']);
    }
  }

}
