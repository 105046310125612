<div class="dashboard-header">
  <app-header ></app-header>
</div>

<!-- <div [ngClass]="headerFix ? 'earn-header' : ''">
  <app-earn-header [showLogo]="headerFix"></app-earn-header>
</div> -->
<div class="bg">
    <!-- <div class="shadow bg-white">
  
    </div> -->
    <div class="headers">
      <!-- <div class="scrollmenu">
  
        <a (click)="openHome()">New</a>
        <a (click)="openHome2()">Deal</a>
        <a (click)="openHome3()">Trending</a>
  
      </div>
      <div class="scrollmenu1">
        <div class="acnleft1">
          <a (click)="openHome4()">
            <img class="menuIconStyl" src="../../../assets/menuicon1.png" alt="">
  
  
       
          </a>
        </div>
  
        <div class="acnleft">
          <a (click)="openHome()">New</a>
        </div>
  
        <div class="acn"> <a (click)="openHome2()">Deal</a></div>
  
        <div class="acnright">
          <a (click)="openHome3()">Trending</a>
        </div>
  
  
      </div>
  
      <div class="menu-box">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-12 col-lg-12 col-md-12">
              <div class="left-menu">
                <a (click)="toggleMenu()"> <img class="menuIconStyl" *ngIf="!menuToggle" src="assets/img/menuicon.svg"
                    alt=""></a>
  
                <i (click)="toggleMenu()" *ngIf="menuToggle" class="fa fa-times menuIconStyl1" aria-hidden="true"></i>
  
                <a style="cursor: pointer;" (click)="openHome()">New</a>
                <a style="cursor: pointer;" (click)="openHome2()">Deal</a>
                <a style="cursor: pointer;" (click)="openHome3()">Trending</a>
  
              </div>
  
              <div class="right-box-menu">
                <ul>
              
  
                  <li>
  
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- <div *ngIf="menuToggle" class="search-div">
        <ul class="list-unstyled">
          <li class="list-group-item bgClr" *ngFor="let item of menuList" [routerLink]="[item.url]"
            routerLinkActive="router-link-active">
            {{ item.mName }}<i class="fa fa-chevron-right iconRight"></i>
          </li>
  
        </ul>
        <div class="modal countryModal" id="countryDetails" *ngIf="showCountryModal">
          <div class="modal-dialog m-0">
            <div class="bg"></div>
            <div class="modal-content">
  
              <div class="modal-header">
                <h4 class="modal-title">Select Language</h4>
                <button type="button" class="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
  
  
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <app-lowhead></app-lowhead>
  
  
      <div class="container-fluid ">
        <div class="headers ">
          <div class="cat-product ">
            <div class="row ">
              <div class="col-lg-3 col-sm-12 con" *ngFor="let pcard of scrollCard">
                <div class="card card2" (click)="openHomee(pcard.categoryId)">
  
                  <div class="image-box ">
                    <img [src]="renderImage(pcard.categoryId)" class="imgSc1 img-thumbnail" />
  
  
                  </div>
                  <p class="ab">{{pcard.name}}</p>
                  <h3 class="pStyl ">
                    {{ pcard.categoryName }}
                    <i class="fa fa-arrow-circle-o-right" aria-hidden="true"></i>
                  </h3>
                </div>
              </div>
  
            </div>
  
          </div>
        </div>
  
      </div>
      <!--     
      <app-footer (LanguageChanged)="langchanged($event)"></app-footer> -->
  
    </div>
  
  
  </div>
  <click-to-connect ></click-to-connect>