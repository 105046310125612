import { Component, OnInit } from "@angular/core";
import { MenuList } from "src/app/data/data";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { AppConstants } from "src/app/app.constants";
@Component({
  selector: "app-search-page",
  templateUrl: "./search-page.component.html",
  styleUrls: ["./search-page.component.scss"],
})
export class SearchPageComponent implements OnInit {
  public menuList: MenuList[];
  public catMenu: string;
  public brandMenu: string;
  public howItWorkMenu: string;
  public register: string;
  public showCountryModal: boolean;
  public languages = AppConstants.LANGUAGES;
  public showScan:boolean;
  constructor(private translate: TranslateService, public router: Router) {
    console.log("lang" + this.translate.currentLang);
    this.translate.get("translations").subscribe((data) => {
      console.log(data);

      //this.catMenu = data.catMenu;
      this.brandMenu = data.brandMenu;
      this.howItWorkMenu = data.howItWorkMenu;
      this.register = data.register;
      this.setMenu();
    });
  }

  ngOnInit() { }
  setMenu() {
    this.menuList = [
      /*{
        id: 1,
        mName: this.catMenu,
        url: "/search",
      },*/
      {
        id: 2,
        mName: this.brandMenu,
        url: "/search",
      },
      {
        id: 3,
        mName: this.howItWorkMenu,
        url: "/",
      },
      {
        id: 4,
        mName: this.register,
        url: "/login/register",
      },
    ];
    console.log(this.menuList);
  }
  changeLanguage(item) {
    this.translate.use(item.frontendCode);
    localStorage.setItem('language', item.frontendCode);
    this.reloadCurrentRoute();
  }
  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }
  scan()
  {
    this.showScan=!this.showScan;
   
  }
}
