<div class="search-div">
  <ul class="list-unstyled">
    <li
      class="list-group-item bgClr"
      *ngFor="let item of menuList"
      [routerLink]="[item.url]"
      routerLinkActive="router-link-active"
    >
      {{ item.mName }}<i class="fa fa-chevron-right iconRight"></i>
    </li>
    <li
      class="list-group-item bgClr"
      (click)="showCountryModal = true"
      data-toggle="modal"
      data-target="#countryDetails"
    >
      {{ "translations.changeLanguageText" | translate
      }}<i class="fa fa-chevron-right iconRight"></i>
    </li>
    <li class="list-group-item bgClr" (click)="scan()"><i *ngIf="!showScan" ></i>Scan Qr Code
    <app-scanner *ngIf="showScan"></app-scanner> </li>
  </ul>
  <div class="modal countryModal" id="countryDetails" *ngIf="showCountryModal">
    <div class="modal-dialog m-0">
      <div class="bg"></div>
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Select Language</h4>
          <button type="button" class="close" data-dismiss="modal">
            &times;
          </button>
        </div>

        <!-- Modal body -->
        <div class="modal-body">
          <ul class="list-unstyled">
            <li
              class="mt-2 p-2 border"
              *ngFor="let item of languages"
              (click)="changeLanguage(item)"
              data-dismiss="modal"
            >
              <div class="row m-0 text-center">
                <div class="col-4 p-0">{{ item.displayText }}</div>
              </div>
            </li>
          </ul>
        </div>

        <!-- Modal footer -->
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
