<div class="row tabs m-0">
  <!-- <div class="col-1 p-2"></div> -->
  <div class="p-3 pl">
    <span class="tab-btn p-0" [ngClass]="loginActive?'activated':''" [routerLink]="['./login']"
      (click)="loginClick()">Sign In</span>
    <!-- <span class="tab-btn p-0 ml-3" [ngClass]="loginActive?'activated':''" [routerLink]="['./login']"
  (click)="loginClick()">Sign In</span> -->
  </div>
  <div class="pt-3">
    <span class="tab-btn p-0" [ngClass]="registerActive?'activated':''" [routerLink]="['./register']"
      (click)="registerClick()">Register</span>
  </div>
  <div class="light pt-2 pb-2" (click)="buttonClosedG()">
    <span class="tab-btn tab-btn-close p-0">×</span>
    <!-- <span class="tab-btn text-dark p-0" style="font-family: Gill Sans MT;">x</span> -->
  </div>
  <!-- <div class="light pt-3" [routerLink]="['../home']">
    <span class="tab-btn cross p-0">X
    </span>
  </div> -->
</div>
<router-outlet></router-outlet>