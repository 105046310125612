import { Component, OnInit } from '@angular/core';
import { CouponServiceService } from 'src/app/services/coupon/coupon-service.service';
// import * as $ from 'jquery';
declare var $: any;

@Component({
  selector: 'app-prodscroll',
  templateUrl: './prodscroll.component.html',
  styleUrls: ['./prodscroll.component.scss']
})
export class ProdscrollComponent implements OnInit {

  constructor(
    private couponService: CouponServiceService,
  ) {
  }

  public coupons = [
    {
      id: 1,
      name: 'galaxyZ',
      desc: '125 AED discount',
      expDate: '11-06-2020',
      imgURL: 'assets/img/galaxyZ.png',
      btnText: 'Earn ',
      amt: 'AED 300!'
    },
    {
      id: 2,
      name: 'Zbra',
      desc: '125 AED discount',
      expDate: '11-06-2020',
      imgURL: 'assets/img/zbra.png',
      btnText: 'Earn ',
      amt: 'AED 300!'
    },
    {
      id: 3,
      name: 'ABMH',
      desc: 'Flat 25% off',
      expDate: '11-06-2020',
      imgURL: 'assets/img/abmh.png',
      btnText: 'Shop Now',
      amt: ''
    },
    {
      id: 4,
      name: 'Jimmy Choo Shoes',
      desc: 'Flat 25% off',
      expDate: '31-09-2020',
      imgURL: 'assets/img/jimmy.jpg',
      btnText: 'Shop Now',
      amt: ''
    },
    {
      id: 5,
      name: 'Jobb',
      desc: 'Flat 25% off',
      expDate: '11-06-2020',
      imgURL: 'assets/img/jobb.jpg',
      btnText: 'Shop Now',
      amt: ''
    }
  ];
  ngOnInit() {
      // $('#myCarousel').carousel({
      // });
      this.getBanner();
    // $('#myCarousel').carousel();
    // ($('div.carousel') as any).carousel();
    // var myCarousel = document.querySelector('#myCarousel')
    // var carousel = new carousel(myCarousel, {
    //   interval: 2000,
    //   wrap: false
    // })
  }

  getBanner() {
    this.couponService.getBanner().subscribe(data => {
      console.log(data);
    }, error => {
      console.log(error);
    });
  }

}
