
<div class="card coupon-card" [ngStyle]="backCss">
  <!-- <img class="card-img-top" src="assets/img/background.png" alt="Card image cap"> -->
  <div class="card-body">
    <!-- <h5 class="card-title">Card title</h5> -->
    <div class="row">
      <div class="col-6">
        <img class="gift" src="assets/img/Galaxy_Gifts.png" alt="">
      </div>
      <div class="col-6">
        <img class="client-logo" src="assets/img/samsung_logo.png" alt="">
      </div>
    </div>
    <div class="row">
      <p class="card-text col-6 text-uppercase text-white p-2">Enjoy exclusive premium offers from popular and award
        winning brands
      </p>
      <div class="col-6  p-2" *ngIf="showCodeDiv">
        <div class="cupDigits"><p class="text-center">6766<span *ngIf="!showCode">*****</span><span *ngIf="showCode">87542</span></p></div>
      </div>
    </div>

    <!-- <a href="#" class="btn btn-primary">Go somewhere</a> -->
    <div class="row text-white m-0">
      <div class="col-5 p-0">
        <div class="row m-0">
          <div class="col-4 p-0">Valid From</div>
          <div class="col-8 p-0 justify-content-center align-self-center">01/20</div>
        </div>
      </div>
      <div class="col-5 p-0">
        <div class="row m-0">
          <div class="col-4 p-0">Valid To</div>
          <div class="col-8 p-0 justify-content-center align-self-center">09/20</div>
        </div>
      </div>
      <div class="col-2 p-0">
        <img src="assets/img/snapti_10X_logo.png" class="snapit-logo" alt="">
      </div>
    </div>
  </div>
</div>
<!-- <app-offers-on></app-offers-on> -->