<div class="dashboard-header">
  <app-headers></app-headers>
</div>
<div [ngClass]="headerFix?'earn-header':''">
  <app-earn-header [showLogo]="headerFix"></app-earn-header>
</div>
<div *ngIf="coupons.length==0" class="empDiv">
  <!-- <div class="empDiv"> -->
  <img class="emptCart" src="assets/img/wish.png" alt="">
  <div class="noData">Your wallet is empty!</div>
  <div class="noData1">Add items you want to shop.</div>
  <button class="shopBtn" [routerLink]="['/category']">Shop Now</button>
</div>
<!-- <div class="justify-content-center ml-2 mt-2 contentr"  *ngFor="let data of coupons">
  <div class="flip-card" >
    <div class="flip-card-inner">
      <div class="flip-card-front">
        <img style="float: left;" class="prod1" [src]="getImage(data.imgSrc,'campaign')" onerror="this.src='assets/img/default-image.png';" height="100%" alt="" />
        <h6 class="card-text mt-2">{{data.subProductName}}-{{data.subProductOfferPercent}}</h6>
    
        <p class="card-text">Validity:{{data.orderTime}}</p>
      </div>
      <div class="flip-card-back">
        <div class="btnlist mt-2">
          <button class="btn" [routerLink]="['/redeem']">Redeem</button>
          <button class="btn" (click)="shareApi($event)">Share</button>
          <button class="btn" (click)="saveLater()">Save for Later</button>
        </div>
        
      </div>
    </div>
  </div>
 
</div> -->
 
  <!-- <div class="card" >
    <h6 class="card-text">20% Off</h6>
    
    <p class="card-text">Validity:{{ValidDate}}</p>
  </div> -->
<!-- <app-product-card [firstValue]="value" [coupons]="coupons"></app-product-card> -->
<!-- <hr *ngIf="couponList.length!=0 && coupons.length!=0" class="dotted"> -->
<!-- <app-product-card [couponList]="couponList"></app-product-card> -->
<!-- <app-product-card [coupons]="couponList"></app-product-card> -->
<!-- <click-to-connect></click-to-connect> -->
<app-tiles-template [campaignData]="coupons"></app-tiles-template>
