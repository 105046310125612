<!-- <UPPER MENU BAR> -->
<div class="headers">
  <div class="header col-12">
    <div class="row">
      
        <ul class="nav navbar-expand-xl nav1">
          <li class="nav-item">
            <a > <img class="menuIconStyl" src="assets/img/menuicon.png" alt=""></a>
          </li>
            <img class="wifi10xLogo" src="assets/img/logo10x.png" alt="">
            <div class="p-1 bg-light rounded rounded-pill shadow-sm m-1 searchDiv">
              <div class="input-group">
                <input type="search" placeholder="Search Here?" aria-describedby="button-addon1" class="form-control border-0 bg-light margnT">
                <div class="input-group-append">
                  <button id="button-addon1" type="submit" [routerLink]="['/search']"  routerLinkActive="router-link-active" class="btn btn-link text-primary"> <img class="menuIcon" src="assets/img/search.png" alt=""></button>
                </div>
              </div>
            </div>
            <li class="nav-item">
                <a > <img class="menuIcon2" src="assets/img/user1.png" alt=""></a>
            </li>
            <li class="nav-item">
              <a > <img class="menuIcon2" src="assets/img/cart.png" alt=""></a>
            </li>
            <li class="nav-item">
              <a ><img class="menuIcon2" src="assets/img/wallet.png" alt=""></a>
            </li>
          </ul>
      
    </div>
    
    <div class="row rowClr">
      <div class="col m-1 ">
        <div class="card rounded-0 cardStyl1">
          <div class="card-body p-1">
            <h5 class="card-title text-center mb-0 p-2 text-uppercase cardText">Earned:<span class="text-uppercase">&nbsp;aed 200</span></h5>
        </div>
        </div>
      </div>
      <div class="col m-1 ">
        <div class="card rounded-0 cardStyl1">
          <div class="card-body p-1">
            <h5 class="card-title text-center mb-0 p-2 text-uppercase cardText">May 2020</h5>
        </div>
        </div>
      </div>
      <div class="col m-1 ">
        <div class="card rounded-0 cardStyl1">
          <div class="card-body p-1">
            <h5 class="card-title text-center mb-0 p-2 text-uppercase cardText">Claims Pending:<span class="text-uppercase">&nbsp;aed 400</span></h5>
        </div>
        </div>
      </div>
    </div>

  </div>
</div>
<!-- <UPPER MENU BAR ENDS> -->

<!-- <UPPER SHOPPING BANNER> -->
<header class="masthead">
  <div class="container h-100">
    <div class="row h-100 align-items-center">
      <div class="col-12 text-left">
        <h1 class="font-weight-bold">Claim. Shop.</h1>
        <p class="lead">Flat 25% Off</p>
      </div>
    </div>
  </div>
</header>
<!-- <UPPER SHOPPING BANNER ENDS> -->

<!-- <BODY> -->
<div class="container">
  <p class="styl1">Exclusive Offers</p>

  <div class="row">
    <div class="col-3" *ngFor="let card of coupons">
      <div class="card card1" >
        <div class="field-image">
          <img class="imgStyl" [src]="card.imgURL" alt="image" data-variantfieldname="Image">
        </div>
        <h6 class="nameStyl">{{card.name}}</h6>
        <p class="fontBlack"> {{card.desc}}</p>
        <p class="fontBlack"> Expiry Date: {{card.expDate}}</p>
        <button class="btnName" [ngClass]="card.btnText=='Claim'?'claim-btn':''"
        (click)="shopNow(card)">{{card.btnText}}</button>
      </div>
    </div>

  </div>
</div>
<!-- <BODY ENDS> -->
