import { Component, OnInit, Input, ViewChild, HostListener } from '@angular/core';
import { CouponServiceService } from 'src/app/services/coupon/coupon-service.service';

import { OfferService } from 'src/app/services/offer/offer.service';
import { CartService } from 'src/app/services/cart/cart.service';

import { Location } from '@angular/common';
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-offer-details-new',
  templateUrl: './offer-details-new.component.html',
  styleUrls: ['./offer-details-new.component.scss']
})
export class OfferDetailsNewComponent implements OnInit {

  addedToWallet:boolean=false;
  public subId:any;
  public imgFile:any;
  otp!: string;
  otpShow=false;
  actv4=true;
  pin!: string;
  public data!: string;
  applogcon=true;
  actv1: boolean = true;
  actv2: boolean = true;
  qrResult: boolean = false;
  mobNo!: number;
  showOtpComponent = true;
  claim = false;
  public headerFix = false;
  isDisable = false;
  isDisableClaim = true;
  public cupDiv = true;
  public claimCode = true;
  public showCode: any = false;
  public imgURL = 'assets/img/galaxyZ.png';
  public paramData:any;
  @Input()
  ClaimCode: boolean = false;
  @ViewChild('ngOtpInput', { static: true }) ngOtpInputRef: any;
  config = {
    allowNumbersOnly: true,
    length: 4,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    inputStyles: {
      width: '13vw',
      height: '13vw',
      'border-radius': '1px solid #c5c5c5',
      border: '0',
      margin: '3% 0% 3% 0%',
      outline: '1px solid #bbbbbb',
      'text-align': 'center',
      'font-size': '17px',
      'background-color': 'rgba(236, 236, 236, 0.59)'
    }
  };
  public offerDetails =
  ['Get 6 months of add-free youTube and YouTube premium Trail. ',
    'Offer is not applicable for existing YouTube  premium Trail user.',
    'Check your subscribtion deatils for YouTube premuium at https://www.youtube.com/paid_memberships',
    'This offer can not be clubbed with any othe ongoing offer discount/cash back promotion.',
    'Reedem the offer within 15 days of claim.'];
public note =
  ['To use the offer on youtube customer need to mandatory update credit/detit cards details ',
 

    'Restricted debit/credit cards that have reccuring payment options allowed for the offer'];

coupon: any;
public couponCode= "WIF222U5793";
public cardDetails:any;
public isClaimButton:boolean = true;
public afClaimButton:boolean = false;

  constructor(  
    private couponService: CouponServiceService,
    private offerService: OfferService,
    private toastr:ToastrService,
    private cartService: CartService,public router: Router,   private route:ActivatedRoute,
    private location:Location) { }

  ngOnInit() {
    if (localStorage.getItem("cardDetails")){
      this.cardDetails = JSON.parse(localStorage.getItem("cardDetails"));
    } else {
      this.cardDetails = JSON.parse(localStorage.getItem("subProdDetails"));
    }
    // this.mobNo= +(localStorage.getItem("mobNo"));
    console.log(this.mobNo,"mob in offffer");
    
    

    console.log(this.cardDetails,"card");
    this.imgFile = this.cardDetails["imgSrc"];
    this.data = JSON.parse(localStorage.getItem('subProdDetails'));
    this.couponCode=this.cardDetails.offerCode;
    
  }
  clBtnzClickedAgainAgain(_card: any) {
    this.isDisable =true;
    this.afClaimButton=true;

    
  }
  clBtnzClicked(card: any) {
    console.log(card,"card clicked");
    
    let mob=localStorage.getItem('mobNo');
    if(mob==null || mob=='')
   {
     this.router.navigate(['login']);
   } else {
    this.isClaimButton= !this.isClaimButton;
   }
    // this.isClaimButton= !this.isClaimButton;
    // this.toastr.warning("Please click once more to reconfirm .Please note  Once you reconfirmed the offer will be considered redeemed and will be removed from your Selction")
  }
  clBtnzClickedAgain(card: any) {
    // this.showCode = true;
    this.isDisable =true;
    this.isClaimButton=true;
    // this.afClaimButton=true;
    this.addtoWalletBtn(card);
  }

  addtoWalletBtn(card:any) {
    let mob=localStorage.getItem('mobNo');
    if(mob==null || mob=='')
   {
     this.router.navigate(['login']);
   } else {
    this.addtoWallet(card);
   }
   
  }
  public addtoWallet(card) {
    const data = {
     /*  id: 1,
      userId: '1',
      productId: 1,
      quantity: 145 */
   
   
      mobileNo:localStorage.getItem('mobNo'),
      productId: card.productId,
      offerId: card.offerId,
      campaignId:card.campaignId
    };
    this.couponService.claimcoupon(data).subscribe(result => {
      // this.addedToWallet=true;
      console.log(' Details: ', result);
      this.toastr.success("","Successfull",{timeOut:1600});
      this.afClaimButton=true;
        this.showCode = true;
      // this.AddToWallet = result.reponseList;
      // console.log('AddToWallet issss .......', result.reponseList);
    }, error => {
      //console.log(error);
      this.toastr.error(error.error.statusDesc);
    });
  }
  buttonClosedG () {
    this.location.back();
  }
  public renderImage( categoryId: number): string {
    return this.couponService.getImageprod( categoryId);
}
  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScrollEvent(_event: any) {
    // console.log(event);
    const data = window.scrollY;
    // this.change.emit(data);
    // this.service.emitScrollEvent(data);
    // console.log(data);
    if (data > 49) {
      this.headerFix = true;
    } else {
      this.headerFix = false;
    }
  }
  eventFired(event: any) {
    //console.log(event);
    if (event) {
            //this.isDisable = false;
            this.isDisableClaim = false;
            this.otpShow=true;
            this.actv4=true;
            //this.showCode = true;
            //this.qrResult = true;
            // localStorage.setItem("code", this.couponCode);
            this.couponCode = "Place1234";
           this.isDisable = false;
        this.showCode=true;
        // this.toastr.success("","Successfull",{timeOut:1600});
        this.isDisableClaim=false;
        this.otpShow=false;
          }
          else{
            // this.toastr.error("","Try Again",{timeOut:1600});
          }
            //localStorage.setItem("subprodId",'testing');}
  /*  if (event) { 
      console.log(event+"     -- 2");
      this.actv2=false;
      this.isDisable = false;
      const data = {
        subproductId: 1,
        userId: 1,
        otp: '1234'
      };
      
     if (this.coupon) {
        data.subproductId = this.coupon.id;
      }
      this.offerService.ValidateOTPforCoupon(data).subscribe(
        result => {
          if (result.status === 200) {
            this.isDisable = false;
            this.showCode = true;
            this.qrResult = true;
            this.couponCode = result.couponResponses[0].couponCode;
            localStorage.setItem("code", this.couponCode);
            localStorage.setItem("subprodId", result.couponResponses[0].subproductId);
          } else {
            this.isDisable = true;
            this.isDisableClaim = true;
            this.showCode = false;
            alert(result.statusDesc);
            this.otp = ' ';
            this.ngOtpInputRef.setValue('');
          }
        }, error => {
          console.log(error);
        }
      );
    }*/
  }
  onOtpChange(otp: string) {
    this.otp = otp;
    // console.log('otp', this.otp);
    if (this.otp.length === 4) {
      if (this.otp === '1234') {
        this.isDisable = false;
        this.showCode = true;
      } else {
        this.isDisable = true;
        this.isDisableClaim = true;
        this.showCode = false;
        alert('Invalid OTP');
        this.otp = ' ';
        console.log(this.ngOtpInputRef);
        this.ngOtpInputRef.setValue('');
      }
  

    }
  }

  claimRewords() {
    this.claimCode = true;
    this.showCode = true;
  }
  copyCode(val: string) {
    this.actv4=false;
    this.isDisableClaim=false;
    
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
   // this.isDisableClaim = false;
  }
  claimCoupon() {
    // this.router.navigate(['/redeem']);
    // if (this.coupon.id === 1) {
      this.mobNo= +(localStorage.getItem("mobNo").substring(0));
      const data = {

        subproductId: 1,
        mobileNumber:this.mobNo,
        quantity: 1


      };

  }

  gotoWalletBtn() {
    this.router.navigate(['/wallet']);
    this.addedToWallet=false;
  }
  pinVaidate(pin: string)
  {
    this.pin = pin;
    // console.log('otp', this.otp);
    
    if (this.pin.length === 4) {

      if(this.pin==='1234')
      {
        this.isDisable = false;
        this.couponCode = 'yht13wer';
        this.showCode=true;
        localStorage.setItem("code", this.couponCode);
        // this.toastr.success("","Successfull",{timeOut:1600});
        this.isDisableClaim=false;
        this.otpShow=false;
      }else{
        // this.toastr.error("","Try Again",{timeOut:1600});
      }
    }

  }
  scan()
  {
    this.actv1=!this.actv1;
    this.actv4=true;
    this.showCode=false;
    this.isDisable=true;
  }



}
