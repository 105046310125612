import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MustMatch } from 'src/app/helper/helper';
import { Router } from '@angular/router';
import { AuthService, GoogleLoginProvider, SocialUser } from 'angularx-social-login';
import { Location } from '@angular/common';
@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {
public isAccept:boolean=false;
registerPage:boolean=true;
buttonName:String='Get OTP';
registerForm: FormGroup;
otpForm:FormGroup;
userDetails:any;
 submitted = false;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private location:Location
    ) { }

  ngOnInit() {

    this.registerForm = this.formBuilder.group({
      fullName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6),Validators.maxLength(15)]],
      confirmPassword: ['', [Validators.required]],
      acceptTerms: [false, [Validators.requiredTrue]]
  }, {
      validator: MustMatch('password', 'confirmPassword')
  });
  
  this.otpForm = this.formBuilder.group({
    otp: ['', [Validators.required]],
    acceptTerms1: [false, [Validators.requiredTrue]],
    acceptTerms2: [false, [Validators.requiredTrue]]
  });
  }

   // convenience getter for easy access to form fields
   get f() {
      return this.registerForm.controls; 
         }

         onSubmit() {
          this.submitted = true;
          console.log("hiiii");
          // stop here if form is invalid
          if (this.registerForm.invalid) {
              return;
          }else if (this.registerForm.valid){
            // this.router.navigate(['/login']);
            this.registerPage=false;
            this.buttonName="Register";
          }
  
          // display form values on success
          // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.registerForm.value, null, 4));
      }
    
  acceptFun(e){
    this.isAccept=e.target.checked;
    console.log("isAccept==>",this.isAccept);
  }

  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then(SocialUser => {
      this.userDetails=SocialUser;
      console.log(SocialUser);
      
      // console.log("fbeufuefeq",this.userDetails[0].Social)
      // localStorage.setItem("gMailUserDetails",this.userDetails);
      // this.location.back();
      this.router.navigate[("./category")];
    }
      )
    // this.router.navigate(["/home"]);
    // this.location.back();
  }
}
