import { EventEmitterService } from 'src/app/services/EventEmitter/event-emitter.service';
import { Component, OnInit, HostListener, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CouponServiceService } from 'src/app/services/coupon/coupon-service.service';
@Component({
  selector: 'app-trending',
  templateUrl: './trending.component.html',
  styleUrls: ['./trending.component.scss']
})
export class TrendingComponent implements OnInit {

  public coupons: any;
  coupons1: any;

  constructor(
    public router: Router,
    public activateRouter: ActivatedRoute,
    private couponService: CouponServiceService,
    private service: EventEmitterService
  ) { }

  ngOnInit(): void {
    this.getAllProduct();
  }
  getAllProduct() {
    this.couponService.getAllProduct().subscribe(
      (data) => {
        console.log('Received data:', data);
        if (data && data.productresponse && Array.isArray(data.productresponse)) {
          this.coupons = data.productresponse;
          console.log('All products:', this.coupons);
  
          // Filter products based on the presence of the tag "new" (case insensitive)
          this.coupons1 = this.coupons.filter((product) => {
            return product.tags && product.tags.includes("trending");
          });
          console.log('Filtered products:', this.coupons1);
        } else {
          console.error('Invalid data format received:', data);
        }
      },
      (error) => {
        console.error('Error fetching product data:', error);
      }
    );
  }

}
