<div class="deskBack">
  <img class="backImg" src="../../../assets/img/backDubai2.jpg">
<div class="headers">
    <!-- <app-header><//app-header> -->
    <!-- <div class="row divP">
            <p class="fontStyl1">New to</p> <p class="fontStyl2">Wifi10X</p><p class="fontStyl1">?</p>
    </div>
    <div class="row">
        <button class="newAccountBtn">Create an Account</button>
    </div>
    <div class="row divStyl2">
        <p class="fontStyl">Already have an Account</p>
    </div> -->
   <!-- <div class="headDiv">
    <div class="row">
      <div class="loginBtnHead">Registration</div>
  </div>
    <form [formGroup]="registerForm" class="formStyl" *ngIf="registerPage">
      
    <div class="form-group">
        <label for="fullName">Full Name:</label>
        <input type="text" formControlName="fullName" class="form-control" id="name" [ngClass]="{ 'is-invalid': submitted && f.fullName.errors }">
        <div *ngIf="submitted && f.fullName.errors" class="invalid-feedback">
          <div *ngIf="f.fullName.errors.required">Full Name is required</div>
      </div>
      </div>
     <div class="form-group">
        <label for="email">Email address:</label>
        <input type="email" formControlName="email" class="form-control" id="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
          <div *ngIf="f.email.errors.required">Email is required</div>
          <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
      </div>
      </div>
    <!-- <div class="form-group">
        <label for="Phone">Phone Number:</label>
        <input type="text" class="form-control" id="phn">
      </div> -->
      <!-- <div class="form-group">
        <label for="pwd">Password:</label>
        <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
            <div *ngIf="f.password.errors.required">Password is required</div>
            <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
            <div *ngIf="f.password.errors.maxlength">Password must be at most 15 characters</div>
        </div>
      </div>
      <div class="form-group">
        <label for="conpwd">Confirm Password:</label>
        <input type="password" formControlName="confirmPassword" class="form-control" id="conpwd" [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }">
        <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
          <div *ngIf="f.confirmPassword.errors.required">Confirm Password is required</div>
          <div *ngIf="f.confirmPassword.errors.mustMatch">Passwords must match</div>
      </div>
      </div>
      <div class="checkbox">
        <label><input type="checkbox" formControlName="acceptTerms" [ngClass]="{ 'is-invalid': submitted && f.acceptTerms.errors }" >Accept all terms and conditions</label>
        <div *ngIf="submitted && f.acceptTerms.errors" class="invalid-feedback">Accept Ts & Cs is required</div>
      </div>

    </form>
    <div class="row mbOtpDiv">
        <form [formGroup]="otpForm" class="formStyl" *ngIf="!1registerPage">
            <label for="otp" class="text-center otpLine">OTP</label>
            <input type="text" formControlName="otp" class="form-control otpInput" maxLength="4" id="name">
            <div class="form-group">
              <label>Please tick to indicate that the customer cnsents to recive to marketing form</label>
            </div>
          <div class="checkbox">
            <label><input type="checkbox" formControlName="acceptTerms1" [ngClass]="{ 'is-invalid': submitted && f.acceptTerms.errors }" >&nbsp;Meras Corporate office</label>
            <div *ngIf="submitted && f.acceptTerms.errors" class="invalid-feedback"></div>
          </div>
          <div class="checkbox">
            <label><input type="checkbox" formControlName="acceptTerms2" [ngClass]="{ 'is-invalid': submitted && f.acceptTerms.errors }" >&nbsp;I have read and agreed to the <b>Terms & Conditions</b>.By accepting and providing my personal I am consenting to 
              <b>Meras privacy Policy</b>.as well as the applicable of data protection laws.</label>
            <div *ngIf="submitted && f.acceptTerms.errors" class="invalid-feedback"></div>
          </div>
        </form>
    </div>
    <div class="row">
        <button class="loginBtn" type="button" (click)="onSubmit()">{{buttonName}}</button>
    </div>
        <p class="or" *ngIf="registerPage">Or</p>
        <div  class="col-12 text-center" *ngIf="registerPage">
            <a href="#" class="text-center"  [routerLink]="['/login']">Login here,If already have an account</a>
        </div>
</div>
</div>
</div> --> 

<div class="row row1">
  <button (click)="signInWithGoogle()" class="socialBtn"><i class="fa fa-google" ></i> Log In Using Gmail</button>
  <!-- <button class="socialBtn"><i class="fa fa-apple " (click)="signInWithGoogle()"></i></button> -->
</div> 
<div class="row mt-2">
  <p class="note">IF YOU ARE A TOURIST PLEASE SIGN IN TO CONTINUE</p>
</div>



  