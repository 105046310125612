import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Country } from 'src/app/data/data';
import { CouponServiceService} from 'src/app/services/coupon/coupon-service.service';

import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss']
})
export class ShareComponent implements OnInit {

  public country: Country[];
  public selectedCountry: Country;

  mobileNo: number;
  mobile: string;
  isDisableShare = true;
  showCountryModal = false;
  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private couponService: CouponServiceService,
    private toastr:ToastrService
  ) { }

  ngOnInit() {
    
    this.country = [
      {
        CountryId: 392,
        Country: 'INDIA',
        CountryCode: 91,
        CountryIcon: 'https://caccount.policybazaar.com/content/countryIcon/in.svg'
      },
      {
        CountryId: 375,
        Country: 'UNITED ARAB EMIRATES',
        CountryCode: 971,
        CountryIcon: 'https://caccount.policybazaar.com/content/countryIcon/ae.svg'
      },
      {
        CountryId: 24,
        Country: 'AUSTRALIA',
        CountryCode: 61,
        CountryIcon: 'https://caccount.policybazaar.com/content/countryIcon/au.svg'
      },
      {
        CountryId: 375,
        Country: 'UNITED ARAB EMIRATES',
        CountryCode: 971,
        CountryIcon: 'https://caccount.policybazaar.com/content/countryIcon/ae.svg'
      },
      {
        CountryId: 24,
        Country: 'AUSTRALIA',
        CountryCode: 61,
        CountryIcon: 'https://caccount.policybazaar.com/content/countryIcon/au.svg'
      },
      {
        CountryId: 375,
        Country: 'UNITED ARAB EMIRATES',
        CountryCode: 971,
        CountryIcon: 'https://caccount.policybazaar.com/content/countryIcon/ae.svg'
      },
      {
        CountryId: 375,
        Country: 'UNITED ARAB EMIRATES',
        CountryCode: 971,
        CountryIcon: 'https://caccount.policybazaar.com/content/countryIcon/ae.svg'
      },
      {
        CountryId: 24,
        Country: 'AUSTRALIA',
        CountryCode: 61,
        CountryIcon: 'https://caccount.policybazaar.com/content/countryIcon/au.svg'
      },
      {
        CountryId: 375,
        Country: 'UNITED ARAB EMIRATES',
        CountryCode: 971,
        CountryIcon: 'https://caccount.policybazaar.com/content/countryIcon/ae.svg'
      },
      {
        CountryId: 375,
        Country: 'UNITED ARAB EMIRATES',
        CountryCode: 971,
        CountryIcon: 'https://caccount.policybazaar.com/content/countryIcon/ae.svg'
      },
      {
        CountryId: 24,
        Country: 'AUSTRALIA',
        CountryCode: 61,
        CountryIcon: 'https://caccount.policybazaar.com/content/countryIcon/au.svg'
      },
      {
        CountryId: 375,
        Country: 'UNITED ARAB EMIRATES',
        CountryCode: 971,
        CountryIcon: 'https://caccount.policybazaar.com/content/countryIcon/ae.svg'
      },
      {
        CountryId: 24,
        Country: 'AUSTRALIA',
        CountryCode: 61,
        CountryIcon: 'https://caccount.policybazaar.com/content/countryIcon/au.svg'
      }
    ];
    this.selectedCountry = this.country[1];
  
  }
  validateNumber() {
    if (this.mobileNo != null && this.mobileNo.toString().length > 9) {
      this.isDisableShare = false;
    } else {
      this.isDisableShare = true;
    }
    // console.log(this.mobileNo.toString().length, this.disableSendOTP);
  }
  
  shareCoupon()
  {
    
    this.mobile = '+' + this.selectedCountry.CountryCode + this.mobileNo;
    var mob2=localStorage.getItem("mobNo");
  // console.log(localStorage.getItem("mobNo"));
 const data = {
      mobileNumber: this.mobile,
      sharedBy: mob2,
      couponCode:'COPN5388473'
    }


    this.couponService.shareCoupon({ mobileNo: this.mobile }).subscribe(
      res => {
        this.toastr.success("","Success",{timeOut:1600});
          this.router.navigate(['/home']);
      }, err => {
        this.toastr.warning("","Failed",{timeOut:1600});
      }
    );
   // console.log("sucess"+ this.mobile);
  }
  clickCountry(data) {
    // console.log(data);
    this.selectedCountry = data;
  }
}
