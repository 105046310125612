import { Component, OnInit, HostListener, EventEmitter } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { EventEmitterService } from "./../../services/EventEmitter/event-emitter.service";
import { CouponServiceService } from "./../../services/coupon/coupon-service.service";
import { ToastrService } from "ngx-toastr";
import { VirtualTimeScheduler } from 'rxjs';
import { LoggingService } from '../../services/logging.service';
import { Logging } from 'src/app/data/logging.model';
import { UserActivity } from 'src/app/data/user-activity.enum';

@Component({
  selector: 'app-front-page-b',
  templateUrl: './front-page-b.component.html',
  styleUrls: ['./front-page-b.component.scss']
})
export class FrontPageBComponent implements OnInit {
  public template: string;
  public headerFix = false;
  public campaignData: [];
  public id: any;

  constructor(
    public router: Router,
    public activateRouter: ActivatedRoute,
    private service: EventEmitterService,
    private couponService: CouponServiceService,
    private toastr: ToastrService,
    private loggingService: LoggingService
  ) { }

  ngOnInit() {

    window.localStorage.setItem("connect-timer", "6");
    this.retrieveData();

  }
  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScrollEvent(event) {

    const data = window.scrollY;

    if (data > 49) {
      this.headerFix = true;
    } else {
      this.headerFix = false;
    }

  }
  retrieveData() {
    console.log("retrueve-==-=");              
    let startTime = new Date();

    console.log();
    var campaignMgmtId = parseInt(localStorage.getItem('campaignId'));
    this.couponService.getFrontPageBData(campaignMgmtId).subscribe((result) => {
      this.template = result["responses"]["templateType"];
      this.campaignData = result.responses.campaignResponses;
      console.log('template type ----->', this.campaignData);
      let endTime = new Date();
      console.log(endTime.valueOf());
      console.log(endTime);
      console.log("ui blocked for" + (endTime.valueOf() - startTime.valueOf()));
      console.log("here");
      

    }, (error) => {
      console.log(error);
    });

  }

}
