import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConstants } from 'src/app/app.constants';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OfferService {

  constructor(
    private http: HttpClient,
  ) { }
  sendOTPforCoupon(data): Observable<any> {
    return this.http.post(AppConstants.BASE_API_URL + 'wifi-portal/sendOtp', data);
  }
  ValidateOTPforCoupon(data): Observable<any> {
    return this.http.post(AppConstants.BASE_API_URL + 'wifi-portal/otpValidation', data);
  }
 
  public getAllCouponBySubProdId(id: number): Observable<any> {
    return this.http.get(AppConstants.BASE_API_URL + 'wifi-portal/getAllCouponBySubPrdctId/' + id);
  }
}
