import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {
  private baseUrl = 'https://place10x.silicontechlab.com';

  constructor(private http: HttpClient) { }

  downloadPdf(storeId: number, year: number, startMonth: number, endMonth: number): void {
    const url = `${this.baseUrl}/wifi10x/dashboard/getPapaJohnPDF/${storeId}/${year}/${startMonth}/${endMonth}`;
    const headers = new HttpHeaders().set('Access-Control-Allow-Origin', '*' );
    this.http.get(url, { headers, responseType: 'blob' }).subscribe(response => {
      const fileName = `papajohns-${storeId}-${year}-${startMonth}-${endMonth}.pdf`;
      saveAs(response, fileName);
    });
  }

  downloadExcel(storeId: number, year: number, startMonth: number, endMonth: number): void {
    const url = `${this.baseUrl}/wifi10x/dashboard/getPapJohnReport/${storeId}/${year}/${startMonth}/${endMonth}`;
    const headers = new HttpHeaders().set('Access-Control-Allow-Origin', '*');
    this.http.get(url, { headers, responseType: 'blob' }).subscribe(response => {
      const fileName = `papajohns-${storeId}-${year}-${startMonth}-${endMonth}.xlsx`;
      saveAs(response, fileName);
    });
  }
}
