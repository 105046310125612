import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MustMatch, StrongPasswordRegx } from 'src/app/helper/helper';
import { SigninService } from 'src/app/services/signin/signin.service';
import { CouponServiceService } from 'src/app/services/coupon/coupon-service.service';
import {formatDate} from '@angular/common';



@Component({
  selector: 'app-registration-new',
  templateUrl: './registration-new.component.html',
  styleUrls: ['./registration-new.component.scss']
})
export class RegistrationNewComponent implements OnInit {
  public isAccept = false;
  registerPage = true;
  buttonName = 'Get OTP';
  registerForm: FormGroup;
  otpForm: FormGroup;
  submitted = false;
  submitOtp = false;
  countries: any;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private signin: SigninService,
    private couponservice:CouponServiceService,
  ) { }

  ngOnInit() {
    this.getAllCountries(this.countries);

    this.registerForm = this.formBuilder.group({
      fullName: ['', [Validators.required]],
      mobile: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(15),Validators.pattern(StrongPasswordRegx)]],
      confirmPassword: ['', [Validators.required]],
      acceptTerms: [false],
      otp: [''],
      dob:""
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });

    this.otpForm = this.formBuilder.group({
      acceptTerms1: [false, [Validators.requiredTrue]],
      acceptTerms2: [false, [Validators.requiredTrue]]
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.registerForm.controls;
  }
  public getAllCountries(data): void {
    // this.spinnerService.show();
    this.couponservice.getCountry(data).subscribe(result => {
        // this.spinnerService.hide();
        // this.countryObj = result;
        this.countries = result.listResponse;
        // this.totalElements = result['totalelement'];
        console.log("country", this.countries)

    },
        error => {
            // this.spinnerService.hide();
        });
}
  onSubmit() {
    this.submitted = true;
    console.log(this.registerForm.value);

    // console.log("1st ",this.registerForm.valid );
    // console.log("2nd ",this.submitOtp === true);
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
      // } else if (this.registerForm.valid && this.submitOtp === true && this.registerPage === false) {

    } else if (this.registerForm.valid && this.submitOtp === true) {
      this.registerPage = false;
      this.buttonName = 'Register';
    } else if (this.registerForm.valid) {
      // this.router.navigate(['/login']);
      this.buttonName = 'Submit';
      this.submitOtp = true;
      this.signin.sendOTP({ mobileNo: this.registerForm.value.mobile }).subscribe(
        res => {
          console.log(res);
        }, err => {
          // alert('Some Error Occurred');
        }
      );
    }

    // display form values on success
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.registerForm.value, null, 4));
  }
  register() {
    console.log('register');
    // const data = {
    //   userName: this.registerForm.value.fullName,
    //   password: this.registerForm.value.password,
    //   emailId: this.registerForm.value.email,
    //   mobileNo: this.registerForm.value.mobile,
    //   otp: this.registerForm.value.otp,
    // };
  
    let val = this.registerForm.get('dob').value;
    val = formatDate(val,'yyyy-MM-dd','en');
    this.registerForm.controls['dob'].setValue(val);
    const data = {
      userRole:"ROLE_MEMBER",
      // fullName: this.registerForm.value.fullName,
      password: this.registerForm.value.password,
      emailId: this.registerForm.value.email,
      mobileNo: this.registerForm.value.mobile,
      // otp: this.registerForm.value.otp,
      nationality:this.registerForm.value.fullName,
      dob:this.registerForm.value.dob
      
      // confirmPassword:this.registerForm.value.confirmPassword,
    };
    // this.signin.createUser(data).subscribe(
    //   res => {
    //     alert('User Registered Successfully');
    //     this.router.navigate(['/home']);
    //   }, err => {
    //     alert('Some error Occurred');
    //   }
    // );
    this.signin.registerUser(data).subscribe(
      res => {
        alert('User Registered Successfully');
        this.router.navigate(['/home']);
      }, err => {
        alert('Some error Occurred');
      }
    );
  }
  acceptFun(e) {
    this.isAccept = e.target.checked;
    console.log('isAccept==>', this.isAccept);
  }

  public restrictNumber(event) {
    const isShiftKey = event.shiftKey;
    const y = Number(event.key);
    const x = event.which || event.keycode;
    if (x === 32) {
      return false;
    } else if (!isNaN(y)) {
      return true;
    } else {
      if (x === 8 || (x >= 35 && x <= 40) || x === 46 || x === 110 || (x === 190 && !isShiftKey)) {
        return true;
      } else {
        return false;
      }
    }
  }

}
