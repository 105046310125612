import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';
import { Router, ActivatedRoute } from '@angular/router';
import { Ad } from '../../data/data';

import { AppConstants } from 'src/app/app.constants';
import { AdsequenceService } from 'src/app/services/adsequence/adsequence.service';
import { CouponServiceService } from 'src/app/services/coupon/coupon-service.service';
import { Logging } from 'src/app/data/logging.model';
import { UserActivity } from 'src/app/data/user-activity.enum';
import { LoggingService } from 'src/app/services/logging.service';
import { debug } from 'console';
import { TranslateService } from '@ngx-translate/core';
import { environment } from "../../../environments/environment";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger('showHello', [
      // transition(':enter',
      //   [style({ transform: 'translateY(100%)', opacity: 0 }),
      //   animate('2000ms', style({ transform: 'translateY(0)', 'opacity': 1 }))]),
      transition(':leave', [
        style({ transform: 'translateY(0)' }),
        animate('500ms', style({ transform: 'translateY(-100%)' }))
      ])
    ])
  ]
})
export class HomeComponent implements OnInit {

  public visible: boolean;
  public videoVisible = false;
  private ad = new Ad();
  public paramData: any;
  public homeBg: any;
  public portalBg: any;
  public mediaType: any;
  @ViewChild('myVideo', { static: true }) myVideo: ElementRef; // => Angular 8
  public video1: string;
  private startTime: Date;
  public basePath = 'campaign';

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private pageService: AdsequenceService,
    private couponService: CouponServiceService,
    private loggingService: LoggingService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    // console.log(this.route.snapshot.paramMap.get('id'));
    // this.homeBg = 'assets/img/visa/banner.jpg';
    // localStorage.removeItem('campaignId');
    if (window.localStorage.getItem("redirected-From") != "WIFI") {
      localStorage.removeItem("deviceId");
    }

    if (window.localStorage.getItem('deviceId') == null || window.localStorage.getItem('deviceId') == "") {
      // this.translate.use("en");
      localStorage.setItem('language', "en");
    } else {
      // this.translate.use("grk");
      localStorage.setItem('language', "grk");
    }

    this.startTime = new Date();
    this.route.queryParams.subscribe(param => {
      console.log(param);
      this.paramData = param;
      if (param != null) {
        this.pageService.getAllDetails().subscribe(
          data => {
            console.log("data----", data);
            this.ad = data;
            // this.ad = getAllDetails;
            this.pageService.ad = data;
            // this.pageService.ad = getAllDetails;
            this.mediaType = this.pageService.ad.mediaType;
            console.log('grgrtg', this.mediaType);
            this.homeBg = this.getImage(data.frontPageAUrl,this.basePath);
            console.log('cgctcyvuvubu', this.homeBg);
            localStorage.setItem('campaignId', data.campaignId);
            //this.pageService.getImage(data.slno, 'bannerImg');
            // this.homeBg = getAllDetails.bannerImg;
            //this.portalBg = this.pageService.ad.portalBgImg;//this.pageService.getImage(data.slno, 'portalBgImg');
            // this.portalBg = getAllDetails.portalBgImg;
            // this.pageService.ad.portalBgImg = this.portalBg;
            // this.pageService.ad.bannerImg = this.homeBg;
            // this.video1 = this.pageService.ad.frontPageAUrl;
            // this.homeBg = this.pageService.getImage(
            //   this.pageService.ad.slno,
            //   'bannerImg'
            // );
          },
          error => {
            console.log(error);
          }
        );
        // this.pageService.ad.targetUrl = param.url;
      }
    });
    this.visible = true;

    // state('closed', style({
    //   animation: fontbulger 2s linear;
    // }))
  }
  homeClick() {

    //window.localStorage.setItem("redirected-From", "WIFI");
    //window.localStorage.setItem("redirection-time", "" + Date.now());
    let userActivity: Logging[] = [];
    let endTime = new Date();
    userActivity.push({
      advId: '',
      longitute:localStorage.getItem("longitude"),
      latitude:localStorage.getItem("Latitude"),
      campaignId: '' + this.ad.campaignId,
      categoryId:'',
      productId: '',
      subProductId: '',
      userId: localStorage.getItem("deviceId"),
      userAction: UserActivity[UserActivity.FRONTPAGE_A_DISPLAY],
      startTime: this.startTime.valueOf(),
      endTime: endTime.valueOf(),
      duration: endTime.valueOf() - this.startTime.valueOf()
    });



    this.loggingService.saveUserActivity(userActivity).subscribe();
    //debugger;
    window.localStorage.setItem("mobileNo", "1234567890");//For greece demo, Client has asked to by pass login
    //alert(window.localStorage.getItem("mobileNo"));
    if (this.isRedirectionAllowed()) {
      this.resetLocalRedirectionData();
      this.router.navigate(['/front-page-b']);
    } else {
      this.router.navigate(['/front-page-b']);
      /*let data = {
        "campaignId": localStorage.getItem('campaignId'),
        "token": localStorage.getItem("wifi-token")
      };
      this.couponService.getAuthorizationLink(data).subscribe(
        response => {

          if (response['status'] == 200) {
            //alert("Ack sent successfully ");
            this.resetLocalRedirectionData();
            window.location.href = response['authorizationUrl'];
          } else {
            alert("Connection Failed");
          }

        }, error => {
          console.log(error);
          alert("Connection Failed");
        }
      );*/



    }
    // this.visible = false;
    // const demo = 'shop10x.app';
    // console.log(AppConstants.BASE_API_URL.search(demo));
    // if (AppConstants.BASE_API_URL.search(demo) < 0) {
    //   console.log(demo);
    //   this.video1 = 'https://shop10x.app/Video/' + this.video1;
    //   this.pageService.ad.video2 =
    //     'https://shop10x.app/Video/' + this.pageService.ad.video2;
    // } else {
    //   console.log('Not ' + demo);*/
    // this.video1 = this.pageService.getImage(this.pageService.ad.slno, 'video1');
    // this.pageService.ad.video2 = this.pageService.getImage(this.pageService.ad.slno, 'video2');
    // }
    // this.video1 = 'https://wifi10x.com/Video/Meraas1.mp4';
    /* this.myVideo.nativeElement.load();
     // $('#main-header').slideUp(2000);
     setTimeout(() => {
       // this.router.navigateByUrl('/pages/video1');
       // this.router.navigateByUrl('/video1');
       // if (this.pageService.ad.adTypeId === 1) {
       this.videoVisible = true;
       // const video = document.getElementById('myVideo');
       this.myVideo.nativeElement.play();
       // this.myVideo.nativeElement.muted = true;
       // this.myVideo.nativeElement.controls = true;
       console.log(this.myVideo);
       // } else {
       // this.router.navigate(['/login']);
       // }
       // video.nativeElement.play();
     }, 500);
     // this.videoVisible = true;
     // const video: HTMLVideoElement = this.myVideo.nativeElement;
     // // this.myVideo.nativeElement.play();
     // video.play(); */
  }

  videoEnd() {
    // this.router.navigate(['/pages/login']);
    // if (this.videoVisible) {
    //alert("VideoEnd");
    /*if (this.isRedirectionAllowed()) {
      this.resetLocalRedirectionData();
      this.router.navigate(['/front-page-b']);
    }*/

    // tslint:disable: max-line-length
    // document.location.href = 'http://test.wifi10x.com/?xaccessToken=' + this.paramData.xaccessToken + '&targetUrl=' + this.pageService.ad.targetUrl;
    // document.location.href =
    //   'http://localhost:4200/?xaccessToken=' +
    //   this.paramData.xaccessToken +
    //   '&targetUrl=' +
    //   this.pageService.ad.targetUrl;
    // }
  }

  // getBgImg(type: string) {
  //   this.pageService.getImage(this.ad.campaignId, type);
  // }
  isRedirectionAllowed() {
    let canRedirect = true;

    if (window.localStorage.getItem("redirected-From") === "WIFI" && environment.production) {
      canRedirect = false;
    }
    return canRedirect;
  }
  resetLocalRedirectionData() {
    window.localStorage.setItem("redirected-From", "");
  }
  getImage(path: string,basePath) {
    let pathName= path.split(/(\\|\/)/);
    var path1 = pathName[pathName.length-1];
    console.log('path11111',path1);
    return this.couponService.getImage(path1,this.basePath);
  }

}
