import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConstants } from 'src/app/app.constants';

@Injectable({
  providedIn: 'root'
})
export class SigninService {

  constructor(
    private http: HttpClient
  ) { }
  sendOTP(data): Observable<any> {
    return this.http.post(AppConstants.BASE_API_URL + 'wifi-portal/generateOtp', data);
    // return this.http.post('http://192.168.0.82:8080/wifi10xapi/' + 'wifi-portal/generateOtp', data);
  }
  validateOtp(data): Observable<any> {
    return this.http.post(AppConstants.BASE_API_URL + 'wifi-portal/validateRegOtp', data);
    
    // return this.http.post('http://192.168.0.82:8080/wifi10xapi/'+ 'wifi-portal/validateRegOtp', data);
  }
  loginuser(data): Observable<any> {
    const headers = new HttpHeaders()
    .set('Content-Type', 'application/json');
    return this.http.post(AppConstants.BASE_API_URL1 + 'admin/userlogin', data, { headers });
    
    // return this.http.post('http://192.168.0.82:8080/wifi10xapi/'+ 'wifi-portal/validateRegOtp', data);
  }
  createUser(data): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json');
    return this.http.post(AppConstants.BASE_API_URL + 'wifi-portal/createuser', data, { headers });
  }
  registerUser(data): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json');
    // return this.http.post(AppConstants.BASE_API_URL + 'wifi-portal/registerUser', data, { headers });
    return this.http.post(AppConstants.BASE_API_URL1 + 'admin/createuser', data, { headers });

    // return this.http.post('http://192.168.0.82:8080/wifi10xapi/wifi-portal/registerUser', data, { headers });
  }
}
