import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CouponServiceService } from 'src/app/services/coupon/coupon-service.service';

@Component({
  selector: 'app-lowhead',
  templateUrl: './lowhead.component.html',
  styleUrls: ['./lowhead.component.scss']
})
export class LowheadComponent implements OnInit {


  constructor(private router:Router,    private couponService:CouponServiceService,) { }

  ngOnInit(): void {
  }
  openHome(){
    this.router.navigate(['../new']);
  }
  openHome2(){
    this.router.navigate(['../deal']);
  }
  openHome3(){
    this.router.navigate(['../trending']);
  }
  openHome4(){
    this.router.navigate(['../home']);
  }
  openhomee(id) {
    localStorage.setItem('userCategory',id);
    console.log("id of  cat",id);
    let endTime = new Date();

    console.log(endTime.getTime())
  
  
    this.couponService.setCategoryId(id);
    this.router.navigate(['/home']);
    //this.router.navigate(['/home',{d:id}],{ relativeTo: this.activeRoute });
  }


}
