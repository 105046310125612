<!-- <app-header></app-header> -->
<div class="deskBack">
  <img class="backImg" src="../../../assets/def.jpeg">
  <!-- <app-claim-header></app-claim-header> -->
  <!-- <app-earn-header></app-earn-header> -->
  <div class="shadow bg-white">
    <div [ngClass]="headerFix?'earn-header':''">
      <app-earn-header [showLogo]="true"></app-earn-header>
    </div>
  </div>

  <div class="otp-page card">
    <div class="card" *ngIf="actv1">
      <!-- <app-coupon-card [couponCode]="cupDiv" [ClaimCode]="claimCode"></app-coupon-card> -->
      <div class="cardImg ">
        <img class="cd "  [src]="renderImage(cardDetails.productId)" onerror="this.src='assets/def.jpeg';" />
      </div>
    </div>

    <!-- <div class="card" *ngIf="!actv1">

      </div> -->



    <div class="offerx ml-2">
      <!-- <button *ngIf="isClaimButton" class="clBtn">Claim</button> -->
      <button *ngIf="!isClaimButton" class="clBtnClicked"><span>Press CLAIM again to re-confirm.</span><br>Please note
        by re-confirming,the offer will be considered reedemed and will be removed from STAR list</button>
      <button *ngIf="afClaimButton" class="clBtnClicked1"> <span>Congratulations!</span> <br> you have Successfully
        claimed the offer</button>

      <div class="row bgColor mt-2">
        <div class="col-9">
          <div class="cupDigits">
            <p class="text-center pt-1" #userinput><span *ngIf="!showCode">WIF222*****</span><span
                *ngIf="showCode">{{couponCode}}</span></p>
          </div>
        </div>
        <div class=" col-2">

          <!-- <button class="text-center text-black btn-sm copyBtn" [disabled]="isDisable"  (click)="copyCode(couponCode)">CLAIM</button> -->
          <button class="btn-sm  text-center copyBtn " (click)="clBtnzClicked(cardDetails)" [disabled]="isDisable"
            *ngIf="isClaimButton">CLAIM</button>
          <button class="btn-sm text-center copyBtn1" (click)="clBtnzClickedAgain(cardDetails)"
            *ngIf="!isClaimButton && !afClaimButton">CLAIM</button>
            
          <!-- <button class="btn-sm text-center copyBtn1"  (click)="clBtnzClickedAgain(cardDetails)"   *ngIf="!isClaimButton">CLAIM</button> -->




        </div>
      </div>
      <div class="col-12 conditions">
        
          <label>Offer Details:</label>
          <div class="offers">
            <ol style="list-style-type:1;">
              <li *ngFor="let item of offerDetails">{{item}}</li>
            </ol>
            <!-- </div> -->
            <hr />
          
          <!-- <div class="note offers"> -->
          <div class="wh">
            <label>Please Note:</label>
            <ul style="list-style-type:star;">
              <li *ngFor="let item of note">{{item}}</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="text-center">
        <button (click)="buttonClosedG()" class="closebtnAcc" >CLOSE </button>

      </div>
      <!-- <button type="button" title="Click for Claim Code" [disabled]="isDisableClaim" class="foot-btn"
          (click)="claimCoupon()">Claim</button> -->
    </div>
  </div>
</div>
<!-- <click-to-connect></click-to-connect> -->
<div class="deskView">
</div>