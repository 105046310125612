
    <app-header></app-header>

  <app-lowhead></app-lowhead>
  
  

  <ng-container>
  
    <app-tiles-templatep [campaignData]="coupons1"></app-tiles-templatep>
  
  
  </ng-container>
  
