<div class="dashboard-header">
  <app-headers (LanguageChanged)="langchanged($event)"></app-headers>
</div>
<!-- <app-earn-header [showLogo]="false"></app-earn-header> -->
<div [ngClass]="headerFix ? 'earn-header' : ''">
  <app-earn-header [showLogo]="headerFix"></app-earn-header>
</div>
<div class="bg">
  <div class="shadow bg-white">
    <!-- <app-header></app-header>
      <app-earn-header></app-earn-header> -->
  </div>
  <div class="headers">
    <div class="scrollmenu">
      <a (click)="openHome(65)">{{ "translations.topEarnersText" | translate }}</a>
      <a  (click)="openHome(34)">{{ "translations.trendingText" | translate }}</a>
      <a (click)="openHome(1)">{{ "translations.newText" | translate }}</a>
      <!-- <a href="">{{ "translations.jumeirahText" | translate }}</a>
      <a href="">{{ "translations.brandzText" | translate }}</a>
      <a href="">{{ "translations.dineText" | translate }}</a> -->
      <!-- <a style="cursor: pointer;" (click)="storeClicked(store.id)" *ngFor="let store of stores">{{store.storeName}}</a> -->
      <!-- <a href="">Top Earners</a>
      <a href="">Trending</a>
      <a href="">New</a>
      <a href="">Jumeirah</a>
      <a href="">Brand Z</a>
      <a href="">Dine</a> -->
      <!-- <div class="right-box-menu"> -->
        <ul>
          <li>
            <select (change)="selectCountry($event)">
              <option >Select Country</option>
              <option *ngFor="let country of countries" [value]="country.countryid">{{country.countryname}}</option>
            </select>
          </li>
          <li>
             <select (change)="selectAdnetwork($event.target.value)">
              <option readOnly>Areas</option>
              <option *ngFor="let network of adnetworks" [value]="network.id">{{network.adNetworkName}}</option>
            </select>
          </li>
          <li>
            <select (change)="mallSelected($event.target.value)">
              <option readOnly>Networks</option>
              <option *ngFor="let mall of malls" [value]="mall.sellerId">{{mall.sellerName}}</option>
            </select>
          </li>
          <li>
          </li>
        </ul>
      <!-- </div> -->
    </div>
    <div class="menu-box">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12 col-lg-12 col-md-12">
            <div class="left-menu">
            <a (click)="toggleMenu()"> <img class="menuIconStyl" *ngIf="!menuToggle" src="assets/img/menuicon.svg"
              alt=""></a>

              <i (click)="toggleMenu()" *ngIf="menuToggle" class="fa fa-times menuIconStyl1" aria-hidden="true"></i>

              <a style="cursor: pointer;" (click)="openHome(65)">{{ "translations.topEarnersText" | translate }}</a>
              <a style="cursor: pointer;" (click)="openHome(34)">{{ "translations.trendingText" | translate }}</a>
              <a style="cursor: pointer;" (click)="openHome(1)">{{ "translations.newText" | translate }}</a>
              <!-- <a href="">{{ "translations.jumeirahText" | translate }}</a>
              <a href="">{{ "translations.brandzText" | translate }}</a>
              <a href="">{{ "translations.dineText" | translate }}</a> -->
              <!-- <a style="cursor: pointer;" (click)="storeClicked(store.id)" *ngFor="let store of stores">{{store.storeName}}</a> -->
            </div>

            <div class="right-box-menu">
              <ul>
                <li (click)="scan()">
                  <i *ngIf="!showScan" class="fa fa-qrcode"></i>
                  <app-scanner *ngIf="showScan"></app-scanner>
                </li>
                
                <li>
                  <select (change)="selectCountry($event)">
                    <option class="op">select Country</option>
                    <option class="ug" *ngFor="let country of countries" [value]="country.countryid">{{country.countryname}}</option>
                  </select>
                </li>
                <li>
                   <select (change)="selectAdnetwork($event.target.value)">
                    <option readOnly>Areas</option>
                    <option *ngFor="let network of adnetworks" [value]="network.id">{{network.adNetworkName}}</option>
                  </select>
                </li>
                <li>
                  <select (change)="mallSelected($event.target.value)">
                    <option readOnly>Networks</option>
                    <option *ngFor="let mall of malls" [value]="mall.sellerId">{{mall.sellerName}}</option>
                  </select>
                </li>
                <li>
                  <!-- <div class="dropdown">
                    <span class="btn1 dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      {{ "translations.storeText" | translate }}
                    </span>
                    <div class="dropdown-menu" >
                      <a class="dropdown-item"  *ngFor="let store of stores" (click)="openHome(store.id)">{{store.storeName}}</a>
                    </div>
                  </div> -->
                  <!-- <select (change)="openHome($event)">
                    <option readOnly>{{ "translations.storeText" | translate }}</option>
                    <option *ngFor="let store of stores" [value]="store.id">{{store.storeName}}</option>
                  </select> -->
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="menuToggle"  class="search-div">
      <ul class="list-unstyled">
        <li
          class="list-group-item bgClr"
          *ngFor="let item of menuList"
          [routerLink]="[item.url]"
          routerLinkActive="router-link-active"
        >
          {{ item.mName }}<i class="fa fa-chevron-right iconRight"></i>
        </li>
        <!-- <li
          class="list-group-item bgClr"
          (click)="showCountryModal = true"
          data-toggle="modal"
          data-target="#countryDetails"
        >
          {{ "translations.changeLanguageText" | translate
          }}<i class="fa fa-chevron-right iconRight"></i>
        </li> -->
      </ul>
      <div class="modal countryModal" id="countryDetails" *ngIf="showCountryModal">
        <div class="modal-dialog m-0">
          <div class="bg"></div>
          <div class="modal-content">
            <!-- Modal Header -->
            <div class="modal-header">
              <h4 class="modal-title">Select Language</h4>
              <button type="button" class="close" data-dismiss="modal">
                &times;
              </button>
            </div>
    
            <!-- Modal body -->
            <div class="modal-body">
              <ul class="list-unstyled">
                <li
                  class="mt-2 p-2 border"
                  *ngFor="let item of languages"
                  (click)="changeLanguage(item)"
                  data-dismiss="modal"
                >
                  <div class="row m-0 text-center">
                    <div class="col-4 p-0">{{ item.displayText }}</div>
                  </div>
                </li>
              </ul>
            </div>
    
            <!-- Modal footer -->
            <div class="modal-footer">
              <button type="button" class="btn btn-danger" data-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <!-- <app-scroll></app-scroll> -->
    <div class="container-fluid ">
      <div class="headers ">
        <div class="cat-product ">
            <div class="row ">
              <div class="col-lg-3 col-sm-12 con" *ngFor="let pcard of scrollCard">
                <div class="card card2 " (click)="openHome(pcard.categoryId)">
                  <div class="image-box ">
                  <img [src]="renderImage(pcard.categoryId)" class="imgSc1 img-thumbnail" />
                  </div>
                  <h3 class="pStyl ">
                    {{ pcard.categoryName }}
                    <i class="fa fa-arrow-circle-o-right" aria-hidden="true"></i>
                  </h3>
                </div>
              </div>
            </div>
          
        </div>
      </div>
    
    </div>
    
    <app-footer (LanguageChanged)="langchanged($event)"></app-footer>
    <!--<app-prodscroll></app-prodscroll>-->
  </div>
  <!-- <click-to-connect></click-to-connect> -->

</div>
<click-to-connect ></click-to-connect>