<div>
  <div class="dashboard-header">
    <app-header></app-header>
  </div>
  <div [ngClass]="headerFix ? 'earn-header' : ''">
    <app-earn-header [showLogo]="headerFix"></app-earn-header>
  </div>
  <div
    class="coupon-wrapper"
    *ngIf="productDetails != null && productDetails != ''"
  >
    <div class="dashboard accordion" id="accordion">
      <div class="card card1">
        <p>{{ "translations.compA" | translate }}</p>
        <img
          [src]="getImage(productDetails.imgSrc,'product')"
          onerror="this.src='assets/img/default-image.png';"
          class="cardImg"
          height="100%"
          [ngStyle]="{ height: height }"
          alt=""
        />
        <div class="sub-product" [id]="'demo' + subProducts[0].id">
          <div class="card cardStyl1">
            <h3 class="dCollec">CHECK OUT OUR TOP SELLERS</h3>
            <h3 class="dSub">Discover the Newness of the Season</h3>
            <div class="row row2 mb-1">
              <div class="col-6 p-0">
                <button type="button" class="btn btn-default btnStylBtm p-0">
                  <u>Learn More</u>
                </button>
              </div>
              <div class="col-6 p-0">
                <button
                  type="button"
                  class="btn btn-default btnStylBtm"
                  (click)="shareApi()"
                >
                  <img
                    class="logoImg1"
                    src="assets/img/shareIcon.svg"
                  />&nbsp;Share
                </button>
              </div>
            </div>
          </div>
          <div *ngIf="subProducts?.length > 0">
            <div
              class="card cardStyl accordion"
              [id]="'card' + subProducts[0].id"
              *ngFor="let pcard of subProducts; index as index"
            >
              <div class="row pb-0" style="padding: 1vh">
                <div class="col-5 autoTB">
                  <img
                    class="prod1"
                    [src]="'https://place10x.com/' + pcard.imgSrc"
                    onerror="this.src='assets/img/default-image.png';"
                    height="100%"
                    alt=""
                  />

                  <div class="row m-0 mt-2">
                    <button
                      class="btn btn-default shopBtnSub"
                      [class.shopBtnShop]="isShopBtnEnabled[index]"
                      [id]="'prodSubID' + productID + pcard.id"
                      (click)="shopNow(productID, pcard, index)"
                    >
                      {{ shopBtnText[index] }}
                    </button>
                  </div>
                </div>
                <div class="col-7 pl-2">
                  <div class="addCard p-0">
                    <button
                      type="button"
                      class="addBtn3"
                      data-toggle="collapse"
                      [id]="'btn' + pcard.id"
                      [attr.data-target]="'#' + productID + 'prod' + pcard.id"
                    >
                      <i
                        class="fa plusIcon"
                        [ngClass]="
                          isSubProdDescToggleOn[index] ? 'fa-minus' : 'fa-plus'
                        "
                        aria-hidden="true"
                        (click)="
                          isSubProdDescToggleOn[index] = !isSubProdDescToggleOn[
                            index
                          ]
                        "
                      ></i>
                    </button>
                  </div>
                  <p class="pStyl1">
                    {{ pcard.subProductName }}
                  </p>
                  <p class="grayText">
                    {{ pcard.subProductDesc }}
                  </p>
                  <p class="grayText1">Size: {{ pcard.size }}</p>
                  <p class="blackText">
                    <b
                      >Est. Retail&nbsp;{{ pcard.subProductPrice }}&nbsp;{{
                        pcard.priceUnit
                      }}</b
                    >
                  </p>
                  <p class="strikeText">
                    {{ pcard.subProductOfferPrice }}&nbsp;{{
                      pcard.priceUnit
                    }}&nbsp;{{ pcard.subProductOfferPercent }},
                  </p>
                  <p class="offExt">
                    <b
                      >{{ pcard.couponAmount }}&nbsp;{{
                        pcard.priceUnit
                      }}&nbsp;EXTRA&nbsp;{{ pcard.couponOfferPercent }}</b
                    >
                  </p>
                </div>
                <div
                  class="row p-0 mt-1 rowS"
                  *ngIf="isSubProdDescToggleOn[index] == true"
                >
                  <div class="col-12 grayText1 p-2">
                    {{ pcard.subProductSpecDesc }}
                  </div>
                </div>

                <div
                  class="row p-0 mt-1 rowS shareBtns"
                  [id]="'prod' + productID + 'sharehide' + pcard.id"
                >
                  <div class="col-6 p-0">
                    <button
                      type="button"
                      class="btn btn-default btnStylBtm p-0"
                    >
                      <u>Learn More</u>
                    </button>
                  </div>
                  <div class="col-6 p-0">
                    <button
                      type="button"
                      class="btn btn-default btnStylBtm"
                      (click)="shareApi()"
                    >
                      <img
                        class="logoImg1"
                        src="assets/img/shareIcon.svg"
                      />&nbsp;Share
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
