import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { EventEmitterService } from 'src/app/services/EventEmitter/event-emitter.service';
import { CouponServiceService } from 'src/app/services/coupon/coupon-service.service';
import { ToastrService } from 'ngx-toastr';
import { Logging } from 'src/app/data/logging.model';
import { UserActivity } from 'src/app/data/user-activity.enum';
import { LoggingService } from 'src/app/services/logging.service';

@Component({
  selector: 'app-expanded-template',
  templateUrl: './expanded-template.component.html',
  styleUrls: ['./expanded-template.component.scss']
})
export class ExpandedTemplateComponent implements OnInit {

  @Input("campaignData")
  public campaignData = [];
  public height = '30vh';
  public shopBtnText: string[] = [];
  public isShopBtnEnabled: boolean[] = [];
  public isSubProdDescToggleOn: boolean[] = [];
  navigator = window.navigator as any;
  startTime= new Date();

  constructor(public router: Router,
    private service: EventEmitterService,
    private couponService: CouponServiceService,
    private toastr: ToastrService,
    private loggingService: LoggingService) { }

  ngOnInit() {
    //this.setDefaultValuesForProductElem(this.campaignData.length);
    let endTime = new Date();

    let userActivity: Logging[] = [];
      console.log(this.startTime);
      console.log(endTime.getTime())
      let campaignId = localStorage.getItem("campainMgmtId");
      userActivity.push({
        advId: '',
        longitute:localStorage.getItem("Longitude"),
        latitude:localStorage.getItem("Latitude"),
        campaignId: campaignId,
        categoryId:'',
        productId: '',
        subProductId: '',
        userId: localStorage.getItem("deviceId"),
        userAction: UserActivity[UserActivity.FRONTPAGE_B_EXPANDED_VIEW_DISPLAY],
        startTime: this.startTime.getTime(),
        endTime:  endTime.getTime(),
        duration: endTime.getTime() - this.startTime.getTime()
      });
      console.log("UserActivity",userActivity);
      this.loggingService.saveUserActivity(userActivity).subscribe();
  }
  getImage(path: string,basePath) {
    let pathName= path.split(/(\\|\/)/);
    var path1 = pathName[pathName.length-1];
    console.log('path11111',path1);
    return this.couponService.getImage(path1,basePath);
  }
  private setDefaultValuesForProductElem(lengthOfProduct: Number) {
    for (var i = 0; i < lengthOfProduct; i++) {
      this.shopBtnText.push("Earn AED");
      this.isShopBtnEnabled.push(false);
      this.isSubProdDescToggleOn.push(false);
    }
  }
  shopNow(productId, subProduct, index, card) {
    if (!subProduct.isShopBtnEnabled) {
      subProduct.isShopBtnEnabled = true;
    } else {
      //Shop button clicked
      //this.shopBtnText[index] = "Earn AED";
      let userActivity: Logging[] = [];
      let campaignId = card.campainMgmtId;
      userActivity.push({
        advId: '',
        longitute:localStorage.getItem("longitude"),
        latitude:localStorage.getItem("Latitude"),
        campaignId: campaignId,
        categoryId:JSON.stringify(card.catId),
        productId: productId,
        subProductId: subProduct.id,
        userId: localStorage.getItem("deviceId"),
        userAction: UserActivity[UserActivity.USER_CLICKED_SHOP_NOW],
        startTime: 0,
        endTime: 0,
        duration: 0
      });



      this.loggingService.saveUserActivity(userActivity);
      subProduct.isShopBtnEnabled = false;
      var test2 = localStorage.getItem("mobileNo");
      if (localStorage.getItem("mobileNo") === null) {
        //user not registered
        this.router.navigate(["/login/login", { id: productId }]);
      } else {
        //user already registered
        localStorage.removeItem("subProdDetails");
        localStorage.removeItem("offer");
        localStorage.setItem("subProdDetails", JSON.stringify(subProduct));
        localStorage.setItem("offer", JSON.stringify(productId));
        localStorage.setItem("subprodId", subProduct.id);
        localStorage.setItem("subprodPrice", subProduct.subProductPrice);
        this.router.navigate(["/offer"]);
      }
    }
  }
  toggleAccordian(event, index) {
    var element = event.target;
    element.classList.toggle("active");
    if (this.campaignData[index].isActive) {
      this.campaignData[index].isActive = false;
    } else {
      this.campaignData[index].isActive = true;
    }
    var panel = element.nextElementSibling;
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px";
    }
  }
  shareApi() {
    if (this.navigator.share) {
      this.navigator
        .share({
          title: "Shop10x.com",
          url: this.router.url,
          text:
            "Hey i found this interesting offer.Visit this link to check the offer" +
            this.router.url,
        })
        .then(() => {
          console.log("Thanks for sharing!");
        })
        .catch(console.error);
    } else {
      this.toastr.error("", "Something went wrong", { timeOut: 1600 });
    }
  }

}
