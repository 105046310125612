import { Component, OnInit } from '@angular/core';
import { Logging } from 'src/app/data/logging.model';
import { UserActivity } from 'src/app/data/user-activity.enum';
import { LoggingService } from 'src/app/services/logging.service';

@Component({
  selector: 'app-redeem',
  templateUrl: './redeem.component.html',
  styleUrls: ['./redeem.component.scss']
})
export class RedeemComponent implements OnInit {
  public template: string;
  public headerFix = false;
  public startTime = new Date ();
  Value:string = "20% Off";
  Longitude:any;
  Latitude:any;
  constructor( private loggingService:LoggingService) { }

  ngOnInit() {
   
    this.getLocation();
    this.saveUserActivity();
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.showPosition);
    } else { 
      console.log( "Geolocation is not supported by this browser.");
    }
  }
  showPosition(position) {
    localStorage.setItem('Latitude',position.coords.latitude);
    // this.Latitude=position.coords.latitude;
    // this.Longitude=position.coords.longitude;
    localStorage.setItem('Longitude',position.coords.longitude);
    console.log( "Latitude: " + position.coords.latitude + 
    "<br>Longitude: " + position.coords.longitude);
  }

  saveUserActivity () {
    let userActivity: Logging[] = [];
    let endTime = new Date();
    userActivity.push({
      advId: '',
      longitute:localStorage.getItem("Longitude"),
      latitude:localStorage.getItem("Latitude"),
      campaignId: '' ,
      categoryId:'',
      productId: '',
      subProductId: '',
      userId: localStorage.getItem("deviceId"),
      userAction: UserActivity[UserActivity.USER_REDEEMED],
      startTime: this.startTime.valueOf(),
      endTime: endTime.valueOf(),
      duration: endTime.valueOf() - this.startTime.valueOf()
    });



    this.loggingService.saveUserActivity(userActivity).subscribe();
  }

}
