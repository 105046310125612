
<app-header></app-header>

<app-lowhead></app-lowhead>



<ng-container>

  <app-tiles-templatep [campaignData]="coupons"></app-tiles-templatep>


</ng-container>

