import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-earn-header',
  templateUrl: './earn-header.component.html',
  styleUrls: ['./earn-header.component.scss']
})
export class EarnHeaderComponent implements OnInit {
  public date=new Date();
  public curdate = (new Date().getMonth() + 1).toString() + '-' + new Date().getFullYear().toString();
  public month = this.date.toLocaleString('default', { month: 'long' });
  public year = this.date.getFullYear();
  constructor() { }
  @Input() showLogo = false;
  ngOnInit() {
  }

}
