import { EventEmitterService } from "./services/EventEmitter/event-emitter.service";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { JwtModule } from '@auth0/angular-jwt';
import { AppRoutingModule } from "./app-routing.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppComponent } from "./app.component";
import { CouponCardComponent } from "./pages/coupon/coupon-card/coupon-card.component";
import { FilterComponent } from "./pages/coupon/filter/filter.component";
import { OfferDetailsComponent } from "./pages/offer-details/offer-details.component";
import { NgOtpInputModule } from "ng-otp-input";
import { ShowCouponComponent } from "./pages/coupon/show-coupon/show-coupon.component";
import { HeaderComponent } from "./pages/header/header.component";
import { SurveyNewComponent } from "./pages/survey-new/survey-new.component";
import { CouponServiceService } from "./services/coupon/coupon-service.service";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { LoginComponent } from "./pages/login/login.component";
import { FooterComponent } from "./pages/footer/footer.component";
import { OfferDetails2Component } from "./pages/offer-details2/offer-details2.component";
import { RegistrationComponent } from "./pages/registration/registration.component";
import { HomeComponent } from "./pages/home/home.component";
import { CartComponent } from "./pages/cart/cart.component";
import { ClaimHeaderComponent } from "./pages/claim-header/claim-header.component";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { SearchBarComponent } from "./pages/search-bar/search-bar.component";
import { EarnHeaderComponent } from "./pages/earn-header/earn-header.component";
import { DashboarddeskComponent } from "./pages/dashboarddesk/dashboarddesk.component";
import { LoginNewComponent } from "./pages/login-new/login-new.component";
import { RegistrationNewComponent } from "./pages/registration-new/registration-new.component";
import { LoginHeaderComponent } from "./pages/login-header/login-header.component";
// import { ModalModule } from 'ngx-bootstrap/modal';
import { ProductCardComponent } from "./pages/product-card/product-card.component";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { SearchPageComponent } from "./pages/search-page/search-page.component";
import { SearchMenuComponent } from "./pages/search-menu/search-menu.component";
import { CategorypageComponent } from "./pages/categorypage/categorypage.component";
import { ScrollComponent } from "./pages/scroll/scroll.component";
import { ProdscrollComponent } from "./pages/prodscroll/prodscroll.component";
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { LocationComponent } from "./pages/location/location.component";
import { WalletComponent } from "./pages/wallet/wallet.component";
import { SigninService } from "./services/signin/signin.service";
import { CartService } from "./services/cart/cart.service";
import { ScannerComponent } from "./pages/scanner/scanner.component";
import { Scanner1Service } from "./services/scanner1.service";
import { ZXingScannerModule } from "@zxing/ngx-scanner";
import { ShareComponent } from "./pages/share/share.component";
import { ToastrModule } from "ngx-toastr";
import { I18nModule } from "./i18n/i18n.module";
import { ProductDetailsComponent } from "./pages/product-details/product-details.component";
import { AdsequenceService } from "./services/adsequence/adsequence.service";
import { RequestInterceptor } from "./interceptor/Request-Interceptor";
import { FrontPageBComponent } from './pages/front-page-b/front-page-b.component';
import { TilesTemplateComponent } from './pages/front-page-b/tiles-template/tiles-template.component';
import { BannerTemplateComponent } from './pages/front-page-b/banner-template/banner-template.component';
import { ExpandedTemplateComponent } from './pages/front-page-b/expanded-template/expanded-template.component';
import { WifiRouterComponent } from './pages/wifi-router-component/wifi-router-component.component';
import { WifiRouterService } from './services/wifi-router/wifi-router-service.service';
import { ClickToConnectComponent } from './pages/front-page-b/click-to-Connect/click-to-connect.component';
import { AuthServiceConfig, GoogleLoginProvider, SocialLoginModule } from "angularx-social-login";
import { RedeemComponent } from './pages/redeem/redeem.component';
import { QRCodeModule } from 'angularx-qrcode';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { QrCodeComponent } from './pages/qr-code/qr-code.component';
import { MallsComponent } from './pages/malls/malls.component';
import { NavComponent } from './nav/nav.component';
import { HeadernewComponent } from './pages/newUI/headernew/headernew.component';
import { LowheadComponent } from './pages/newUI/lowhead/lowhead.component';
import { CategoryComponent } from './pages/newUI/category/category.component';
import { TilesTemplatepComponent } from './pages/front-page-b/tiles-templatep/tiles-templatep.component';
import { TilesTemplatewComponent } from './pages/front-page-b/tiles-templatew/tiles-templatew.component';
import { WalletnewComponent } from './pages/newUI/walletnew/walletnew.component';
import { OfferDetailsNewComponent } from './pages/newUI/offer-details-new/offer-details-new.component';
import { SearchBarNewComponent } from './pages/newUI/searchnew/search-bar-new/search-bar-new.component';
import { SearchComponent } from './pages/newUI/searchnew/search/search.component';
import { StoreComponent } from './pages/newUI/store/store.component';
import { DashboardnewComponent } from './pages/newUI/dashboardnew/dashboardnew.component';
import { OfferDetailsnew2Component } from './pages/offer-detailsnew2/offer-detailsnew2.component';
import { ProductComponent } from './pages/newUI/product/product.component';
import { NewComponent } from './pages/newUI/filters/new/new.component';
import { DealComponent } from './pages/newUI/filters/deal/deal.component';
import { TrendingComponent } from './pages/newUI/filters/trending/trending.component';
import { AllproductsComponent } from './pages/newUI/filters/allproducts/allproducts.component';


let config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider("966426996944-5qdcvvlpiahbmsrgjfofk0l7se0rbnb5.apps.googleusercontent.com")
  },
  
]);
export function provideConfig() {
  return config;
}

@NgModule({
  declarations: [
    AppComponent,
    FilterComponent,
    CouponCardComponent,
    HeaderComponent,
    ShowCouponComponent,
    OfferDetailsComponent,
    SurveyNewComponent,
    OfferDetails2Component,
    DashboardComponent,
    LoginComponent,
    FooterComponent,
    RegistrationComponent,
    HomeComponent,
    CartComponent,
    ClaimHeaderComponent,
    EarnHeaderComponent,
    SearchBarComponent,
    DashboarddeskComponent,
    LoginNewComponent,
    RegistrationNewComponent,
    LoginHeaderComponent,
    ProductCardComponent,
    SearchPageComponent,
    SearchMenuComponent,
    CategorypageComponent,
    ScrollComponent,
    ProdscrollComponent,
    LocationComponent,
    WalletComponent,
    ScannerComponent,
    ShareComponent,
    ProductDetailsComponent,
    FrontPageBComponent,
    TilesTemplateComponent,
    BannerTemplateComponent,
    ExpandedTemplateComponent,
    WifiRouterComponent,
    ClickToConnectComponent,
    RedeemComponent,
    QrCodeComponent,
    MallsComponent,
    NavComponent,
    HeadernewComponent,
    LowheadComponent,
    CategoryComponent,
    TilesTemplatepComponent,
    TilesTemplatewComponent,
    WalletnewComponent,
    OfferDetailsNewComponent,
    SearchBarNewComponent,
    SearchComponent,
    StoreComponent,
    DashboardnewComponent,
    OfferDetailsnew2Component,
    ProductComponent,
    NewComponent,
    DealComponent,
    TrendingComponent,
    AllproductsComponent
  ],
  imports: [
    HttpClientModule,
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    NgOtpInputModule,
    BrowserAnimationsModule,
    Ng2SearchPipeModule,
    ZXingScannerModule,
    ToastrModule.forRoot(),
    I18nModule,
    SocialLoginModule,
    QRCodeModule,
   
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    JwtModule.forRoot({
      config: {
        tokenGetter: () => localStorage.getItem('access_token'),
        allowedDomains: ['place10x.silicontechlab.com'],
        disallowedRoutes: ['place10x.silicontechlab.com/wifi10x/oauth/token']
      }
    })
   

    // ModalModule,
    // ModalModule.forRoot(),
  ],
  providers: [
    HttpClient,
    CouponServiceService,
    EventEmitterService,
    SigninService,
    CartService,
    Scanner1Service,
    
    AdsequenceService,
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    WifiRouterService,
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
