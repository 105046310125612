import { TranslateService } from '@ngx-translate/core';
import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { UserActivity } from 'src/app/data/user-activity.enum';
import { Logging } from 'src/app/data/logging.model';
import { LoggingService } from 'src/app/services/logging.service';
import { CouponServiceService } from 'src/app/services/coupon/coupon-service.service';

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  
  public stores:any;
  public malls:any;
  @Output() storeId = new EventEmitter<any>();
  @Output() LanguageChanged = new EventEmitter<any>();
  @Output() categoryIdChanged = new EventEmitter<any>();
  public scrollCard = [];
  showFooter:boolean = false;
  showCategory:boolean = true;
  showMalls:boolean = false;
  private startTime= new Date();
  constructor(public router:Router,public translate:TranslateService, private loggingService:LoggingService,private couponService:CouponServiceService) {
    let currentUrl = this.router.url;
    if(currentUrl == '/banner') {
      this.showFooter = true;
    } else {
      this.showFooter = false;
    }
    if(currentUrl == '/category') {
      this.showCategory = false;
      this.showMalls = false;
    } else if (currentUrl == '/malls'){
      this.showCategory = false;
      this.showMalls = true;
    } else {
      this.showMalls = false;
      this.showCategory = true;
    }
  }

  ngOnInit() {
    this.getAllCategory();
    this.stores=JSON.parse(localStorage.getItem("stores"));
  }

  NewTab() {
    window.open("https://Place10x.silicontechlab.com/Mall10XV2/index.html#/login", "_blank");
  }
  NewTab1() {
    window.open("https://Place10x.silicontechlab.com/wyyn/index.html#/login/admin", "_blank");
  }

  storeClicked(id) {
    this.storeId.emit(id);
    console.log("currentUrl",this.storeId);
    let currentUrl = this.router.url;
  
    // this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
    //   console.log("currentUrl",currentUrl);
    //   this.router.navigate([currentUrl]);
    // })
  }

  getAllMalls (id) {
    this.couponService.getAllMalls(id).subscribe(result => {
      console.log(result);
      this.malls = result['sellerresponse'];
    })
  }

  mallSelected(id) {
    this.router.navigate(['/malls'],{ queryParams: { mId: id }});
    // this.getAllStores(id);
  }
  
  getAllCategory() {
    this.scrollCard = [];
    this.couponService.getAllCategory().subscribe(data => {
      this.scrollCard = data.categoryResponses;
      // this.splitCategoryArray();
    }, error => {
      console.log(error);
      alert('Some Error Occured');
    });

}

  openHome(id) {
    console.log("id",id);
    // let endTime = new Date();
    // let userActivity: Logging[] = [];
    // console.log(this.startTime);
    // console.log(endTime.getTime())
    // userActivity.push({
    //   advId: '',
    //   longitute:localStorage.getItem("Longitude"),
    //   latitude:localStorage.getItem("Latitude"),
    //   campaignId:'' ,
    //   categoryId:JSON.stringify("id"),
    //   productId: '',
    //   subProductId: '',
    //   userId: localStorage.getItem("deviceId"),
    //   userAction: UserActivity[UserActivity.USER_CLICKED_CATEGORY],
    //   startTime: this.startTime.getTime(),
    //   endTime:  endTime.getTime(),
    //   duration: endTime.getTime() - this.startTime.getTime()
    // });
    // console.log("UserActivity",userActivity);



    // this.loggingService.saveUserActivity(userActivity).subscribe();
    this.couponService.setCategoryId(id);
    this.categoryIdChanged.emit(id);
    this.router.navigate(['/home']);
    //this.router.navigate(['/home',{d:id}],{ relativeTo: this.activeRoute });
  }

  changeLanguage(item) {
    console.log("item",item);
    this.translate.use(item);
    localStorage.setItem('language', item);
    this.LanguageChanged.emit(item);
    this.getAllCategory();
    // this.reloadCurrentRoute();
    
  }
  reloadCurrentRoute() {
    let currentUrl = this.router.url;
  
    this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
      console.log("currentUrl",currentUrl);
      this.router.navigate([currentUrl]);
    });
  }
}
