import { Component, OnInit, HostListener ,EventEmitter} from '@angular/core';
import { Router ,ActivatedRoute} from '@angular/router';
import { Location } from '@angular/common';

import { ToastrService } from 'ngx-toastr';
import { Logging } from 'src/app/data/logging.model';
import { UserActivity } from 'src/app/data/user-activity.enum';
import { CouponServiceService } from 'src/app/services/coupon/coupon-service.service';
import { LoggingService } from 'src/app/services/logging.service';
@Component({
  selector: 'app-walletnew',
  templateUrl: './walletnew.component.html',
  styleUrls: ['./walletnew.component.scss']
})
export class WalletnewComponent implements OnInit {

  public show = false;
  public headerFix = false;
  public scrollY!: string;
  public difference = 280;
  public value = 250;
  public id=2;
  public change: EventEmitter<number> = new EventEmitter<number>();
  public coupons: any;
  public categoryId!: Number;

  // public headerFix = false;
  // public difference = 280;
  // public value = 250;
  public value1 = 250;
  // public coupons=[];
  public couponList=[];
  ValidDate:any;
  private startTime= new Date();
  navigator = window.navigator as any;
  coupon: { advId: string; campaignId: number; categoryId: number; productId: number; productName: string; brandId: number; new: number; deal: number; trend: number; exclusive: number; price: string; imgSrc: string; isaddButton: boolean; }[] | undefined;

  constructor(
    private router:Router,
    public activateRouter: ActivatedRoute,
    private toastr:ToastrService,
    private location:Location,
    private loggingService: LoggingService,
    private couponService: CouponServiceService,
  ) { }

  ngOnInit(): void {
    this.getAllWallet();
    this.coupon=[ {
      advId: '',
      campaignId: 1,
      categoryId: 1,
      productId: 1,
      productName: "P1",
      brandId: 1,
      new: 1,
      deal: 0,
      trend:1,
      exclusive: 0,
      price: "H",
      imgSrc: "../../../assets/cart.jpeg",
      isaddButton:true,
    },
    {
      advId: '',
      campaignId: 2,
      categoryId: 1,
      productId: 1,
      productName: "P2",
      brandId: 1,
      new: 0,
      deal: 1,
      trend:0,
      exclusive: 0,
      price: "L",
      imgSrc: "../../../assets/def.jpeg",
        isaddButton:true,
    },
    {
      advId: '',
      campaignId: 3,
      categoryId: 1,
      productId: 1,
      productName: "P3",
      brandId: 1,
      new: 0,
      deal: 1,
      trend:1,
      exclusive: 1,
      price: "L",
      imgSrc: "../../../assets/close.jpeg",
        isaddButton:true,
    },];
  }
  openHome(){
    this.router.navigate(['../walletnew']);
  }
  openHome1(){
    this.router.navigate(['../walletnew']);
  }
  openHome2(){
    this.router.navigate(['../walletnew']);
  }

  getAllWallet() {
    let mobileNo=localStorage.getItem("mobileNo");
    console.log(mobileNo,"mob");
    
    this.couponService.getAllWallet1(mobileNo).subscribe(data => {
      // console.log(data);
      this.coupons = data.productresponse
      ;
      console.log('walletResponses List:', this.coupons);
      console.log('walletResponses List:', data);
      this.ValidDate=data.productresponses.orderTime;
    }, error => {
      console.log(error);
    });
  }
}
