import { Injectable, Injector } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { Router } from "@angular/router";


@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor(private router: Router) { }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // console.log("Intercepter request--->>>");
    // console.log(req.headers);
    const headers = new HttpHeaders({
      'Accept-Language': window.localStorage.getItem('language'),
      'deviceId': window.localStorage.getItem('deviceId'),

    });
    // .set('deviceId', window.localStorage.getItem('deviceId') == null ? "NO_ID" : window.localStorage.getItem('deviceId'))
    req = req.clone({
      headers: req.headers.set('Accept-Language', localStorage.getItem('language'))
        .set('deviceId', window.localStorage.getItem('routerdeviceId') == null ? "NO_ID" : window.localStorage.getItem('routerdeviceId'))

    });
    //headers: req.headers.set('Accept-Language', localStorage.getItem('language')),
    //req.headers.delete("Accept-Language");
    //req.headers.set("Accept-Language", localStorage.getItem('language'));
    /*{
      setHeaders: {
        "Accept-Language": localStorage.getItem('language')
      }
    }*/
    return next.handle(req).pipe(
      tap(
        () => { },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            // console.log("Intercept->>>>");
            if (err.status !== 401) {
              return;
            }
            //this.router.navigate(["login"]);
          }
        }
      )
    );
  }
}
