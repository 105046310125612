<app-headers></app-headers>
<app-earn-header></app-earn-header>
<div class="menu-box">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12 col-lg-12 col-md-12">
        <div class="left-menu">
          <a (click)="toggleMenu()"> <img class="menuIconStyl" *ngIf="menuToggle" src="assets/img/menuicon.svg"
          alt=""></a>

          <i (click)="toggleMenu()" *ngIf="!menuToggle" class="fa fa-times menuIconStyl clsbtn" aria-hidden="true"></i>

          <a href="">{{ "translations.topEarnersText" | translate }}</a>
          <a href="">{{ "translations.trendingText" | translate }}</a>
          <a href="">{{ "translations.newText" | translate }}</a>
          <a href="">{{ "translations.jumeirahText" | translate }}</a>
          <a href="">{{ "translations.brandzText" | translate }}</a>
          <a href="">{{ "translations.dineText" | translate }}</a>
        </div>
        <div class="right-box-menu">
          <ul>
            <li (click)="scan()">
              <i *ngIf="!showScan" class="fa fa-qrcode"></i>
              <app-scanner *ngIf="showScan"></app-scanner>
            </li>
            <li>
              <div class="dropdown">
                <span class="btn1 dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                 Mall
                </span>
                <div class="dropdown-menu" >
                  <a class="dropdown-item" *ngFor="let store of stores">{{store.storeName}}</a>
                  <!-- <a class="dropdown-item" href="#">Another action</a> -->
                  <!-- <a class="dropdown-item" href="#">Something else here</a> -->
                </div>
              </div>
            </li>
            <li>
              <i class="fa fa-calendar" aria-hidden="true"></i>
              <h5>
                {{month}}
                <span>{{year}}</span>
              </h5>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="bgclr">
  <app-search-page></app-search-page>

  <app-scroll></app-scroll>
  <app-footer></app-footer>
</div>














