import { Component, OnInit, Input, HostListener, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CouponServiceService } from 'src/app/services/coupon/coupon-service.service';
@Component({
  selector: 'app-tiles-templatew',
  templateUrl: './tiles-templatew.component.html',
  styleUrls: ['./tiles-templatew.component.scss']
})
export class TilesTemplatewComponent implements OnInit {

  @Input() campaignData: any;
  @ViewChild('scrollLock')
  scrollLock!: ElementRef;
  public height = '30vh';
  public shopBtnText: string[] = [];
  public isShopBtnEnabled: boolean[] = [];
  public isSubProdDescToggleOn: boolean[] = [];
  private startTime= new Date();
  navigator = window.navigator as any;
  public couponCode= "WWWWWyyy";
  public claimCard:any;
  public claimModalOpen:boolean=false;
  public isClaimButton:boolean = true;
  public showCode:boolean= false;
  public shareModal :boolean = false;
  public showMoreText:boolean =true;
  public desc = "BY PROVIDING THIS MOBILE NUMBER YOU CONFIRM YOU HAVE THE RECIPIENTS APPROVAL TO SHARE THE SAME."


  constructor(public router: Router, private couponService:CouponServiceService,private toastr: ToastrService,) { }

  ngOnInit(): void {
    const data = window.scrollY;
    console.log("Scrollinggggggg",data);
    let startTime = new Date();
    // this.setDefaultValuesForProductElem(this.campaignData.length);
    let endTime = new Date();
    this.campaignData.forEach((element: any) => {
     
    });

  }
  clBtnzClicked(claimCard: any) {
  
    this.isClaimButton= !this.isClaimButton;
    // this.toastr.warning("Please click once more to reconfirm . Once reconfirmed the voucher will be considered used")
  }
  claimBTNClicked(card: { id: any; }) {
    localStorage.setItem("cardDetails",JSON.stringify(card));
    localStorage.removeItem("subProdDetails");
    this.router.navigate(["/offer"],{ queryParams: { id: card.id }});
   
    this.claimModalOpen= true;
    console.log("temppppppp",card);
    this.claimCard=card;
  }
  claimsubproductBTNClicked(pcard: { id: any; }) {
    localStorage.setItem("subProdDetails",JSON.stringify(pcard));
    localStorage.removeItem("cardDetails");
    this.router.navigate(["/offer"],{ queryParams: { id: pcard.id }});
  }
  clBtnzClickedAgain(card: any) {
    this.showCode = true;
    
  }
  claimModalDismiss () {
    this.showCode = false;
    this.isClaimButton = true;
  }
  walletBtnclicked () {
    // this.toastr.success("added to wallet successfully");
  }
  @HostListener("window:scroll", [])
  onScroll(): void {
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
      // Load Your Data Here
      console.log("datatatatatatatatat");
    }
  }
  onScroll1(event: any) {
    console.log('scrolling');
  }
  clickMoreText() {
    this.desc = '';
    this.showMoreText = !this.showMoreText
    if(!this.showMoreText) {
      this.desc ="BY PROVIDING THIS MOBILE NUMBER YOU CONFIRM YOU HAVE THE RECIPIENTS APPROVAL TO SHARE THE SAME. BY ACCEPTING OUR TOS YOU AGREE TO HAVE READ AND UNDERSTAND HOW THE SERVICE IS PROVIDED."
    } else {
      this.desc = "BY PROVIDING THIS MOBILE NUMBER YOU CONFIRM YOU HAVE THE RECIPIENTS APPROVAL TO SHARE THE SAME."
    }
  }
  shareApi(card) {
    let endTime = new Date();
    
    // Shop button clicked
    // this.shopBtnText[index] = "Earn AED";
    // let userActivity: Logging[] = [];
    let campaignId = card.campainMgmtId;
    // userActivity.push({
    //   advId: '',
    //   longitute:localStorage.getItem("Longitude"),
    //   latitude:localStorage.getItem("Latitude"),
    //   campaignId: JSON.stringify(card.campainMgmtId),
    //   categoryId:JSON.stringify(card.catId),
    //   productId: JSON.stringify(card.productId),
    //   subProductId: '',
    //   userId: localStorage.getItem("userId"),
    //   // userAction: UserActivity[UserActivity.USER_SHARED],
    //   startTime: this.startTime.getTime(),
    //   endTime:  endTime.getTime(),
    //   duration: endTime.getTime() - this.startTime.getTime()
    // });
    // console.log("UserActivity",userActivity);
    


    // this.loggingService.saveUserActivity(userActivity).subscribe();

    if (this.navigator.share) {
      this.navigator
        .share({
          title: "place10x.com",
          url: this.router.url,
          text:
            "Hey i found this interesting offer.Visit this link to check the offer"
        })
        .then(() => {
          console.log("Thanks for sharing!");
        })
        .catch(console.error);
    } else {
      this.toastr.error("", "Something went wrong", { timeOut: 1600 });
    }
  }
  public renderImage( categoryId: number): string {
    return this.couponService.getImageprod( categoryId);
}
}
