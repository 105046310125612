<div class="deskBack">
  <img class="backImg" src="assets/img/backDubai2.jpg">
  <div class="headers">
  <!-- <app-header></app-header> -->
<div class="card cardiv">
  <div class="row divP">
    <p class="fontStyl1">New to</p>
    <p class="fontStyl2">&nbsp;Wifi10X</p>
    <p class="fontStyl1">&nbsp;?</p>
  </div>
  <div class="row">
    <button class="newAccountBtn" [routerLink]="['/register']">Create an Account</button>
  </div>
  <div class="row divStyl2">
    <p class="fontStyl">Already have an Account</p>
  </div>
  <div class="formStyl">
    <div class="form-group">
      <label for="email">Email address:</label>
      <input type="email" class="form-control" id="email">
    </div>
    <div class="form-group">
      <label for="pwd">Password:</label>
      <input type="password" class="form-control" id="pwd">
    </div>
    <div class="checkbox">
      <label><input type="checkbox"> Keep me logged in, What is this?</label>
    </div>
    <div>
      <a href="#">Forgot your Password?</a>
    </div>
    <div class="row">
      <button class="loginBtn" (click)="login()">Login</button>
    </div>
    <p class="or">Or</p>
    <div class="row">
      <button class="fbLoginBtn">Login with Facebook</button>
    </div>
  </div>
</div>
<div class="footer">
  <app-footer></app-footer>
  </div>

</div>
</div>
