<div *ngIf="!showFooter" class="container-fluid">
    <div class="row row1 pt-2">
      <div class="col-12">
        <div class="cokl">
          <div class="log" [routerLink]="['/category']">
            <img style="width: 60px !important;" src="assets/img/wifi10xlogo.png"   alt="">
          </div>
        <div class="right-footer" style="text-align: left;">
          <h5 class="text-left link1"><span>Quick Links</span></h5>
            <ul>
              <!-- <li ><a  href="" ></a>{{ "translations.topEarnersText" | translate }}</li>
              <li ><a  href=""></a>{{ "translations.trendingText" | translate }}</li>
              <li ><a  href="" ></a>{{ "translations.newText" | translate }}</li>
              <li ><a target="_blank" href=""></a>{{ "translations.jumeirahText" | translate }}</li>
              <li ><a  href="" ></a>{{ "translations.brandzText" | translate }}</li>
              <li ><a target="_blank" href=""></a>{{ "translations.dineText" | translate }}</li> -->
              <!-- <li *ngFor="let store of stores" (click)="storeClicked(store.id)"><a ></a>{{store.storeName}}</li> -->
              <li (click)="NewTab1()"><a target="_blank" href="https://place10x.silicontechlab.com/wyyn"></a>{{ "translations.AdminLogText" | translate }}</li>
              <li (click)="NewTab()"><a  href="https://place10x.silicontechlab.com/Mall10XV2" ></a>Bawadi Mall Sales</li>
              <div *ngIf="showCategory"><li *ngFor="let pcard of scrollCard" (click)="openHome(pcard.id)">{{pcard.catName}}</li></div>
              <div *ngIf="showMalls"><li *ngFor="let mall of malls"  (click)="mallSelected(mall.sellerId)">{{mall.sellerName}}</li></div>
            </ul>
          <select #lang (change)="changeLanguage(lang.value)">
            <option readOnly>Select Your Language</option>
            <option value="en">English</option>
            <option value="grk">Greek</option>
          </select>
        </div>
        </div>
           
        
        
      </div>
    </div>
 
</div>


<div *ngIf="showFooter" class="footer fs-ad-footer">
  <div class="footer-content">
    <div class="row m-0">
      <!-- <div class="col-2">
   
      </div> -->
      <div class="col-6 text-left">
        <b>{{ "translations.tapText" | translate }}</b>
        <br>
        <b>{{ "translations.connectText" | translate }}</b>
      </div>
      <div class="col-6" style="margin: right -85px;">
          <img class="logo-img mt-1"  src="assets/img/wifi10xlogo.png" >
        <!-- <img class="nissan-img float-right mt-1" src="assets/img/nissan.jfif"> -->
        <br>
      </div>
    </div>
  </div>
  <!-- Click here for Free High-Speed Internet -->
</div>