import { CouponServiceService } from './../../../services/coupon/coupon-service.service';
import { Component, OnInit, Input } from '@angular/core';
import { Coupon } from 'src/app/data/data';

@Component({
  selector: 'app-coupon-card',
  templateUrl: './coupon-card.component.html',
  styleUrls: ['./coupon-card.component.scss']
})
export class CouponCardComponent implements OnInit {

  @Input() backImage: Coupon;
  @Input() couponCode: boolean;
  @Input() ClaimCode: boolean;
  backCss;
  public showCodeDiv:boolean;
  public showCode:boolean=false;
  public backId = 1;
  constructor(public couponServe: CouponServiceService) {
  }

  ngOnInit() {

    if (this.backImage !== undefined) {
      this.backId = this.backImage.id;
    }
    this.backCss = {
      'background-image': 'url(assets/img/background' + this.backId + '.png)',
    };
    if (this.couponCode === true) {
      this.showCodeDiv = true;
    } else {
      this.showCodeDiv = false;
    }
    console.log('this.ClaimCode', this.ClaimCode);


  this.couponServe.getUpdatedData.subscribe( () =>{
      console.log("this.ClaimCode",this.ClaimCode);
      if(this.ClaimCode===true){
        this.showCode=true;
      }else{
        this.showCode=false;
      }
    });
  }

}
