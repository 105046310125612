import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Country } from "src/app/data/data";
import { SigninService } from "src/app/services/signin/signin.service";
import { ToastrService } from "ngx-toastr";
import { environment } from "src/environments/environment";
import { Location } from "@angular/common";

@Component({
  selector: "app-login-new",
  templateUrl: "./login-new.component.html",
  styleUrls: ["./login-new.component.scss"],
})
export class LoginNewComponent implements OnInit {
  starIcon = false;
  otp: string;
  mobileNo: string;
  mobile: string;
  showOtpComponent = true;
  showCountryModal = false;
  claim = false;
  cookieMessage;
  cookieDismiss;
  cookieLinkText;
  isDisableLogin = true;
  isDisableClaim = true;
  disableSendOTP = true;
  disableInput = false;
  public showCode = false;
  public country: Country[];
  public selectedCountry: Country;
  @Input() ClaimCode: boolean;
  @ViewChild("ngOtpInput", { static: true }) ngOtpInput: any;
  config = {
    allowNumbersOnly: true,
    length: 4,
    isPasswordInput: false,
    disableAutoFocus: true,
    placeholder: "",
    containerStyles: {
      width: "100%",
      // padding: '0.5rem !important';
    },
    inputStyles: {
      // width: "12vw",
      // height: "12vw",
      // 'margin-right': '11%',
      // 'div: nth - child(n + 6)' ? 'background-color:grr' : 'red',
      "border-radius": "0px !important",
      border: "0",
      outline: "1px solid #bbbbbb",
      "text-align": "center",
      "font-size": "17px",
      "background-color": "rgba(236, 236, 236, 0.59)",
    },
  };

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private loginService: SigninService,
    private toastr: ToastrService,
    private location:Location
  ) { }

  ngOnInit() {
    /*let cc = window as any;
    cc.cookieconsent.initialise({
      palette: {
        popup: {
          background: "#164969"
        },
        button: {
          background: "#ffe000",
          text: "#164969"
        }
      },
      theme: "classic",
      content: {
        message: this.cookieMessage,
        dismiss: this.cookieDismiss,
        link: this.cookieLinkText,
        href: environment + "/dataprivacy"
      }
    });*/

    this.country = [
      {
        CountryId: 423,
        Country: "Greece",
        CountryCode: 30,
        CountryIcon:
          "https://caccount.policybazaar.com/content/countryIcon/in.svg",
      },
      {
        CountryId: 392,
        Country: "INDIA",
        CountryCode: 91,
        CountryIcon:
          "https://caccount.policybazaar.com/content/countryIcon/in.svg",
      },
      {
        CountryId: 375,
        Country: "UNITED ARAB EMIRATES",
        CountryCode: 971,
        CountryIcon:
          "https://caccount.policybazaar.com/content/countryIcon/ae.svg",
      },
      {
        CountryId: 24,
        Country: "AUSTRALIA",
        CountryCode: 61,
        CountryIcon:
          "https://caccount.policybazaar.com/content/countryIcon/au.svg",
      },
      {
        CountryId: 375,
        Country: "Brazil",
        CountryCode: 55,
        CountryIcon:
          "https://caccount.policybazaar.com/content/countryIcon/br.svg",
      },
      {
        CountryId: 24,
        Country: "Canada",
        CountryCode: 1,
        CountryIcon:
          "https://caccount.policybazaar.com/content/countryIcon/ca.svg",
      },
      {
        CountryId: 375,
        Country: "Hong Kong",
        CountryCode: 582,
        CountryIcon:
          "https://caccount.policybazaar.com/content/countryIcon/hk.svg",
      },
      {
        CountryId: 375,
        Country: "Italy",
        CountryCode: 39,
        CountryIcon:
          "https://caccount.policybazaar.com/content/countryIcon/it.svg",
      },
      {
        CountryId: 24,
        Country: "Iceland",
        CountryCode: 354,
        CountryIcon:
          "https://caccount.policybazaar.com/content/countryIcon/is.svg",
      },
      {
        CountryId: 375,
        Country: "Japan",
        CountryCode: 81,
        CountryIcon:
          "https://caccount.policybazaar.com/content/countryIcon/jp.svg",
      },
      {
        CountryId: 375,
        Country: "Malaysia",
        CountryCode: 60,
        CountryIcon:
          "https://caccount.policybazaar.com/content/countryIcon/my.svg",
      },
      {
        CountryId: 24,
        Country: "Mexico",
        CountryCode: 52,
        CountryIcon:
          "https://caccount.policybazaar.com/content/countryIcon/mx.svg",
      },
      {
        CountryId: 375,
        Country: "Qatar",
        CountryCode: 974,
        CountryIcon:
          "https://caccount.policybazaar.com/content/countryIcon/qa.svg",
      },
      {
        CountryId: 24,
        Country: "Saudi Arabia",
        CountryCode: 966,
        CountryIcon:
          "https://caccount.policybazaar.com/content/countryIcon/sa.svg",
      },
    ];
    this.selectedCountry = this.country[0];
  }

  onOtpChange(otp) {
    this.otp = otp;
    // this.mobile = '+' + this.selectedCountry.CountryCode + this.mobileNo;
    // console.log('otp', this.otp);
    // if (this.otp === '1234') {
    //   this.isDisable = false;
    //   this.showCode = true;
    // } else {
    //   this.isDisable = true;
    //   this.isDisableClaim = true;
    //   this.showCode = false;
    //   if (this.otp.length === 4) {
    //     alert('Invalid OTP');
    //     // tslint:disable: no-unused-expression
    //     this.otp === ' ';
    //     console.log(this.ngOtpInput);
    //     this.ngOtpInput.setValue('');
    //   }
    // }
    if (this.otp.length === 4) {
      // this.mobileNo=+(this.mobile.substring(1));
      console.log(this.mobile,"mobile");
      const data = {
        mobileNo: this.mobile,
        otp: this.otp,
      };
      this.loginService.validateOtp(data).subscribe(
        (res) => {
          if (res.status == 200) {
            this.isDisableLogin = false;
            localStorage.setItem("mobileNo", this.mobile);
            this.toastr.success("", "OTP success", { timeOut: 1600 });
          } else {
            // alert("Wrong OTP");
            this.toastr.error("", "Invalid OTP", { timeOut: 1600 });
          }

          // console.log(res);
        },
        (err) => {
          // console.log(err.err);
        
          // this.toastr.warning("", "OTP faild", { timeOut: 1600 });
          // this.disableInput = false;
          this.isDisableLogin = false;
          localStorage.setItem("mobileNo", this.mobile);
          this.toastr.success("", "OTP success", { timeOut: 1600 });
        }
      );
      /*if (otp==1234) {
        this.isDisableLogin = false;
        localStorage.setItem("mobileNo",this.mobile);
        this.toastr.success("","OTP success",{timeOut:1600});
      }
      else
      {
        console.log(otp);
       // alert("Wrong OTP");
       this.toastr.error("","Invalid OTP",{timeOut:1600});
      }*/
    }
  }

  clickCountry(data) {
    // console.log(data);
    this.selectedCountry = data;
  }
  numberOnly(event): boolean {
    console.log(event);
    // console.log(this.mobileNo.length, event);
    // console.log(this.mobileNo.length > 9);
    const charCode = event.which ? event.which : event.keyCode;
    if (
      (charCode > 31 && (charCode < 48 || charCode > 57)) ||
      this.mobileNo.toString().length > 15
    ) {
      return false;
    }
    return true;
  }
  validateNumber() {
    // if (this.mobileNo != null && this.mobileNo.toString().length > 9) {

    if (this.mobileNo != null) {
      this.disableSendOTP = false;
    } else {
      this.disableSendOTP = true;
    }
    // console.log(this.mobileNo.toString().length, this.disableSendOTP);
  }

  sendOTP() {
    console.log("+" + this.selectedCountry.CountryCode + this.mobileNo);
    // this.mobile = "+" + this.selectedCountry.CountryCode + this.mobileNo;
        this.mobile =this.mobileNo;

    if (confirm("Are you sure to send OTP to " + this.mobile)) {
      // console.log('Confirmed');
      this.loginService.sendOTP({ mobileNo: this.mobile }).subscribe(
        (res) => {
          // console.log(res);
          this.disableSendOTP = true;
          this.disableInput = true;
        },
        (err) => {
          console.log(err);
        }
      );
    }
    // this.disableSendOTP = true;
    // this.disableInput = true;
  }

  login() {
    console.log(this.mobile);
      const data = {
        mobileNo: this.mobile,
        otp: this.otp,
      };
      this.loginService.loginuser(data).subscribe(
        (res) => {
          if (res.status == 200) {
       
            localStorage.setItem('userType','2');
            localStorage.setItem("mobNo", this.mobile);
            this.toastr.success("", "success", { timeOut: 1600 });
          } else {
            // alert("Wrong OTP");
            this.toastr.error("", "Invalid", { timeOut: 1600 });
          }

          // console.log(res);
        },
        (err) => {
          console.log(err.err);
          //alert('Invalid OTP');
          this.toastr.warning("", " faild", { timeOut: 1600 });
          this.disableInput = false;
        }
      );
    // localStorage.setItem('userType','2');
    // localStorage.setItem("mobNo", "" + this.mobile);
    var id = this.route.snapshot.paramMap.get("id");
    
    console.log(" iddddd  ;......." + id);
    if (id != null) this.router.navigate(["/offer"]);
    else
    //  this.router.navigate(["/home"]);
    this.location.back();
  }
  toggleIcon() {
    this.starIcon = !this.starIcon;
  }
}
