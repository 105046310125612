<div class="bg">
  <div class="shadow bg-white">
    <div [ngClass]="headerFix?'earn-header':''">
      <app-earn-header [showLogo]="true"></app-earn-header>
    </div>
  </div>
  <div class="headers">
    <!-- [ngStyle]="{'margin-top': headerFix?'100px':'0px'}" -->
    <div class="header col-12">
      <div class="row navStyl">
        <ul class="nav">
          <li class="nav-item">
            <a> <i class="fa fa-bars iconStyl" aria-hidden="true"></i></a>
          </li>
          <img class="noonLogo" src="assets/img/noon.png" alt="">
          <p class="pStyl">
            <a> <i class="fa fa-search iconStyl2" aria-hidden="true"></i></a>
            <a> <i class="fa fa-shopping-cart iconStyl2" aria-hidden="true"></i></a>
          </p>
        </ul>
      </div>
    </div>

    <div *ngIf="cartResp.length==0" class="empDiv">
      <img class="emptCart" src="assets/img/empCart7.png" alt="">
      <div class="noData">Your cart is empty!</div>
      <div class="noData1">Add items to it now.</div>
      <button class="shopBtn" [routerLink]="['/category']">Shop Now</button>
    </div>

    <div *ngIf="cartResp.length!=0" class="overflow row col-12 p-0">
      <div class="card jumbStyl col-12">
        <button class="checkoutBtn">Checkout Now</button>
      </div>
      <div class="card cardStyl col-sm-12 col-lg-6" *ngFor="let pcard of cartResp">
        <div class="row mt-2">
          <div class="col-3 p-1">
            <!-- <img class="prod1 img-thumbnail" [src]="'https://wifi10x.com/'+pcard.imgSrc" alt=""> -->
            <img class="prod1 img-thumbnail" [src]="getImage(pcard.imgSrc,'product')" alt="">
          </div>
          <div class="col-9">
            <!-- <p class="pStyl3" style="margin-top: 1vh;font-weight: 500; letter-spacing: 0.5px;">
              Positively Conscious
            </p> -->
            <p class="pStyl1">
              <!-- Galaxy Z Flip Mirror Purple 8GB RAM 256GB 4G -->
              {{pcard.subProductName}}
            </p>
            <p class="pStyl1St">
              <!-- Model Number: SM-F700FZPD -->
              {{pcard.modelNumber}}
            </p>
            <!-- <p class="pStyl3 ">
              Estimated Delivery
            </p>
            <p class="pStyl2">
              April 20 - April 28
            </p> -->
            <p style=" margin: 0vh;margin-top: 1vh;">
              <span class="pStyl3"> Delivered by </span> <span class="pStyl7"> Tue, July 25 </span>
            </p>
            <p class="pStyl4">
              When you order in   {{pcard.orderTime}}
            </p>
            <p style="margin-bottom: 0vh;">
              <span class="pStyl4"> {{pcard.priceUnit}} </span> <span class="pStyl6">&nbsp; {{pcard.subProductPrice}} </span><br>
              <!-- <span class="pStyl4"> AED </span> <span class="pStyl6"> {{pcard.subProductPrice}} </span><br> -->
              <!-- <span class="float-right pStyl3">
                <i class="fa fa-credit-card iconStyl5"></i>Prepaid Only
              </span> -->
            </p>
            <p class="pStyl3" style="margin-bottom: 0vh;">
              <i class="fa fa-credit-card iconStyl5"></i>Prepaid Only
            </p>
            <!-- <p class="pStyl3">
              <i class="fa fa-credit-card iconStyl5"></i>Prepaid Only
            </p> -->
            <p class="pStyl4">
              <i class="fa fa-undo iconStyl5"></i>This item cannot be exchanged or returned.
            </p>
          </div>
        </div>
        <div class="row mt-2 mb-1">
          <div class="col-6">
            <button (click)="removeItem(pcard.id)" class="btn removeBtn"><i class="fa fa-trash iconStyl5"></i> Remove</button>
          </div>
          <div class="col-6">
            <div>
              <p class="pdStyl">
                Qty:
                <select class="browser-default custom-select" style="width: 45%;font-size: 14px;">
                  <option selected>1</option>
                  <option value="1">2</option>
                  <option value="2">3</option>
                  <option value="3">4</option>
                </select>
              </p>
            </div>
          </div>

        </div>

      <!-- </div> -->
      <div class="col-sm-12 col-lg-6  jumb2">
        <div class="input-group inGrp">
          <!-- <input type="text" class="form-control copn" maxlength="11" minlength="11" (keypress)="applyBtn(myInput.value)" #myInput placeholder="Coupon Code" required aria-label="Coupon Code" aria-describedby="basic-addon2"> -->
          <input type="text" class="form-control copn" #myInput placeholder="Coupon Code" required>
          <div class="input-group-prepend">
            <button class="btn copnBtn" type="button" (click)="applyBtnClick(myInput.value)">Apply</button>
          </div>
        </div>
        <div class="row mb-1 inGrp">
        <!-- <div class="row mb-1 inGrp"  *ngFor="let disc of couponResp"> -->
          <!-- <div class="col-6">
            <p class="pLStyl"> {{subtotal}} </p>
            <p *ngIf="showDiscount" class="pLStyl">{{discount}} </p>
            <p class="pLStyl"> {{shipping}} </p>
          </div>
          <div class="col-6 ">
            <p class="pfStyl">AED&nbsp; {{subtotalList[0]}}</p>
            <p *ngIf="showDiscount" class="pfStyl">AED&nbsp; -{{discountList[0]}}</p>
            <p class="pfStyl" style="color: rgb(15, 15, 189); font-weight: bold;">AED&nbsp; {{shippingList[0]}}</p>
          </div> -->
          <div class="col-6">
            <p class="pLStyl"> {{subtotal}} </p>
            <p *ngIf="showDiscount" class="pLStyl">{{discount}} </p>
            <p class="pLStyl"> {{shipping}} </p>
          </div>
          <div class="col-6 ">
            <p class="pfStyl">{{cartResp[0].priceUnit}}&nbsp; {{cartResp[0].subProductPrice}}</p>
            <!-- <p class="pfStyl">{{couponResp.priceUnit}}&nbsp; {{couponResp.subProductPrice}}</p> -->
            <!-- <p *ngIf="showDiscount" class="pfStyl">-&nbsp;{{couponResp.priceUnit}}&nbsp; {{couponResp.subProductOfferPrice}}</p> -->
            <p *ngIf="showDiscount" class="pfStyl">-&nbsp;{{couponResp.priceUnit}}&nbsp; {{couponResp.couponDiscountAmount}}</p>
            <p class="pfStyl" style="color: rgb(15, 15, 189); font-weight: bold;">{{shippingList}}</p>
          </div>
        </div>

        <hr class="hrStyl">
        <div class="row well inGrp">
          <div class="col-6">
            <p class="pTStyl"> {{total}}&nbsp;<span class="pStyl4">(Inclusive of VAT)</span> </p>
          </div>
          <div class="col-6 ">
            <p class="pfStyl">{{cartResp[0].priceUnit}}&nbsp;{{showDiscount?totalAmtCalc:cartResp[0].subProductPrice}} </p>
            <!-- <p *ngIf="!show" class="pfStyl">AED&nbsp; {{subtotalList[0]}} </p> -->
            <!-- <p class="pfStyl">AED&nbsp;{{showDiscount?totalAmtCalc:subtotalList[0]}} </p> -->
            <!-- <p class="pfStyl">{{totalAmt[0]}} </p> -->
          </div>
        </div>

        <hr class="hrStyl">

      </div>
    </div>
    <div class="col-12 bg-primary">
      
        <p style="    margin-top: 4px;
        margin-bottom: 4px;" >{{grandTotal}} &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;   {{cartResp[0].priceUnit}}&nbsp;{{totalPrice}}</p>
      
    </div>
    <div class="card cardSlide">
      <p class="pStyl1Q">Frequently Bought Together</p>
      <!-- <div class="row" scrollX="true" id="filter" *ngFor="let item of filters"> -->
      <div class="row" style="width: 100%;">
        <div class="scrolling-wrapper">
          <div class="card card0">
            <img class="imgSc img-thumbnail" src="assets/img/samsung2.jpg">
            <h3 class="pStylT">
              Galaxy S10+ 128GB (Black, 6Gb|128GB)
            </h3>
            <h3 class="pStylQ">Product 1</h3>
            <h3 class="pStylQ">AED 200.25</h3>
          </div>
          <div class="card card1">
            <img class="imgSc img-thumbnail" src="assets/img/samsung1.jpg">
            <h3 class="pStylT">
              Galaxy S10+ 128GB (Black, 6Gb|128GB)
            </h3>
            <h3 class="pStylQ">Product 2</h3>
            <h3 class="pStylQ">AED 200.25</h3>
          </div>
          <div class="card card1">
            <img class="imgSc img-thumbnail" src="assets/img/Samsunglandingpage.jpg">
            <h3 class="pStylT">
              Galaxy S10+ 128GB (Black, 6Gb|128GB)
            </h3>
            <h3 class="pStylQ">Product 3</h3>
            <h3 class="pStylQ">AED 200.25</h3>
          </div>
          <div class="card card1">
            <img class="imgSc img-thumbnail" src="assets/img/samsung1.jpg">
            <h3 class="pStylT">
              Galaxy S10+ 128GB (Black, 6Gb|128GB)
            </h3>
            <h3 class="pStylQ">Product 2</h3>
            <h3 class="pStylQ">AED 200.25</h3>
          </div>
          <div class="card card1">
            <img class="imgSc img-thumbnail" src="assets/img/Samsunglandingpage.jpg">
            <h3 class="pStylT">
              Galaxy S10+ 128GB (Black, 6Gb|128GB)
            </h3>
            <h3 class="pStylQ">Product 3</h3>
            <h3 class="pStylQ">AED 200.25</h3>
          </div>
          <div class="card card1">
            <img class="imgSc img-thumbnail" src="assets/img/samsung1.jpg">
            <h3 class="pStylT">
              Galaxy S10+ 128GB (Black, 6Gb|128GB)
            </h3>
            <h3 class="pStylQ">Product 2</h3>
            <h3 class="pStylQ">AED 200.25</h3>
          </div>
          <div class="card card1">
            <img class="imgSc img-thumbnail" src="assets/img/samsung1.jpg">
            <h3 class="pStylT">
              Galaxy S10+ 128GB (Black, 6Gb|128GB)
            </h3>
            <h3 class="pStylQ">Product 2</h3>
            <h3 class="pStylQ">AED 200.25</h3>
          </div>
          <div class="card card1">
            <img class="imgSc img-thumbnail" src="assets/img/Samsunglandingpage.jpg">
            <h3 class="pStylT">
              Galaxy S10+ 128GB (Black, 6Gb|128GB)
            </h3>
            <h3 class="pStylQ">Product 3</h3>
            <h3 class="pStylQ">AED 200.25</h3>
          </div>
          <div class="card card1">
            <img class="imgSc img-thumbnail" src="assets/img/samsung1.jpg">
            <h3 class="pStylT">
              Galaxy S10+ 128GB (Black, 6Gb|128GB)
            </h3>
            <h3 class="pStylQ">Product 2</h3>
            <h3 class="pStylQ">AED 200.25</h3>
          </div>

        </div>
      </div>
    </div>

    </div>
  </div>
</div>
