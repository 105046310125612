<div class="dashboard-header">
    <app-header></app-header>
</div>
<div [ngClass]="headerFix ? 'earn-header' : ''">
    <app-earn-header [showLogo]="headerFix"></app-earn-header>
</div>
<ng-container *ngIf="template=='TILES'">
    <!--Tiles-->
    <app-tiles-template [campaignData]="campaignData"></app-tiles-template>
</ng-container>
<ng-container *ngIf="template=='EXPANDED_VIEW'">
    <!--Expanded View-->
    <app-expanded-template [campaignData]="campaignData"></app-expanded-template>
</ng-container>
<ng-container *ngIf="template=='FULL_BANNER'">
    <app-banner-template [campaignData]="campaignData"></app-banner-template>
    <!--Banner-->
</ng-container>
<!-- <click-to-connect></click-to-connect> -->