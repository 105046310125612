import { LoginHeaderComponent } from "./pages/login-header/login-header.component";
import { SurveyNewComponent } from "./pages/survey-new/survey-new.component";``
import { ShowCouponComponent } from "./pages/coupon/show-coupon/show-coupon.component";
import { FilterComponent } from "./pages/coupon/filter/filter.component";
import { CouponCardComponent } from "./pages/coupon/coupon-card/coupon-card.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";
import { OfferDetailsComponent } from "./pages/offer-details/offer-details.component";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { LoginComponent } from "./pages/login/login.component";
import { OfferDetails2Component } from "./pages/offer-details2/offer-details2.component";
import { RegistrationComponent } from "./pages/registration/registration.component";
import { HomeComponent } from "./pages/home/home.component";
import { CartComponent } from "./pages/cart/cart.component";
import { SearchBarComponent } from "./pages/search-bar/search-bar.component";
import { EarnHeaderComponent } from "./pages/earn-header/earn-header.component";
import { DashboarddeskComponent } from "./pages/dashboarddesk/dashboarddesk.component";
import { RegistrationNewComponent } from "./pages/registration-new/registration-new.component";
import { LoginNewComponent } from "./pages/login-new/login-new.component";
import { SearchPageComponent } from "./pages/search-page/search-page.component";
import { SearchMenuComponent } from "./pages/search-menu/search-menu.component";
import { CategorypageComponent } from "./pages/categorypage/categorypage.component";
import { ScrollComponent } from "./pages/scroll/scroll.component";
import { ProdscrollComponent } from "./pages/prodscroll/prodscroll.component";
import { LocationComponent } from "./pages/location/location.component";
import { WalletComponent } from "./pages/wallet/wallet.component";
import { ShareComponent } from "./pages/share/share.component";
import { ProductDetailsComponent } from "./pages/product-details/product-details.component";
import { FrontPageBComponent } from './pages/front-page-b/front-page-b.component';
import { WifiRouterComponent } from './pages/wifi-router-component/wifi-router-component.component';
import { RedeemComponent } from "./pages/redeem/redeem.component";
import { QrCodeComponent } from "./pages/qr-code/qr-code.component";
import { MallsComponent } from "./pages/malls/malls.component";
import { NavComponent } from "./nav/nav.component";
import { CategoryComponent } from "./pages/newUI/category/category.component";
import { WalletnewComponent } from "./pages/newUI/walletnew/walletnew.component";
import { OfferDetailsNewComponent } from "./pages/newUI/offer-details-new/offer-details-new.component";
import { SearchBarNewComponent } from "./pages/newUI/searchnew/search-bar-new/search-bar-new.component";
import { SearchComponent } from "./pages/newUI/searchnew/search/search.component";
import { StoreComponent } from "./pages/newUI/store/store.component";
import { DashboardnewComponent } from "./pages/newUI/dashboardnew/dashboardnew.component";
import { OfferDetailsnew2Component } from "./pages/offer-detailsnew2/offer-detailsnew2.component";
import { ProductComponent } from "./pages/newUI/product/product.component";
import { DealComponent } from "./pages/newUI/filters/deal/deal.component";
import { NewComponent } from "./pages/newUI/filters/new/new.component";
import { TrendingComponent } from "./pages/newUI/filters/trending/trending.component";
import { AllproductsComponent } from "./pages/newUI/filters/allproducts/allproducts.component";



let variable =2;


const routes: Routes = [
  
   {
    path: 'download',
    component: NavComponent,
  },
  // {
  //   path: 'survey',
  //   component: SurveyNewComponent,
  // },
  {
    path: "",
    redirectTo: "category",
    pathMatch: "full",
  },

  // {
    //   path: 'card',  
    //   component: CouponCardComponent
    // }, {
    //   path: 'filter',
    //   component: FilterComponent
    // },
    {
    path: "coupon",
    component: ShowCouponComponent,
  },
  // {
  //   path: "offero",
  //   // component: OfferDetailsComponent
  //   component: OfferDetails2Component,
  // },
  // {
  //   path: "offer",
  //   component: OfferDetailsNewComponent,
  // },
  {
    path: "wifi",
    // component: OfferDetailsComponent
    component: WifiRouterComponent,
  },
  // {
  //   path: 'loginNew',
  //   component: LoginNewComponent
  // },
  // {
  //   path: 'registrationNew',
  //   component: RegistrationNewComponent
  // },
  //  {
  //   path: 'survey',
  //   component: SurveyNewComponent
  // },
  {
    path: "search",
    component: SearchBarComponent,
  },
  {
    path: "searchbar",
    component: SearchComponent,
  },
  {
    path: "searchbarN",
    component: SearchBarNewComponent,
  },
  {
    path: 'campaign/:param2/:param1',
    component: StoreComponent,
  },
  {
    path: 'home/product/:param1',
    component: ProductComponent,
  },
  {
    path: 'deal',
    component: DealComponent,
  },
  {
    path: 'new',
    component: NewComponent,
  },
  {
    path: 'allproducts',
    component: AllproductsComponent,
  },
  {
    path: 'trending',
    component: TrendingComponent,
  },
  // {
  //   path: "offero",
  //   // component: OfferDetailsComponent
  //   component: OfferDetailsnew2Component,
  // },
  {
    path: "offer",
    component: variable==1 ? OfferDetailsnew2Component:OfferDetailsNewComponent,
  },
  {
    path: "home",
    component: variable==1 ?DashboardComponent :DashboardnewComponent,data: { animationState: 'One'} 
  },
  {
    path: "category",
    component: variable==1 ?CategorypageComponent :CategoryComponent,data: { animationState: 'Two'} 
  },


  {
    path: "wallet",
    component: variable==1 ?WalletComponent:WalletnewComponent,data: { animationState: 'Three'} 
  },
  
  {
    path: "location",
    component: LocationComponent,
  },
  {
    path: "walletnew",
    component: WalletnewComponent,
  },
  // ,
  {
    path: "banner",
    component: HomeComponent,
  },
  {
    path: "cart",
    component: CartComponent,
  },
  {
    path: "searchPage",
    component: SearchPageComponent,
  },
  {
    path: "searchMenu",
    component: SearchMenuComponent,
  },
  {
    path: "prod",
    component: ProdscrollComponent,
  },
  {
    path: "scroll",
    component: ScrollComponent,
  },
  //
  // , {
  //   path: 'login',
  //   component: LoginComponent
  // }
  {
    path: "dashboardDesk",
    component: DashboarddeskComponent,
  },
  //   , {
  //   path: 'earn',
  //     component: EarnHeaderComponent
  // }
  // , {
  //   path: 'register',
  //   component: RegistrationComponent
  // },
  {
    path: "login",
    component: LoginHeaderComponent,
    children: [
      { path: "", redirectTo: "login", pathMatch: "full" },
      { path: "login", component: LoginNewComponent },
      { path: "register", component: RegistrationNewComponent },
    ],
  },
  {
    path: "share",
    component: ShareComponent,
  },
  {
    path: "product-details",
    component: ProductDetailsComponent,
  },
  {
    path: "front-page-b",
    component: FrontPageBComponent
  }

   ,{  path: 'redeem',
    component: RedeemComponent
  },
  {  path: 'qr',
    component: QrCodeComponent
  },
  {  path: 'malls',
    component: MallsComponent
  },
 
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
