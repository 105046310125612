import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConstants } from 'src/app/app.constants';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  constructor(
    private http: HttpClient,
  ) { }
  validateCouponCode(data): Observable<any> {
    // return this.http.post(AppConstants.BASE_API_URL + 'wifi-portal/getOfferDetails', data);
    return this.http.post('https://place10x.com/wifi10xapi/wifi-portal/validateCouponBySubProducts' , data);
  }
  addToCart(data): Observable<any> {
    return this.http.post(AppConstants.BASE_API_URL + 'wifi-portal/addToCart', data);
    // return this.http.post('http://192.168.0.82:8080/wifi10xapi/' + 'wifi-portal/addToCart', data);
  }
  deleteCartItem( id) {
    return this.http.post(AppConstants.BASE_API_URL + 'wifi-portal/addToCart', id);
  }
  

}
