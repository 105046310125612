import { CouponServiceService } from './../../services/coupon/coupon-service.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { $ } from 'protractor';

@Component({
  selector: 'app-offer-details',
  templateUrl: './offer-details.component.html',
  styleUrls: ['./offer-details.component.scss']
})
export class OfferDetailsComponent implements OnInit {

  otp: string;
  showOtpComponent = true;
  claim:boolean=false;
  isDisable:boolean=true;
  public cupDiv:boolean=true;
  public claimCode:boolean=true;
  // @ViewChild('ngOtpInput', { static: true }) ngOtpInput: any;
  config = {
    allowNumbersOnly: true,
    length: 4,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    inputStyles: {
      width: '25px',
      height: '25px',
      ' border-radius': '4px',
      border: '1px solid #c5c5c5',
      'text-align': 'center',
      'font-size': '22px',
      'background-color': '#ececec'
    }
  };
  public offerDetails =
    ['Get 6 months of add-free youTube and YouTube premium Trail. ',
      'Offer is not applicable for existing YouTube  premium Trail user.',
      'Check your subscribtion deatils for YouTube premuium at https://www.youtube.com/paid_memberships',
      'This offer can not be clubbed with any othe ongoing offer discount/cash back promotion.',
      'Reedem the offer within 15 days of claim.'];
  public note =
    ['Please Note:',
      'To use the offer on youtube customer need to mandatory update credit/detit cards details ',
      'Restricted debit/credit cards that have reccuring payment options allowed for the offer'];
  constructor(public couponServe: CouponServiceService) { }

  ngOnInit() {
  }
  onOtpChange(otp) {
    this.otp = otp;
    console.log('otp', this.otp);
    if (this.otp.length === 4) {
      this.isDisable = false;
    } else {
      this.isDisable = true;
    }
  }

  // setVal(val) {
  //   this.ngOtpInput.setValue(val);
  // }
  onConfigChange() {
    this.showOtpComponent = false;
    this.otp = null;
    setTimeout(() => {
      this.showOtpComponent = true;
    }, 0);
  }

  claimRewords() {
    this.couponServe.onChangeClaimComponent();
    this.claimCode = true;
  }

}
