import { EventEmitterService } from './../../services/EventEmitter/event-emitter.service';
import { Component, OnInit, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CouponServiceService } from 'src/app/services/coupon/coupon-service.service';
import { ToastrService } from "ngx-toastr";
@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnInit {
  @Input() firstValue: number;
  @Input() difference: number;
  // @Input() firstValue1: number;
  // @Input() difference1: number;
  public scrollValue: any;
  public wishStringVar: any;
  public boolValue: any;
  @Input() coupons: any;
  @Input() couponList: any;
  public prodCard = [];
  var1 = String;
  var2 = String;
  public subProdListbyId: any;
  public scrollEvent: any;
  public buttonFix = false;
  public pathString:string;
  // public path1:string;
  shareCon = false;
  showDiv = true;
  varBtn: string;
  activeCard = false;
  fixBtnTog = false;
  hideDiv = false;
  openSharePop = true;
  plusMInus = false;
  height = '30vh';
  toggleBtn = false;
  mobileNo = localStorage.getItem("mobileNo");
  mob1: number;
 

  constructor(
    public router: Router,
    private service: EventEmitterService,
    private couponService: CouponServiceService,
    private toastr: ToastrService,

  ) {
    this.scrollEvent = this.service.onChange.subscribe({
      next: (data: any) => {
        // console.log(data);
        this.scrollValue = data;
        this.fixBtn(this.scrollValue);
      }
    });
  }

  ngOnInit() {
    // this.getAllProduct();
    // console.log(this.coupons);
    // this.getAllSubProduct();
    // console.log(this.firstValue);
    // console.log(this.difference);
    const vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    // document.documentElement.style.setProperty('--vh', `${vh}px`);
    // alert(vh);
    this.height = '30vh';
    // We listen to the resize event
    window.addEventListener('resize', () => {
      // We execute the same script as before
      const vha = window.innerHeight * 0.01;
      // alert(vh);
      // alert(vha);
      if (vha < vh) {
        const data = (vh / vha * 30);
        this.height = data + 'vh';
      } else {
        this.height = '30vh';
      }
      // document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
  }

  ngAfterViewInit() {
    console.log("nfjrngirngirngiwrngirnhi");
  }

  getImage(path,basePath) {
    let pathName= path.split(/(\\|\/)/);
    var path1 = pathName[pathName.length-1];
    // console.log('path11111',path1);
    return this.couponService.getImage(path1,basePath);
  }

  shopNow(subcatId: string, subProd) {
    // tslint:disable: prefer-for-of
    // console.log('btn id is : ', subcatId, subProd);
    const btnVal = document.getElementById(subcatId).innerText;
    const elementShop = document.getElementById(subcatId);
    // console.log('btnVal issss...', btnVal);
    // console.log('elementShop issss...', elementShop);
    // this.buttonName = 'Shop Now';
    if (btnVal === 'Earn EUR') {
      // if (btnVal === 'Earn AED') {
      elementShop.classList.add('shopBtnShop');
      document.getElementById(subcatId).innerText = 'Shop Now';
    }
    else if (btnVal === 'Shop Now') {
      var test2 = localStorage.getItem("mobileNo");
      if (localStorage.getItem("mobileNo") === null) {

        // this.router.navigate(['/login/login', { id: subcatId }]);
        localStorage.removeItem('subProdDetails');
        localStorage.removeItem('offer');

        // localStorage.clear();

        localStorage.setItem('subProdDetails', JSON.stringify(subProd));
        localStorage.setItem('offer', JSON.stringify(subcatId));
        this.router.navigate(['/offer']);
      }
      else {
        localStorage.removeItem('subProdDetails');
        localStorage.removeItem('offer');

        // localStorage.clear();

        localStorage.setItem('subProdDetails', JSON.stringify(subProd));
        localStorage.setItem('offer', JSON.stringify(subcatId));
        this.router.navigate(['/offer']);
      }
      console.log(test2 + "     test2");
      //localStorage.clear();
      //localStorage.setItem('subProdDetails', JSON.stringify(subProd));
      // localStorage.setItem('offer', JSON.stringify(subcatId));
      //  this.router.navigate(['/offer']);
    }
  }

  fixBtn(data: number) {
    const dataEle = document.getElementsByClassName('collapse sub-product show') as HTMLCollectionOf<HTMLElement>;
    for (let i = 0; i < dataEle.length; i++) {
      const divId = +dataEle[i].id.replace('demo', '');
      // console.log((this.firstValue + ((divId - 1) * this.difference)), data);
      if ((divId === 1 && data >= 250) || (divId > 1 && data >= (this.firstValue + ((divId - 1) * this.difference)))) {
        document.getElementById('btntoggle' + divId).classList.add('fixed-btn');
      } else {
        this.removeFixBtn();
      }
      // if (dataEle[i].id !== divID) {
      //   dataEle[i].classList.remove('show');
      // }
      // console.log(divId);
    }
  }

  displayDiv(iconId: string, element: string, extendedDiv: string) {
    // this.showDiv = !this.showDiv;
    // this.hideDiv = !this.hideDiv;
    // this.plusMInus = !this.plusMInus;
    // const re = /prodicon/gi;
    // const prodId = element.replace(re, '');
    // console.log(element);
    this.hideExtendedShare(extendedDiv);
    // this.showShareBtns();
    const dataEle = document.getElementsByClassName('shareBtns') as HTMLCollectionOf<HTMLElement>;
    for (let i = 0; i < dataEle.length; i++) {
      const ele = dataEle[i];
      if (!(ele.id === element)) {
        ele.classList.remove('hide');
      }
    }
    // console.log('helloooooooooooo', prodId);
    document.getElementById(element).classList.toggle('hide');
    // document.getElementById('prod' + prodId).classList.toggle('show');
    const elements = document.querySelectorAll('.plusIcon.fa-minus');
    elements.forEach(ele => {
      if (!(ele.id === iconId)) {
        ele.classList.toggle('fa-minus');
      }
    });
    document.getElementById(iconId).classList.toggle('fa-minus');
    document.getElementById(extendedDiv).classList.toggle('show');
    // if (elements.length === 0) {
    //   document.getElementById('prodsharehide' + prodId).style.display = 'flex';
    // } else {
    //   console.log('coming ', prodId);
    //   document.getElementById('prodsharehide' + prodId).style.display = 'none';
    // }
  }

  // openShare() {
  //     this.openSharePop = !this.openSharePop;
  // }
  /*   openShare(shareId: string) {
      // console.log('btn id is : ', shareId);
      this.hideAllShare();
      const popup = document.getElementById(shareId);
      popup.classList.toggle('show');
    } */

  //update 12-12-2020
  openShare(shareId: string) {
    console.log('btn id is : ', shareId);
    this.hideAllShare();
    const popup = document.getElementById(shareId);
    popup.classList.toggle('show');

    //this.router.navigate["/share"];
  }
  openShare1() {

    console.log("coupon------" + this.coupons);
    if (localStorage.getItem("mobNo") === null || localStorage.getItem("mobileNo") === null) {

      this.router.navigate(['/login/login']);
    }
    else {
      this.router.navigate(["/share"]);
    }
  }


  toggle(element: string) {
    // console.log(element);
    this.showShareBtns();
    this.removeFixBtn();
    this.hideExtendedShare('0');
    this.hideAllShare();
    this.fixBtnTog = false;
    //  var varBtn = document.getElementsByName("element");
    const elements = document.querySelectorAll('.fa-minus');
    const cardExpElements = document.querySelectorAll('.cardExpand');
    // console.log(cardExpElements);
    // console.log(elements);

    const re = /icon/gi;
    const btnid = element.replace(re, '');
    // console.log('button id is...', btnid);

    cardExpElements.forEach(elem => {
      // console.log(elem.id);
      if (!(elem.id === ('cardexp' + btnid))) {
        elem.classList.toggle('cardExpand');
      }
    });
    const cardExpId = document.getElementById('cardexp' + btnid);
    cardExpId.classList.toggle('cardExpand');
    elements.forEach(ele => {
      // console.log(ele.id);
      if (!(ele.id === element)) {
        ele.classList.toggle('fa-minus');
        // let removeBtnId = document.getElementById(this.varBtn);
        // removeBtnId.classList.remove('addBtnStick');
        // let cardExpId = document.getElementById('cardexp' + btnid);
        // cardExpId.classList.remove('cardExpand');
      }
    });
    const data = document.getElementById(element);
    data.classList.toggle('fa-minus');
    if (elements.length === 0) {
      this.fixBtnTog = !this.fixBtnTog;
      this.varBtn = 'btntoggle' + btnid;
      // let cardExpId = document.getElementById('cardexp' + btnid);
      // cardExpId.classList.add("cardExpand");
    } else {
      this.fixBtnTog = false;
      // let removeBtnId = document.getElementById(this.varBtn);
      // removeBtnId.classList.remove("addBtnStick");
      // let cardExpId = document.getElementById('cardexp' + btnid);
      // cardExpId.classList.remove("cardExpand");
    }
  }
  showShareBtns() {
    const dataEle = document.getElementsByClassName('shareBtns') as HTMLCollectionOf<HTMLElement>;
    for (let i = 0; i < dataEle.length; i++) {
      dataEle[i].classList.remove('hide');
    }
  }

  hideExtendedShare(divID: string) {
    const dataEle = document.getElementsByClassName('expanded') as HTMLCollectionOf<HTMLElement>;
    for (let i = 0; i < dataEle.length; i++) {
      if (dataEle[i].id !== divID) {
        dataEle[i].classList.remove('show');
      }
    }
  }
  removeFixBtn() {
    const dataEle = document.getElementsByClassName('fixed-btn') as HTMLCollectionOf<HTMLElement>;
    for (let i = 0; i < dataEle.length; i++) {
      dataEle[i].classList.remove('fixed-btn');
    }
  }
  hideAllShare() {
    const dataEle = document.getElementsByClassName('popuptext') as HTMLCollectionOf<HTMLElement>;
    // dataEle.classList.add('hide');
    // for (const ele of dataEle) {

    // }
    for (let i = 0; i < dataEle.length; i++) {
      dataEle[i].classList.remove('show');
    }
  }

  getAllProduct() {
    this.couponService.getAllProduct().subscribe(data => {
      // console.log(data);
      this.coupons = data.productresponses;
      console.log('AllProduct List:', this.coupons);
    }, error => {
      console.log(error);
    });
  }

  getAllSubProduct() {
    this.couponService.getAllSubProduct().subscribe(data => {
      console.log('AllSubProduct Data are====', data);
      this.prodCard = data.responses;
    }, error => {
      console.log(error);
    });
  }

  addToWishlistBtn(var1, var2) {
    // console.log('valueeeee isss', var1, var2);
    const data = {
      'prodId': var1,
      'subproductId': var2
    };
    if (localStorage.getItem("mobileNo") === null) {

      this.router.navigate(['/login/login']);
    }
    this.toggleBtn = !this.toggleBtn;
    const cardWishListId = document.getElementById('AddWishToggle' + var1 + var2);
    // console.log("id "+'AddWishToggle' + var1 + var2);
    // console.log(cardWishListId);
    if (this.toggleBtn == true) {
      cardWishListId.classList.remove('black');
      cardWishListId.classList.toggle('red');
    } else {
      cardWishListId.classList.remove('red');
      cardWishListId.classList.toggle('black');
    }

    // this.couponService.addToWishlist(data).subscribe(data => {
    //   console.log(data);
    //   // this.couponsList = data.responseList;
    // }, error => {
    //   console.log(error);
    // });
  }

  addToWishlistBtn2(var1, var2) {
    console.log('valueeeee isss', var1, var2);
    this.mob1 = +(localStorage.getItem("mobNo").substring(0));
    const data = {
      //'prodId': var1,
      //'subproductId': var2

      'mobileNumber': this.mob1,
      'subproductId': var2
    };
    this.couponService.addToWishlist(data).subscribe(data => {
      console.log(data);
      this.toastr.success("", "added in wishList", { timeOut: 1600 });
      //this.couponsList = data.responseList;
    }, error => {
      this.toastr.error("", "Faild", { timeOut: 1600 });
    });
    this.toggleBtn = !this.toggleBtn;
    const cardWishListId = document.getElementById('AddWishToggle2' + var1 + var2);
    // console.log("id "+'AddWishToggle' + var1 + var2);
    // console.log(cardWishListId);
    if (this.toggleBtn == true) {
      cardWishListId.classList.remove('black');
      cardWishListId.classList.toggle('red');
    } else {
      cardWishListId.classList.remove('red');
      cardWishListId.classList.toggle('black');
    }

    /*  this.couponService.addToWishlist(data).subscribe(data => {
        console.log(data);
  
       //this.couponsList = data.responseList;
       }, error => {
  
      });*/
  }


  public getSubProductByProdId(data) {
    this.couponService.getSubProductByProdId(data).subscribe(result => {
      // console.log('=========== SubProductByProdId Details: ' + data + '===========');
      this.prodCard = result.responses;
      console.log('subProdListbyId issss .......', result.responses);
      localStorage.setItem("subprodId", result.responses[0].id);
      localStorage.setItem("subprodPrice", result.responses[0].subProductPrice);
      // let wishStringVar = result.responses.wishStatus;
      // var boolValue = wishStringVar.toLowerCase() == "true" ? true : false;
      // console.log('Flag Is', wishStringVar );
      // console.log('boolValue of Flag Is', boolValue );
    }, error => {
      console.log(error);
    });
  }

}
