import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Scanner1Service } from 'src/app/services/scanner1.service';
import { OfferService } from 'src/app/services/offer/offer.service';
@Component({
  selector: 'app-scanner',
  templateUrl: './scanner.component.html',
  styleUrls: ['./scanner.component.scss']
})
export class ScannerComponent implements OnInit {

  qrResultString: string = null;
  // result1: string;
  isClicked = false;
  test: string;
  //result = 'https://test.wifi10x.com/Coupon';
  result='https://place10x.org/Coupon';
  actv2: boolean = false;
  subProductId=localStorage.getItem("subprodId");
  @Output() newItemEvent = new EventEmitter<boolean>();

  constructor(private offerService: OfferService,private scanRes: Scanner1Service) { }

  ngOnInit() {
    this.validatedCoupon(this.subProductId);
    //this.validatedCoupon1();
  }


  clearResult(): void {
    this.qrResultString = null;
  }
  onCodeResult(resultString) {
    console.log("resultstring",resultString);
    
    localStorage.setItem('routerdeviceId', resultString);

    if(resultString =="Deepak") {
      console.log("xxxx");
      this.actv2 = true;
    } else {
      this.actv2 =false;
    }
  //   const subId=parseInt(localStorage.getItem('subprodId'));
  //   const data={
  //     "couponName":resultString,
  //    "subProductid":subId
  //  }
  //  console.log('dvufd',data);
    // this.scanRes.validateCoupon(data).subscribe(result => {
    // this.qrResultString = result.couponName;
    // console.log('Coupon Code--', this.qrResultString);
    // localStorage.setItem("code", this.qrResultString);
    //   this.actv2 = true;
    //   this.scanRes.setShowData(true);
    //   this.newItemEvent.emit(this.actv2);
    // },error => {
    //   this.qrResultString = resultString;
    //   this.actv2 = false;
     // this.newItemEvent.emit(this.actv2);
    // });
  //  if(resultString===this.result)
  //  {
  //   this.qrResultString = resultString;
  //     this.actv2 = true;
  //     this.scanRes.setShowData(true);
  //     this.newItemEvent.emit(this.actv2);
  //  }
  //  else{
  //   this.qrResultString = resultString;
  //     this.actv2 = false;
  //  }
    
    // if (resultString.includes("https://")) {
    //   this.result1 = resultString;
    // }
    // else {
    //   this.test = "https://";
    //   this.result1 = this.test.concat(resultString);
    // }
    //this.router.navigate(['/scanResult/:resultString']);

  }
  validatedCoupon(data)
  {
    console.log('ValidateCoupon');
    this.offerService.getAllCouponBySubProdId(data).subscribe(result => {
      // console.log('=========== SubProductByProdId Details: ' + data + '===========');
      //this.prodCard = result.responses;
     console.log('subProdListbyId issss .......', result.responses);
     localStorage.setItem("subprodId", result.responses[0].subprodId);
     console.log('codeeeeeeeeeeee----->',result.responses[0].couponCode);
     localStorage.setItem("code", result.responses[0].couponCode);
      let wishStringVar = result.responses.wishStatus;
      var boolValue = wishStringVar.toLowerCase() == "true" ? true : false;
      console.log('Flag Is', wishStringVar );
      console.log('boolValue of Flag Is', boolValue );
    }, error => {
      console.log(error);
    });
  }

}
