<div class="claimHeaders">
  <div class="header col-12">
    <p class="head">Rewards Store</p>
    <div class="menuDiv col-offset-3">
      <div class="row">
        <div class="col-1"><img class="gift" src="assets/img/gift_head.png" /></div>
        <div class="col-6  justify-content-center align-self-center claim">&nbsp; Claimed Rewards</div>
        <div class="col-1"> <span class="num">10</span></div>
        <div class="col-3"><i class="fa fa-bars" aria-hidden="true"></i></div>
      </div>
    </div>
  </div>
</div>