import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConstants } from 'src/app/app.constants';
import { Ad } from 'src/app/data/data';

@Injectable({
  providedIn: 'root'
})
export class AdsequenceService {
  public ad = new Ad();

  constructor(
    private http: HttpClient,
  ) { }

  getAllDetails(): Observable<any> {
    return this.http.get(AppConstants.BASE_API_URL+"campaign/getFrontPageA" );
    //AppConstants.BASE_API_URL + 'adSequence/getAdSequenceById/' + id
  }

  getImage(id: number, type: string): Observable<any> {
    // return AppConstants.BASE_API_URL + 'adSequence/getMediaFile/' + id + '/' + type;
      return this.http.get("../../assets/mock/getImage.json" )
  }

  sendOTP(mobileNumber: string): Observable<any> {
    return this.http.get(AppConstants.BASE_API_URL + 'adSequence/sendOtp/' + mobileNumber);
  }

  validateOtp(mobileNumber: string, otp: string): Observable<any> {
    return this.http.get(AppConstants.BASE_API_URL + 'adSequence/validateOtp/' + mobileNumber + '/' + otp);
  }
}
