<div class="dashboard-header">
    <app-header></app-header>
  </div>
  <!-- <div [ngClass]="headerFix?'earn-header':''">
    <app-earn-header [showLogo]="headerFix"></app-earn-header>
  </div> -->
  <div class="headers">
    <div class="scrollmenu1">
      <a (click)="openHome()">Latest</a>
      <a (click)="openHome1()">Expiring</a>
      <a (click)="openHome2()">Exclusive</a>
          <a> <img class="menuIcon2" src="assets/user.jpeg"  alt=""></a>
    </div>
    <div class="scrollmenu">
      <a class="acn" (click)="openHome()">Latest</a>
      <a class="acn1"(click)="openHome1()">Expiring</a>
      <a class="acn2"(click)="openHome2()">Exclusive</a>
      <a> <img class="menuIcon2" src="assets/user.jpeg"  alt="" routerLink="../login"></a>
    </div>
  </div>
  <ng-container>
    <div *ngIf="coupons.length==0" class="empDiv">
      <!-- <div class="empDiv"> -->
      <img class="emptCart" src="assets/img/wish.png" alt="">
      <div class="noData">Your wallet is empty!</div>
      <div class="noData1">Add items you want to claim.</div>
      <button class="shopBtn" [routerLink]="['/home']">claim Now</button>
    </div>
  <app-tiles-templatep [campaignData]="coupons" ></app-tiles-templatep>
</ng-container>
  <!-- <app-tile-templatee></app-tile-templatee>
  <app-tile-templatee></app-tile-templatee> -->