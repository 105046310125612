<ng-container >
    <div class="click-to-connect" *ngIf="show">
        <span style="bottom: 5%; margin-top: 5px;"> By Clicking this you are accepting our terms & conditions.</span>
       
        <!-- <button class="red-btn center" [disabled]="counter!=0" (click)="sendAuthorizationLink()">Accept
            <ng-container *ngIf="counter>=1"> -->
                <!-- ({{counter}}) -->
            <!-- </ng-container>
        </button> -->
        <button class="accept-btn" (click)="acceptClicked()">Accept</button>
        <button class="accept-btn" (click)="cancelClicked()">Cancel</button>
    </div>
</ng-container>