import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConstants } from '../app.constants';
import { Logging } from '../data/logging.model';


@Injectable({
    providedIn: 'root'
})
export class LoggingService {
    constructor(
        private http: HttpClient,
    ) { }

    public saveUserActivity(req: Logging[]): Observable<any> {
        let reqObj = {
            data: req
        };
        console.log("reqObj",reqObj);
        return this.http.post(AppConstants.BASE_API_URL + 'campaign/saveUserActivity', reqObj);
    }
}