import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboarddesk',
  templateUrl: './dashboarddesk.component.html',
  styleUrls: ['./dashboarddesk.component.scss']
})
export class DashboarddeskComponent implements OnInit {
  public coupons = [
    {
      id: 1,
      name: 'samsung S10',
      desc: '125 AED discount',
      expDate: '11-06-2020',
      imgURL: 'assets/img/samsung S10.png',
      btnText: 'Claim'
    },
    {
      id: 2,
      name: 'Dell Laptops',
      desc: 'Flat 25% off',
      expDate: '31-05-2020',
      imgURL: 'assets/img/dell.jpg',
      btnText: 'Shop Now'
    }, {
      id: 3,
      name: 'Gucci Bag',
      desc: 'flat 20% off ',
      expDate: '19-08-2020',
      imgURL: 'assets/img/gucci.jpg',
      btnText: 'Shop Now'
    }, {
      id: 4,
      name: 'adidas',
      desc: '10% off',
      expDate: '20-08-2020',
      imgURL: 'assets/img/adidas.jpg',
      btnText: 'Shop Now'
    }, {
      id: 5,
      name: 'huawei phones',
      desc: 'Flat 25% off',
      expDate: '31-05-2020',
      imgURL: 'assets/img/huawei.jpg',
      btnText: 'Shop Now'
    }, {
      id: 6,
      name: 'Oppo phones',
      desc: 'AED 250 off',
      expDate: '31-08-2020',
      imgURL: 'assets/img/oppo-mobile-phones.jpg',
      btnText: 'Shop Now'
    }, {
      id: 7,
      name: 'Nike Shoes',
      desc: 'Flat 10% off',
      expDate: '25-05-2020',
      imgURL: 'assets/img/nike.jpg',
      btnText: 'Shop Now'
    }, {
      id: 8,
      name: 'Gucci Deo',
      desc: '100 AED discount',
      expDate: '31-12-2020',
      imgURL: 'assets/img/couponimg1.jpg',
      btnText: 'Shop Now'
    },
  ];

  constructor(
    public router: Router
  ) { }

  ngOnInit() {
  }
  shopNow(card) {
    localStorage.clear();
    localStorage.setItem('offer', JSON.stringify(card));
    this.router.navigate(['/offer']);

  }

}
