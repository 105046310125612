
import { Location } from '@angular/common';
import { Component, OnInit, HostListener, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
// import { CookieService } from 'ngx-cookie-service';
import { CouponServiceService } from 'src/app/services/coupon/coupon-service.service';
@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {
  

 public userId:any;
  public userType:any;
  public catId:any;
  public showCountryModal: boolean = false;
  public showScan:boolean=false;

  
  public menuCrossImg = 'assets/img/menuicon.svg';
  public date=new Date();
  public month = this.date.toLocaleString('default', { month: 'long' });
  public year = this.date.getFullYear();
  public curdate = (new Date().getMonth() + 1).toString() + '-' + new Date().getFullYear().toString();
  public menuToggle = false;
  public headerFix = false;
 
  access_token=localStorage.getItem('Token');
  public countries:any;
  public adnetworks:any;
  public malls:any;
  public stores = [ 
      {
        storeId: 1,
        storeName:"Olympia"
      },
      {
        storeId: 34,
        storeName:"Athens Wifi"
      }
    ];
  public categoryArrays = [];
  private startTime= new Date();
  public scrollCard = [];
  category: { id: number; imgSrc: string; cName: string;url:string ;name:string}[] = [];
  menuList: { id: number; mName: any; url: string; }[] = [];
  brandMenu: any;
  howItWorkMenu: any;
  register: any;
 
 
  constructor(
    // private couponService: CategoryService,
   private couponService:CouponServiceService,
    private router:Router,private location:Location,
    
  ) {
    this.userId=localStorage.getItem("userId");
    if(this.userId == null) {
      localStorage.setItem("userId",'10000001');
    }else {
      console.log("userId",this.userId);
    }
    this.userType = localStorage.getItem("userType");
    if(this.userType == null) {
      localStorage.setItem("userType",'0');
    } else {
      console.log(this.userType);
    }
 

   }

  ngOnInit() {
    this.getAllCategory();
    // this.getAllCategory();
    this.category = [
      {
        id: 1  ,
        imgSrc:"ts",
        cName: "Beauty",
        url:"../beauty",
        name:"C1",
      }, {
        id: 2,
        imgSrc:"ts",
        cName: "Fashion",
        url:"../fashion",
        name:"C2",
      }, {
        id: 3,
        imgSrc:"ts",
        cName: 'Convenience',
        url:"../convenience",
        name:"C3",
      }, {
        id: 4,
        imgSrc:"ts",
        cName: 'Sports',
        url:"../sport",
        name:"C4",
      }, {
        id: 5,
        imgSrc:"ts",
        cName: 'Electronics',
        url:"../electronics",
        name:"C5",
      }, {
        id: 6,
        imgSrc:"ts",
        cName: 'Home Interiors',
        url:"../home",
        name:"C6",
      }, {
        id: 7,
        imgSrc:"ts",
        cName: 'Jewellery',
        url:"../fun",
        name:"C7",

      }
    ];
   
    // this.getAllStores();

    console.log(this.date);
    if (this.router.url === '/searchMenu') {
      this.menuToggle = true;
    } else {
      this.menuToggle = false;
    }
  }
  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScrollEvent() {
    // console.log(event);
    const data = window.scrollY;

    if (data > 49) {
      this.headerFix = true;
    } else {
      this.headerFix = false;
    }

  }

  scan()
  {
    this.showScan=!this.showScan;
  }
  eventFired() {
    //console.log(event);
    console.log(event);
  }
  async toggleMenu() {
    this.menuToggle = !this.menuToggle;
    if (this.menuToggle === true) {
      // this.router.navigate(['/searchMenu']);
    } else {
      // this.router.navigate(['/category']);
      // this.location.back();

    }
  }
  public renderImage( categoryId: number ): string {
    return this.couponService.getImagee( categoryId);
}
getAllCategory() {
  this.couponService.getAllCategory().subscribe(data => {
    this.scrollCard = data;
    // this.splitCategoryArray();
    console.log(this.scrollCard,"scrollllll");
    
  }, error => {
    console.log(error);
    alert('Some Error Occured');
  });

}
openHomee(id) {
  localStorage.setItem('userCategory',id);
  localStorage.setItem('userCampaign',"0");
  console.log("id of  cat",id);
  let endTime = new Date();
  console.log(this.startTime);
  console.log(endTime.getTime())


  this.couponService.setCategoryId(id);
  this.couponService.setCampaignId(0);
  this.router.navigate(['/home']);
  //this.router.navigate(['/home',{d:id}],{ relativeTo: this.activeRoute });
}

  
//   getAllCategory() {
//     this.couponService.getAllCategory().subscribe(data => {
//       this.scrollCard = data.categoryResponses;
//       console.log(this.scrollCard,"card");
      
//     }, error => {
//       console.log(error);
//       alert('Some Error Occured');
//     });
// }
  setMenu() {
    this.menuList = [

      {
        id: 2,
        mName: this.brandMenu,
        url: "/search",
      },
      {
        id: 3,
        mName: this.howItWorkMenu,
        url: "/",
      },
      {
        id: 4,
        mName: this.register,
        url: "/login/register",
      },
    ];
    console.log(this.menuList);
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }
  openHome(){
    this.router.navigate(['../new']);
  }
  openHome2(){
    this.router.navigate(['../deal']);
  }
  openHome3(){
    this.router.navigate(['../trending']);
  }
  openHome4(){
    this.router.navigate(['../allproduct']);
  }
  goToCategory(url:any){
    // setTimeout(() => {
    //   this.router.navigate([url])
    // }, 150);
      this.router.navigate([url]);
  }
}
