import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { HttpClient, HttpClientModule } from "@angular/common/http";
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

@NgModule({
  imports: [
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [TranslateModule],
})
export class I18nModule {
  constructor(translate: TranslateService) {
    translate.addLangs(["en", "ru"]);
    const browserLang = translate.getBrowserLang();
    localStorage.setItem('language', "en");
    let lang = localStorage.getItem('language');
    console.log("lang==" + lang);
    if (lang === 'undefined' || lang == null) {
      translate.use("en");
      localStorage.setItem('language', "en");
    } else {
      translate.use(lang);
    }
    localStorage.setItem('language', "en");
  }
}
export function translateLoaderFactory(httpClient: HttpClient) {
  const path = window.location.origin + '/Coupon/assets/i18n/';
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}
