import { Component, EventEmitter, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CouponServiceService } from 'src/app/services/coupon/coupon-service.service';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {

  param1: string;
  public coupons: any;
public productresponse =[]

  constructor(private route: ActivatedRoute
    ,public router: Router,

    private couponService: CouponServiceService,) {}

  ngOnInit() {

    this.param1 = this.route.snapshot.params['param1'];
    this.getCampaignByproductId(this.param1);
    console.log("store");
    
    
  }
  getCampaignByproductId(id) {
    this.couponService.getCampaignByproductId(id).subscribe(result => {
      this.coupons =result['productresponse'];
      console.log(result,"res");
      
      console.log(this.coupons,"store");
      
    })
  }

}
