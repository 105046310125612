<!-- <app-header></app-header> -->
<div class="coupon-wrapper">
    <div class="dashboard accordion" id="accordion">


        <div class="card card1" *ngFor="let card of campaignData;let i = index;">

            <!-- <img [src]="getImage(card.imgSrc,'campaign')" onerror="this.src='assets/def.jpeg';" -->
            <img [src]="renderImage(card.productId)" onerror="this.src='assets/def.jpeg';" class="cardImg" height="100%"
                [ngStyle]="{'height': height }" alt="" />
            <!-- <h3 class="ab">{{card.productName}}</h3> -->
            <!--If add type is Ad_commerce then shop now button will come-->

            <ng-container *ngIf="card.ad_type!='static'">
                <!-- <ng-container *ngIf="card.subProducts?.length > 0"> -->
                <!-- Used for that tab -->
                <div (scroll)="onScroll1($event)" class="btn-box" [class.cardExpand]="card.isActive"
                    [class.marginBtm]="card.isActive && card.subProducts?.length == 0">

                    <!-- <div>{{card.brandId}}</div> -->
                    <button class="sharebtnAcc" data-toggle="modal" data-target="#shareModal">
                        <!-- <i class="fa fa-share-alt" aria-hidden="true"></i> -->

                        <img class="menuIconStyl2" src="./assets/Share2.png" alt="" (click)="shareApion(card)">
                    </button>
                    <button class="claimbtnAcc text-center" (click)="claimBTNClicked(card)">Claim</button>

                    <!-- <button class="walletbtnAcc " (click)="walletBtnclicked(card)">

                        <img class="menuIconStyl3" src="./assets/Star.png" alt="">
                    </button> -->
                    <button class="walletbtnAcc " (click)="walletBtnclicked(card)" [disabled]="isDisable"
                    *ngIf="!card.isaddButton">
                    <img class="menuIconStyl3" src="./assets/Star.png" alt="">
                </button>
                <button class="walletbtnAcc1 " (click)="walletBtnclickedAgain(card)" *ngIf="card.isaddButton">
                    <img class="menuIconStyl4" src="./assets/starrnew.png" alt="">

                </button>
                    


                </div>

            </ng-container>
            <!--A Patch to show the bottom the image incase of ad_type of static. In this case no button should come-->
            <ng-container *ngIf="card.ad_type =='static'">
                <div class="card"></div>

            </ng-container>
            <div class="sub-product" *ngIf="card.isActive && card.subProducts?.length > 0">

                <div *ngIf="card.subProducts?.length > 0">
                    <ng-container>
                        <div class="card card1 cardStyl accordion"
                            *ngFor="let pcard of card.subProducts; index as index" [class.cardEx]="card.isActive">
                           

                            <img class="cardImg" src="../../../assets/def.jpeg"
                                onerror="this.src='assets/img/default-image.png';" height="100%" alt="" />

                            <div class=" cardBtn cardExpand1">

                                <button class="sharebtnAcc " data-toggle="modal" data-target="#shareModal">
                                    <i class="fa fa-share-alt" aria-hidden="true"></i>
                                </button>
                                <button class="claimbtnAcc text-center"
                                    (click)="claimsubproductBTNClicked(pcard)">Claim</button>
                                <button class="walletbtnAcc" (click)="walletBtnclicked(card.campaignId)"><i
                                        class="fa fa-shopping-bag" aria-hidden="true"></i></button>
                                <button class="buttonsss">LearnMore</button>

                            </div>

                        </div>
                    </ng-container>
                </div>
            </div>

        </div>
        

    </div>
</div>

<div class="modal fade" id="shareModal" tabindex="-1" aria-labelledby="shareModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="top: 40%;">
        <div class="modal-content">
            <div class="modal-header">
                <!-- <h6 class="modal-title" id="shareModalLabel">Mobile No</h6>
                <input style="margin-left: 8px; border-radius: 3px;">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button> -->
            </div>
            <div class="modal-body font1">
                {{desc}} <button *ngIf="showMoreText" (click)="clickMoreText()" class="moreText">READ
                    MORE</button><button (click)="clickMoreText()" *ngIf="!showMoreText" class="moreText">READ
                    LESS</button>
            </div>
            <div class="modal-footer font1">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" (click)="shareApi($event)" class="btn btn-primary">Share</button>
            </div>
        </div>
    </div>
</div>