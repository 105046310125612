import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { switchMap, take, tap, map } from 'rxjs/operators';
import { Subject, Observable, Subscription, timer } from 'rxjs';
import { CouponServiceService } from 'src/app/services/coupon/coupon-service.service';
@Component({
    selector: 'click-to-connect',
    templateUrl: './click-to-connect.component.html',
    styleUrls: ['./click-to-connect.component.scss']
})
export class ClickToConnectComponent implements OnInit {
    countDown: Subscription;
    public show:boolean = true;
    counter = 7;
    tick = 1000;
    public timer: number;
    public isTimerRequired: string;
    public interval: number = 1;
    // optin = localStorage.getItem("opt-in");
    userType = localStorage.getItem("userType");

    constructor(private couponService: CouponServiceService) {
        console.log(this.userType);
        if(this.userType == '0' || this.userType == null) {
            this.show = true;
        } else {
            this.show = false;
        }
        // if(this.optin == 'true') {
        //     this.show = false;
        // } else {
        //     this.show = true;
        // }
    }
    ngOnDestroy() {
        this.countDown = null;
    }


    ngOnInit(): void {
        this.timer = parseInt(window.localStorage.getItem("connect-timer") != null ?
            window.localStorage.getItem("connect-timer") : "0");
        this.isTimerRequired = window.localStorage.getItem("isTimerRequired");
        if (this.isTimerRequired === 'true') {
            this.countDown = timer(0, this.tick)
                .subscribe(() => {
                    --this.counter;
                    if (this.counter == 0) {
                        window.localStorage.setItem("connect-timer", this.counter + "");
                        this.countDown.unsubscribe();
                    }
                });
        }

    }

    sendAuthorizationLink() {
        let data = {
            "campaignId": localStorage.getItem('campaignId'),
            "token": localStorage.getItem("wifi-token")
        };
        this.couponService.getAuthorizationLink(data).subscribe(
            response => {

                if (response['status'] == 200) {
                    //alert("Ack sent successfully ");
                    this.resetLocalRedirectionData();
                    window.localStorage.setItem("isTimerRequired", "false");
                    this.isTimerRequired = "false";
                    window.location.href = response['authorizationUrl'];
                } else {
                    alert("Connection Failed");
                    this.isTimerRequired = "false";
                }

            }, error => {
                console.log(error);
                alert("Connection Failed");
                this.isTimerRequired = "false";
            }
        );
    }
    resetLocalRedirectionData() {
        window.localStorage.setItem("redirected-From", "");

    }
    acceptClicked() {
        this.show = false;
        // this.optin = 'true';
        localStorage.setItem("userType",'1');
        // localStorage.setItem("opt-in",this.optin);
    }
    cancelClicked() {
        this.show = false;
    }
}