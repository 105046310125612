<app-header></app-header>

<div class="offer">
  <div class="card">
    <!-- <app-coupon-card [couponCode]="cupDiv" [ClaimCode]="claimCode"></app-coupon-card> -->
    <img src="assets/img/couponimg.jpg" />
  </div>
  <div class="offerx">
    <div class="col-12">
      <div class="row">
        <div class="col-5">
          <img src="assets/img/samsung_logo.png" />
        </div>
        <div class="col-7">
   
          <p class="info-text">Enjoy Exclusive Premium offers from Popular and Awards Winning Brands.</p>
        </div>
      </div>
      <div class="col-12 col-offset-6">
        <div class="row">
          <div class="col-3"></div>
          <div class="col-9">
            <button type="submit">scan</button>
            <ng-otp-input #ngOtpInput (onInputChange)="onOtpChange($event)" [config]="config"></ng-otp-input>
            <span *ngIf="otp" class="o-t-p text-danger">Entered OTP :{{otp}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <label>Offer Details:</label>
      <div class="offers">
        <ul style="list-style-type:star;">
          <li *ngFor="let item of offerDetails">{{item}}</li>
        </ul>
        <!-- </div> -->
        <hr />
        <!-- <div class="note offers"> -->
        <ul style="list-style-type:star;">
          <li *ngFor="let item of note">{{item}}</li>
        </ul>
      </div>
    </div>
    <button type="button"  title="Click for Claim Code" [disabled]="isDisable" class="foot-btn" (click)="claimRewords()">Claim</button>
  </div>
</div>
