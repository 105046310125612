
<div class="coupon-wrapper">
    <div class="dashboard accordion" id="accordion">


        <div class="card card1" *ngFor="let card of campaignData;let i = index;">

            <!-- <img [src]="getImage(card.imgSrc,'campaign')" onerror="this.src='assets/def.jpeg';" -->
            <img src="../../../assets/def.jpeg" onerror="this.src='assets/def.jpeg';" class="cardImg" height="100%"
                [ngStyle]="{'height': height }" alt="this.src='assets/def.jpeg" />
            <!--If add type is Ad_commerce then shop now button will come-->

            <ng-container *ngIf="card.ad_type!='static'">
                <!-- <ng-container *ngIf="card.subProducts?.length > 0"> -->
                <!-- Used for that tab -->
                <div (scroll)="onScroll1($event)" class="btn-box" [class.cardExpand]="card.isActive"
                    [class.marginBtm]="card.isActive && card.subProducts?.length == 0">

                    <!-- <div>{{card.brandId}}</div> -->
                    <button class="sharebtnAcc" data-toggle="modal" data-target="#shareModal">
                        <!-- <i class="fa fa-share-alt" aria-hidden="true"></i> -->

                        <img class="menuIconStyl2" src="../../../assets/Share2.png" alt="">
                    </button>
                    <button class="claimbtnAcc text-center" (click)="claimBTNClicked(card)">Claim</button>
                    <button class="walletbtnAcc " (click)="walletBtnclicked()">
                        <img class="menuIconStyl3" src="../../../assets/Star.png" alt="">
                    </button>


                </div>

            </ng-container>
            <!--A Patch to show the bottom the image incase of ad_type of static. In this case no button should come-->
            <ng-container *ngIf="card.ad_type =='static'">
                <div class="card"></div>

            </ng-container>
            <div class="sub-product" *ngIf="card.isActive && card.subProducts?.length > 0">

                <div *ngIf="card.subProducts?.length > 0">
                    <ng-container>
                        <div class="card card1 cardStyl accordion"
                            *ngFor="let pcard of card.subProducts; index as index" [class.cardEx]="card.isActive">
                            <!-- <ng-container *ngIf="pcard.subProductName !=null && pcard.subProductName !='' ">
                                <div class="row pb-0" style="padding: 0vh;">
                                    <div class="col-5 autoTB">
                                        <img class="prod1" [src]="getImage(pcard.imgSrc,'campaign')"
                                            onerror="this.src='assets/img/default-image.png';" height="100%" alt="" />

                                        <div class="row m-0 mt-2">
                                            <button class="btn btn-default shopBtnSub"
                                                [class.shopBtnShop]="pcard.isShopBtnEnabled"
                                                (click)="shopNow(card.productId, pcard, index, card)">
                                                {{ pcard.isShopBtnEnabled ?("translations.earnButtonText" | translate ):
                                                ("translations.shopNowText"| translate) }}
                                            </button>
                                        </div>
                                    </div>
                                    <div class="col-7 pl-2">
                                        <div class="addCard p-0">
                                            <button type="button" class="addBtn3" data-toggle="collapse">
                                                <i class="fa plusIcon" [ngClass]="
                                    isSubProdDescToggleOn[index] ? 'fa-minus' : 'fa-plus'
                                  " aria-hidden="true" (click)="
                                    isSubProdDescToggleOn[index] = !isSubProdDescToggleOn[
                                      index
                                    ]
                                  "></i>
                                            </button>
                                        </div>
                                        <p class="pStyl1">
                                            {{ pcard.subProductName }}
                                        </p>
                                        <p class="grayText">
                                            {{ pcard.subProductDesc }}
                                        </p>
                                        <p class="grayText1">Size: {{ pcard.size }}</p>
                                        <p class="blackText">
                                            <b>Est. Retail&nbsp;{{ pcard.subProductPrice }}&nbsp;{{
                                                pcard.priceUnit
                                                }}</b>
                                        </p>
                                        <p class="strikeText">
                                            {{ pcard.subProductOfferPrice }}&nbsp;{{
                                            pcard.priceUnit
                                            }}&nbsp;{{ pcard.subProductOfferPercent }},
                                        </p>
                                        <p class="offExt">
                                            <b>{{ pcard.couponAmount }}&nbsp;{{
                                                pcard.priceUnit
                                                }}&nbsp;EXTRA&nbsp;{{ pcard.couponOfferPercent }}</b>
                                        </p>
                                    </div>
                                    <div class="row p-0 mt-1 rowS" *ngIf="isSubProdDescToggleOn[index] == true">
                                        <div class="col-12 grayText1 p-2">
                                            {{ pcard.subProductSpecDesc }}
                                        </div>
                                    </div>

                                    <div class="row p-0 mt-1 rowS shareBtns">
                                        <div class="col-6 p-0">
                                            <button type="button" class="btn btn-default btnStylBtm p-0">
                                                <u>{{ "translations.learnmoretext" | translate }}</u>
                                            </button>
                                        </div>
                                        <div class="col-6 p-0">
                                            <button type="button" class="btn btn-default btnStylBtm"
                                                (click)="shareApi1(card,pcard)">
                                                <i class="fa fa-share-alt logoImg1" aria-hidden="true"></i>
                                                &nbsp;Share
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </ng-container> -->

                            <img class="cardImg" src="../../../assets/def.jpeg"
                                onerror="this.src='assets/img/default-image.png';" height="100%" alt="" />

                            <div class=" cardBtn cardExpand1">

                                <button class="sharebtnAcc " data-toggle="modal" data-target="#shareModal">
                                    <i class="fa fa-share-alt" aria-hidden="true"></i>
                                </button>
                                <button class="claimbtnAcc text-center"
                                    (click)="claimsubproductBTNClicked(pcard)">Claim</button>
                                <button class="walletbtnAcc" (click)="walletBtnclicked()"><i class="fa fa-shopping-bag"
                                        aria-hidden="true"></i></button>
                                <button class="buttonsss">LearnMore</button>

                            </div>

                        </div>
                    </ng-container>
                </div>
            </div>

        </div>


    </div>
</div>

<div class="modal fade" id="shareModal" tabindex="-1" aria-labelledby="shareModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="top: 40%;">
        <div class="modal-content">
            <div class="modal-header">
                <h6 class="modal-title" id="shareModalLabel">Mobile No</h6>
                <input style="margin-left: 8px; border-radius: 3px;">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body font1">
                {{desc}} <button *ngIf="showMoreText" (click)="clickMoreText()" class="moreText">READ
                    MORE</button><button (click)="clickMoreText()" *ngIf="!showMoreText" class="moreText">READ
                    LESS</button>
            </div>
            <div class="modal-footer font1">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" (click)="shareApi($event)" class="btn btn-primary">Share</button>
            </div>
        </div>
    </div>
</div>