import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { Component, OnInit, HostListener, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { AppConstants } from 'src/app/app.constants';
import { MenuList } from 'src/app/data/data';
// import { CookieService } from 'ngx-cookie-service';
import { CouponServiceService } from 'src/app/services/coupon/coupon-service.service';
import { UserActivity } from 'src/app/data/user-activity.enum';
import { LoggingService } from 'src/app/services/logging.service';
import { Logging } from 'src/app/data/logging.model';

@Component({
  selector: 'app-categorypage',
  templateUrl: './categorypage.component.html',
  styleUrls: ['./categorypage.component.scss']
})
export class CategorypageComponent implements OnInit {
  public userId:any;
  public userType:any;
  public catId:any;
  public menuList: MenuList[];
  public catMenu: string;
  public brandMenu: string;
  public howItWorkMenu: string;
  public register: string;
  public showCountryModal: boolean;
  public languages = AppConstants.LANGUAGES;
  
  public menuCrossImg = 'assets/img/menuicon.svg';
  public date=new Date();
  public month = this.date.toLocaleString('default', { month: 'long' });
  public year = this.date.getFullYear();
  public curdate = (new Date().getMonth() + 1).toString() + '-' + new Date().getFullYear().toString();
  public menuToggle = false;
  public headerFix = false;
  public showScan:boolean=false;
  access_token=localStorage.getItem('Token');
  public countries:any;
  public adnetworks:any;
  public malls:any;
  public stores = [ 
      {
        storeId: 1,
        storeName:"Olympia"
      },
      {
        storeId: 34,
        storeName:"Athens Wifi"
      }
    ];
  public categoryArrays = [];
  private startTime= new Date();
  public scrollCard = [];
 
  constructor(
    private couponService: CouponServiceService,
    private router:Router,private location:Location,private translate:TranslateService,
    private loggingService:LoggingService
    
  ) {
    this.userId=localStorage.getItem("userId");
    if(this.userId == null) {
      localStorage.setItem("userId",'10000001');
    }else {
      console.log("userId",this.userId);
    }
    this.userType = localStorage.getItem("userType");
    if(this.userType == null) {
      localStorage.setItem("userType",'0');
    } else {
      console.log(this.userType);
    }
    console.log("lang" + this.translate.currentLang);
    this.translate.get("translations").subscribe((data) => {
      console.log(data);

      this.catMenu = data.catMenu;
      this.brandMenu = data.brandMenu;
      this.howItWorkMenu = data.howItWorkMenu;
      this.register = data.register;
      this.setMenu();
    })
   }

  ngOnInit() {
    this.getLocation();
    // this.getAllStores();
    this.getAllCategory();
    this.getAllCountries();
    this.CheckAuth();
    console.log(this.date);
    if (this.router.url === '/searchMenu') {
      this.menuToggle = true;
    } else {
      this.menuToggle = false;
    }
  }
  

  langchanged(item) {
    console.log("fddsfs",item);
    this.scrollCard = [];
    this.getAllCategory();
  }

  storeChange(id) {
    console.log("iddddd",id);
    // this.getAllCategoryById(id);
  }

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScrollEvent(event) {
    // console.log(event);
    const data = window.scrollY;
    // this.change.emit(data);
    // this.service.emitScrollEvent(data);
    // console.log(data);
    if (data > 49) {
      this.headerFix = true;
    } else {
      this.headerFix = false;
    }
    // document.getElementById('carouselExampleSlidesOnly').classList.add('pointer-event');
    // $('#carouselExampleSlidesOnly').carousel();
    // document.getElementById('carouselExampleSlidesOnly').carousel({ interval: 3000, cycle: true });
    // console.log(document.getElementById('carouselExampleSlidesOnly').classList);
  }

  scan()
  {
    this.showScan=!this.showScan;
   
  }

  eventFired(event) {
    //console.log(event);
    console.log(event);
  }
  CheckAuth(){
    if (this.access_token === null) {
      this.couponService.getToken().subscribe(
        res => {
         var token=res.accessToken;
          console.log(res);
          localStorage.setItem('Token',token);
          // this.Cookies.set('_token', token);
        }, err => {
          console.log(err);
          alert('Some error Occurred');
        }
      );
   this.saveAuth();
    }
     else {
      console.log(this.access_token);
    }
  }

  getAllCountries() {
    this.couponService.getAllCountries().subscribe(result => {
      console.log(result);
      this.countries = result['locationResponse'];
    })
  }
  selectCountry (id) {
    console.log('country id selected',id.target.value);
    this.getAllAdnetwork(id.target.value);
  }

  getAllAdnetwork (id) {
    this.couponService.getAllAdnetwork(id).subscribe(result => {
      console.log(result);
      this.adnetworks = result['masterAdNetworksResponse'];
    })
  }

  selectAdnetwork(id) {
    this.getAllMalls(id);
  }

  getAllMalls (id) {
    this.couponService.getAllMalls(id).subscribe(result => {
      console.log(result);
      this.malls = result['sellerresponse'];
    })
  }
  mallSelected(id) {
    this.router.navigate(['/malls'],{ queryParams: { mId: id }});
    // this.getAllStores(id);
  }


  // getImage(path: string,basePath) {
  //   let pathName= path.split(/(\\|\/)/);
  //   var path1 = pathName[pathName.length-1];
  //   return this.couponService.getImage(path1,basePath);
  // }

  public renderImage( categoryId: number): string {
    return this.couponService.getImagee( categoryId);
}

 
  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.showPosition);
    } else { 
      console.log( "Geolocation is not supported by this browser.");
    }
  }
  
 

  getAllCategory() {
      this.couponService.getAllCategory().subscribe(data => {
        this.scrollCard = data;
        // this.splitCategoryArray();
        console.log(this.scrollCard,"scrollllll");
        
      }, error => {
        console.log(error);
        alert('Some Error Occured');
      });
  
  }
  // public getAllCategories(data: object): void {

  //   this.categoryService.getAllCategories().subscribe(
  //     (result: any) => { 
       
  //       this.categoryObj = result;
       
  //       this.categories = result;
  //       this.totalElements = result.totalElements; 

  //       console.log('Received categories:', this.category);
  //       console.log('Received categories response:', result);
  //     },
      
  //   );
  // }
//   public renderImage( productId: number): string {
//     return this.couponService.getImage( productId);
// }
  // getAllCategoryById(id) {
  //   this.scrollCard=[];
  //   this.couponService.getAllCategoryById(id).subscribe(data => {
  //     this.scrollCard = data['categoryResponses'];
  //     // this.splitCategoryArray();
  //   }, error => {
  //     console.log(error);
  //     alert('Some Error Occured');
  //   });
  // }

  openHome(id) {
    localStorage.setItem('userCategory',id);
    console.log("id",id);
    let endTime = new Date();
    let userActivity: Logging[] = [];
    console.log(this.startTime);
    console.log(endTime.getTime())
    userActivity.push({
      advId: '',
      longitute:localStorage.getItem("Longitude"),
      latitude:localStorage.getItem("Latitude"),
      campaignId:'' ,
      categoryId:JSON.stringify(id),
      productId: '',
      subProductId: '',
      userId: localStorage.getItem("userId"),
      userAction: UserActivity[UserActivity.USER_CLICKED_CATEGORY],
      startTime: this.startTime.getTime(),
      endTime:  endTime.getTime(),
      duration: endTime.getTime() - this.startTime.getTime()
    });
    console.log("UserActivity",userActivity);



    this.loggingService.saveUserActivity(userActivity).subscribe();
    this.couponService.setCategoryId(id);
    this.router.navigate(['/home']);
    //this.router.navigate(['/home',{d:id}],{ relativeTo: this.activeRoute });
  }

  
  splitCategoryArray() {
    const category = this.scrollCard.slice(0);
    while (category.length) {
      this.categoryArrays.push(category.splice(0, 4))
    }
  }


  showPosition(position) {
    localStorage.setItem('Latitude',position.coords.latitude);
    localStorage.setItem('Longitude',position.coords.longitude);
    console.log( "Latitude: " + position.coords.latitude + 
    "<br>Longitude: " + position.coords.longitude);
  }
  storeClicked(id) {
    
    // localStorage.setItem("catId",id);
    // this.getAllCategoryById(id);
  }
  saveAuth() {
    const browserInfo: object = {
      userAgent: navigator.userAgent,
      appName: navigator.appName,
      appCodeName: navigator.appCodeName,
      appVersion: navigator.appVersion,
      platform: navigator.platform,
      cookieEnabled: '' + navigator.cookieEnabled,
      javaEnabled: '' + navigator.javaEnabled(),
      colorDepth: '' + window.screen.colorDepth,
      screenWidth: '' + window.screen.width,
      screenHeight: '' + window.screen.height,
      availWidth: '' + window.screen.availWidth,
      availHeight: '' + window.screen.availHeight,
      outerWidth: '' + window.outerWidth,
      outerHeight: '' + window.outerHeight,
      cpuClass: window.navigator.platform,
      referrer: document.referrer,
      localDate: (new Date().toLocaleString()),
      now: '' + Date.now(),
      longitude:localStorage.getItem('Longitude'),
      latitude:localStorage.getItem('Latitude'),
      language: navigator.language,
      browserLanguage: window.navigator.language,
      systemLanguage: window.navigator.language,
      userLanguage: navigator.language,
      accessToken:localStorage.getItem('Token')
    };
    // this.Cookies.set( '_token', JSON.stringify(browserInfo) );
  //  var cookieValue=this.Cookies.get('_token');
    console.log(browserInfo);
    localStorage.setItem("browserInfo",JSON.stringify(browserInfo));
    
    this.couponService.saveBrowserInfo(browserInfo).subscribe(result=>
      {
        console.log(result);
      },err => {
        console.log(err);
       
      }
      )
  }

  async toggleMenu() {
    this.menuToggle = !this.menuToggle;
    if (this.menuToggle === true) {
      // this.router.navigate(['/searchMenu']);
    } else {
      // this.router.navigate(['/category']);
      // this.location.back();

    }
  }

  NewTab() {
    window.open("https://www.Place10x.com/Mall10XV2", "_blank");
  }
  NewTab1() {
    window.open("https://www.Place10x.com/wyyn", "_blank");
  }


  setMenu() {
    this.menuList = [
      /*{
        id: 1,
        mName: this.catMenu,
        url: "/search",
      },*/
      {
        id: 2,
        mName: this.brandMenu,
        url: "/search",
      },
      {
        id: 3,
        mName: this.howItWorkMenu,
        url: "/",
      },
      {
        id: 4,
        mName: this.register,
        url: "/login/register",
      },
    ];
    console.log(this.menuList);
  }
  changeLanguage(item) {
    this.translate.use(item.frontendCode);
    localStorage.setItem('language', item.frontendCode);
    this.reloadCurrentRoute();
  }
  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }
   
  
  
 

}
