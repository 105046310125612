import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.scss']
})
export class QrCodeComponent implements OnInit {
   public paramData:any;
  constructor(private route:ActivatedRoute,private router:Router) { }

  ngOnInit() {
    this.route.queryParams.subscribe(param => {
      console.log(param);
      this.paramData = param;
      this.saveAuthorization();
    });
  }

  saveAuthorization() {
    console.log("param",this.paramData);
    localStorage.setItem('routerdeviceId', this.paramData.id);
    // localStorage.setItem('routerdeviceId', this.paramData.ap);
    this.router.navigate(['/category']);
  }

}
