<div class="dashboard-header">
  <app-headers></app-headers>
</div>
<!-- <app-lowhead></app-lowhead> -->


<div [ngClass]="headerFix?'earn-header':''">
  <app-earn-header [showLogo]="headerFix"></app-earn-header>
</div>
<div style="height: 50px;
  width: 100%;
  background: #000;
  margin-bottom: 10px;" class="headers card">
</div>
<ng-container>

  <!-- <app-tiles-template [campaignData]="coupons"></app-tiles-template> -->
  <app-tiles-templatep [campaignData]="coupons"></app-tiles-templatep>


</ng-container>

<!-- <app-footer (categoryIdChanged)="categoryChanged($event)"></app-footer> -->