<app-header></app-header>
<app-filter></app-filter>
<div *ngFor="let item of coupons" class="coupon">
  <div class="card btns-size">
    <div class="card-body">
      <app-coupon-card [backImage]="item"></app-coupon-card>
      <div class="row m-0">
        <div class="col p-1">
          <button type="button" class="btn btn-primary action-btn gift"><img class="btn-img" src="assets/img/gift.png"
              alt="">&nbsp;Gift</button>
        </div>
        <div class="col p-1">
          <button type="button" class="btn btn-primary action-btn claim" [routerLink]="['/survey']"><img class="btn-img" src="assets/img/claim.png"
              alt="">&nbsp;Claim</button>
        </div>
        <div class="col p-1">
          <button type="button" class="btn btn-primary action-btn wallet"><img class="btn-img"
              src="assets/img/wallet.png" alt="">&nbsp;Wallet</button>
        </div>
        <div class="col-2 p-1" data-toggle="collapse" [attr.data-target]="'#demo'+ item.id" *ngIf="item.hasChild">
          <button type="button" (click)="btnIcon=!btnIcon"class="btn btn-primary action-btn plus"><i [ngClass]="btnIcon ? 'fa fa-minus' : 'fa fa-plus'"
              aria-hidden="true"></i></button>
        </div>
      </div>
    </div>
  </div>
  <div class="card collapse child-card" [id]="'demo'+item.id">
    <div class="card-body" >
      <div class="row m-0 mt-1" *ngFor="let item of childCoupons">
      <app-coupon-card [backImage]="item"></app-coupon-card>
    </div>
      <div class="row m-0">
        <div class="col p-1">
          <button type="button" class="btn btn-primary action-btn gift"><img class="btn-img" src="assets/img/gift.png"
              alt="">&nbsp;Gift</button>
        </div>
        <div class="col p-1">
          <button type="button" class="btn btn-primary action-btn claim" [routerLink]="['/survey']"><img class="btn-img" src="assets/img/claim.png"
              alt="">&nbsp;Claim</button>
        </div>
        <div class="col p-1">
          <button type="button" class="btn btn-primary action-btn wallet"><img class="btn-img"
              src="assets/img/wallet.png" alt="">&nbsp;Wallet</button>
        </div>
      </div>
    </div>
  </div>
</div>
