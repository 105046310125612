<app-headers></app-headers>
<div [ngClass]="headerFix?'earn-header':''">
  <app-earn-header [showLogo]="headerFix"></app-earn-header>
</div>
<div class="bg" [ngStyle]="{'margin-top': headerFix?'55px':'0'}">
  <!-- <div class="shadow bg-white">
        <app-header></app-header>
    </div> -->
  <div class="headers">
    <div class="overflow">
      <div class="card">
        <div class="row row1">
          <div class="col-12 disply1">
            <button type="button" class="btnClr" data-toggle="modal" data-target="#categoryModal">
               {{catgBtn}}&nbsp;&nbsp;<i class="fa fa-plus plusIcon1" aria-hidden="true"></i>
            </button>
            <button type="button" class="btnClr" data-toggle="modal" data-target="#brandModal">
               {{brandBtn}}&nbsp;&nbsp;<i class="fa fa-plus plusIcon2" aria-hidden="true"></i>
            </button>
            <button type="button" class="btnClr" data-toggle="modal" data-target="#viewListModal">
              {{listBtn}}&nbsp;&nbsp;<i class="fa fa-sliders plusIcon3" aria-hidden="true"></i>
            </button>
          </div>

        </div>
      </div>

      <div class="modal categoryModal" id="categoryModal">
        <div class="modal-dialog modalDia">
          <div class="bg"></div>
          <div class="modal-content">
            <!-- Modal Header -->
            <div class="modal-header">
              <!-- <h4 class="modal-title">Category</h4> -->
              <h4 class="modal-title">{{ "translations.catMenu" | translate }}</h4>
              <button type="button" class="close clsbtn btn-light p-2" data-dismiss="modal">&times;</button>
            </div>
            <!-- Modal body -->
            <div class="modal-body">
              <div class="search-div">
                <!-- <input class="form-control" type="text" name="searchC" [(ngModel)]="searchText" autocomplete="off"
                  placeholder="Search here. . . . . ."> -->

                  <!-- <div class="input-group">
                    <input type="search" placeholder="Search here. . . . . ." type="text" name="searchC" [(ngModel)]="searchText" autocomplete="off" aria-describedby="button-addon1"
                      class="form-control border-0 bg-light margnT">
                    <div class="input-group-append" style="width: 20%;">
                      <button type="submit" class="button-addon1 btn btn-link text-primary"> <img class="menuIcon"
                          src="assets/img/search.png" alt=""></button>
                    </div>
                  </div> -->

              </div>
              <ul class="list-unstyled" *ngFor="let item of categories | filter:searchText">
                <li class="list-group-item borderT" (click)="selectCatg(item)" data-dismiss="modal">{{item.catName}}</li>
              </ul>
            </div>

            <!-- Modal footer -->
            <!-- <div class="modal-footer">
              <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
            </div> -->

          </div>
        </div>
      </div>

      <div class="modal brandModal" id="brandModal">
        <div class="modal-dialog modalDia">
          <div class="bg"></div>
          <div class="modal-content">
            <!-- Modal Header -->
            <div class="modal-header">
              <h4 class="modal-title">{{ "translations.brandMenu" | translate }}</h4>
              <!-- <h4 class="modal-title">Brand</h4> -->
              <button type="button" class="close clsbtn btn-light p-2" data-dismiss="modal">&times;</button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
              <div class="search-div">
                <!-- <input class="form-control" type="text" name="searchB" [(ngModel)]="searchText" autocomplete="off"
                  placeholder="Search here. . . . . ."> -->
<!-- 
                  <div class="input-group">
                    <input type="search" placeholder="Search here. . . . . ." type="text" name="searchC" [(ngModel)]="searchText" autocomplete="off" aria-describedby="button-addon1"
                      class="form-control border-0 bg-light margnT">
                    <div class="input-group-append" style="width: 20%;">
                      <button type="submit" class="button-addon1 btn btn-link text-primary"> <img class="menuIcon"
                          src="assets/img/search.png" alt=""></button>
                    </div>
                  </div> -->

              </div>  
              <ul class="list-unstyled" *ngFor="let item of brand | filter:searchText">
                <li class="list-group-item borderT" (click)="selectBrand(item)" data-dismiss="modal">{{item.name}}</li>
              </ul>
            </div>

            <!-- Modal footer -->
            <!-- <div class="modal-footer">
              <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
            </div> -->

          </div>
        </div>
      </div>


      <div class="modal viewListModal" id="viewListModal">
        <div class="modal-dialog modalDia">
          <div class="bg"></div>
          <div class="modal-content">
            <!-- Modal Header -->
            <div class="modal-header">
              <!-- <h4 class="modal-title">Sort</h4> -->
              <h4 class="modal-title">{{ "translations.sortMenu" | translate }}</h4>
              <button type="button" class="close clsbtn btn-light p-2" data-dismiss="modal">&times;</button>
            </div>
            <!-- Modal body -->
            <div class="modal-body">
              <!-- <div class="search-div">
                <input class="form-control" type="text" name="searchS" [(ngModel)]="searchText" autocomplete="off"
                  placeholder="Search here. . . . . .">
              </div> -->
              <ul class="list-unstyled" *ngFor="let item of viewList">
                <li class="list-group-item borderT" (click)="selectList(item)" data-dismiss="modal">{{item.vName}}</li>
              </ul>
            </div>

            <!-- Modal footer -->
            <!-- <div class="modal-footer">
              <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
            </div> -->

          </div>
        </div>
      </div>

      <!-- <div class="p-1 bg-light rounded rounded-pill shadow-sm m-1 searchDiv"> -->
     
      <div class="mb-2">
        <app-filter></app-filter>
      </div>
      <!-- <nav class="navbar navbar-expand-sm bg-light">
                Links
                <ul class="navbar-nav mt-0 justify-content-center " style="margin-left: auto; margin-right: auto;">
                    <li class="nav-item active mr-1 ml-1">
                        <a class="nav-link navLinks active"  href="#">All</a>
                      </li>
                    <li class="nav-item active mr-1 ml-1">
                      <a class="nav-link navLinks"  href="#">#</a>
                    </li>
                    <li class="nav-item mr-1 ml-1">
                      <a class="nav-link navLinks" href="#">A-E</a>
                    </li>
                    <li class="nav-item mr-1 ml-1">
                        <a class="nav-link navLinks" href="#">F-J</a>
                      </li>
                      <li class="nav-item mr-1 ml-1">
                        <a class="nav-link navLinks" href="#">K-O</a>
                      </li>
                      <li class="nav-item mr-1 ml-1" >
                        <a class="nav-link navLinks" href="#">P-T</a>
                      </li>
                      <li class="nav-item mr-1 ml-1">
                        <a class="nav-link navLinks" href="#">U-Z</a>
                      </li>
                  </ul>

            </nav> -->
      <!-- <div class="container"> -->
      <!-- <div class="card cardStyl">
          <div class="row" style="padding: 1vh;">
            <div class="col-4">
              <img class="prod1" src="assets/img/samsung2.jpg" alt="">
            </div>
            <div class="col-8">
              <p class="pStyl1">
                Earn upto AED 100
              </p>
              <p class="pStyl1T">
                A premium demin brand that's earned acclaim for its fine fits, fabrics and finishes.
              </p>
            </div>

          </div>
        </div>

        <div class="card cardStyl">
          <div class="row" style="padding: 1vh;">
            <div class="col-4">
              <img class="prod1" src="assets/img/samsung1.jpg" alt="">
            </div>
            <div class="col-8">
              <p class="pStyl1">
                Earn upto AED 100
              </p>
              <p class="pStyl1T">
                A premium demin brand that's earned acclaim for its fine fits, fabrics and finishes.
              </p>
            </div>

          </div>
        </div>

        <div class="card cardStyl">
          <div class="row" style="padding: 1vh;">
            <div class="col-4">
              <img class="prod1" src="assets/img/Samsunglandingpage.jpg" alt="">
            </div>
            <div class="col-8">
              <p class="pStyl1">
                Earn upto AED 100
              </p>
              <p class="pStyl1T">
                A premium demin brand that's earned acclaim for its fine fits, fabrics and finishes.
              </p>
            </div>

          </div>
        </div>

        <div class="card cardStyl">
          <div class="row" style="padding: 1vh;">
            <div class="col-4">
              <img class="prod1" src="assets/img/samsung1.jpg" alt="">
            </div>
            <div class="col-8">
              <p class="pStyl1">
                Earn upto AED 100
              </p>
              <p class="pStyl1T">
                A premium demin brand that's earned acclaim for its fine fits, fabrics and finishes.
              </p>
            </div>

          </div>
        </div> -->
      <!-- <div class="dashboard accordion" id="accordion">
          <div class="card card1" *ngFor="let card of coupons" [id]="'card'+card.id">
            <img [src]="card.imgURL" class="cardImg" height="100%" alt="">
            <button class="shop-btn1"><i class="fa fa-share-alt" aria-hidden="true"></i></button>
            <button class="shop-btn2"><img class="imgCart" src="assets/img/cartBlack.png"></button>
            <span class="description">
              {{card.desc}}
              <br>
              Expiry Date: {{card.expDate}}
            </span>
            <button class="btn btn-default shop-btn" [ngClass]="card.btnText=='Claim'?'claim-btn':''"
              (click)="shopNow(card)">{{card.btnText}}</button>
            <button type="button" data-toggle="collapse" [attr.data-target]="'#demo'+ card.id" class="shop-btn3"><i
                class="fa fa-plus" aria-hidden="true"></i></button>
            <div class="collapse mt-2" [id]="'demo'+card.id" [attr.aria-labelledby]="'#card'+card.id"
              data-parent="#accordion">
              <div class="container bg-light">
                <div class="card cardStyl" *ngFor="let card of prodCard">
                  <div class="row" style="padding: 1vh;">
                    <div class="col-4">
                      <img class="prod1" [src]="card.imgURL" height="100%" alt="">
                    </div>
                    <div class="col-8">
                      <p class="pStyl1">
                        {{card.name}}
                      </p>
                      <p class="pStyl1T">
                        {{card.desc}}
                      </p>
                    </div>
                    <button type="button" class="addBtn3"><i class="fa fa-plus plusIcon"
                        aria-hidden="true"></i></button>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      <!-- <app-product-card [firstValue]="value" [difference]="difference" [coupons]="coupons"></app-product-card> -->
      <ng-container>
        <!--Tiles-->
        <app-tiles-template [campaignData]="coupons"></app-tiles-template>
      </ng-container>

    </div>

  </div>
</div>
<!-- </div> -->
