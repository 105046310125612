import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EventEmitterService {
  public onChange: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  public emitScrollEvent(scrollY: number) {
    // do something, then...
    this.onChange.emit(scrollY);
  }
}
