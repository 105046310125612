import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-claim-header',
  templateUrl: './claim-header.component.html',
  styleUrls: ['./claim-header.component.scss']
})
export class ClaimHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
