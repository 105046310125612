import { Component, AfterViewInit, Renderer2 } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { RouterOutlet } from '@angular/router';
import { routeTransitionAnimations } from './route-transition-animations';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routeTransitionAnimations]
})
export class AppComponent implements AfterViewInit {
  title = 'Coupon';
  constructor(private renderer: Renderer2,updates: SwUpdate) {
    updates.available.subscribe(event => {
    //   if (promptUser(event)) {
    //     updates.activateUpdate().then(() => document.location.reload());
    //   }
    });
   }
  ngAfterViewInit(): void {
    let loader = this.renderer.selectRootElement('#preloader');
    this.renderer.setStyle(loader, 'display', 'none');
  }
  prepareRoute(outlet: RouterOutlet) {
    return outlet && 
      outlet.activatedRouteData && 
      outlet.activatedRouteData['animationState'];
   }

}

