<div class="deskBack">
  <img class="backImg" src="assets/img/backDubai2.jpg">
  <!-- <app-claim-header></app-claim-header> -->
  <!-- <app-earn-header></app-earn-header> -->
  <div class="shadow bg-white">
    <div [ngClass]="headerFix?'earn-header':''">
      <app-earn-header [showLogo]="true"></app-earn-header>
    </div>
  </div>
  <div class="otp-page card">
    <div class="card" *ngIf="actv1">
      <!-- <app-coupon-card [couponCode]="cupDiv" [ClaimCode]="claimCode"></app-coupon-card> -->
      <div class="cardImg ">
        <img class=" " [src]="getImage(imgFile)" />
      </div>
    </div>

    <div class="card" *ngIf="!actv1">
      <!-- <app-coupon-card [couponCode]="cupDiv" [ClaimCode]="claimCode"></app-coupon-card> -->
      <!-- <div class="cardImg ">
        <app-scanner (newItemEvent)="eventFired($event)"></app-scanner>
      </div> -->
    </div>
    <!-- type="button" class="btn-sm btns" -->
    <!-- <button type="button" class="btn btn-info btn-rounded scants" (click)="scan()" *ngIf="isDisableClaim"
      style="background-color: #555555;"> <i class="fa fa-qrcode" aria-hidden="true"></i> &nbsp;Scan</button> -->


    <div class="offerx ml-2">
      <!-- <div class="row btnblocks" *ngIf="!actv4"> -->
      <!-- <button *ngIf="!addedToWallet" type="button" class="btn-sm btns" style="margin-left: 20%;" (click)="addtoWalletBtn()">Add to Wallet</button>
        <button *ngIf="addedToWallet" type="button" class="btn-sm btns" style="margin-left: 20%;" (click)="gotoWalletBtn()">Go to Wallet</button>
        <button type="button" class="btn-sm btns" (click)="claimCoupon()">Redeem Now</button> -->
      <button (click)="clBtnzClicked(cardDetails)" *ngIf="isClaimButton" class="clBtn">Claim</button>
      <button (click)="clBtnzClickedAgain(cardDetails)" *ngIf="!isClaimButton" class="clBtnClicked">Claim</button>
      <!-- </div> -->
      <!-- <div class="row row1" [ngStyle]="{'display': isDisableClaim?'block':'none'}">
        <div class="col-12 mb-1">
          <span class="text-black pl-0 mb-1">Enter OTP</span>
          <ng-otp-input #ngOtpInput class="otp-wrapper" (onInputChange)="onOtpChange($event)" [config]="config">
          </ng-otp-input>
          <span *ngIf="otp" class="o-t-p text-left">Entered OTP :{{otp}}</span>
        </div>
      </div> -->
      <!-- <div class="row row1" [ngStyle]="{'display': otpShow?'block':'none'}">
        <div class="col-12 mb-1">
          <span class="text-black pl-0 mb-1">Enter Pin</span>
          <ng-otp-input   class="otp-wrapper" (onInputChange)="pinVaidate($event)" [config]="config">
          </ng-otp-input>
          <span *ngIf="otp" class="o-t-p text-left">Entered pin :{{pin}}</span>
        </div>
      </div>  -->
      <div class="row bgColor mt-2">
        <div class="col-9">
          <div class="cupDigits">
            <p class="text-center pt-1" #userinput><span *ngIf="!showCode">WIF222*****</span><span
                *ngIf="showCode">{{couponCode}}</span></p>
          </div>
        </div>
        <div class="col-2">
          <div>
            <button class="text-center text-black btn-sm copyBtn" [disabled]="isDisable"
              (click)="copyCode(couponCode)">COPY</button>
          </div>
        </div>
      </div>
      <div class="col-12 conditions">
        <label>Offer Details:</label>
        <div class="offers">
          <ol style="list-style-type:1;">
            <li *ngFor="let item of offerDetails">{{item}}</li>
          </ol>
          <!-- </div> -->
          <hr />
          <!-- <div class="note offers"> -->
          <div class="wh">
            <label>Please Note:</label>
            <ul style="list-style-type:star;">
              <li *ngFor="let item of note">{{item}}</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="text-center">
        <button (click)="buttonClosedG()" class="closeBtn"><img src="assets/img/close.png" alt=""></button>

      </div>
      <!-- <button type="button" title="Click for Claim Code" [disabled]="isDisableClaim" class="foot-btn"
        (click)="claimCoupon()">Claim</button> -->
    </div>
  </div>
</div>
<!-- <click-to-connect></click-to-connect> -->
<div class="deskView">
</div>