<div class="dashboard-header">
  <app-header></app-header>
</div>
<div [ngClass]="headerFix?'earn-header':''">
  <app-earn-header [showLogo]="headerFix"></app-earn-header>
</div>
<app-product-card [firstValue]="value" [difference]="difference" [coupons]="coupons"></app-product-card>
<hr class="dotted">
<!-- <app-product-card [firstValue]="value" [difference]="difference" [coupons]="coupons"></app-product-card>
<hr class="dotted">
<app-product-card [firstValue]="value" [difference]="difference" [coupons]="coupons"></app-product-card> -->
