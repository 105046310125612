<div class="headers">
    <div class="header">
  
  
      <div class="scrollmenu1">
        <div class="acnleft1">
          <a (click)="openHome4()">
            <img class="menuIconStyl" src="assets/menuicon1.png" alt="">
  
  
            <!-- <i class="bi bi-list"></i> -->
          </a>
        </div>
        <div class="acnleft">
          <a (click)="openHome()">New</a>
        </div>
  
        <div class="acn"> <a (click)="openHome2()">Deal</a></div>
  
        <div class="acnright">
          <a (click)="openHome3()">Trending</a>
        </div>
      </div>
    </div>
  
  </div>