import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CouponServiceService } from 'src/app/services/coupon/coupon-service.service';

@Component({
  selector: 'app-banner-template',
  templateUrl: './banner-template.component.html',
  styleUrls: ['./banner-template.component.scss']
})
export class BannerTemplateComponent implements OnInit {

  @Input() campaignData: any;



  constructor(private router: Router,
    private couponService: CouponServiceService) { }

  ngOnInit() {
    console.log(this.campaignData[0].imgSrc);
  }
  shopNow() {
    localStorage.removeItem('subProdDetails');
    localStorage.removeItem('offer');

    // localStorage.clear();

    localStorage.setItem('subProdDetails', JSON.stringify(this.campaignData.Prod));
    localStorage.setItem('offer', JSON.stringify(this.campaignData.subcatId));
    this.router.navigate(['/offer']);
  }
  getImage(path: string,basePath) {
    let pathName= path.split(/(\\|\/)/);
    var path1 = pathName[pathName.length-1];
    console.log('path11111',path1);
    return this.couponService.getImage(path1,basePath);
  }

}
