import { Location } from '@angular/common';
import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Logging } from 'src/app/data/logging.model';
import { UserActivity } from 'src/app/data/user-activity.enum';
import { CouponServiceService } from 'src/app/services/coupon/coupon-service.service';
import { LoggingService } from 'src/app/services/logging.service';

@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.scss']
})
export class WalletComponent implements OnInit {

  public headerFix = false;
  // public difference = 280;
  public value = 250;
  public value1 = 250;
  public coupons=[];
  public couponList=[];
  ValidDate:any;
  private startTime= new Date();
  navigator = window.navigator as any;
  // public coupons = [
  //   {
  //     id: 4,
  //     productName: 'Tops',
  //     productDesc: 'Flat 25% off',
  //     productPrice: 3000,
  //     productOfferPrice: 2500,
  //     productWeight: 200,
  //     productWeightMeasure: 'gram',
  //     productManufacturer: 'AMBH',
  //     productSpecification1: 'Cotton',
  //     productSpecification2: 'Printed',
  //     productSpecification3: 'Printed',
  //     productSpecification4: 'Printed',
  //     productSpecification5: 'Printed',
  //     productOverview1: 'Red',
  //     productOverview2: 'Light',
  //     productOverview3: 'Light',
  //     productOverview4: 'Cotton',
  //     productOverview5: 'Light',
  //     subProducts: [
  //       {
  //         subProductName: 'Floral Tops',
  //         subProductDesc: 'A premium denim brand thats earned acclaim for its fine fits, fabrics and finishes.',
  //         subProductPrice: 2500,
  //         subProductOfferPrice: 2250,
  //         subProductWeight: 100,
  //         subProductWeightMeasure: 'gram',
  //         subProductManufacturer: 'AMBH',
  //         subProductSpecification1: 'Cotton',
  //         subProductOverview1: 'Floral',
  //         size: 'M',
  //         imgSrc: 'Video/Coupon/Product/abmh.png',
  //         extraPrice: 'Rs.250',
  //         button: '\'Earn ! AED 300\'',
  //         productId: 4
  //       },
  //       {
  //         subProductName: 'Formal Tops',
  //         subProductDesc: 'A premium denim brand thats earned acclaim for its fine fits, fabrics and finishes.',
  //         subProductPrice: 2500,
  //         subProductOfferPrice: 2000,
  //         subProductWeight: 100,
  //         subProductWeightMeasure: 'gram',
  //         subProductManufacturer: 'AMBH',
  //         subProductSpecification1: 'Cotton',
  //         subProductOverview1: 'Formal',
  //         size: 'XXL',
  //         imgSrc: 'Video/Coupon/Product/abmh.png',
  //         extraPrice: 'Rs.500',
  //         button: '\'Earn ! AED 300\'',
  //         productId: 4
  //       }
  //     ],
  //     imgSrc: 'Video/Coupon/Product/abmh.png',
  //     extraPrice: 'Rs.500',
  //     buttonName: 'Earn Extra! Up to AED 300',
  //     catId: 1,
  //     subcatId: 1,
  //     brandId: 4,
  //     discountId: 0
  //   },
  //   {
  //     id: 2,
  //     productName: 'Canvas Shoes',
  //     productDesc: '125 AED discount',
  //     productPrice: 2200,
  //     productOfferPrice: 2000,
  //     productWeight: 200,
  //     productWeightMeasure: 'gram',
  //     productManufacturer: 'clymb',
  //     productSpecification1: 'Canvas',
  //     productSpecification2: 'Synthetic',
  //     productSpecification3: 'comfort',
  //     productSpecification4: 'washable',
  //     productSpecification5: 'good',
  //     productOverview1: 'White Colour',
  //     productOverview2: 'Good',
  //     productOverview3: 'Good',
  //     productOverview4: 'Awesome',
  //     productOverview5: 'comfortable',
  //     subProducts: [],
  //     imgSrc: 'Video/Coupon/Product/clymb.png',
  //     extraPrice: 'Rs.200',
  //     buttonName: 'Earn Extra! Up to AED 300',
  //     catId: 1,
  //     subcatId: 1,
  //     brandId: 2,
  //     discountId: 0
  //   },
  // ];
  constructor(
    private couponService: CouponServiceService,
    private loggingService: LoggingService,
    private router:Router,
    private toastr:ToastrService,
    private location:Location
  ) { }

  ngOnInit() {
    this.getAllWallet();
    // this.getWishList();
  }

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScrollEvent(event) {
    // console.log(event);
    const data = window.scrollY;
    // this.change.emit(data);
    // this.service.emitScrollEvent(data);
    // console.log(data);
    if (data > 49) {
      this.headerFix = true;
    } else {
      this.headerFix = false;
    }
  }
  getImage(path: string,basePath) {
    let pathName= path.split(/(\\|\/)/);
     var path1 = pathName[pathName.length-1];
    // console.log(pathName);
    // console.log('path11111',path1);
    return this.couponService.getImage(path1,basePath);
  }

  shareApi(card) {
    let endTime = new Date();
    //Shop button clicked
    //this.shopBtnText[index] = "Earn AED";
    let userActivity: Logging[] = [];
    let campaignId = card.campainMgmtId;
    userActivity.push({
      advId: "",
      longitute:localStorage.getItem("Longitude"),
      latitude:localStorage.getItem("Latitude"),
      campaignId: JSON.stringify(card.campainMgmtId),
      categoryId:JSON.stringify(card.catId),
      productId: JSON.stringify(card.productId),
      subProductId: '',
      userId: localStorage.getItem("deviceId"),
      userAction: UserActivity[UserActivity.USER_SHARED],
      startTime: this.startTime.getTime(),
      endTime:  endTime.getTime(),
      duration: endTime.getTime() - this.startTime.getTime()
    });
    console.log("UserActivity",userActivity);
    


    this.loggingService.saveUserActivity(userActivity).subscribe();

    if (this.navigator.share) {
      this.navigator
        .share({
          title: "place10x.app",
          url: this.router.url,
          text:
            "Hey i found this interesting offer.Visit this link to check the offer" +
            this.router.url,
        })
        .then(() => {
          console.log("Thanks for sharing!");
        })
        .catch(console.error);
    } else {
      this.toastr.error("", "Something went wrong", { timeOut: 1600 });
    }
  }
  

  getAllWallet() {
    let mobileNo=localStorage.getItem("mobileNo");
    this.couponService.getAllWallet(mobileNo).subscribe(data => {
      // console.log(data);
      this.coupons = data.walletResponses;
      console.log('walletResponses List:', this.coupons);
      this.ValidDate=data.walletResponses.orderTime;
    }, error => {
      console.log(error);
    });
  }

  getWishList() {
    this.couponService.getWishList().subscribe(result => {
      // console.log("Result issss ",result);
      this.couponList = result.wishResponses;
      console.log('wishResponses List:', this.couponList);
    }, error => {
      console.log(error);
    });
  }
//To save for Later
  saveLater() {
  let deviceId=localStorage.getItem("deviceID");
  this.couponService.saveLater(deviceId).subscribe(result =>{
    this.toastr.show("Saved Successfully");
    this.location.back();
  })
  }
}
