<div class="container-fluid">
  <div class="headers">
    <div class="cat-product">
        <div class="row">
          <div class="col-lg-3 col-sm-12 con" *ngFor="let pcard of scrollCard">
            <div class="card card2" (click)="openHome(pcard.categoryId)">
              <div class="image-box">
              <img [src]="renderImage(pcard.categoryId)" class="imgSc1 img-thumbnail"/>
              </div>
              <h3 class="pStyl">
                {{ pcard.categoryName }}
                <i class="fa fa-arrow-circle-o-right" aria-hidden="true"></i>
              </h3>
            </div>
          </div>
        </div>
      
    </div>
  </div>
</div>
