<!-- <app-lowhead></app-lowhead> -->

<div class="wrapper" (click)="homeClick();">
  <div id="main-header">
    <!-- <img class="bg-img" src="assets/img/bg-final.jpg"> -->
    <img class="bg-img" *ngIf="mediaType === 'image'" [src]="homeBg">
    <!-- <img class="banner-img"   [src]="homeBg"> -->
    <!-- <video class="full-video" [style.display]="videoVisible ? 'block':'none' " #myVideo muted="true" autoplay="true"
      preload="auto" playsinline width="100%" height="100%" (ended)="videoEnd()" > -->
    <video #video (canplay)="video.play()" (loadedmetadata)="video.muted = true" class="full-video"
      *ngIf="mediaType === 'video'" playsinline controls autoplay (ended)="homeClick()">
      <source [src]="homeBg" type="video/mp4">
      <!-- <source src="assets/img/visa/video1.mp4" type="video/mp4"> Sorry, your browser doesn't support embedded videos. -->
      <!-- <source src="https://wifi10x.com/Video/Meraas1.mp4" type="video/mp4"> Sorry, your browser doesn't support embedded videos. -->
      <!-- <source src="assets/img/SamsungAd1.mp4" type="video/mp4"> Sorry, your browser doesn't support embedded videos. -->
      <!-- <source [src]="video1" type="video/mp4">  -->
      Sorry, your browser doesn't support embedded videos.
      <!-- <source src="{{video1}}" type="video/mp4"> Sorry, your browser doesn't support embedded videos. -->
    </video>
  </div>

  <div class="footer fs-ad-footer" *ngIf="!videoVisible">
    <app-footer></app-footer>
  </div>
  <!-- Click here for Free High-Speed Internet -->
</div>