import { Component, OnInit, HostListener } from '@angular/core';
import { Cart } from 'src/app/data/data';
import { CouponServiceService } from 'src/app/services/coupon/coupon-service.service';
import { CartService } from 'src/app/services/cart/cart.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {
  public show: boolean = false;
  public buttonName: any = 'Apply';
  public showDiscount: boolean;
  public headerFix = false;
  public totalAmtCalc: any;
  discount = 'Extra Discount';
  subtotal = 'Subtotal';
  total = 'Total';
  grandTotal = 'Grand Total';
  shipping = 'Shipping';
  totalPrice=0.0;
  
  // discountList: string[] = ['300.00'];
  // subtotalList: string[] = ['4299.00'];
  shippingList: string[] = ['FREE'];
  // totalAmt: string[] = ['4299.00 '];
  public filters: Cart[];
  public cartResp = [];
  public couponResp: any;
  constructor(
    private couponService: CouponServiceService,
    private cartService: CartService,
    public router: Router
  ) {
    this.filters = [
      {
        id: 1,
        name: 'Perfume 1',
        image: 'assets/img/prod5.jpg',
        price: 2000,
      },
      {
        id: 2,
        name: 'Perfume 2',
        image: 'assets/img/prod5.jpg',
        price: 2000,
      },
      {
        id: 3,
        name: 'Perfume 3',
        image: 'assets/img/prod5.jpg',
        price: 2000,
      },
      {
        id: 4,
        name: 'Perfume 4',
        image: 'assets/img/prod5.jpg',
        price: 2000,
      },
      // {
      //   id: 5,
      //   name: 'Perfume 5',
      //   image: 'assets/img/prod5.jpg',
      //   price : 2000,
      // },
      // {
      //   id: 6,
      //   name: 'Perfume 6',
      //   image: 'assets/img/prod5.jpg',
      //   price : 2000,
      // },
    ];
  }

  ngOnInit() {
    this.getAllCart()
    this.showDiscount = false;
   
  }
  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScrollEvent(event) {
    // console.log(event);
    const data = window.scrollY;
    // this.change.emit(data);
    // this.service.emitScrollEvent(data);
    // console.log(data);
    // COU457
    if (data > 49) {
      this.headerFix = true;
    } else {
      this.headerFix = false;
    }
  }

  showDiscountAmt(subProductPrice, subProductOfferPrice) {
    this.show = !this.show;
    let tAmount: number ;
    subProductOfferPrice=subProductOfferPrice.split('AED ')[1];
    subProductPrice=subProductPrice.split('Est. Retail ')[1];
    console.log('subProductPrice, subProductOfferPrice', subProductPrice, subProductOfferPrice);
    if(subProductOfferPrice===null) {
      this.totalAmtCalc=subProductPrice;
      console.log('if --tatl price ',this.totalAmtCalc);
      
    }
    else {
      tAmount = parseFloat(subProductPrice) - parseFloat(subProductOfferPrice);
      this.totalPrice=this.totalPrice - parseFloat(subProductOfferPrice);
      this.totalAmtCalc = tAmount.toFixed(2);
      console.log('else --tatl price ',this.totalAmtCalc);
      
    }
  

    // this.show = !this.show;
    // let tAmount: any = parseFloat(this.totalAmt[0]);
    // let sAmount: any = parseFloat(this.subtotalList[0]);
    // let dAmount: any = parseFloat(this.discountList[0]);
    // tAmount = sAmount - dAmount;
    // this.totalAmtCalc = tAmount;
    // this.showDiscount= false;
  }
  getImage(path: string,basePath) {
    let pathName= path.split('/(\\|\/)/');
    var path1 = pathName[pathName.length-1];
    console.log('path11111',path1);
    return this.couponService.getImage(path1,basePath);
  }
  applyBtnClick(value: string) {
    var coupon = localStorage.getItem('code');
    var subProdId = localStorage.getItem('subprodId')
    console.log(value);
    const data = {
     // subproductId: Number(subProdId),
     subProductid:parseInt(localStorage.getItem('subprodId')),
     couponName: coupon,

    };
    console.log('dataaaaaaa...',data);
    this.cartService.validateCouponCode(data).subscribe(
      res => {
        console.log('status---------->',res.status);
        // if (res.status==200) {
          console.log("Coupon Code matched");
          this.showDiscount = true;
          this.couponResp = res;
          console.log("couponResp", this.couponResp);
          var subProductPrice=localStorage.getItem('subprodPrice');
          console.log("subProductPrice--", subProductPrice);
          console.log("couponDiscountAmount--", this.couponResp['couponDiscountAmount']);
          this.showDiscountAmt(subProductPrice, this.couponResp['couponDiscountAmount']);
          // this.showDiscountAmt(this.couponResp['subProductPrice'], this.couponResp['subProductOfferPrice']);
        //    if (res.status==200) {
        //     alert("Coupon Applied!!");
        //  } else {
        //    alert("Invalid Coupon");
        //   this.showDiscount = false;
        // }
      }, err => {
        console.log(err);
        alert("Invalid Coupon");
        this.showDiscount = false;
      }
    );
    // if (value === coupon) {
    //   console.log("Coupon Code matched");
    //   this.showDiscount = true;
    //   // this.showDiscountAmt();
    // }
    // else {
    //   // console.log("Quit");
    //   alert("Invalid Coupon");
    //   this.showDiscount = false;
    // }
    // return this.showDiscount;
    // if (value !== null) {
    //   this.applyBtnClick = false;
    // } else {
    //   this.applyBtnClick = true;
    // }
    // console.log("WIF22230909", value);
  }

  getAllCart() {
    this.couponService.getAllCart().subscribe(data => {
      console.log(data);
      this.cartResp = data.cartResponses;
      this.cartResp.forEach(amount => {
        if(amount.subProductPrice.split('Est. Retail ')[1])
        {
          this.totalPrice=this.totalPrice+parseFloat(amount.subProductPrice.split('Est. Retail ')[1]);
          
        }else{
          this.totalPrice=this.totalPrice+parseFloat(amount.subProductPrice);
        }
        
      });
      
      // this.totalAmtCalc = this.cartResp[0].subProductPrice;
      // console.log(this.totalAmtCalc);
    }, error => {
      console.log(error);
    });
  }
  removeItem(id:any) {
    this.cartService.deleteCartItem(id).subscribe((successResponse : Response) => {
      console.log(successResponse);
      if (successResponse !== null) {  
        this.router.navigate(['/cart']);
       }
      }
  );
      } 
    
    }
