export class Filter {
    id: number;
    name: string;
    url: string;
    desc: string;
}
export class Coupon {
    id: number;
    name: string;
    url: string;
    desc: string;
    hasChild: boolean;
}
export class Cart {
    id: number;
    name: string;
    image: string;
    price: number;
}
export class Category {
    id: number;
    cName: string;
}
export class Brand {
    id: number;
    bName: string;
}
export class ViewList {
    id: number;
    vName: string;
}
export class MenuList {
    id: number;
    mName: string;
    url: string;
}
export class Country {
    CountryId: number;
    Country: string;
    CountryCode: number;
    CountryIcon: string;
}
export class Ad{
    campaignName: string;
    campaignType:any;
    campaignId: number;
    frontPageAUrl: any;
    mediaType: string;
}
export class subProduct {
buttonName: string
couponAmount: number
couponOfferPercent: number
discountId: number
exchangeCode: string
id: number
imgSrc: string
modelNumber: string
orderTime: string
priceUnit: string
productId: number
size: string
subProductDesc: string
subProductManufacturer: string
subProductName: string
subProductOfferPercent: string
subProductOfferPrice: string
subProductOverview1: string
subProductPrice: string
subProductSpecDesc:string
subProductSpecification1: string
subProductWeight: number
subProductWeightMeasure: string
wishStatus: string
}
