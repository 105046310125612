import { EventEmitterService } from 'src/app/services/EventEmitter/event-emitter.service';
import { Component, OnInit, HostListener, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CouponServiceService } from 'src/app/services/coupon/coupon-service.service';

@Component({
  selector: 'app-new',
  templateUrl: './new.component.html',
  styleUrls: ['./new.component.scss']
})
export class NewComponent implements OnInit {

  public coupons: any;
  coupons1: any;

  constructor(
    public router: Router,
    public activateRouter: ActivatedRoute,
    private couponService: CouponServiceService,
    private service: EventEmitterService
  ) { }

  ngOnInit(): void {
    this.getAllProduct();
  }
  // getAllProduct() {
  //   this.couponService.getAllProduct().subscribe(data => {
  //     console.log(data,"what");
  //     this.coupons = data.productresponse;
  //     console.log(this.coupons,"coupon");
  //     const filterData1 = this.coupons.filter(product => product.tags.includes("new"));

  //     this.coupons1 = filterData1;
  //     console.log('AllProduct List:', this.coupons1);
  //   }, error => {
  //     console.log(error);
  //   });
  // }


  getAllProduct() {
    this.couponService.getAllProduct().subscribe(
      (data) => {
        console.log('Received data:', data);
        if (data && data.productresponse && Array.isArray(data.productresponse)) {
          this.coupons = data.productresponse;
          console.log('All products:', this.coupons);
  
          // Filter products based on the presence of the tag "new" (case insensitive)
          this.coupons1 = this.coupons.filter((product) => {
            return product.tags && product.tags.includes("new");
          });
          console.log('Filtered products:', this.coupons1);
        } else {
          console.error('Invalid data format received:', data);
        }
      },
      (error) => {
        console.error('Error fetching product data:', error);
      }
    );
  }
  
  
}
