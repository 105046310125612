<div class="dashboard-header">
    <app-header></app-header>
  </div>
  
  
  <div [ngClass]="headerFix?'earn-header':''">
    <app-earn-header [showLogo]="headerFix"></app-earn-header>
  </div>
  <!-- <div style="height: 50px;
    width: 100%;
    background: #000;
    margin-bottom: 10px;" class="headers card">
  
  
  </div> -->
  <div class="menu-box">
    <div class="container-fluid">
      <div class="right-box-menu">
        <select (change)="storeChange($event.target.value)">
          <option *ngFor="let data of stores" [value]="data.id">{{data.storeName}}</option>
      </select>
      </div>
    </div>
  </div>
  <ng-container>
  
    <app-tiles-template [campaignData]="coupons"></app-tiles-template>
  
  </ng-container>
  <app-footer></app-footer>