import { Component, OnInit, HostListener } from '@angular/core';
import { Filter } from 'src/app/data/data';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {

  public filters: Filter[];
  constructor() {
    this.filters = [
      {
        id: 1,
        name: 'All',
        url: null,
        desc: 'All'
      },
      {
        id: 2,
        name: '#',
        url: null,
        desc: 'Entertainment'
      },
      {
        id: 3,
        name: 'A - E',
        url: null,
        desc: 'Food'
      },
      {
        id: 4,
        name: 'F - J',
        url: null,
        desc: 'Travel'
      },
      {
        id: 5,
        name: 'K - O',
        url: null,
        desc: 'Shopping'
      },
      {
        id: 6,
        name: 'P - T',
        url: null,
        desc: 'All'
      },
      {
        id: 7,
        name: 'U - Z',
        url: null,
        desc: 'Entertainment'
      },
      // {
      //   id: 3,
      //   name: 'Food',
      //   url: null,
      //   desc: 'Food'
      // },
      // {
      //   id: 4,
      //   name: 'Travel',
      //   url: null,
      //   desc: 'Travel'
      // },
      // {
      //   id: 5,
      //   name: 'Shopping',
      //   url: null,
      //   desc: 'Shopping'
      // },
    ];
  }

  ngOnInit() {
    document.getElementById('filter').addEventListener('mousewheel', this.onScrollEvent, true);
  }
  // @HostListener('window:mousewheel', ['$event']) // for window scroll events
  onScrollEvent(event) {
    // console.log(event);
    const delta = Math.max(-1, Math.min(1, (event.wheelDelta || -event.detail)));
    document.getElementById('filter').scrollLeft -= (delta * 40); // Multiplied by 40
    event.preventDefault();
    // console.log(delta);
  }
  onScroll(event) {
    console.log(event);
    // console.log(delta);
  }

}
