<div class="headers">
  <!-- <div id="myCarousel" class="carousel slide container cont1" data-ride="carousel" data-interval="250000"> -->
    <div id="myCarousel" class="container cont1">
    <div class="carousel-inner"> 
      <div class="carousel-item active">
        <div class="card card1">
          <img src='assets/img/galaxyZ.png' class="cardImg" alt="">
          <button class="btn btn-default shop-btn ">Earn Extra! Upto <span class="boldTxt">&nbsp;AED 300</span></button>
        </div>
      </div>
      <div class="carousel-item">
        <div class="card card1">
          <img src='assets/img/zbra.png' class="cardImg" alt="">
          <button class="btn btn-default shop-btn ">Shop Now <span class="boldTxt">&nbsp;</span></button>
        </div>
      </div>
      <div class="carousel-item">
        <div class="card card1">
          <img src='assets/img/jumeirah.jpg' class="cardImg" alt="">
          <button class="btn btn-default shop-btn ">Earn Extra! Upto <span class="boldTxt">&nbsp;AED 300</span></button>
        </div>
      </div>
      <div class="carousel-item">
        <div class="card card1">
          <img src='assets/img/jimmy.jpg' class="cardImg" alt="">
          <button class="btn btn-default shop-btn ">Earn Extra! Upto <span class="boldTxt">&nbsp;AED 300</span></button>
        </div>
      </div>
      <div class="carousel-item">
        <div class="card card1">
          <img src='assets/img/jobb.jpg' class="cardImg" alt="">
          <button class="btn btn-default shop-btn ">Shop Now <span class="boldTxt">&nbsp;</span></button>
        </div>
      </div>
      <div class="carousel-item">
        <div class="card card1">
          <img src='assets/img/black.jpg' class="cardImg" alt="">
          <button class="btn btn-default shop-btn ">Earn Extra! Upto <span class="boldTxt">&nbsp;AED 300</span></button>
        </div>
      </div>
      <div class="carousel-item ">
        <div class="card card1">
          <img src='assets/img/fujiya.jpg' class="cardImg" alt="">
          <button class="btn btn-default shop-btn ">Shop Now <span class="boldTxt">&nbsp;</span></button>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="overflow row col-12 p-0">
      <div class="card cardSlide" data-ride="carousel" data-interval="200">
        <div class="scrolling-wrapper" style="width: 100%;">
          <div class="top">
            <div class="card card1"  *ngFor="let card of coupons" [id]="'card'+card.id">
                <img [src]="card.imgURL" class="cardImg" alt="">
                  <button class="btn btn-default shop-btn "
                    [ngClass]="card.btnText=='Claim'?'claim-btn':''">{{card.btnText}} <span class="boldTxt">&nbsp;{{card.amt}}</span>
                  </button>
              </div>
          </div>

        </div>
        
      </div>

    </div> -->
</div>
