
import { Location } from '@angular/common';
import { Component, OnInit, Output, EventEmitter,HostListener } from '@angular/core';
import { Router, ActivatedRoute,NavigationEnd } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { filter } from 'rxjs/operators';
import { CouponServiceService } from 'src/app/services/coupon/coupon-service.service';


@Component({
  selector: 'app-header',
  templateUrl: './headernew.component.html',
  styleUrls: ['./headernew.component.scss']
})
export class HeadernewComponent implements OnInit {

  imageUrl: string = "../../../assets/Star.png";
  imageUrlh: string = "../../../assets/home1.png";

  public menuCrossImg = 'assets/img/menuicon.svg';
  @Output() LanguageChanged = new EventEmitter<any>();
  public menuToggle = false;
  public isaddButton:boolean = true;
  isDisable = false;

  public language:any;
  isLoggedIn:any;
  
  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private couponService:CouponServiceService,

  ) {
   let temp = localStorage.getItem('mobileNo');
    if(temp) {
      this.isLoggedIn = true;
    } else {
      this.isLoggedIn = false;
    }

  }

  ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      console.log(event);
      
      this.checkActiveRoute();
    });
    if (this.router.url === '/searchMenu') {
      this.menuToggle = true;
    } else {
      this.menuToggle = false;
    }

  }
  

  checkActiveRoute() {

    const currentRoute = this.router.routerState.snapshot.url;
    console.log(currentRoute,":currentroute");
    if (currentRoute === '/wallet' || currentRoute === '/walletnew' || currentRoute === 'expiring' || currentRoute === 'deall') {
      this.imageUrl = "../../../assets/starrnew.png";
    } else {
      this.imageUrl = "../../../assets/Star.png";
    }
    // if (currentRoute === 'Experiences' ) {
    //   this.imageUrlh = "../../../assets/homeb1.png";
    // }
    // else {
    //   this.imageUrlh = "../../../assets/home1.png";
    // }

  }



  reloadCurrentRoute() {
    let currentUrl = this.router.url;
  
    this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
      console.log("currentUrl",currentUrl);
      this.router.navigate([currentUrl]);
    });
  }
  // categoryPg() {
  //   this.router.navigate(['/category']);
  // }
  // wallet() {
  //   let temp=localStorage.getItem("mobileNo");
  //   // console.log("temppppp",temp);
  //   if(temp) {
  //     this.router.navigate(['../walletnew']);
  //   } else {
  //     this.router.navigate(['/walletnew']);
  //   }
 
  // }
  wallet() {
    let temp=localStorage.getItem("mobileNo");
    console.log("temppppp",temp);
    if(temp) {
      this.router.navigate(['/walletnew']);
    } else {
      this.router.navigate(['/login']);
    }
  }
  walletAgain(){
    
  }

  async toggleMenu() {
    this.menuToggle = !this.menuToggle;
    if (this.menuToggle === true) {
      this.router.navigate(['/']);
    } else {
      this.router.navigate(['']);

    }
  }
  openhomee(id) {
    localStorage.setItem('userCategory',id);
    console.log("id of  cat",id);
    let endTime = new Date();

    console.log(endTime.getTime())
  
  
    this.couponService.setCategoryId(id);
    this.router.navigate(['/allproducts']);
    //this.router.navigate(['/home',{d:id}],{ relativeTo: this.activeRoute });
  }
 
  resetLocalRedirectionData() {
    window.localStorage.setItem("redirected-From", "");
}
walletBtnclicked () {

  // card.isaddButton=!card.isaddButton;
 
  // window.alert(`Product added to star`);
  this.isaddButton=!this.isaddButton;

  this.router.navigate(["../searchb"]);
  
 

}
walletBtnclickedAgain () {


    
    this.isaddButton=true;
    
  this.router.navigate(["../searchbar"]);
  

}
// @HostListener('window:scroll', [])
// onScroll(): void {

//   const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;


//   this.router.navigate(["../searchbar"]);
// }

}
