import { Component, OnInit, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { basename } from 'path';
import { Logging } from 'src/app/data/logging.model';
import { UserActivity } from 'src/app/data/user-activity.enum';
import { CouponServiceService } from 'src/app/services/coupon/coupon-service.service';
import { LoggingService } from 'src/app/services/logging.service';

@Component({
  selector: 'app-scroll',
  templateUrl: './scroll.component.html',
  styleUrls: ['./scroll.component.scss']
})
export class ScrollComponent implements OnInit {

  showView = true;
  public text = 'View All';
  public categoryArrays = [];
  public scrollCard = [];
  private startTime= new Date();
  constructor(
    public router: Router,
    public activeRoute: ActivatedRoute,
    private couponService: CouponServiceService,
    private loggingService:LoggingService
  ) { }


  //   {
  //     id: 1,
  //     name: 'Women\'s Fashion',
  //     imgURL: 'assets/img/WF1.jpg',
  //   },
  //   {
  //     id: 2,
  //     name: 'Men\'s Fashion',
  //     imgURL: 'assets/img/menF2.jpg',
  //   },
  //   {
  //     id: 3,
  //     name: 'Jewelry',
  //     imgURL: 'assets/img/FP.jpg',
  //   },
  //   {
  //     id: 4,
  //     name: ' Beauty',
  //     imgURL: 'assets/img/bp.jpg',
  //   },
  //   {
  //     id: 5,
  //     name: '  Mobiles and Digital',
  //     imgURL: 'assets/img/g.jpg',
  //   },
  //   {
  //     id: 6,
  //     name: 'Eat Out',
  //     imgURL: 'assets/img/et6.jpg',
  //   },
  //   {
  //     id: 7,
  //     name: 'Fun',
  //     imgURL: 'assets/img/lg4.jpg',
  //   },
  //   {
  //     id: 8,
  //     name: 'Grocery',
  //     imgURL: 'assets/img/dv1.jpg',
  //   }
  // ];

  ngOnInit() {
    this.getAllCategory();
    //this.getAllSubCategory();
    console.log("card list is ", this.scrollCard);
  }

  getImage(path: string,basePath) {
    let pathName= path.split(/(\\|\/)/);
    var path1 = pathName[pathName.length-1];
    // console.log('path11111',path1);
    return this.couponService.getImage(path1,basePath);
  }

  openViewAll() {
    if (this.text === 'View All') {
      this.text = 'View Less';
    } else {
      this.text = 'View All';
    }
    console.log('texttt', this.text);
    console.log('showView', this.showView);
    console.log("card list is ", this.scrollCard);
    this.showView = !this.showView;
  }
  openHome(id) {
    let endTime = new Date();
    let userActivity: Logging[] = [];
    console.log(this.startTime);
    console.log(endTime.getTime())
    // let campaignId = card.campainMgmtId;
    userActivity.push({
      advId: "",
      longitute:localStorage.getItem("Longitude"),
      latitude:localStorage.getItem("Latitude"),
      campaignId: '',
      categoryId:JSON.stringify("id"),
      productId: '',
      subProductId: '',
      userId: localStorage.getItem("deviceId"),
      userAction: UserActivity[UserActivity.USER_CLICKED_CATEGORY],
      startTime: this.startTime.getTime(),
      endTime:  endTime.getTime(),
      duration: endTime.getTime() - this.startTime.getTime()
    });
    console.log("UserActivity",userActivity);



    this.loggingService.saveUserActivity(userActivity).subscribe();
    this.couponService.setCategoryId(id);
    this.router.navigate(['/home']);
    //this.router.navigate(['/home',{d:id}],{ relativeTo: this.activeRoute });
  }

  // public getAllSubCategoryByCatId(data) {
  //   this.couponService.getAllSubCategoryByCatId(data).subscribe(result => {
  //       console.log('=========== getAllSubCategoryByCatId Details: ' + data + '===========');
  //       console.log('getAllSubCategoryByCatId issss .......', result.categoryResponses);
  //   }, error => {
  //     console.log(error);
  //   });
  // }
  getAllCategory() {
    let temp = localStorage.getItem("catId");
    if(temp) {
      this.couponService.getAllCategory().subscribe(data => {
        // console.log(data);
        // this.couponsList = data.responseList;
        this.scrollCard = data['categoryResponses'];
        // console.log(this.scrollCard);
        this.splitCategoryArray();
        // console.log(this.scrollCard);
      }, error => {
        console.log(error);
        alert('Some Error Occured');
      });

    } else {
      this.couponService.getAllCategory().subscribe(data => {
        this.scrollCard = data;
        // this.splitCategoryArray();
        console.log(this.scrollCard,"scrollllll");
        
      }, error => {
        console.log(error);
        alert('Some Error Occured');
      });
    }
  
  }
  splitCategoryArray() {
    const category = this.scrollCard.slice(0);
    while (category.length) {
      this.categoryArrays.push(category.splice(0, 4))
    }
    // console.log(this.scrollCard);
  }
  public renderImage( categoryId: number): string {
    return this.couponService.getImagee( categoryId);
}
  getAllSubCategory() {
    this.couponService.getAllSubCategory().subscribe(data => {
      // console.log(data);
      // this.couponsList = data.responseList;
    }, error => {
      console.log(error);
    });
  }

}
