import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConstants } from 'src/app/app.constants';

@Injectable({
  providedIn: 'root'
})
export class Scanner1Service {

  constructor(
    private http: HttpClient,
  ) { }


  private showData: boolean;
  getShowData() {
    return this.showData;
  }
  setShowData(showData1: boolean) {
    return this.showData = showData1;
  }
  public createCoupon(data) {
    // return this.http.post('http://192.168.0.82:8080/wifi10x/wifi10x/createVoucher',data);
    return this.http.post('https://place10x.com/wifi10x/wifi10x/createVoucher',data);
    // return this.http.post(AppConstants.BASE_API_URL_WIFI + 'wifi10x/createVoucher', data);
  }

  public validateCoupon(data): Observable<any> {
    // return this.http.get('http://192.168.0.82:8080/wifi10x/wifi10x/getVoucherBySystemCode/'+data);
   // return this.http.get(AppConstants.BASE_API_URL_WIFI + 'wifi10x/getVoucherBySystemCode/' + data);
    return this.http.post('https://place10x.com/wifi10xapi/wifi-portal/validateCouponBySubProducts' , data);
    // return this.http.post('http://192.168.0.82:8080/wifi10xapi/wifi-portal/validateCouponBySubProducts',data);
  }
  


}
