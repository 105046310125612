import { Component, EventEmitter, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CouponServiceService } from 'src/app/services/coupon/coupon-service.service';
@Component({
  selector: 'app-store',
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.scss']
})
export class StoreComponent implements OnInit {

  param1: string;
  public scrollCard = [];
  type:string;
  public coupons: any;
public productresponse =[]

  constructor(private route: ActivatedRoute
    ,public router: Router,

    private couponService: CouponServiceService,) {}

  ngOnInit() {

    this.param1 = this.route.snapshot.params['param1'];
    this.type = this.route.snapshot.params['param2'];
    // this.type="store";
    this.getCampaignByStoreId(this.param1);
    this.getCampaignByStoreId1(this.type,this.param1);
    console.log("store");
    
    
  }
  getCampaignByStoreId(id) {
    this.couponService.getCampaignByStoreId(id).subscribe(result => {
      this.coupons =result['productresponse'];
      console.log(result,"res");
      
      console.log(this.coupons,"store");
      
    })
  }
  getCampaignByStoreId1(type,id) {
    this.couponService.getCampaignByStoreId1(type,id).subscribe(result => {
      this.scrollCard =result['cmpgnResponse'];
      console.log(result,"res camp");
      
      console.log(this.scrollCard,"cam by store");
      
    })
  }
  public renderImage( campaignId: number ): string {
    return this.couponService.getImageC( campaignId);
}
  openHomee(id) {
    localStorage.setItem('userCategory',"0");
    localStorage.setItem('userCampaign',id);
    console.log("id of  camp",id);
    let endTime = new Date();
    // console.log(this.startTime);
    console.log(endTime.getTime())
  
  
    this.couponService.setCategoryId(0);
    this.couponService.setCampaignId(id);
    this.router.navigate(['/home']);
    //this.router.navigate(['/home',{d:id}],{ relativeTo: this.activeRoute });
  }
  

}
