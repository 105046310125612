


<div class="headers-desktop">
    <div class="container-fluid">
      <div class="rw">
        <div class="col-lg-12">
          <div class="log">
            <img style="width: 60px !important;" src="assets/img/logo_plus.jpeg" alt="" (click)="openhomee(0)">
          </div>
          <!-- <form>
              <input type="text" placeholder="Search...">
              <button class="btn" type="submit"><i class="fa fa-search" aria-hidden="true"></i></button>
            </form> -->
  
          <div class="right-box">
            <!-- <select #lang >
                <option readOnly>Select Your Language</option>
                <option value="en">English</option>
                <option value="grk">Greek</option>
              </select> -->
            <!-- <ul>
                <li *ngIf="!isLoggedIn"><a [routerLink]="['/login']"><i class="fa fa-user-o" aria-hidden="true"></i>Hello, Sign in</a></li>
                <li ><a  (click)="wallet()"><span><i class="fa fa-shopping-cart" aria-hidden="true"></i></span>{{ "translations.cartText" | translate }}</a></li>
              </ul> -->
            <ul>
             
              <!-- 
              <li><a> <img class="menuIconsearch" src="../../../assets/Search1.png" alt="" routerLink="../searchb">
                </a></li> -->
             <button class="btnsearcgh ms-4" (click)="walletBtnclicked()" [disabled]="isDisable"
                  *ngIf="isaddButton">
                  <div class=" textCenter">
                    <a> <img class="menuIconsearch" src="assets/Search1.png" alt="" routerLink="../searchbarN">
                    </a>
  
                  </div>
                </button>
          
               <button class="btnsearcgh ms-4" (click)="walletBtnclickedAgain()" *ngIf="!isaddButton">
                  <div class=" textCenter">
  
                    <a> <img class="menuIconsearch" src="assets/Search1.png" alt="" routerLink="../searchbar">
                    </a>
                  </div>
                </button>
                <li> <a><img class="menuIconsearchh " src="assets/home1.png" alt="" routerLink="../category"></a></li>
  
              <li> <a><img class="menuIconsearch " src="assets/Star.png" alt="" (click)="wallet()"></a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="headers">
    <div class="header col-12 fixed-top p-0">
      <div class="row m-0">
        <div class="col-5">
          <div class="row p-0">
            <div class="col-4 p-0 textCenter">
              <a> <img class="menuIconStyl" src="assets/home1.png" alt="" [routerLink]="['']">
                <!-- <a (click)="toggleMenu()"> <img class="menuIconStyl" *ngIf="!menuToggle" src="../../../assets/home1.png"
                    alt="" [routerLink]="['']"> -->
                <!-- <span *ngIf="menuToggle" class="clsbtn btn-light p-0">&times;</span> -->
                <!-- <img *ngIf="menuToggle" src="../../../assets/homeb1.png" class="menuIconStyl" [routerLink]="['']"/> -->
              </a>
            </div>
  
            <div class="col-4 p-0 textCenter">
              <a>
                <h6 class="menuIcon2" routerLinkActive="router-link-active">ATHENS</h6>
              </a>
            </div>
            <!-- <div class="col-4 p-0 textCenter"> -->
            <!-- <a >  <i class="fa fa-filter iconStyl" aria-hidden="true"></i></a> -->
            <!-- </div> -->
          </div>
        </div>
        <div class="col-2 p-2">
          <!-- <img class="wifi10xLogo" src="assets/img/logo10x.png" [routerLink]="['/home']" alt=""> -->
          <!-- <img class="wifiLogo" src="assets/img/logo_plus.jpeg" [routerLink]="['/category']" alt=""> -->
          <img class="menuIconlogo " src="assets/img/Plus10x_cmyk.png" alt="" (click)="openhomee(0)">
        </div>
        <div class="col-5">
          <div class="row p-0">
            <div class="col-4 p-0 textCenter">
            </div>
  
            <button class="btnsearcgh col-3 p-0" (click)="walletBtnclicked()" [disabled]="isDisable" *ngIf="isaddButton">
              <div class=" textCenter">
                <a> <img class="menuIconsearch" src="assets/Search1.png" alt="" routerLink="../searchbarN">
                </a>
  
              </div>
            </button>
            <button class="btnsearcgh col-3 p-0" (click)="walletBtnclickedAgain()" *ngIf="!isaddButton">
              <div class=" textCenter">
  
                <a> <img class="menuIconsearch" src="assets/Search1.png" alt="" routerLink="../searchbar">
                </a>
              </div>
            </button>
  
            <div class="col-4 p-0 textCenter">
              <!-- <a><img class="menuIconstar1 " src="../../../assets/Star.png" [src]="imageUrl" alt="" (click)="wallet()"></a> -->
              <a><img class="menuIconstar1 " src="assets/Star.png" alt="" (click)="wallet()"></a>
  
              <!-- <img class="menuIconstar " src="../../../assets/Notification.png" alt="o"> -->
  
            </div>
            <!-- <button class="btnsearcgh col-4 p-0" (click)="wallet()" [disabled]="isDisable" *ngIf="isaddButton">
              <div class=" textCenter">
                <a><img class="menuIconstar1 " src="../../../assets/Star.png" alt="" (click)="wallet()"></a>
              </div>
            </button>
            <button class="btnsearcgh col-3 p-0" *ngIf="!isaddButton">
              <div class=" textCenter">
  
                <a><img class="menuIconstar1 " src="../../../assets/starrnew.png" alt="" (click)="wallet()"></a>
          
              </div>
            </button>
             -->
  
          </div>
        </div>
  
        <!-- <ul class="nav navS">
              <li class="nav-item navTMenu">
                <a> <img class="menuIconStyl" src="assets/img/menuicon.svg" alt=""></a>
              </li>
              <li class="nav-item navT">
                <a> <img class="menuIcon2" src="assets/img/search.png" alt="" [routerLink]="['/search']"
                    routerLinkActive="router-link-active"></a>
              </li> -->
        <!-- <li class="nav-item">
                    <a >  <i class="fa fa-filter iconStyl" aria-hidden="true"></i></a>
                </li> -->
        <!-- <img class="wifi10xLogo" src="assets/img/logo10x.png" [routerLink]="['/home']" alt="">
    
              <li class="nav-item navTM">
                <a> <img class="menuIcon2" src="assets/img/user1.png" [routerLink]="['/login']" alt=""></a>
              </li>
              <li class="nav-item navTM">
                <a> <img class="menuIcon2" src="assets/img/cart.png" alt=""></a>
              </li>
              <li class="nav-item navTM">
                <a><img class="menuIcon2" src="assets/img/wallet.png" alt=""></a>
              </li>
    
            </ul> -->
  
      </div>
  
    </div>
  </div>