<!-- <div class="headers">
  <div class="header col-12">
    <p class="head">Rewards Store</p>
    <div class="menuDiv col-offset-3">
      <div class="row">
        <div class="col-1"><img class="gift" src="assets/img/gift_head.png" /></div>
        <div class="col-6  justify-content-center align-self-center claim">&nbsp; Claimed Rewards</div>
        <div class="col-1"> <span class="num">10</span></div>
        <div class="col-3"><i class="fa fa-bars" aria-hidden="true"></i></div>
      </div>
    </div>
  </div>
</div> -->
<div class="headers-desktop">
  <div class="container-fluid">
    <div class="rw">
      <div class="col-lg-12">
        <div class="log">
          <img style="width: 60px !important;" src="assets/img/logo_plus.jpeg" [routerLink]="['/category']" alt="">
        </div>
        <form>
          <input type="text" placeholder="Search...">
          <button class="btn" type="submit"><i class="fa fa-search" aria-hidden="true"></i></button>
        </form>
        <!-- <ul class="save-money">
          <li>
            <h4> {{ "translations.earnYouHaveEarnedText" | translate }}<span>EUR 1000</span></h4>
          </li>
          <li>
            <h4> {{ "translations.earnYouhaveClaimed" | translate }}<span>EUR 700</span></h4>
          </li>
        </ul> -->
        <div class="right-box">
          <select #lang (change)="changeLanguage(lang.value)">
            <option readOnly>Select Your Language</option>
            <option *ngFor="let item of languages" [value]="item.frontendCode">{{item.displayText}}</option>
            <!-- <option value="en">English</option>
            <option value="grk">Greek</option> -->
          </select>
          <ul>
            <li *ngIf="!isLoggedIn"><a [routerLink]="['/login']"><i class="fa fa-user-o" aria-hidden="true"></i>Hello, Sign in</a></li>
            <li ><a  (click)="wallet()"><span><i class="fa fa-shopping-cart" aria-hidden="true"></i></span>{{ "translations.cartText" | translate }}</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="headers">
  <div class="header col-12 fixed-top p-0">
    <div class="row m-0">
      <div class="col-5">
        <div class="row p-0">
          <div class="col-4 p-0 textCenter">
            <a (click)="toggleMenu()"> <img class="menuIconStyl" *ngIf="!menuToggle" src="assets/img/icon2.jpeg"
                alt="">
              <!-- <span *ngIf="menuToggle" class="clsbtn btn-light p-0">&times;</span> -->
              <i *ngIf="menuToggle" class="fa fa-times clsbtn" aria-hidden="true"></i>
            </a>
          </div>
          <div class="col-4 p-0 textCenter">
            <a> <img class="menuIcon2" src="assets/img/search_new.png" alt="" [routerLink]="['/search']"
                routerLinkActive="router-link-active"></a>
          </div>
          <div class="col-4 p-0 textCenter">
            <!-- <a >  <i class="fa fa-filter iconStyl" aria-hidden="true"></i></a> -->
          </div>
        </div>
      </div>
      <div class="col-2 p-2">
        <!-- <img class="wifi10xLogo" src="assets/img/logo10x.png" [routerLink]="['/home']" alt=""> -->
        <img class="wifiLogo" src="assets/img/logo_plus.jpeg" [routerLink]="['/category']" alt="">
      </div>
      <div class="col-5">
        <div class="row p-0">
          <div class="col-4 p-0 textCenter">
            <!-- <a> <img class="menuIcon2 menuIcon3 location" src="assets/img/internet1.svg" (click)="sendAuthorizationLink()"></a> -->
          </div>
          <div class="col-4 p-0 textCenter">
            <a> <img class="menuIcon2" src="assets/img/user1.png" [routerLink]="['/login']" alt=""></a>
            <!-- <a> <img class="menuIcon2" src="assets/img/cart.png" [routerLink]="['/category']" alt=""></a> -->
          </div>
          <div class="col-4 p-0 textCenter">
            <a><img class="menuIcon2 " src="assets/img/icon1.jpeg" alt="" (click)="wallet()"></a>
          </div>
        </div>
      </div>

      <!-- <ul class="nav navS">
        <li class="nav-item navTMenu">
          <a> <img class="menuIconStyl" src="assets/img/menuicon.svg" alt=""></a>
        </li>
        <li class="nav-item navT">
          <a> <img class="menuIcon2" src="assets/img/search.png" alt="" [routerLink]="['/search']"
              routerLinkActive="router-link-active"></a>
        </li> -->
      <!-- <li class="nav-item">
              <a >  <i class="fa fa-filter iconStyl" aria-hidden="true"></i></a>
          </li> -->
      <!-- <img class="wifi10xLogo" src="assets/img/logo10x.png" [routerLink]="['/home']" alt="">

        <li class="nav-item navTM">
          <a> <img class="menuIcon2" src="assets/img/user1.png" [routerLink]="['/login']" alt=""></a>
        </li>
        <li class="nav-item navTM">
          <a> <img class="menuIcon2" src="assets/img/cart.png" alt=""></a>
        </li>
        <li class="nav-item navTM">
          <a><img class="menuIcon2" src="assets/img/wallet.png" alt=""></a>
        </li>

      </ul> -->

    </div>
  </div>
</div>