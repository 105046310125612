<div class="coupon-wrapper">
  <div class="dashboard accordion" id="accordion">
    <div class="card card1" *ngFor="let card of coupons" [id]="'card'+card.id">
      <img [src]="getImage(card.imgSrc,'product')" onerror="this.src='assets/img/default-image.png';" class="cardImg"
        height="100%" [ngStyle]="{'height': height }" alt="">
      <!-- <button class="shop-btn1 btn-small"><i class="fa fa-share-alt imgCart" aria-hidden="true"></i></button>
      <button class="shop-btn2 btn-small"><img class="imgCart" src="assets/img/cartBlack.png"></button> -->
      <span class="description">
        {{card.desc}}
        <br>
        Expiry Date: {{card.expDate}}
      </span>
      <div class="card cardBtn" data-toggle="collapse" [id]="'cardexp'+card.id" [attr.data-target]="'#demo'+ card.id"
        (click)="toggle('icon'+card.id)">
        <!-- <button class="btn btn-default shop-btn pull-left">{{card.btnText}} <span class="boldTxt">&nbsp;{{card.amt}}</span></button> -->
        <button class="btn btn-default shop-btn pull-left"
          [ngClass]="card.btnText=='Claim'?'claim-btn':''">{{ "translations.claimBtnText" | translate }} <span
            class="boldTxt">
            <!-- [ngClass]="card.btnText=='Claim'?'claim-btn':''">{{card.buttonName}} <span class="boldTxt"></span> -->
            &nbsp;{{card.amt}}</span></button>
        <!-- (click)="shopNow(card)">{{card.btnText}}</button> -->
        <!-- <button type="button" data-toggle="collapse" [id]="'btn'+card.id" [attr.data-target]="'#demo'+ card.id" -->
        <button type="button" data-toggle="collapse" [id]="'btntoggle'+card.id" [attr.data-target]="'#demo'+ card.id"
          class="shop-btn3 btn-small pull-right" (click)="getSubProductByProdId(card.id)">
          <!-- <button type="button" data-toggle="collapse" [id]="'btntoggle'+card.id" [attr.data-target]="'#demo'+ card.id"
          class="shop-btn3 btn-small pull-right"> -->
          <i class="fa fa-plus imgCart" aria-hidden="true" [id]="'icon'+card.id"></i>
        </button>
      </div>

      <!--[ngClass]="activeCard?'cardExpand':'cardBtn'"  [ngClass]="buttonFix?'addBtnStick':''" [ngClass]="buttonFix?'addBtnStick':''" -->

      <!-- <button type="button" [id]="'icon'+card.id" data-toggle="collapse" [attr.data-target]="'#demo'+ card.id"
        class="shop-btn3"><i class="fa fa-plus"
          [ngClass]="{'fa-plus': ('icon'+card.id).classList.contains('d-none'), 'fa-minus': !('icon'+card.id).classList.contains('d-none')}"
          aria-hidden="true"></i></button> -->
      <div class="collapse sub-product" [id]="'demo'+card.id" [attr.aria-labelledby]="'#card'+card.id"
        data-parent="#accordion">
        <!-- <div class="container bg-light"> -->
        <div class="card cardStyl1">
          <h3 class="dCollec">
            <!--  -->
            {{ "translations.prodDetText" | translate }}
          </h3>
          <h3 class="dSub">
            <!-- Discover the Newness of the Season -->
            {{ "translations.prodDet2Text" | translate }}
          </h3>
          <div class="row row2 mb-1">
            <div class="col-4 p-0">
              <button type="button"
                class=" btn btn-default btnStylBtm p-0"><u>{{ "translations.learnmoretext" | translate }}</u></button>
            </div>

            <div class="col-4 p-0 popup">
              <button type="button" class=" btn btn-default btnStylBtm" [id]="'shareToggle'+card.id"
                (click)="openShare('myPopup'+card.id)">
                <img class="logoImg1"
                  src="assets/img/shareIcon.svg">&nbsp;{{ "translations.shareText" | translate }}</button>
              <!-- <img class="logoImg1" src="assets/img/shareIcon.svg">&nbsp;Share</button> -->
              <p class="popuptext" [id]="'myPopup'+card.id">
                <a href="javascript:void(0)" class="aLink">
                  <i class="fa fa-facebook-f"></i>
                </a>
                <a href="javascript:void(0)" class="aLink">
                  <i class="fa fa-twitter"></i>
                </a>
                <a href="javascript:void(0)" class="aLink">
                  <i class="fa fa-pinterest-p"></i>
                </a>
                <a href="javascript:void(0)" class="aLink">
                  <i class="fa fa-envelope"></i>
                </a>
                <a class="vl"></a>
                <a href="javascript:void(0)" (click)="hideAllShare()" class="aLink">
                  <i class="fa fa-times ml-1" aria-hidden="true"></i>
                </a>
              </p>
            </div>




            <!-- <div class="col-4 p-0">
              <button type="button" class=" btn btn-default btnStylBtm pb-0" (click)="addToWishlistBtn(card.id,'0')">
                <i class="fa fa-heart" [id]="'AddWishToggle'+card.id+'0'"></i>&nbsp;Add to wishList</button>
            </div> -->
          </div>
        </div>
        <!-- <div class="accordion" id="subProdAccordian"> -->
        <div *ngIf="prodCard.length> 0">
          <div class="card cardStyl accordion" [id]="'card'+card.id" *ngFor="let pcard of prodCard">
            <!-- <div class="container"> -->
            <div class="row pb-0" style="padding: 1vh;">

              <div class="col-5 autoTB">
                <img class="prod1" [src]="getImage(pcard.imgSrc,'product')" onerror="this.src='assets/img/default-image.png';"
                  height="100%" alt="">

                <!-- <img class="prod1" [src]="'https://wifi10x.com/'+pcard.imgSrc" onerror="this.src='assets/img/default-image.png';" height="100%" alt="">-->
                <div class="row m-0 mt-2">
                  <!-- <div class="col-8 pr-0"> -->
                  <button class="btn btn-default shopBtnSub" [id]="'prodSubID'+card.id+pcard.id"
                    (click)="shopNow('prodSubID'+ card.id+ pcard.id,pcard)">Earn EUR</button>
                  <!-- <button class="btn btn-default earnBtnSub" (click)="shopNow(prodSubID'+card.id)">{{buttonName}}</button> -->
                  <!-- </div> -->
                </div>
              </div>
              <!-- <div class="col-7 pl-2 text-center"> -->
              <div class="col-7 pl-2">
                <div class="addCard p-0">
                  <button type="button" class="addBtn3"
                    (click)="displayDiv('prodicon'+card.id +pcard.id,'prod'+card.id+'sharehide' + pcard.id,card.id+'prod'+pcard.id)"
                    data-toggle="collapse" [id]="'btn'+pcard.id" [attr.data-target]="'#'+card.id+'prod'+pcard.id">
                    <i class="fa fa-plus plusIcon" aria-hidden="true" [id]="'prodicon'+card.id +pcard.id"></i>
                  </button>
                  <!-- <i class="plusIcon"  [className]=" plusMInus ?  'fa fa-minus':'fa fa-plus'"></i></button> -->
                </div>

                <p class="pStyl1">
                  {{pcard.subProductName}}
                </p>
                <p class="grayText">
                  {{pcard.subProductDesc}}
                </p>
                <p class="grayText1">
                  Size: {{pcard.size}}
                </p>
                <p class="blackText">
                  <b>Est. Retail&nbsp;{{pcard.subProductPrice}}&nbsp;EUR</b>
                  <!-- <b>Est. Retail&nbsp;{{pcard.subProductPrice}}&nbsp;{{pcard.priceUnit}}</b>  -->
                </p>
                <p class="strikeText ">
                  {{pcard.subProductOfferPrice}}&nbsp;EUR&nbsp;{{pcard.subProductOfferPercent}},
                  <!-- {{pcard.subProductOfferPrice}}&nbsp;{{pcard.priceUnit}}&nbsp;{{pcard.subProductOfferPercent}}, -->
                </p>
                <p class="offExt">
                  <b>{{pcard.couponAmount}}&nbsp;EUR&nbsp;EXTRA&nbsp;{{pcard.couponOfferPercent}}</b>
                  <!-- <b>{{pcard.couponAmount}}&nbsp;{{pcard.priceUnit}}&nbsp;EXTRA&nbsp;{{pcard.couponOfferPercent}}</b> -->
                </p>
                <!-- <div class="row"> -->
                <!-- <div class="col-8 pr-0">
                  <button class="btn btn-default shopBtnSub" [id]="'prodSubID'+card.id" (click)="shopNow('prodSubID'+card.id)">Earn AED</button> -->
                <!-- <button class="btn btn-default earnBtnSub" (click)="shopNow(prodSubID'+card.id)">{{buttonName}}</button> -->
                <!-- </div> -->
                <!-- <div class="col-3 p-0">
                  <button class="btn btn-default moreSub1" (click)="shopNow(card)">More</button>
                </div> -->
                <!-- </div> -->
              </div>

              <!-- <div class="row p-0 mt-1 rowS" *ngIf="!hideDiv"> -->

              <div class="row p-0 mt-1 rowS shareBtns" [id]="'prod'+card.id+'sharehide' + pcard.id">
                <!-- <div class="col-6 p-0 popup">
                <button type="button" class=" btn btn-default btnStylBtm pb-0" [id]="'shareToggle'+pcard.id"
                  (click)="openShare('myPopup'+card.id+'normal'+pcard.id)">
                  <img class="logoImg" src="assets/img/shareIcon.svg">&nbsp;Share</button>
                <p class="popuptext" [id]="'myPopup'+card.id+'normal'+pcard.id">
                  <a href="javascript:void(0)" class="aLink">
                    <i class="fa fa-facebook-f"></i>
                  </a>
                  <a href="javascript:void(0)" class="aLink">
                    <i class="fa fa-twitter"></i>
                  </a>
                  <a href="javascript:void(0)" class="aLink">
                    <i class="fa fa-pinterest-p"></i>
                  </a>
                  <a href="javascript:void(0)" class="aLink">
                    <i class="fa fa-envelope"></i>
                  </a>
                  <a class="vl"></a>
                  <a href="javascript:void(0)" (click)="hideAllShare()" class="aLink">
                    <i class="fa fa-times ml-1" aria-hidden="true"></i>
                  </a>
                </p>
              </div>-->
                <div class="col-4 p-0 ">
                  <button type="button" class=" btn btn-default btnStylBtm" (click)="openShare1()">
                    <img class="logoImg1"
                      src="assets/img/shareIcon.svg">&nbsp;{{ "translations.shareText" | translate }}</button>
                  <!-- <img class="logoImg1" src="assets/img/shareIcon.svg">&nbsp;Share</button> -->

                </div>
                <!-- <div class="col-6 p-0">
                <button type="button" class=" btn btn-default btnStylBtm pb-0" (click)="addToWishlistBtn(card.id,pcard.id)">
                  <i [id]="'AddWishToggle'+card.id+pcard.id" class="fa fa-heart" aria-hidden="true" [ngClass]="pcard.wishStatus.toString().toLowerCase()=='true'?'red':'black'"></i>&nbsp;Add to wishList</button>
              </div> -->
              </div>

              <div class="row p-0 mt-1 rowS collapse expanded" [id]="card.id+'prod'+pcard.id"
                [attr.aria-labelledby]="'#'+card.id+'prod'+pcard.id" data-parent='#demoCard1'>
                <div class="grayText1 p-2">
                  {{pcard.subProductSpecDesc}}
                </div>
                <div class="row row1">
                  <div class="col-4 p-0">
                    <button type="button"
                      class=" btn btn-default btnStylBtm p-0"><u>{{ "translations.learnmoretext" | translate }}&nbsp;&nbsp;</u></button>
                    <!-- <button type="button" class=" btn btn-default btnStylBtm p-0"><u>Learn More</u></button> -->
                  </div>

                  <div class="col-4 p-0 popup">
                    <button type="button" class=" btn btn-default btnStylBtm " [id]="'shareToggle'+pcard.id"
                      (click)="openShare('myPopup'+card.id+'expand'+pcard.id)">
                      <img class="logoImg1"
                        src="assets/img/shareIcon.svg">&nbsp;{{ "translations.shareText" | translate }}</button>
                    <p class="popuptext " [id]="'myPopup'+card.id+'expand'+pcard.id">
                      <a href="javascript:void(0)" class="aLink">
                        <i class="fa fa-facebook-f"></i>
                      </a>
                      <a href="javascript:void(0)" class="aLink">
                        <i class="fa fa-twitter"></i>
                      </a>
                      <a href="javascript:void(0)" class="aLink">
                        <i class="fa fa-pinterest-p"></i>
                      </a>
                      <a href="javascript:void(0)" class="aLink">
                        <i class="fa fa-envelope"></i>
                      </a>
                      <a class="vl"></a>
                      <a href="javascript:void(0)" (click)="hideAllShare()" class="aLink">
                        <i class="fa fa-times ml-1" aria-hidden="true"></i>
                      </a>
                    </p>
                  </div>



                  <!-- <div class="col-4 p-0">
                  <button type="button" class=" btn btn-default btnStylBtm pb-0" (click)="addToWishlistBtn2(card.id,pcard.id)">
                    <i [id]="'AddWishToggle2'+card.id+pcard.id" class="fa fa-heart" aria-hidden="true" [ngClass]="pcard.wishStatus.toString().toLowerCase()=='true'?'red':'black'"></i>&nbsp;Add to wishList</button>
                </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- </div> -->
    </div>
  </div>



</div>
<!-- </div> -->