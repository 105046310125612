import { Component, OnInit, Input } from '@angular/core';
import { Coupon } from 'src/app/data/data';

@Component({
  selector: 'app-show-coupon',
  templateUrl: './show-coupon.component.html',
  styleUrls: ['./show-coupon.component.scss']
})
export class ShowCouponComponent implements OnInit {

  public coupons: Coupon[];
  public btnIcon:boolean =false;
  public childCoupons:Coupon[];
  @Input()  backImage: string;
  constructor() {
    this.childCoupons = [
      {
      id: 1,
      name: 'All',
      url: null,
      desc: 'All',
      hasChild: false
    },
      {
        id: 2,
        name: 'All',
        url: null,
        desc: 'All',
        hasChild: false
      },
        {
          id: 3,
          name: 'All',
          url: null,
          desc: 'All',
          hasChild: false
        }
    ];
    this.coupons = [
      {
        id: 1,
        name: 'All',
        url: null,
        desc: 'All',
        hasChild: true,
      },
      {
        id: 2,
        name: 'Entertainment',
        url: null,
        desc: 'Entertainment',
        hasChild: false,
      },
      {
        id: 3,
        name: 'Food',
        url: null,
        desc: 'Food',
        hasChild: false,
      },
      {
        id: 4,
        name: 'Travel',
        url: null,
        desc: 'Travel',
        hasChild: false,
      },
      {
        id: 5,
        name: 'Shopping',
        url: null,
        desc: 'Shopping',
        hasChild: true,
      },
      {
        id: 6,
        name: 'All',
        url: null,
        desc: 'All',
        hasChild: false,
      }
    ];
  }

  ngOnInit() {
  }
}
