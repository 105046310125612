<div class="coupon-wrapper" *ngIf="campaignData != null && campaignData.length > 0">
    <div class="dashboard accordion" id="accordion">
        <div class="card card1">
            <!-- <img [src]="getImage(campaignData[0].imgSrc,'campaign')" onerror="this.src='assets/img/default-image.png';" -->
            <img [src]="getImage(campaignData[0].imgSrc,'campaign')" onerror="this.src='assets/img/default-image.png';"

                class="cardImg" height="100%" [ngStyle]="{ height: height }" alt="" />
            <div class="sub-product">
                <div class="card cardStyl1">
                    <h3 class="dCollec">{{ "translations.prodDetText" | translate }}</h3>
                    <h3 class="dSub">{{ "translations.prodDet2Text" | translate }}</h3>
                    <div class="row row2 mb-1">
                        <div class="col-6 p-0">
                            <button type="button" class="btn btn-default btnStylBtm p-0">
                                <u>{{ "translations.learnmoretext" | translate }}</u>
                            </button>
                        </div>
                        <div class="col-6 p-0">
                            <button type="button" class="btn btn-default btnStylBtm" (click)="shareApi()">
                                <img class="logoImg1"
                                    src="assets/img/shareIcon.svg" />&nbsp;{{ "translations.shareText" | translate }}
                            </button>
                        </div>
                    </div>
                </div>
                <div *ngIf="campaignData?.length > 0">
                    <div class="card cardStyl accordion"
                        *ngFor="let pcard of campaignData[0].subProducts; index as index">
                        <div class="row pb-0" style="padding: 1vh">
                            <div class="col-5 autoTB">
                                <img class="prod1" [src]="'https://place10x.com/' + pcard.imgSrc"
                                    onerror="this.src='assets/img/default-image.png';" height="100%" alt="" />

                                <div class="row m-0 mt-2">
                                    <button class="btn btn-default shopBtnSub"
                                        [class.shopBtnShop]="pcard.isShopBtnEnabled"
                                        (click)="shopNow(campaignData[0].productId, pcard, index,campaignData[0])">
                                        {{ pcard.isShopBtnEnabled ?("translations.shopNowText"  | translate ): ("translations.earnButtonText"| translate) }}
                                    </button>
                                </div>
                            </div>
                            <div class="col-7 pl-2">
                                <div class="addCard p-0">
                                    <button type="button" class="addBtn3" data-toggle="collapse">
                                        <i class="fa plusIcon" [ngClass]="
                                        pcard.isSubProdDescToggleOn ? 'fa-minus' : 'fa-plus'
                          " aria-hidden="true" (click)="
                          pcard.isSubProdDescToggleOn = !pcard.isSubProdDescToggleOn 
                          "></i>
                                    </button>
                                </div>
                                <p class="pStyl1">
                                    {{ pcard.subProductName }}
                                </p>
                                <p class="grayText">
                                    {{ pcard.subProductDesc }}
                                </p>
                                <p class="grayText1">Size: {{ pcard.size }}</p>
                                <p class="blackText">
                                    <b>Est. Retail&nbsp;{{ pcard.subProductPrice }}&nbsp;{{
                          pcard.priceUnit
                        }}</b>
                                </p>
                                <p class="strikeText">
                                    {{ pcard.subProductOfferPrice }}&nbsp;{{
                        pcard.priceUnit
                      }}&nbsp;{{ pcard.subProductOfferPercent }},
                                </p>
                                <p class="offExt">
                                    <b>{{ pcard.couponAmount }}&nbsp;{{
                          pcard.priceUnit
                        }}&nbsp;EXTRA&nbsp;{{ pcard.couponOfferPercent }}</b>
                                </p>
                            </div>
                            <div class="row p-0 mt-1 rowS" *ngIf="pcard.isSubProdDescToggleOn == true">
                                <div class="col-12 grayText1 p-2">
                                    {{ pcard.subProductSpecDesc }}
                                </div>
                            </div>

                            <div class="row p-0 mt-1 rowS shareBtns">
                                <div class="col-6 p-0">
                                    <button type="button" class="btn btn-default btnStylBtm p-0">
                                        <u>{{ "translations.learnmoretext" | translate }}</u>
                                    </button>
                                </div>
                                <div class="col-6 p-0">
                                    <button type="button" class="btn btn-default btnStylBtm" (click)="shareApi()">
                                        <img class="logoImg1"
                                            src="assets/img/shareIcon.svg" />&nbsp;{{ "translations.shareText" | translate }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>