export enum UserActivity {
    FRONTPAGE_A_DISPLAY,
    FRONTPAGE_B_TILES_DISPLAY,
    FRONTPAGE_B_EXPANDED_VIEW_DISPLAY,
    FRONTPAGE_B_BANNER_DISPLAY,
    USER_ADDED_TO_WISH_LIST,
    USER_SHARED,
    USER_EXPANDED_THE_TILE,
    USER_CLICKED_SHOP_NOW,
    USER_CLICKED_CATEGORY,
    USER_REDEEMED
}