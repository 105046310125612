
import { Component, OnInit, TemplateRef, ElementRef, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Category, Brand, ViewList } from 'src/app/data/data';
import { TranslateService } from '@ngx-translate/core';
import { CouponServiceService } from 'src/app/services/coupon/coupon-service.service';



@Component({
  selector: 'app-search-bar-new',
  templateUrl: './search-bar-new.component.html',
  styleUrls: ['./search-bar-new.component.scss']
})
export class SearchBarNewComponent implements OnInit {

  public catgBtn = 'Category';
  public brandBtn = 'Brand';
  public listBtn = 'List';
  // public catgBtn = 'Category';
  // public brandBtn = 'Brand';
  // public listBtn = 'Sort';
  public show = false;

  searchText: any;
  public category: Category[] = [];
  public brand: Brand[] = [];
  public viewList: ViewList[] = [];
  public selectedCatg = Category;
  public headerFix = false;
  public selectedBrand = Brand;
  public selectedView = ViewList;
  public value = 400;
  public difference = 280;
  public newBrand = [
    {
      id: 1,
      brandId: 1,
      ct: "Beauty",
      categoryId: 1,
      bName: 'Brand A',
    }, {
      id: 2,
      ct: "Beauty",
      brandId: 2,
      categoryId: 1,
      bName: 'Brand B',
    },{
      id: 3,
      ct: "Fashion",
      brandId: 3,
      categoryId: 2,
      bName: 'Brand C',
    }, {
      id: 4,
      ct: "Convenience",
      brandId: 4,
      categoryId: 3,
      bName: 'Brand D',
    }, {
      id: 5,
      ct: "Sports",
      brandId: 5,
      categoryId: 4,
      bName: 'Brand E',
    }, {
      id: 6,
      ct: "Electronics",
      brandId: 6,
      categoryId: 5,
      bName: 'Brand F',
    }, {
      id: 7,
      ct: "Home Interiors",
      brandId: 7,
      categoryId: 6,
      bName: 'Brand G',
    }, {
      id: 8,
      ct: "jewellery",
      brandId: 8,
      categoryId: 7,
      bName: 'Brand H',
    }

  ];


  public prodCard = [
    {
      id: 1,
      name: 'samsung S10',
      desc: ' A premium denim brand thats earned acclaim for its fine fits, fabrics and finishes.',
      expDate: '11-06-2020',
      imgURL: 'assets/img/samsung S10.png',
      btnText: 'Claim'
    }, {
      id: 2,
      name: 'adidas',
      desc: ' A premium denim brand thats earned acclaim for its fine fits, fabrics and finishes.',
      expDate: '20-08-2020',
      imgURL: 'assets/img/adidas.jpg',
      btnText: 'Shop Now'
    }, {
      id: 3,
      name: 'Gucci Bag',
      desc: ' A premium denim brand thats earned acclaim for its fine fits, fabrics and finishes. ',
      expDate: '19-08-2020',
      imgURL: 'assets/img/gucci.jpg',
      btnText: 'Shop Now'
    }, {
      id: 4,
      name: 'Gucci Deo',
      desc: 'A premium denim brand thats earned acclaim for its fine fits, fabrics and finishes.',
      expDate: '31-12-2020',
      imgURL: 'assets/img/couponimg1.jpg',
      btnText: 'Shop Now'
    }, {
      id: 5,
      name: 'huawei phones',
      desc: 'A premium denim brand thats earned acclaim for its fine fits, fabrics and finishes.',
      expDate: '31-05-2020',
      imgURL: 'assets/img/huawei.jpg',
      btnText: 'Shop Now'
    }
  ];
  public NewcampaignData = [
    { advId: '', campaignId: 1, categoryId: 1, productId: 1, productName: "P1", brandId: 1, new: 1, deal: 0, trend: 1, exclusive: 0, price: "H", imgSrc: "../../../assets/cart.jpeg", isaddButton: true },
    { advId: '', campaignId: 2, categoryId: 1, productId: 1, productName: "P2", brandId: 1, new: 0, deal: 1, trend: 0, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 3, categoryId: 1, productId: 1, productName: "P3", brandId: 1, new: 0, deal: 1, trend: 1, exclusive: 1, price: "L", imgSrc: "../../../assets/close.jpeg", isaddButton: true },
    { advId: '', campaignId: 4, categoryId: 1, productId: 1, productName: "P4", brandId: 2, new: 1, deal: 0, trend: 1, exclusive: 1, price: "H", imgSrc: "../../../assets/search.jpeg", isaddButton: true },
    { advId: '', campaignId: 5, categoryId: 1, productId: 1, productName: "P5", brandId: 2, new: 0, deal: 1, trend: 0, exclusive: 1, price: "H", imgSrc: "../../../assets/user.jpeg", isaddButton: true },
    { advId: '', campaignId: 6, categoryId: 1, productId: 1, productName: "P6", brandId: 2, new: 0, deal: 1, trend: 0, exclusive: 0, price: "L", imgSrc: "../../../assets/tick.jpeg", isaddButton: true },
    { advId: '', campaignId: 7, categoryId: 2, productId: 1, productName: "P7", brandId: 3, new: 1, deal: 0, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 8, categoryId: 2, productId: 1, productName: "P8", brandId: 3, new: 1, deal: 1, trend: 1, exclusive: 1, price: "H", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 9, categoryId: 2, productId: 1, productName: "P9", brandId: 3, new: 0, deal: 1, trend: 0, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true, },
    { advId: '', campaignId: 10, categoryId: 3, productId: 1, productName: "P10", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true, },
    { advId: '', campaignId: 11, categoryId: 3, productId: 1, productName: "P11", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true, },
    { advId: '', campaignId: 12, categoryId: 3, productId: 1, productName: "P12", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 1, price: "H", imgSrc: "../../../assets/def.jpeg", isaddButton: true, },
    { advId: '', campaignId: 13, categoryId: 4, productId: 1, productName: "P13", brandId: 5, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 14, categoryId: 4, productId: 1, productName: "P14", brandId: 5, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 15, categoryId: 4, productId: 1, productName: "P15", brandId: 5, new: 1, deal: 1, trend: 1, exclusive: 0, price: "H", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 16, categoryId: 5, productId: 1, productName: "P16", brandId: 6, new: 1, deal: 1, trend: 1, exclusive: 1, price: "H", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 17, categoryId: 5, productId: 1, productName: "P17", brandId: 6, new: 1, deal: 1, trend: 1, exclusive: 0, price: "H", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 18, categoryId: 5, productId: 1, productName: "P18", brandId: 6, new: 1, deal: 1, trend: 1, exclusive: 0, price: "H", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 19, categoryId: 6, productId: 1, productName: "P19", brandId: 7, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 20, categoryId: 6, productId: 1, productName: "P20", brandId: 7, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 21, categoryId: 6, productId: 1, productName: "P21", brandId: 7, new: 0, deal: 1, trend: 0, exclusive: 0, price: "H", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 22, categoryId: 7, productId: 1, productName: "P22", brandId: 8, new: 0, deal: 1, trend: 0, exclusive: 1, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 23, categoryId: 7, productId: 1, productName: "P23", brandId: 8, new: 1, deal: 0, trend: 1, exclusive: 0, price: "H", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 24, categoryId: 7, productId: 1, productName: "P24", brandId: 8, new: 0, deal: 1, trend: 0, exclusive: 0, price: "H", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 25, categoryId: 3, productId: 1, productName: "P25", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 26, categoryId: 3, productId: 26, productName: "P26", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 27, categoryId: 3, productId: 27, productName: "P27", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 28, categoryId: 3, productId: 28, productName: "P28", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 29, categoryId: 3, productId: 29, productName: "P29", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 30, categoryId: 3, productId: 30, productName: "P30", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 31, categoryId: 3, productId: 31, productName: "P31", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 32, categoryId: 3, productId: 32, productName: "P32", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 33, categoryId: 3, productId: 33, productName: "P33", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 34, categoryId: 3, productId: 34, productName: "P34", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 35, categoryId: 3, productId: 35, productName: "P35", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 36, categoryId: 3, productId: 36, productName: "P36", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 37, categoryId: 3, productId: 37, productName: "P37", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 38, categoryId: 3, productId: 38, productName: "P38", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 39, categoryId: 3, productId: 39, productName: "P39", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 40, categoryId: 3, productId: 40, productName: "P40", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 41, categoryId: 3, productId: 41, productName: "P41", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 42, categoryId: 3, productId: 42, productName: "P42", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 43, categoryId: 3, productId: 43, productName: "P43", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 44, categoryId: 3, productId: 44, productName: "P44", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 45, categoryId: 3, productId: 45, productName: "P45", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 46, categoryId: 3, productId: 46, productName: "P46", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 47, categoryId: 3, productId: 47, productName: "P47", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 48, categoryId: 3, productId: 48, productName: "P48", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 49, categoryId: 3, productId: 49, productName: "P49", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 50, categoryId: 3, productId: 50, productName: "P50", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 51, categoryId: 3, productId: 51, productName: "P51", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 52, categoryId: 3, productId: 52, productName: "P52", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 53, categoryId: 3, productId: 53, productName: "P53", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 54, categoryId: 3, productId: 54, productName: "P54", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 55, categoryId: 3, productId: 55, productName: "P55", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 56, categoryId: 3, productId: 56, productName: "P56", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 57, categoryId: 3, productId: 57, productName: "P57", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 58, categoryId: 3, productId: 58, productName: "P58", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 59, categoryId: 3, productId: 59, productName: "P59", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 60, categoryId: 3, productId: 60, productName: "P60", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 61, categoryId: 3, productId: 61, productName: "P61", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 62, categoryId: 3, productId: 32, productName: "P62", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 63, categoryId: 3, productId: 33, productName: "P63", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 64, categoryId: 3, productId: 34, productName: "P64", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 65, categoryId: 3, productId: 35, productName: "P65", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 66, categoryId: 3, productId: 36, productName: "P66", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 67, categoryId: 3, productId: 37, productName: "P67", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 68, categoryId: 3, productId: 38, productName: "P68", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 69, categoryId: 3, productId: 39, productName: "P69", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 70, categoryId: 3, productId: 30, productName: "P70", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 71, categoryId: 3, productId: 31, productName: "P71", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 72, categoryId: 3, productId: 32, productName: "P72", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 73, categoryId: 3, productId: 33, productName: "P73", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 74, categoryId: 3, productId: 34, productName: "P74", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 75, categoryId: 3, productId: 35, productName: "P75", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 76, categoryId: 3, productId: 36, productName: "P76", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 77, categoryId: 3, productId: 37, productName: "P77", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 78, categoryId: 3, productId: 38, productName: "P78", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 79, categoryId: 3, productId: 39, productName: "P79", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 80, categoryId: 3, productId: 30, productName: "P80", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 81, categoryId: 3, productId: 31, productName: "P81", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 82, categoryId: 3, productId: 32, productName: "P82", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 83, categoryId: 3, productId: 33, productName: "P83", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 84, categoryId: 3, productId: 34, productName: "P84", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 85, categoryId: 3, productId: 35, productName: "P85", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 86, categoryId: 3, productId: 36, productName: "P86", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 87, categoryId: 3, productId: 37, productName: "P87", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 88, categoryId: 3, productId: 38, productName: "P88", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 89, categoryId: 3, productId: 39, productName: "P89", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 90, categoryId: 3, productId: 30, productName: "P90", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 91, categoryId: 3, productId: 31, productName: "P91", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 92, categoryId: 3, productId: 32, productName: "P92", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 93, categoryId: 3, productId: 33, productName: "P93", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 94, categoryId: 3, productId: 34, productName: "P94", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 95, categoryId: 3, productId: 35, productName: "P95", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 96, categoryId: 3, productId: 36, productName: "P96", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 97, categoryId: 3, productId: 37, productName: "P97", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 98, categoryId: 3, productId: 38, productName: "P98", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 99, categoryId: 3, productId: 39, productName: "P99", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 100, categoryId: 3, productId: 30, productName: "P100", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 101, categoryId: 3, productId: 31, productName: "P101", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 102, categoryId: 3, productId: 32, productName: "P102", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 103, categoryId: 3, productId: 33, productName: "P103", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 104, categoryId: 3, productId: 34, productName: "P104", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 105, categoryId: 3, productId: 35, productName: "P105", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 106, categoryId: 3, productId: 36, productName: "P106", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 107, categoryId: 3, productId: 37, productName: "P107", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 108, categoryId: 3, productId: 38, productName: "P108", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 109, categoryId: 3, productId: 39, productName: "P109", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 100, categoryId: 3, productId: 30, productName: "P110", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 111, categoryId: 3, productId: 31, productName: "P111", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 112, categoryId: 3, productId: 32, productName: "P112", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 113, categoryId: 3, productId: 33, productName: "P113", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 114, categoryId: 3, productId: 34, productName: "P114", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 115, categoryId: 3, productId: 35, productName: "P115", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 116, categoryId: 3, productId: 36, productName: "P116", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 117, categoryId: 3, productId: 37, productName: "P117", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 118, categoryId: 3, productId: 38, productName: "P118", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 119, categoryId: 3, productId: 39, productName: "P119", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 120, categoryId: 3, productId: 30, productName: "P120", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 121, categoryId: 3, productId: 31, productName: "P121", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 122, categoryId: 3, productId: 32, productName: "P122", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 123, categoryId: 3, productId: 33, productName: "P123", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 124, categoryId: 3, productId: 34, productName: "P124", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 125, categoryId: 3, productId: 34, productName: "P125", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 126, categoryId: 3, productId: 26, productName: "P126", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 127, categoryId: 3, productId: 27, productName: "P127", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 128, categoryId: 3, productId: 28, productName: "P128", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 129, categoryId: 3, productId: 29, productName: "P129", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 130, categoryId: 3, productId: 30, productName: "P130", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 131, categoryId: 3, productId: 31, productName: "P131", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 132, categoryId: 3, productId: 32, productName: "P132", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 133, categoryId: 3, productId: 33, productName: "P133", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 134, categoryId: 3, productId: 34, productName: "P134", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 135, categoryId: 3, productId: 35, productName: "P135", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 136, categoryId: 3, productId: 36, productName: "P136", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 137, categoryId: 3, productId: 37, productName: "P137", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 138, categoryId: 3, productId: 38, productName: "P138", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 139, categoryId: 3, productId: 39, productName: "P139", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 140, categoryId: 3, productId: 40, productName: "P140", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 141, categoryId: 3, productId: 41, productName: "P141", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 142, categoryId: 3, productId: 42, productName: "P142", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 143, categoryId: 3, productId: 43, productName: "P143", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 144, categoryId: 3, productId: 44, productName: "P144", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 145, categoryId: 3, productId: 45, productName: "P145", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 146, categoryId: 3, productId: 46, productName: "P146", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 147, categoryId: 3, productId: 47, productName: "P147", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 148, categoryId: 3, productId: 48, productName: "P148", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 149, categoryId: 3, productId: 49, productName: "P149", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 150, categoryId: 3, productId: 50, productName: "P150", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 151, categoryId: 3, productId: 51, productName: "P151", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 152, categoryId: 3, productId: 52, productName: "P152", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 153, categoryId: 3, productId: 53, productName: "P153", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 154, categoryId: 3, productId: 54, productName: "P154", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 155, categoryId: 3, productId: 55, productName: "P155", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 156, categoryId: 3, productId: 56, productName: "P156", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 157, categoryId: 3, productId: 57, productName: "P157", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 158, categoryId: 3, productId: 58, productName: "P158", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 159, categoryId: 3, productId: 59, productName: "P159", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 160, categoryId: 3, productId: 60, productName: "P160", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 161, categoryId: 3, productId: 61, productName: "P161", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 162, categoryId: 3, productId: 32, productName: "P162", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 163, categoryId: 3, productId: 33, productName: "P163", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 164, categoryId: 3, productId: 34, productName: "P164", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 165, categoryId: 3, productId: 35, productName: "P165", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 166, categoryId: 3, productId: 36, productName: "P166", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 167, categoryId: 3, productId: 37, productName: "P167", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 168, categoryId: 3, productId: 38, productName: "P168", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 169, categoryId: 3, productId: 39, productName: "P169", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 170, categoryId: 3, productId: 30, productName: "P170", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 171, categoryId: 3, productId: 31, productName: "P171", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 172, categoryId: 3, productId: 32, productName: "P172", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 173, categoryId: 3, productId: 33, productName: "P173", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 174, categoryId: 3, productId: 34, productName: "P174", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 175, categoryId: 3, productId: 35, productName: "P175", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 176, categoryId: 3, productId: 36, productName: "P176", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 177, categoryId: 3, productId: 37, productName: "P177", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 178, categoryId: 3, productId: 38, productName: "P178", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 179, categoryId: 3, productId: 39, productName: "P179", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 180, categoryId: 3, productId: 30, productName: "P180", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 181, categoryId: 3, productId: 31, productName: "P181", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 182, categoryId: 3, productId: 32, productName: "P182", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 183, categoryId: 3, productId: 33, productName: "P183", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 184, categoryId: 3, productId: 34, productName: "P184", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 185, categoryId: 3, productId: 35, productName: "P185", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 186, categoryId: 3, productId: 36, productName: "P186", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 187, categoryId: 3, productId: 37, productName: "P187", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 188, categoryId: 3, productId: 38, productName: "P188", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 189, categoryId: 3, productId: 39, productName: "P189", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 190, categoryId: 3, productId: 30, productName: "P190", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 191, categoryId: 3, productId: 31, productName: "P191", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 192, categoryId: 3, productId: 32, productName: "P192", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 193, categoryId: 3, productId: 33, productName: "P193", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 194, categoryId: 3, productId: 34, productName: "P194", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 195, categoryId: 3, productId: 35, productName: "P195", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 196, categoryId: 3, productId: 36, productName: "P196", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 197, categoryId: 3, productId: 37, productName: "P197", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 198, categoryId: 3, productId: 38, productName: "P198", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 199, categoryId: 3, productId: 39, productName: "P199", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 200, categoryId: 3, productId: 30, productName: "P200", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 201, categoryId: 3, productId: 31, productName: "P201", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 202, categoryId: 3, productId: 32, productName: "P202", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 203, categoryId: 3, productId: 33, productName: "P203", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 204, categoryId: 3, productId: 34, productName: "P204", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 205, categoryId: 3, productId: 35, productName: "P205", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 206, categoryId: 3, productId: 36, productName: "P206", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 207, categoryId: 3, productId: 37, productName: "P207", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 208, categoryId: 3, productId: 38, productName: "P208", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 209, categoryId: 3, productId: 39, productName: "P209", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 210, categoryId: 3, productId: 30, productName: "P210", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 211, categoryId: 3, productId: 31, productName: "P211", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 212, categoryId: 3, productId: 32, productName: "P212", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 213, categoryId: 3, productId: 33, productName: "P213", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 214, categoryId: 3, productId: 34, productName: "P214", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 215, categoryId: 3, productId: 35, productName: "P215", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 216, categoryId: 3, productId: 36, productName: "P216", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 217, categoryId: 3, productId: 37, productName: "P217", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 218, categoryId: 3, productId: 38, productName: "P218", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 219, categoryId: 3, productId: 39, productName: "P219", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 220, categoryId: 3, productId: 30, productName: "P220", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 221, categoryId: 3, productId: 31, productName: "P221", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 222, categoryId: 3, productId: 32, productName: "P222", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 223, categoryId: 3, productId: 33, productName: "P223", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 224, categoryId: 3, productId: 34, productName: "P224", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 225, categoryId: 3, productId: 34, productName: "P225", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 226, categoryId: 3, productId: 26, productName: "P226", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 227, categoryId: 3, productId: 27, productName: "P227", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 228, categoryId: 3, productId: 28, productName: "P228", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 229, categoryId: 3, productId: 29, productName: "P229", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 230, categoryId: 3, productId: 30, productName: "P230", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 231, categoryId: 3, productId: 31, productName: "P231", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 232, categoryId: 3, productId: 32, productName: "P232", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 233, categoryId: 3, productId: 33, productName: "P233", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 234, categoryId: 3, productId: 34, productName: "P234", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 235, categoryId: 3, productId: 35, productName: "P235", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 236, categoryId: 3, productId: 36, productName: "P236", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 237, categoryId: 3, productId: 37, productName: "P237", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 238, categoryId: 3, productId: 38, productName: "P238", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 239, categoryId: 3, productId: 39, productName: "P239", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 240, categoryId: 3, productId: 40, productName: "P240", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 241, categoryId: 3, productId: 41, productName: "P241", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 242, categoryId: 3, productId: 42, productName: "P242", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 243, categoryId: 3, productId: 43, productName: "P243", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 244, categoryId: 3, productId: 44, productName: "P244", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 245, categoryId: 3, productId: 45, productName: "P245", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 246, categoryId: 3, productId: 46, productName: "P246", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 247, categoryId: 3, productId: 47, productName: "P247", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 248, categoryId: 3, productId: 48, productName: "P248", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 249, categoryId: 3, productId: 49, productName: "P249", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 250, categoryId: 3, productId: 50, productName: "P250", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 51, categoryId: 3, productId: 51, productName: "P251", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 52, categoryId: 3, productId: 52, productName: "P252", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 53, categoryId: 3, productId: 53, productName: "P253", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 54, categoryId: 3, productId: 54, productName: "P254", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 55, categoryId: 3, productId: 55, productName: "P255", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 56, categoryId: 3, productId: 56, productName: "P256", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 57, categoryId: 3, productId: 57, productName: "P257", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 58, categoryId: 3, productId: 58, productName: "P258", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 59, categoryId: 3, productId: 59, productName: "P259", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 60, categoryId: 3, productId: 60, productName: "P260", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 61, categoryId: 3, productId: 61, productName: "P261", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 32, categoryId: 3, productId: 32, productName: "P262", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 33, categoryId: 3, productId: 33, productName: "P263", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 34, categoryId: 3, productId: 34, productName: "P264", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 35, categoryId: 3, productId: 35, productName: "P265", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 36, categoryId: 3, productId: 36, productName: "P266", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 37, categoryId: 3, productId: 37, productName: "P267", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 38, categoryId: 3, productId: 38, productName: "P268", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 39, categoryId: 3, productId: 39, productName: "P269", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 30, categoryId: 3, productId: 30, productName: "P270", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 31, categoryId: 3, productId: 31, productName: "P271", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 32, categoryId: 3, productId: 32, productName: "P272", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 33, categoryId: 3, productId: 33, productName: "P273", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 34, categoryId: 3, productId: 34, productName: "P274", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 35, categoryId: 3, productId: 35, productName: "P275", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 36, categoryId: 3, productId: 36, productName: "P276", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 37, categoryId: 3, productId: 37, productName: "P277", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 38, categoryId: 3, productId: 38, productName: "P278", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 39, categoryId: 3, productId: 39, productName: "P279", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 30, categoryId: 3, productId: 30, productName: "P280", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 31, categoryId: 3, productId: 31, productName: "P281", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 32, categoryId: 3, productId: 32, productName: "P282", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 33, categoryId: 3, productId: 33, productName: "P283", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 34, categoryId: 3, productId: 34, productName: "P284", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 35, categoryId: 3, productId: 35, productName: "P285", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 36, categoryId: 3, productId: 36, productName: "P286", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 37, categoryId: 3, productId: 37, productName: "P287", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 38, categoryId: 3, productId: 38, productName: "P288", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 39, categoryId: 3, productId: 39, productName: "P289", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 30, categoryId: 3, productId: 30, productName: "P290", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 31, categoryId: 3, productId: 31, productName: "P291", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 32, categoryId: 3, productId: 32, productName: "P292", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 33, categoryId: 3, productId: 33, productName: "P293", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 34, categoryId: 3, productId: 34, productName: "P294", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 35, categoryId: 3, productId: 35, productName: "P295", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 36, categoryId: 3, productId: 36, productName: "P296", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 37, categoryId: 3, productId: 37, productName: "P297", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 38, categoryId: 3, productId: 38, productName: "P298", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 39, categoryId: 3, productId: 39, productName: "P299", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 30, categoryId: 3, productId: 30, productName: "P300", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 31, categoryId: 3, productId: 31, productName: "P301", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 32, categoryId: 3, productId: 32, productName: "P302", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 33, categoryId: 3, productId: 33, productName: "P303", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 34, categoryId: 3, productId: 34, productName: "P304", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 35, categoryId: 3, productId: 35, productName: "P305", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 36, categoryId: 3, productId: 36, productName: "P306", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 37, categoryId: 3, productId: 37, productName: "P307", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 38, categoryId: 3, productId: 38, productName: "P308", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 39, categoryId: 3, productId: 39, productName: "P309", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 30, categoryId: 3, productId: 30, productName: "P310", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 31, categoryId: 3, productId: 31, productName: "P311", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 32, categoryId: 3, productId: 32, productName: "P312", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 33, categoryId: 3, productId: 33, productName: "P313", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 34, categoryId: 3, productId: 34, productName: "P314", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 35, categoryId: 3, productId: 35, productName: "P315", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 36, categoryId: 3, productId: 36, productName: "P316", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 37, categoryId: 3, productId: 37, productName: "P317", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 38, categoryId: 3, productId: 38, productName: "P318", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 39, categoryId: 3, productId: 39, productName: "P319", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 30, categoryId: 3, productId: 30, productName: "P320", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 31, categoryId: 3, productId: 31, productName: "P321", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 32, categoryId: 3, productId: 32, productName: "P322", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 33, categoryId: 3, productId: 33, productName: "P323", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 34, categoryId: 3, productId: 34, productName: "P324", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 34, categoryId: 3, productId: 34, productName: "P325", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 26, categoryId: 3, productId: 26, productName: "P326", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 27, categoryId: 3, productId: 27, productName: "P327", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 28, categoryId: 3, productId: 28, productName: "P328", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 29, categoryId: 3, productId: 29, productName: "P329", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 30, categoryId: 3, productId: 30, productName: "P330", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 31, categoryId: 3, productId: 31, productName: "P331", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 32, categoryId: 3, productId: 32, productName: "P332", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 33, categoryId: 3, productId: 33, productName: "P333", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 34, categoryId: 3, productId: 34, productName: "P334", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 35, categoryId: 3, productId: 35, productName: "P335", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 36, categoryId: 3, productId: 36, productName: "P336", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 37, categoryId: 3, productId: 37, productName: "P337", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 38, categoryId: 3, productId: 38, productName: "P338", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 39, categoryId: 3, productId: 39, productName: "P339", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 40, categoryId: 3, productId: 40, productName: "P340", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 41, categoryId: 3, productId: 41, productName: "P341", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 42, categoryId: 3, productId: 42, productName: "P342", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 43, categoryId: 3, productId: 43, productName: "P343", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 44, categoryId: 3, productId: 44, productName: "P344", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 45, categoryId: 3, productId: 45, productName: "P345", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 46, categoryId: 3, productId: 46, productName: "P346", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 47, categoryId: 3, productId: 47, productName: "P347", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 48, categoryId: 3, productId: 48, productName: "P348", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 49, categoryId: 3, productId: 49, productName: "P349", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 50, categoryId: 3, productId: 50, productName: "P350", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 51, categoryId: 3, productId: 51, productName: "P351", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 52, categoryId: 3, productId: 52, productName: "P352", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 53, categoryId: 3, productId: 53, productName: "P353", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 54, categoryId: 3, productId: 54, productName: "P354", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 55, categoryId: 3, productId: 55, productName: "P355", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 56, categoryId: 3, productId: 56, productName: "P356", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 57, categoryId: 3, productId: 57, productName: "P357", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 58, categoryId: 3, productId: 58, productName: "P358", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 59, categoryId: 3, productId: 59, productName: "P359", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 60, categoryId: 3, productId: 60, productName: "P360", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 61, categoryId: 3, productId: 61, productName: "P361", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 32, categoryId: 3, productId: 32, productName: "P362", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 33, categoryId: 3, productId: 33, productName: "P363", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 34, categoryId: 3, productId: 34, productName: "P364", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 35, categoryId: 3, productId: 35, productName: "P365", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 36, categoryId: 3, productId: 36, productName: "P366", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 37, categoryId: 3, productId: 37, productName: "P367", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 38, categoryId: 3, productId: 38, productName: "P368", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 39, categoryId: 3, productId: 39, productName: "P369", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 30, categoryId: 3, productId: 30, productName: "P370", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 31, categoryId: 3, productId: 31, productName: "P371", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 32, categoryId: 3, productId: 32, productName: "P372", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 33, categoryId: 3, productId: 33, productName: "P373", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 34, categoryId: 3, productId: 34, productName: "P374", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 35, categoryId: 3, productId: 35, productName: "P375", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 36, categoryId: 3, productId: 36, productName: "P376", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 37, categoryId: 3, productId: 37, productName: "P377", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 38, categoryId: 3, productId: 38, productName: "P378", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 39, categoryId: 3, productId: 39, productName: "P379", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 30, categoryId: 3, productId: 30, productName: "P380", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 31, categoryId: 3, productId: 31, productName: "P381", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 32, categoryId: 3, productId: 32, productName: "P382", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 33, categoryId: 3, productId: 33, productName: "P383", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 34, categoryId: 3, productId: 34, productName: "P384", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 35, categoryId: 3, productId: 35, productName: "P385", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 36, categoryId: 3, productId: 36, productName: "P386", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 37, categoryId: 3, productId: 37, productName: "P387", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 38, categoryId: 3, productId: 38, productName: "P388", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 39, categoryId: 3, productId: 39, productName: "P389", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 30, categoryId: 3, productId: 30, productName: "P390", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 31, categoryId: 3, productId: 31, productName: "P391", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 32, categoryId: 3, productId: 32, productName: "P392", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 33, categoryId: 3, productId: 33, productName: "P393", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 34, categoryId: 3, productId: 34, productName: "P394", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 35, categoryId: 3, productId: 35, productName: "P395", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 36, categoryId: 3, productId: 36, productName: "P396", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 37, categoryId: 3, productId: 37, productName: "P397", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 38, categoryId: 3, productId: 38, productName: "P398", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 39, categoryId: 3, productId: 39, productName: "P399", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 30, categoryId: 3, productId: 30, productName: "P400", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 31, categoryId: 3, productId: 31, productName: "P401", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 32, categoryId: 3, productId: 32, productName: "P402", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 33, categoryId: 3, productId: 33, productName: "P403", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 34, categoryId: 3, productId: 34, productName: "P404", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 35, categoryId: 3, productId: 35, productName: "P405", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 36, categoryId: 3, productId: 36, productName: "P406", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 37, categoryId: 3, productId: 37, productName: "P407", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 38, categoryId: 3, productId: 38, productName: "P408", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 39, categoryId: 3, productId: 39, productName: "P409", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 30, categoryId: 3, productId: 30, productName: "P410", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 31, categoryId: 3, productId: 31, productName: "P411", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 32, categoryId: 3, productId: 32, productName: "P412", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 33, categoryId: 3, productId: 33, productName: "P413", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 34, categoryId: 3, productId: 34, productName: "P414", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 35, categoryId: 3, productId: 35, productName: "P415", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 36, categoryId: 3, productId: 36, productName: "P416", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 37, categoryId: 3, productId: 37, productName: "P417", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 38, categoryId: 3, productId: 38, productName: "P418", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 39, categoryId: 3, productId: 39, productName: "P419", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 30, categoryId: 3, productId: 30, productName: "P420", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 31, categoryId: 3, productId: 31, productName: "P421", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 32, categoryId: 3, productId: 32, productName: "P422", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 33, categoryId: 3, productId: 33, productName: "P423", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 34, categoryId: 3, productId: 34, productName: "P424", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 34, categoryId: 3, productId: 34, productName: "P425", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 26, categoryId: 3, productId: 26, productName: "P426", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 27, categoryId: 3, productId: 27, productName: "P427", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 28, categoryId: 3, productId: 28, productName: "P428", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 29, categoryId: 3, productId: 29, productName: "P429", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 30, categoryId: 3, productId: 30, productName: "P430", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 31, categoryId: 3, productId: 31, productName: "P431", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 32, categoryId: 3, productId: 32, productName: "P432", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 33, categoryId: 3, productId: 33, productName: "P433", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 34, categoryId: 3, productId: 34, productName: "P434", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 35, categoryId: 3, productId: 35, productName: "P435", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 36, categoryId: 3, productId: 36, productName: "P436", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 37, categoryId: 3, productId: 37, productName: "P437", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 38, categoryId: 3, productId: 38, productName: "P438", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 39, categoryId: 3, productId: 39, productName: "P439", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 40, categoryId: 3, productId: 40, productName: "P440", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 41, categoryId: 3, productId: 41, productName: "P441", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 42, categoryId: 3, productId: 42, productName: "P442", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 43, categoryId: 3, productId: 43, productName: "P443", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 44, categoryId: 3, productId: 44, productName: "P444", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 45, categoryId: 3, productId: 45, productName: "P445", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 46, categoryId: 3, productId: 46, productName: "P446", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 47, categoryId: 3, productId: 47, productName: "P447", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 48, categoryId: 3, productId: 48, productName: "P448", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 49, categoryId: 3, productId: 49, productName: "P449", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 50, categoryId: 3, productId: 50, productName: "P450", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 51, categoryId: 3, productId: 51, productName: "P451", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 52, categoryId: 3, productId: 52, productName: "P452", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 53, categoryId: 3, productId: 53, productName: "P453", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 54, categoryId: 3, productId: 54, productName: "P454", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 55, categoryId: 3, productId: 55, productName: "P455", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 56, categoryId: 3, productId: 56, productName: "P456", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 57, categoryId: 3, productId: 57, productName: "P457", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 58, categoryId: 3, productId: 58, productName: "P458", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 59, categoryId: 3, productId: 59, productName: "P459", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 60, categoryId: 3, productId: 60, productName: "P460", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 61, categoryId: 3, productId: 61, productName: "P461", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 32, categoryId: 3, productId: 32, productName: "P462", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 33, categoryId: 3, productId: 33, productName: "P463", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 34, categoryId: 3, productId: 34, productName: "P464", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 35, categoryId: 3, productId: 35, productName: "P465", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 36, categoryId: 3, productId: 36, productName: "P466", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 37, categoryId: 3, productId: 37, productName: "P467", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 38, categoryId: 3, productId: 38, productName: "P468", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 39, categoryId: 3, productId: 39, productName: "P469", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 30, categoryId: 3, productId: 30, productName: "P470", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 31, categoryId: 3, productId: 31, productName: "P471", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 32, categoryId: 3, productId: 32, productName: "P472", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 33, categoryId: 3, productId: 33, productName: "P473", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 34, categoryId: 3, productId: 34, productName: "P474", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 35, categoryId: 3, productId: 35, productName: "P475", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 36, categoryId: 3, productId: 36, productName: "P476", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 37, categoryId: 3, productId: 37, productName: "P477", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 38, categoryId: 3, productId: 38, productName: "P478", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 39, categoryId: 3, productId: 39, productName: "P479", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 30, categoryId: 3, productId: 30, productName: "P480", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 31, categoryId: 3, productId: 31, productName: "P481", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 32, categoryId: 3, productId: 32, productName: "P482", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 33, categoryId: 3, productId: 33, productName: "P483", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 34, categoryId: 3, productId: 34, productName: "P484", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 35, categoryId: 3, productId: 35, productName: "P485", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 36, categoryId: 3, productId: 36, productName: "P486", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 37, categoryId: 3, productId: 37, productName: "P487", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 38, categoryId: 3, productId: 38, productName: "P488", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 39, categoryId: 3, productId: 39, productName: "P489", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 30, categoryId: 3, productId: 30, productName: "P490", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 31, categoryId: 3, productId: 31, productName: "P491", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 32, categoryId: 3, productId: 32, productName: "P492", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 33, categoryId: 3, productId: 33, productName: "P493", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 34, categoryId: 3, productId: 34, productName: "P494", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 35, categoryId: 3, productId: 35, productName: "P495", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 36, categoryId: 3, productId: 36, productName: "P496", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 37, categoryId: 3, productId: 37, productName: "P497", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 38, categoryId: 3, productId: 38, productName: "P498", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 39, categoryId: 3, productId: 39, productName: "P499", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 30, categoryId: 3, productId: 30, productName: "P500", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 31, categoryId: 3, productId: 31, productName: "P501", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 32, categoryId: 3, productId: 32, productName: "P502", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 33, categoryId: 3, productId: 33, productName: "P503", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 34, categoryId: 3, productId: 34, productName: "P504", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 35, categoryId: 3, productId: 35, productName: "P505", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 36, categoryId: 3, productId: 36, productName: "P506", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 37, categoryId: 3, productId: 37, productName: "P507", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 38, categoryId: 3, productId: 38, productName: "P508", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 39, categoryId: 3, productId: 39, productName: "P509", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 30, categoryId: 3, productId: 30, productName: "P510", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 31, categoryId: 3, productId: 31, productName: "P511", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 32, categoryId: 3, productId: 32, productName: "P512", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 33, categoryId: 3, productId: 33, productName: "P513", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 34, categoryId: 3, productId: 34, productName: "P514", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 35, categoryId: 3, productId: 35, productName: "P515", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 36, categoryId: 3, productId: 36, productName: "P516", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 37, categoryId: 3, productId: 37, productName: "P517", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 38, categoryId: 3, productId: 38, productName: "P518", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 39, categoryId: 3, productId: 39, productName: "P519", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 30, categoryId: 3, productId: 30, productName: "P520", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 31, categoryId: 3, productId: 31, productName: "P521", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 32, categoryId: 3, productId: 32, productName: "P522", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 33, categoryId: 3, productId: 33, productName: "P523", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 34, categoryId: 3, productId: 34, productName: "P524", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 34, categoryId: 3, productId: 34, productName: "P525", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
  
    { advId: '', campaignId: 26, categoryId: 3, productId: 26, productName: "P526", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 27, categoryId: 3, productId: 27, productName: "P527", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 28, categoryId: 3, productId: 28, productName: "P528", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 29, categoryId: 3, productId: 29, productName: "P529", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 30, categoryId: 3, productId: 30, productName: "P530", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 31, categoryId: 3, productId: 31, productName: "P531", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 32, categoryId: 3, productId: 32, productName: "P532", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 33, categoryId: 3, productId: 33, productName: "P533", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 34, categoryId: 3, productId: 34, productName: "P534", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 35, categoryId: 3, productId: 35, productName: "P535", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 36, categoryId: 3, productId: 36, productName: "P536", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 37, categoryId: 3, productId: 37, productName: "P537", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 38, categoryId: 3, productId: 38, productName: "P538", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 39, categoryId: 3, productId: 39, productName: "P539", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 40, categoryId: 3, productId: 40, productName: "P540", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 41, categoryId: 3, productId: 41, productName: "P541", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 42, categoryId: 3, productId: 42, productName: "P542", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 43, categoryId: 3, productId: 43, productName: "P543", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 44, categoryId: 3, productId: 44, productName: "P544", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 45, categoryId: 3, productId: 45, productName: "P545", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 46, categoryId: 3, productId: 46, productName: "P546", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 47, categoryId: 3, productId: 47, productName: "P547", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 48, categoryId: 3, productId: 48, productName: "P548", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 49, categoryId: 3, productId: 49, productName: "P549", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 50, categoryId: 3, productId: 50, productName: "P550", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 51, categoryId: 3, productId: 51, productName: "P551", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 52, categoryId: 3, productId: 52, productName: "P552", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 53, categoryId: 3, productId: 53, productName: "P553", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 54, categoryId: 3, productId: 54, productName: "P554", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 55, categoryId: 3, productId: 55, productName: "P555", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 56, categoryId: 3, productId: 56, productName: "P556", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 57, categoryId: 3, productId: 57, productName: "P557", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 58, categoryId: 3, productId: 58, productName: "P558", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 59, categoryId: 3, productId: 59, productName: "P559", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 60, categoryId: 3, productId: 60, productName: "P560", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 61, categoryId: 3, productId: 61, productName: "P561", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 32, categoryId: 3, productId: 32, productName: "P562", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 33, categoryId: 3, productId: 33, productName: "P563", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 34, categoryId: 3, productId: 34, productName: "P564", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 35, categoryId: 3, productId: 35, productName: "P565", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 36, categoryId: 3, productId: 36, productName: "P566", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 37, categoryId: 3, productId: 37, productName: "P567", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 38, categoryId: 3, productId: 38, productName: "P568", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 39, categoryId: 3, productId: 39, productName: "P569", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 30, categoryId: 3, productId: 30, productName: "P570", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 31, categoryId: 3, productId: 31, productName: "P571", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 32, categoryId: 3, productId: 32, productName: "P572", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 33, categoryId: 3, productId: 33, productName: "P573", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 34, categoryId: 3, productId: 34, productName: "P574", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 35, categoryId: 3, productId: 35, productName: "P575", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 36, categoryId: 3, productId: 36, productName: "P576", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 37, categoryId: 3, productId: 37, productName: "P577", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 38, categoryId: 3, productId: 38, productName: "P578", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 39, categoryId: 3, productId: 39, productName: "P579", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 30, categoryId: 3, productId: 30, productName: "P580", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 31, categoryId: 3, productId: 31, productName: "P581", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 32, categoryId: 3, productId: 32, productName: "P582", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 33, categoryId: 3, productId: 33, productName: "P583", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 34, categoryId: 3, productId: 34, productName: "P584", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 35, categoryId: 3, productId: 35, productName: "P585", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 36, categoryId: 3, productId: 36, productName: "P586", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 37, categoryId: 3, productId: 37, productName: "P587", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 38, categoryId: 3, productId: 38, productName: "P588", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 39, categoryId: 3, productId: 39, productName: "P589", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 30, categoryId: 3, productId: 30, productName: "P590", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 31, categoryId: 3, productId: 31, productName: "P591", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 32, categoryId: 3, productId: 32, productName: "P592", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 33, categoryId: 3, productId: 33, productName: "P593", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 34, categoryId: 3, productId: 34, productName: "P594", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 35, categoryId: 3, productId: 35, productName: "P595", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 36, categoryId: 3, productId: 36, productName: "P596", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 37, categoryId: 3, productId: 37, productName: "P597", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 38, categoryId: 3, productId: 38, productName: "P598", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 39, categoryId: 3, productId: 39, productName: "P599", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 30, categoryId: 3, productId: 30, productName: "P600", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 31, categoryId: 3, productId: 31, productName: "P601", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 32, categoryId: 3, productId: 32, productName: "P602", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 33, categoryId: 3, productId: 33, productName: "P603", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 34, categoryId: 3, productId: 34, productName: "P604", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 35, categoryId: 3, productId: 35, productName: "P605", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 36, categoryId: 3, productId: 36, productName: "P606", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 37, categoryId: 3, productId: 37, productName: "P607", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 38, categoryId: 3, productId: 38, productName: "P608", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 39, categoryId: 3, productId: 39, productName: "P609", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 30, categoryId: 3, productId: 30, productName: "P610", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 31, categoryId: 3, productId: 31, productName: "P611", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 32, categoryId: 3, productId: 32, productName: "P612", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 33, categoryId: 3, productId: 33, productName: "P613", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 34, categoryId: 3, productId: 34, productName: "P614", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 35, categoryId: 3, productId: 35, productName: "P615", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 36, categoryId: 3, productId: 36, productName: "P616", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 37, categoryId: 3, productId: 37, productName: "P617", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 38, categoryId: 3, productId: 38, productName: "P618", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 39, categoryId: 3, productId: 39, productName: "P619", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 30, categoryId: 3, productId: 30, productName: "P620", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 31, categoryId: 3, productId: 31, productName: "P621", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 32, categoryId: 3, productId: 32, productName: "P622", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 33, categoryId: 3, productId: 33, productName: "P623", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 34, categoryId: 3, productId: 34, productName: "P624", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 34, categoryId: 3, productId: 34, productName: "P625", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 26, categoryId: 3, productId: 26, productName: "P626", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 27, categoryId: 3, productId: 27, productName: "P627", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 28, categoryId: 3, productId: 28, productName: "P628", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 29, categoryId: 3, productId: 29, productName: "P629", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 30, categoryId: 3, productId: 30, productName: "P630", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 31, categoryId: 3, productId: 31, productName: "P631", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 32, categoryId: 3, productId: 32, productName: "P632", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 33, categoryId: 3, productId: 33, productName: "P633", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 34, categoryId: 3, productId: 34, productName: "P634", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 35, categoryId: 3, productId: 35, productName: "P635", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 36, categoryId: 3, productId: 36, productName: "P636", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 37, categoryId: 3, productId: 37, productName: "P637", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 38, categoryId: 3, productId: 38, productName: "P638", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 39, categoryId: 3, productId: 39, productName: "P639", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 40, categoryId: 3, productId: 40, productName: "P640", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 41, categoryId: 3, productId: 41, productName: "P641", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 42, categoryId: 3, productId: 42, productName: "P642", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 43, categoryId: 3, productId: 43, productName: "P643", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 44, categoryId: 3, productId: 44, productName: "P644", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 45, categoryId: 3, productId: 45, productName: "P645", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 46, categoryId: 3, productId: 46, productName: "P646", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 47, categoryId: 3, productId: 47, productName: "P647", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 48, categoryId: 3, productId: 48, productName: "P648", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 49, categoryId: 3, productId: 49, productName: "P649", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 50, categoryId: 3, productId: 50, productName: "P650", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 51, categoryId: 3, productId: 51, productName: "P651", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 52, categoryId: 3, productId: 52, productName: "P652", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 53, categoryId: 3, productId: 53, productName: "P653", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 54, categoryId: 3, productId: 54, productName: "P654", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 55, categoryId: 3, productId: 55, productName: "P655", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 56, categoryId: 3, productId: 56, productName: "P656", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 57, categoryId: 3, productId: 57, productName: "P657", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 58, categoryId: 3, productId: 58, productName: "P658", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 59, categoryId: 3, productId: 59, productName: "P659", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 60, categoryId: 3, productId: 60, productName: "P660", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 61, categoryId: 3, productId: 61, productName: "P661", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 32, categoryId: 3, productId: 32, productName: "P662", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 33, categoryId: 3, productId: 33, productName: "P663", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 34, categoryId: 3, productId: 34, productName: "P664", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 35, categoryId: 3, productId: 35, productName: "P665", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 36, categoryId: 3, productId: 36, productName: "P666", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 37, categoryId: 3, productId: 37, productName: "P667", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 38, categoryId: 3, productId: 38, productName: "P668", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 39, categoryId: 3, productId: 39, productName: "P669", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 30, categoryId: 3, productId: 30, productName: "P670", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 31, categoryId: 3, productId: 31, productName: "P671", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 32, categoryId: 3, productId: 32, productName: "P672", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 33, categoryId: 3, productId: 33, productName: "P673", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 34, categoryId: 3, productId: 34, productName: "P674", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 35, categoryId: 3, productId: 35, productName: "P675", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 36, categoryId: 3, productId: 36, productName: "P676", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 37, categoryId: 3, productId: 37, productName: "P677", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 38, categoryId: 3, productId: 38, productName: "P678", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 39, categoryId: 3, productId: 39, productName: "P679", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 30, categoryId: 3, productId: 30, productName: "P680", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 31, categoryId: 3, productId: 31, productName: "P681", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 32, categoryId: 3, productId: 32, productName: "P682", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 33, categoryId: 3, productId: 33, productName: "P683", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 34, categoryId: 3, productId: 34, productName: "P684", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 35, categoryId: 3, productId: 35, productName: "P685", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 36, categoryId: 3, productId: 36, productName: "P686", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 37, categoryId: 3, productId: 37, productName: "P687", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 38, categoryId: 3, productId: 38, productName: "P688", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 39, categoryId: 3, productId: 39, productName: "P689", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 30, categoryId: 3, productId: 30, productName: "P690", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 31, categoryId: 3, productId: 31, productName: "P691", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 32, categoryId: 3, productId: 32, productName: "P692", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 33, categoryId: 3, productId: 33, productName: "P693", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 34, categoryId: 3, productId: 34, productName: "P694", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 35, categoryId: 3, productId: 35, productName: "P695", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 36, categoryId: 3, productId: 36, productName: "P696", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 37, categoryId: 3, productId: 37, productName: "P697", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 38, categoryId: 3, productId: 38, productName: "P698", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 39, categoryId: 3, productId: 39, productName: "P699", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 30, categoryId: 3, productId: 30, productName: "P700", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 31, categoryId: 3, productId: 31, productName: "P701", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 32, categoryId: 3, productId: 32, productName: "P702", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 33, categoryId: 3, productId: 33, productName: "P703", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 34, categoryId: 3, productId: 34, productName: "P704", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 35, categoryId: 3, productId: 35, productName: "P705", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 36, categoryId: 3, productId: 36, productName: "P706", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 37, categoryId: 3, productId: 37, productName: "P707", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 38, categoryId: 3, productId: 38, productName: "P708", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 39, categoryId: 3, productId: 39, productName: "P709", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 30, categoryId: 3, productId: 30, productName: "P710", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 31, categoryId: 3, productId: 31, productName: "P711", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 32, categoryId: 3, productId: 32, productName: "P712", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 33, categoryId: 3, productId: 33, productName: "P713", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 34, categoryId: 3, productId: 34, productName: "P714", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 35, categoryId: 3, productId: 35, productName: "P715", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 36, categoryId: 3, productId: 36, productName: "P716", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 37, categoryId: 3, productId: 37, productName: "P717", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 38, categoryId: 3, productId: 38, productName: "P718", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 39, categoryId: 3, productId: 39, productName: "P719", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 30, categoryId: 3, productId: 30, productName: "P720", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 31, categoryId: 3, productId: 31, productName: "P721", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 32, categoryId: 3, productId: 32, productName: "P722", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 33, categoryId: 3, productId: 33, productName: "P723", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 34, categoryId: 3, productId: 34, productName: "P724", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 34, categoryId: 3, productId: 34, productName: "P725", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 26, categoryId: 3, productId: 26, productName: "P726", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 27, categoryId: 3, productId: 27, productName: "P727", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 28, categoryId: 3, productId: 28, productName: "P728", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 29, categoryId: 3, productId: 29, productName: "P729", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 30, categoryId: 3, productId: 30, productName: "P730", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 31, categoryId: 3, productId: 31, productName: "P731", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 32, categoryId: 3, productId: 32, productName: "P732", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 33, categoryId: 3, productId: 33, productName: "P733", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 34, categoryId: 3, productId: 34, productName: "P734", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 35, categoryId: 3, productId: 35, productName: "P735", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 36, categoryId: 3, productId: 36, productName: "P736", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 37, categoryId: 3, productId: 37, productName: "P737", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 38, categoryId: 3, productId: 38, productName: "P738", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 39, categoryId: 3, productId: 39, productName: "P739", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 40, categoryId: 3, productId: 40, productName: "P740", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 41, categoryId: 3, productId: 41, productName: "P741", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 42, categoryId: 3, productId: 42, productName: "P742", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 43, categoryId: 3, productId: 43, productName: "P743", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 44, categoryId: 3, productId: 44, productName: "P744", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 45, categoryId: 3, productId: 45, productName: "P745", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 46, categoryId: 3, productId: 46, productName: "P746", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 47, categoryId: 3, productId: 47, productName: "P747", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 48, categoryId: 3, productId: 48, productName: "P748", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 49, categoryId: 3, productId: 49, productName: "P749", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 50, categoryId: 3, productId: 50, productName: "P750", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 51, categoryId: 3, productId: 51, productName: "P751", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 52, categoryId: 3, productId: 52, productName: "P752", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 53, categoryId: 3, productId: 53, productName: "P753", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 54, categoryId: 3, productId: 54, productName: "P754", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 55, categoryId: 3, productId: 55, productName: "P755", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 56, categoryId: 3, productId: 56, productName: "P756", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 57, categoryId: 3, productId: 57, productName: "P757", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 58, categoryId: 3, productId: 58, productName: "P758", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 59, categoryId: 3, productId: 59, productName: "P759", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 60, categoryId: 3, productId: 60, productName: "P760", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 61, categoryId: 3, productId: 61, productName: "P761", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 32, categoryId: 3, productId: 32, productName: "P762", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 33, categoryId: 3, productId: 33, productName: "P763", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 34, categoryId: 3, productId: 34, productName: "P764", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 35, categoryId: 3, productId: 35, productName: "P765", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 36, categoryId: 3, productId: 36, productName: "P766", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 37, categoryId: 3, productId: 37, productName: "P767", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 38, categoryId: 3, productId: 38, productName: "P768", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 39, categoryId: 3, productId: 39, productName: "P769", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 30, categoryId: 3, productId: 30, productName: "P770", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 31, categoryId: 3, productId: 31, productName: "P771", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 32, categoryId: 3, productId: 32, productName: "P772", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 33, categoryId: 3, productId: 33, productName: "P773", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 34, categoryId: 3, productId: 34, productName: "P774", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 35, categoryId: 3, productId: 35, productName: "P775", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 36, categoryId: 3, productId: 36, productName: "P776", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 37, categoryId: 3, productId: 37, productName: "P777", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 38, categoryId: 3, productId: 38, productName: "P778", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 39, categoryId: 3, productId: 39, productName: "P779", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 30, categoryId: 3, productId: 30, productName: "P780", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 31, categoryId: 3, productId: 31, productName: "P781", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 32, categoryId: 3, productId: 32, productName: "P782", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 33, categoryId: 3, productId: 33, productName: "P783", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 34, categoryId: 3, productId: 34, productName: "P784", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 35, categoryId: 3, productId: 35, productName: "P785", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 36, categoryId: 3, productId: 36, productName: "P786", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 37, categoryId: 3, productId: 37, productName: "P787", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 38, categoryId: 3, productId: 38, productName: "P788", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 39, categoryId: 3, productId: 39, productName: "P789", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 30, categoryId: 3, productId: 30, productName: "P790", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 31, categoryId: 3, productId: 31, productName: "P791", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 32, categoryId: 3, productId: 32, productName: "P792", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 33, categoryId: 3, productId: 33, productName: "P793", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 34, categoryId: 3, productId: 34, productName: "P794", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 35, categoryId: 3, productId: 35, productName: "P795", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 36, categoryId: 3, productId: 36, productName: "P796", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 37, categoryId: 3, productId: 37, productName: "P797", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 38, categoryId: 3, productId: 38, productName: "P798", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 39, categoryId: 3, productId: 39, productName: "P799", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 30, categoryId: 3, productId: 30, productName: "P800", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 31, categoryId: 3, productId: 31, productName: "P801", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 32, categoryId: 3, productId: 32, productName: "P802", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 33, categoryId: 3, productId: 33, productName: "P803", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 34, categoryId: 3, productId: 34, productName: "P804", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 35, categoryId: 3, productId: 35, productName: "P805", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 36, categoryId: 3, productId: 36, productName: "P806", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 37, categoryId: 3, productId: 37, productName: "P807", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 38, categoryId: 3, productId: 38, productName: "P808", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 39, categoryId: 3, productId: 39, productName: "P809", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 30, categoryId: 3, productId: 30, productName: "P810", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 31, categoryId: 3, productId: 31, productName: "P811", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 32, categoryId: 3, productId: 32, productName: "P812", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 33, categoryId: 3, productId: 33, productName: "P813", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 34, categoryId: 3, productId: 34, productName: "P814", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 35, categoryId: 3, productId: 35, productName: "P815", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 36, categoryId: 3, productId: 36, productName: "P816", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 37, categoryId: 3, productId: 37, productName: "P817", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 38, categoryId: 3, productId: 38, productName: "P818", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 39, categoryId: 3, productId: 39, productName: "P819", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 30, categoryId: 3, productId: 30, productName: "P820", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 31, categoryId: 3, productId: 31, productName: "P821", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 32, categoryId: 3, productId: 32, productName: "P822", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 33, categoryId: 3, productId: 33, productName: "P823", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 34, categoryId: 3, productId: 34, productName: "P824", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 34, categoryId: 3, productId: 34, productName: "P825", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 26, categoryId: 3, productId: 26, productName: "P826", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 27, categoryId: 3, productId: 27, productName: "P827", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 28, categoryId: 3, productId: 28, productName: "P828", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 29, categoryId: 3, productId: 29, productName: "P829", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 30, categoryId: 3, productId: 30, productName: "P830", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 31, categoryId: 3, productId: 31, productName: "P831", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 32, categoryId: 3, productId: 32, productName: "P832", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 33, categoryId: 3, productId: 33, productName: "P833", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 34, categoryId: 3, productId: 34, productName: "P834", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 35, categoryId: 3, productId: 35, productName: "P835", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 36, categoryId: 3, productId: 36, productName: "P836", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 37, categoryId: 3, productId: 37, productName: "P837", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 38, categoryId: 3, productId: 38, productName: "P838", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 39, categoryId: 3, productId: 39, productName: "P839", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 40, categoryId: 3, productId: 40, productName: "P840", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 41, categoryId: 3, productId: 41, productName: "P841", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 42, categoryId: 3, productId: 42, productName: "P842", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 43, categoryId: 3, productId: 43, productName: "P843", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 44, categoryId: 3, productId: 44, productName: "P844", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 45, categoryId: 3, productId: 45, productName: "P845", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 46, categoryId: 3, productId: 46, productName: "P846", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 47, categoryId: 3, productId: 47, productName: "P847", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 48, categoryId: 3, productId: 48, productName: "P848", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 49, categoryId: 3, productId: 49, productName: "P849", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 50, categoryId: 3, productId: 50, productName: "P850", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 51, categoryId: 3, productId: 51, productName: "P851", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 52, categoryId: 3, productId: 52, productName: "P852", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 53, categoryId: 3, productId: 53, productName: "P853", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 54, categoryId: 3, productId: 54, productName: "P854", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 55, categoryId: 3, productId: 55, productName: "P855", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 56, categoryId: 3, productId: 56, productName: "P856", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 57, categoryId: 3, productId: 57, productName: "P857", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 58, categoryId: 3, productId: 58, productName: "P858", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 59, categoryId: 3, productId: 59, productName: "P859", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 60, categoryId: 3, productId: 60, productName: "P860", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 61, categoryId: 3, productId: 61, productName: "P861", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 32, categoryId: 3, productId: 32, productName: "P862", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 33, categoryId: 3, productId: 33, productName: "P863", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 34, categoryId: 3, productId: 34, productName: "P864", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 35, categoryId: 3, productId: 35, productName: "P865", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 36, categoryId: 3, productId: 36, productName: "P866", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 37, categoryId: 3, productId: 37, productName: "P867", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 38, categoryId: 3, productId: 38, productName: "P868", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 39, categoryId: 3, productId: 39, productName: "P869", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 30, categoryId: 3, productId: 30, productName: "P870", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 31, categoryId: 3, productId: 31, productName: "P871", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 32, categoryId: 3, productId: 32, productName: "P872", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 33, categoryId: 3, productId: 33, productName: "P873", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 34, categoryId: 3, productId: 34, productName: "P874", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 35, categoryId: 3, productId: 35, productName: "P875", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 36, categoryId: 3, productId: 36, productName: "P876", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 37, categoryId: 3, productId: 37, productName: "P877", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 38, categoryId: 3, productId: 38, productName: "P878", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 39, categoryId: 3, productId: 39, productName: "P879", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 30, categoryId: 3, productId: 30, productName: "P880", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 31, categoryId: 3, productId: 31, productName: "P881", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 32, categoryId: 3, productId: 32, productName: "P882", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 33, categoryId: 3, productId: 33, productName: "P883", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 34, categoryId: 3, productId: 34, productName: "P884", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 35, categoryId: 3, productId: 35, productName: "P885", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 36, categoryId: 3, productId: 36, productName: "P886", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 37, categoryId: 3, productId: 37, productName: "P887", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 38, categoryId: 3, productId: 38, productName: "P888", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 39, categoryId: 3, productId: 39, productName: "P889", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 30, categoryId: 3, productId: 30, productName: "P890", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 31, categoryId: 3, productId: 31, productName: "P891", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 32, categoryId: 3, productId: 32, productName: "P892", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 33, categoryId: 3, productId: 33, productName: "P893", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 34, categoryId: 3, productId: 34, productName: "P894", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 35, categoryId: 3, productId: 35, productName: "P895", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 36, categoryId: 3, productId: 36, productName: "P896", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 37, categoryId: 3, productId: 37, productName: "P897", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 38, categoryId: 3, productId: 38, productName: "P898", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 899, categoryId: 3, productId: 899, productName: "P899", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
    { advId: '', campaignId: 900, categoryId: 3, productId: 900, productName: "P900", brandId: 4, new: 1, deal: 1, trend: 1, exclusive: 0, price: "L", imgSrc: "../../../assets/def.jpeg", isaddButton: true },
  ];
  // public categories: any;
  public categories: any;
  coupons: any;
  brands: any;
  couponss: any;
  campaignData: ({ advId: string; campaignId: number; categoryId: number; productId: number; brandId: number; imgSrc: string; userId?: undefined; } | { advId: string; longitute: number; latitude: number; campaignId: number; categoryId: number; productId: number; brandId: string; userId: number; imgSrc: string; })[] | undefined;

  constructor(
    public router: Router,
    private couponService: CouponServiceService,
    private translate: TranslateService

  ) {
    this.translate.get("translations").subscribe((data) => {
      console.log(data);

      this.catgBtn = data.searchFilterCatBtn;

      this.brandBtn = data.searchFilterBrandBtn;
      this.listBtn = data.searchFilterListBtn;

    });

  }

  ngOnInit() {
    // this.getAllCategory();
    this.catgBtn = "Category";
    this.brandBtn = "Brand";
    this.listBtn = "Sort"
    this.category = [
      {
        id: 1,
        cName: "Beauty",
      }, {
        id: 2,
        cName: "Fashion",
      }, {
        id: 3,
        cName: 'Convenience',
      }, {
        id: 4,
        cName: 'Sports',
      }, {
        id: 5,
        cName: 'Electronics',
      }, {
        id: 6,
        cName: 'Home Interiors',
      }, {
        id: 7,
        cName: 'jewellery',
      }
    ];
    this.brand = this.newBrand;
    this.couponss = this.coupons;
    this.campaignData = this.NewcampaignData;
    this.viewList = [

      {
        id: 1,
        vName: 'Price (high to low) '
      },
      {
        id: 2,
        vName: 'Price (low to high  ) '
      },
      {
        id: 3,
        vName: 'Deal '
      },
      {
        id: 4,
        vName: 'New '
      },
      {
        id: 5,
        vName: 'Trending '
      },
    ];
    
    this.getAllProduct();
    this.getAllCategory();
    this.getAllBrand();


  }

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScrollEvent() {

    const data = window.scrollY;

    if (data > 49) {
      this.headerFix = true;
    } else {
      this.headerFix = false;
    }
  }
  getAllCategory() {
    this.couponService.getAllCategory().subscribe(data => {
      this.categories = data;
      console.log(this.categories,"datatatatt");
      console.log(data);
      
      
    }, error => {
      console.log(error);
      alert('Some Error Occured');
    });
  }
  getAllProduct() {
    this.couponService.getAllProduct().subscribe(data => {
      // console.log(data);
      this.coupons = data.productresponse;
      console.log('AllProduct List is', this.coupons);
    }, error => {
      console.log(error);
    });
  }
  getAllBrand() {
    this.couponService.getAllBrand().subscribe(data => {
      // console.log(data);
      this.brand = data.response;
      console.log('all brand List:', this.brand);
    }, error => {
      console.log(error);
    });
  }

  cardListDisplay() {
    console.log('comiujsadf');
    this.show = !this.show;
  }
  shopNow(card: any) {
    localStorage.clear();
    localStorage.setItem('offer', JSON.stringify(card));
    this.router.navigate(['/offer']);
  }
  // selectCatg(data: any) {

  //   console.log(data);
  //   this.selectedCatg = data;
  //   this.catgBtn = data.cName;
  //   console.log("datatt:", data.cName);

  //   if (data.cName == "Beauty") {
  //     // const filterData = this.newBrand.filter(data => data.ct == "Beauty");
  //     const filterData1 = this.NewcampaignData.filter(data => data.categoryId == 1);

  //     // console.log(filterData);

  //     // this.brand = filterData;
  //     this.campaignData = filterData1;


  //   } else if (data.cName == "Fashion") {

  //     // const filterData = this.newBrand.filter(data => data.ct == "Fashion");
  //     const filterData1 = this.NewcampaignData.filter(data => data.categoryId == 2);
  //     // console.log(filterData);

  //     // this.brand = filterData;
  //     this.campaignData = filterData1;
  //   }
  //   else if (data.cName == "Convenience") {

  //     // const filterData = this.newBrand.filter(data => data.ct == "Convenience");
  //     const filterData1 = this.NewcampaignData.filter(data => data.categoryId == 3);

  //     // console.log(filterData);

  //     // this.brand = filterData;
  //     this.campaignData = filterData1;
  //   }
  //   else if (data.cName == "Sports") {

  //     // const filterData = this.newBrand.filter(data => data.ct == "Sports");
  //     const filterData1 = this.NewcampaignData.filter(data => data.categoryId == 4);

  //     // console.log(filterData);

  //     // this.brand = filterData;
  //     this.campaignData = filterData1;
  //   }
  //   else if (data.cName == "Electronics") {

  //     // const filterData = this.newBrand.filter(data => data.ct == "Electronics");
  //     const filterData1 = this.NewcampaignData.filter(data => data.categoryId == 5);

  //     // console.log(filterData);

  //     // this.brand = filterData;
  //     this.campaignData = filterData1;
  //   }
  //   else if (data.cName == "Home Interiors") {

  //     // const filterData = this.newBrand.filter(data => data.ct == "Home Interiors");
  //     const filterData1 = this.NewcampaignData.filter(data => data.categoryId == 6);

  //     // console.log(filterData);

  //     // this.brand = filterData;
  //     this.campaignData = filterData1;
  //   }
  //   else if (data.cName == "jewellery") {

  //     // const filterData = this.newBrand.filter(data => data.ct == "jewellery");
  //     const filterData1 = this.NewcampaignData.filter(data => data.categoryId == 7);

  //     // console.log(filterData);

  //     // this.brand = filterData;
  //     this.campaignData = filterData1;
  //   }
  //   // this.couponService.getProductByCategoryId(data.id).subscribe(
  //   //   (      res: { productresponses: any; }) => {
  //   //     console.log(res);
  //   //     this.couponss = res.productresponses;

  //   //   }, (err: any) => {
  //   //     console.log(err);
  //   //   }
  //   // );
  // }
  selectCatg(data) {
    console.log(data,"cat ddata");
    this.selectedCatg = data;
    this.catgBtn = data.categoryName;
    this.couponService.getProductByCategoryId(data.categoryId).subscribe(
      res => {
        console.log(res);
        this.coupons = res.productresponse;
      }, err => {
        console.log(err);
      }
    );
  }
  // selectBrand(data: any) {
  //   console.log("dat", data);
  //   this.selectedBrand = data;
  //   this.brandBtn = data.bName;
  //   console.log("brand", this.brandBtn);
  //   if (data.brandId == 1) {

  //     const filterData1 = this.NewcampaignData.filter(data => data.brandId == 1);
  //     this.campaignData = filterData1;

  //   }
  //   else if (data.brandId == 2) {

  //     const filterData1 = this.NewcampaignData.filter(data => data.brandId == 2);
  //     this.campaignData = filterData1;

  //   }
  //   else if (data.brandId == 3) {

  //     const filterData1 = this.NewcampaignData.filter(data => data.brandId == 3);
  //     this.campaignData = filterData1;

  //   } else if (data.brandId == 4) {
  //     const filterData1 = this.NewcampaignData.filter(data => data.brandId == 4);


  //     this.campaignData = filterData1;

  //   }
  //   else if (data.brandId == 5) {
  //     const filterData1 = this.NewcampaignData.filter(data => data.brandId == 5);


  //     this.campaignData = filterData1;

  //   }
  //   else if (data.brandId == 6) {
  //     const filterData1 = this.NewcampaignData.filter(data => data.brandId = 6);


  //     this.campaignData = filterData1;

  //   } else if (data.brandId == 7) {

  //     const filterData1 = this.NewcampaignData.filter(data => data.brandId == 7);

  //     this.campaignData = filterData1;
  //   }
  //   else if (data.brandId == 8) {

  //     const filterData1 = this.NewcampaignData.filter(data => data.brandId == 8);

  //     this.campaignData = filterData1;
  //   }
   

  //   // this.couponService.getProductByCategoryId(data.id).subscribe(
  //   //   (      res: { productresponses: any; }) => {
  //   //     console.log(res);
  //   //     this.couponss = res.productresponses;
  //   //     console.log("list from brands",this.couponss);

  //   //   }, (err: any) => {
  //   //     console.log(err);
  //   //   }
  //   // );
  // }
  selectBrand(data) {
    console.log(data);
    this.selectedBrand = data;
    this.brandBtn = data.brandName;
    this.couponService.getProductByBrandId(data.brandId).subscribe(
      res => {
        console.log(res);
        this.coupons = res.productresponse;
      }, err => {
        console.log(err);
      }
    );
  }
  selectList(data: any) {
    console.log("dat", data);
    this.selectedView = data;
    // this.viewList = data;
    this.listBtn = data.vName;
    if (data.id == 1) {

      const filterData1 = this.NewcampaignData.filter(data => data.price == "H");
      this.campaignData = filterData1;
      // const filterData2 = this.NewcampaignData.filter(data => data.price == "L");
      // this.campaignData = filterData2;


    }
    else if (data.id == 2) {

      const filterData1 = this.NewcampaignData.filter(data => data.price == "L");
      this.campaignData = filterData1;

    }
    else if (data.id == 3) {

      const filterData1 = this.NewcampaignData.filter(data => data.deal ==1);
      this.campaignData = filterData1;

    }
    else if (data.id == 4) {

      const filterData1 = this.NewcampaignData.filter(data => data.new ==1);
      this.campaignData = filterData1;

    }
    else if (data.id == 5) {

      const filterData1 = this.NewcampaignData.filter(data => data.trend ==1);
      this.campaignData = filterData1;

    }
    // this.couponService.getProductByCategoryId(data.id).subscribe(
    //   (      res: { productresponses: any; }) => {
    //     console.log(res);
    //     this.couponss = res.productresponses;
    //     console.log("list from brands",this.couponss);

    //   }, (err: any) => {
    //     console.log(err);
    //   }
    // );
  }


}
