<div class="deskBack">
  <img class="backImg" src="assets/img/backDubai2.jpg">
  <div class="headers">
    <!-- <app-header></app-header> -->
    <div class="card cardiv pt-4 pl pr pb-5">
      <div class="mb-0">
        <p class="pSign mb-0">Existing Users Please Sign In</p>
      </div>
      <div class="formStyl pt-3">
        <!-- <div class="form-group">
            <label for="mobile">Mobile Number:</label>
            <input type="mobile" class="form-control" id="mobile">
          </div> -->
        <div>
          <label class="text-uppercase input-label" for="phNo">Mobile Number<span class="">*</span>
          </label>
          <!-- <input type="phNo" class="form-control" id="email"> -->
          <!-- <div class="row mobilePart">
            <div class="part_1 row m-0 p-2" data-toggle="modal" data-target="#countryDetails">
              <span class="pl-2 country-code"> + {{selectedCountry.CountryCode}}</span>
              <i class="fa fa-chevron-down pl-2 pt-1 pr-2 country-code"></i>
            </div>
            <div class="part_2 p-2 ml-3">
              <input class="mobile pl-2 ml-1" [(ngModel)]="mobileNo" (keypress)="numberOnly($event)"
                placeholder="50 123 4567" type="text" min="10" max="15">
            </div>
          </div> -->
          <div class="row m-0 mobilePart">
            <a class="col-4 part_1 p-2" (click)="showCountryModal=true" data-toggle="modal"
              data-target="#countryDetails">
              <span class="pl-2 country-code"> + {{selectedCountry.CountryCode}}</span>&nbsp;
              <span class="drop-down">&#709;</span>
              <!-- &#8964; -->
              <!-- <i class="fa fa-chevron-down pl-2 pt-1 pr-2 country-code"></i> -->
            </a>
            <div class="col-1 p-0"></div>
            <div class="col-7 part_2 p-2">
              <input class="mobile pl-2 ml-1" [(ngModel)]="mobileNo" autocomplete="off" placeholder="50 123 4567"
                (keyup)="validateNumber()" type="number" min="10" max="15" [disabled]="disableInput" autocomplete="off">
            </div>
            <span class="m-0 text-primary sendOtp" (click)="!disableSendOTP && sendOTP()">Send OTP</span>
          </div>
        </div>
        <div class="form-group mb-0">
          <label class="input-label" for="pwd">OTP<span class="">*</span>
          </label>
          <!-- <input type="otp" class="form-control" id="pwd"> -->
          <!-- <span class="text-black pl-5">Enter OTP</span> -->
          <div class="row m-0">
            <div class="otp">
              <ng-otp-input #ngOtpInput class="otp-wrapper" (onInputChange)="onOtpChange($event)" [config]="config">
              </ng-otp-input>
              <div *ngIf="otp" class="o-t-p text-danger mt-3">Entered OTP : {{otp}}</div>
            </div>
          </div>
        </div>
        <div class="otpCaut mb-4 mt-4">
          <label class="otpLabel mb-0">Please enter OTP to verify your mobile number.</label>
        </div>
        <div class="star mb-4">
          <label><i class="starStyl" (click)="toggleIcon()"
              [className]=" starIcon ? 'fa fa-circle' : 'fa fa-circle-o'"></i>
            Keep me logged in, <u>What is this?</u></label>
        </div>
        <!-- <div>
          <a href="#">Forgot your Password?</a>
        </div> -->
        <div class="row m-0">
          <button class="loginBtn" (click)="!isDisableLogin && login()" [disabled]="isDisableLogin">
            <p class="lockP m-0">Login
              <!-- <i class="fa fa-lock lockIcon" aria-hidden="true"></i> -->
            </p>
          </button>
          <!-- <button class="btn"><i class="fa fa-lock lockIcon"></i> Home</button> -->
        </div>
        <!-- <p class="or">Or</p>
      <div class="row">
        <button class="fbLoginBtn">Login with Facebook</button>
      </div> -->
      </div>

      <div class="modal countryModal" id="countryDetails" *ngIf="showCountryModal">
        <div class="modal-dialog m-0">
          <div class="bg"></div>
          <div class="modal-content">
            <!-- Modal Header -->
            <div class="modal-header">
              <h4 class="modal-title">Select Country</h4>
              <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
              <ul class="list-unstyled">
                <li class="mt-2 p-2 border" *ngFor="let item of country" (click)="clickCountry(item)"
                  data-dismiss="modal">
                  <div class="row m-0 text-center">
                    <div class="col-2 p-0">
                      <img [src]="item.CountryIcon" alt="">
                    </div>
                    <div class="col-6 p-0">
                      {{item.Country}}
                    </div>
                    <div class="col-4 p-0">
                      + {{item.CountryCode}}
                    </div>
                  </div>
                </li>
              </ul>
            </div>

            <!-- Modal footer -->
            <div class="modal-footer">
              <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
            </div>

          </div>
        </div>
      </div>


    </div>
    <!-- <div class="footer">
    <app-footer></app-footer>
    </div> -->
    <div class="divP m-4">
      <p class="fontStyl1">Are you a tourist to Greece?</p>
      <!-- <p class="fontStyl2">&nbsp;Wifi10X</p>
          <p class="fontStyl1">&nbsp;?</p> -->
      <button class="newAccountBtn" [routerLink]="['/login/register']">Click here</button>
    </div>

  </div>
</div>
