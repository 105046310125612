import { EventEmitterService } from './../../services/EventEmitter/event-emitter.service';
import { Component, OnInit, HostListener, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CouponServiceService } from 'src/app/services/coupon/coupon-service.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  public show = false;
  public headerFix = false;
  public scrollY: string;
  public difference = 280;
  public value = 250;
  public change: EventEmitter<number> = new EventEmitter<number>();
  public coupons: any;
  public categoryId: Number;

  constructor(
    public router: Router,
    public activateRouter: ActivatedRoute,
    private couponService: CouponServiceService,
    private service: EventEmitterService
  ) { }

  ngOnInit() {
    // this.categoryId=Number(this.activateRouter.snapshot.paramMap.get('d'));

    localStorage.setItem("CategoryId", "" + this.couponService.getCategoryId());
    this.categoryId = parseInt(localStorage.getItem("CategoryId"));
    console.log("cat id-----------------", this.categoryId)
    this.goHome();
    // this.getAllProduct();
  }
  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScrollEvent(event) {
    // console.log(event);
    const data = window.scrollY;
    this.change.emit(data);
    this.service.emitScrollEvent(data);
    // console.log(data);
    if (data > 49) {
      this.headerFix = true;
    } else {
      this.headerFix = false;
    }
  }
  goHome() {
    if (this.categoryId > 0) {
      this.getProductByCategoryId(this.categoryId);
      console.log(this.categoryId,"id goes");
      
    }
    else {
      this.getAllProduct();
    }
  }
  getAllProduct() {
    this.couponService.getAllProduct().subscribe(data => {
      console.log(data,"what");
      this.coupons = data.productresponse;
      console.log('AllProduct List:', this.coupons);
    }, error => {
      console.log(error);
    });
  }
  categoryChanged(id) {
    this.getProductByCategoryId(id);
  }

  public getProductByCategoryId(data) {
    this.coupons = [];
    this.couponService.getCampaignByCategoryId(data).subscribe(result => {
      console.log(result,"camppppp");
      
      // console.log('=========== SubProductByProdId Details: ' + data + '===========');
      this.coupons = result.productresponse;
      console.log('getProductByCategoryId issss this', result.productresponse);
      localStorage.setItem("CategoryID", "" + this.couponService.getCategoryId());
      // let wishStringVar = result.responses.wishStatus;
      // var boolValue = wishStringVar.toLowerCase() == "true" ? true : false;
      // console.log('Flag Is', wishStringVar );
      // console.log('boolValue of Flag Is', boolValue );
    }, error => {
      console.log(error);
    });
  }

}
