import { Component, OnInit } from '@angular/core';
import { DownloadService } from '../download.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {



  ngOnInit(): void {
  }
  title = '#/download';
  storeId!: number;
  year!: number;
  startMonth!: number;
  endMonth!: number;

  constructor(private downloadService: DownloadService) { 
    
  }

  downloadPdf(): void {
    this.downloadService.downloadPdf(this.storeId, this.year, this.startMonth, this.endMonth);
  }

  downloadExcel(): void {
    this.downloadService.downloadExcel(this.storeId, this.year, this.startMonth, this.endMonth);
  }

}
