import { Component, OnInit, HostListener, EventEmitter } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { EventEmitterService } from "./../../services/EventEmitter/event-emitter.service";
import { CouponServiceService } from "src/app/services/coupon/coupon-service.service";
import { ToastrService } from "ngx-toastr";
// import { NgNavigatorShareService } from "ng-navigator-share";

@Component({
  selector: "app-product-details",
  templateUrl: "./product-details.component.html",
  styleUrls: ["./product-details.component.scss"],
})
export class ProductDetailsComponent implements OnInit {
  public productID: string;
  public change: EventEmitter<number> = new EventEmitter<number>();
  public scrollY: string;
  public difference = 280;
  public value = 250;
  public headerFix = false;
  public subProducts: any[];
  public height = "30vh";
  public shopBtnText: string[] = [];
  public isShopBtnEnabled: boolean[] = [];

  navigator = window.navigator as any;
  public isSubProdDescToggleOn: boolean[] = [];
  public productDetails: any;

  constructor(
    public router: Router,
    public activateRouter: ActivatedRoute,
    private service: EventEmitterService,
    private couponService: CouponServiceService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.activateRouter.queryParams.subscribe((params) => {
      this.productID = params["pid"];
      this.getProductByCategoryId(this.productID);
    });
  }

  @HostListener("window:scroll", ["$event"]) // for window scroll events
  onScrollEvent(event) {
    const data = window.scrollY;
    this.change.emit(data);
    this.service.emitScrollEvent(data);
    if (data > 49) {
      this.headerFix = true;
    } else {
      this.headerFix = false;
    }
  }
  getImage(path: string,basePath) {
    let pathName= path.split(/(\\|\/)/);
    var path1 = pathName[pathName.length-1];
    console.log('path11111',path1);
    return this.couponService.getImage(path1,basePath);
  }
  //addToWishlistBtn(var1, var2) {}
  shopNow(productId, subProduct, index) {
    if (!this.isShopBtnEnabled[index]) {
      this.shopBtnText[index] = "Shop";
      this.isShopBtnEnabled[index] = true;
    } else {
      //Shop button clicked
      this.shopBtnText[index] = "Earn AED";
      this.isShopBtnEnabled[index] = false;
      var test2 = localStorage.getItem("mobileNo");
      if (localStorage.getItem("mobileNo") === null) {
        //user not registered
        this.router.navigate(["/login/login", { id: productId }]);
      } else {
        //user already registered
        localStorage.removeItem("subProdDetails");
        localStorage.removeItem("offer");
        localStorage.setItem("subProdDetails", JSON.stringify(subProduct));
        localStorage.setItem("offer", JSON.stringify(productId));
        this.router.navigate(["/offer"]);
      }
    }
  }
  openShare1() {}
  addToWishlistBtn(productId, subProduct) {
    console.log("valueeeee isss", productId, subProduct);

    if (localStorage.getItem("mobileNo") === null) {
      this.router.navigate(["/login/login"]);
    }
    let mob1 = +localStorage.getItem("mobNo").substring(0);
    const data = {
      //'prodId': var1,
      //'subproductId': var2

      mobileNumber: mob1,
      subproductId: subProduct.id,
    };
    this.couponService.addToWishlist(data).subscribe(
      (data) => {
        console.log(data);
        let message = "";
        if (subProduct.wishStatus.toString().toLowerCase() == "true") {
          //it was already added the user has decided to remove from the wishlist
          message = "Removed from wishList";
          //changing value in local to show the status
          subProduct.wishStatus = "false";
        } else {
          message = "Added in wishList";
          //changing value in local to show the status
          subProduct.wishStatus = "false";
        }
        this.toastr.success("", message, { timeOut: 1600 });
        //this.couponsList = data.responseList;
      },
      (error) => {
        this.toastr.error("", "Something went wrong", { timeOut: 1600 });
      }
    );
  }

  public getProductByCategoryId(data) {
    this.couponService.getSubProductByProdId(data).subscribe(
      (result) => {
        this.subProducts = result.responses;
        this.productDetails = result["productResponse"];
        console.log("prdDetails");
        console.log(this.productDetails);
        this.subProducts.push(result.responses[0]);
        this.setDefaultValuesForProductElem(this.subProducts.length);
        // localStorage.setItem("CategoryId", result.productresponses[0].id);productResponse
      },
      (error) => {
        console.log(error);
      }
    );
  }
  private setDefaultValuesForProductElem(lengthOfProduct: Number) {
    for (var i = 0; i < lengthOfProduct; i++) {
      this.shopBtnText.push("Earn AED");
      this.isShopBtnEnabled.push(false);
      this.isSubProdDescToggleOn.push(false);
    }
  }
  shareApi() {
    if (this.navigator.share) {
      this.navigator
        .share({
          title: "Shop10x.com",
          url: this.router.url,
          text:
            "Hey i found this interesting offer.Visit this link to check the offer" +
            this.router.url,
        })
        .then(() => {
          console.log("Thanks for sharing!");
        })
        .catch(console.error);
    } else {
      this.toastr.error("", "Something went wrong", { timeOut: 1600 });
    }
  }
}
