import { Component, EventEmitter, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CouponServiceService } from 'src/app/services/coupon/coupon-service.service';
import { EventEmitterService } from 'src/app/services/EventEmitter/event-emitter.service';

@Component({
  selector: 'app-malls',
  templateUrl: './malls.component.html',
  styleUrls: ['./malls.component.scss']
})
export class MallsComponent implements OnInit {
  public show = false;
  public headerFix = false;
  public scrollY: string;
  public change: EventEmitter<number> = new EventEmitter<number>();
  public coupons: any;
  public stores :any;
  public categoryId: Number;
  public paramData:any;
  constructor( public router: Router,
    public activateRouter: ActivatedRoute,
    private couponService: CouponServiceService,
    private service: EventEmitterService) { }

  ngOnInit() {
    
    this.activateRouter.queryParams.subscribe(param => {
     
      this.paramData = param.mId;
      console.log(this.paramData);
      this.getAllStores(this.paramData);
      this.getCampaignByMallId(this.paramData);
    })
  }
  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScrollEvent(event) {
    // console.log(event);
    const data = window.scrollY;
    this.change.emit(data);
    this.service.emitScrollEvent(data);
    // console.log(data);
    if (data > 49) {
      this.headerFix = true;
    } else {
      this.headerFix = false;
    }
  }

  //TO GET STORE Sujit 09.02.22

  getAllStores(id) {
    this.couponService.getAllStores(id).subscribe(result=> {
      this.stores = result['storeMstResponses'];
    })
  }

  getCampaignByMallId(id) {
    this.couponService.getCampaignByMallId(id).subscribe(result => {
      this.coupons = result['responses'].campaignResponses;
    }) 
  }

  getCampaignByStoreId(id) {
    this.couponService.getCampaignByStoreId(id).subscribe(result => {
      this.coupons =result['responses'].campaignResponses;
    })
  }

  storeChange(id) {
    console.log("iddd",id);
    this.getCampaignByStoreId(id);
  }

}
