import { Category } from 'src/app/data/data';
import { Location } from '@angular/common';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppConstants } from 'src/app/app.constants';
import { CouponServiceService } from 'src/app/services/coupon/coupon-service.service';

@Component({
  selector: 'app-headers',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public menuCrossImg = 'assets/img/menuicon.svg';
  @Output() LanguageChanged = new EventEmitter<any>();
  public menuToggle = false;
  public languages = AppConstants.LANGUAGES;
  public language:any;
  isLoggedIn:any;
  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private couponService:CouponServiceService,
    private location:Location,
    private translate: TranslateService
  ) {
   let temp = localStorage.getItem('mobileNo');
    if(temp) {
      this.isLoggedIn = true;
    } else {
      this.isLoggedIn = false;
    }
    this.translate.get("translations").subscribe((data) => {
      // console.log(data);
    })
  }

  ngOnInit() {
    if (this.router.url === '/searchMenu') {
      this.menuToggle = true;
    } else {
      this.menuToggle = false;
    }

  }

  changeLanguage(item) {
    
    let temp = item;
    console.log("item",item);
    this.translate.use(temp);
    localStorage.setItem('language', item);
    this.LanguageChanged.emit(item);
    // this.reloadCurrentRoute();
    
  }
  reloadCurrentRoute() {
    let currentUrl = this.router.url;
  
    this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
      console.log("currentUrl",currentUrl);
      this.router.navigate([currentUrl]);
    });
  }
  // categoryPg() {
  //   this.router.navigate(['/category']);
  // }
  wallet() {
    let temp=localStorage.getItem("mobileNo");
    console.log("temppppp",temp);
    if(temp) {
      this.router.navigate(['/wallet']);
    } else {
      this.router.navigate(['/login']);
    }
  }

  async toggleMenu() {
    this.menuToggle = !this.menuToggle;
    if (this.menuToggle === true) {
      this.router.navigate(['/searchMenu']);
    } else {
      // this.router.navigate(['/category']);
      this.location.back();

    }
  }
  sendAuthorizationLink() {
    let data = {
        "campaignId": localStorage.getItem('campaignId'),
        "token": localStorage.getItem("wifi-token")
    };
    this.couponService.getAuthorizationLink(data).subscribe(
        response => {

            if (response['status'] == 200) {
                //alert("Ack sent successfully ");
                this.resetLocalRedirectionData();
                window.localStorage.setItem("isTimerRequired", "false");
                
                window.location.href = response['authorizationUrl'];
            } else {
                alert("Connection Failed");
               
            }

        }, error => {
            console.log(error);
            alert("Connection Failed");
        }
    );
  }
  resetLocalRedirectionData() {
    window.localStorage.setItem("redirected-From", "");

}
}
