import { environment } from './../environments/environment';
import { Injectable } from '@angular/core';

@Injectable()
export class AppConstants {
    // DEVELOPMENT IP
    public static get BASE_API_URL(): string {
        if (environment.production) {
            console.log(window.location.href);
            const URL = (window.location.href).split('/');
            const CUR_SERVER_LOC = URL[0] + '//' + URL[2];
            // console.log((window.location.href).split('/index.html')[0])
            console.log(CUR_SERVER_LOC);
            localStorage.setItem('serverLocation', JSON.stringify(CUR_SERVER_LOC));
            // PRODUCTION SERVER PATH
            const SERVER_LOC = JSON.parse(localStorage.getItem('serverLocation'));
            // return 'https://' + SERVER_LOC + '//'; Previously
            // return SERVER_LOC + '//';
            return 'https://place10x.org/wifi10xapi/';
            // return 'https://place10x.silicontechlab.com/wifi10xapi/';
            // return 'http://203.193.144.93:8080/wifi10xapi/';
        } else {
            // DEVELOPMENT SERVER PATH
        //    return 'https://place10x.silicontechlab.com/wifi10xapi/';  // Production
           return 'https://place10x.org/wifi10xapi/';
            // return 'http://203.193.144.93:8080/wifi10xapi/';  2// Production
            // return  'http://192.168.0.90:8086/wifi10xapi/';
            //  return 'http://192.168.0.96:8088/wifi10xapi/';
            // return 'https://localhost:8080/wifi10xapi';
            // http://localhost:8080/dspservapi/
        }
    }
    public static get BASE_API_URL1(): string {
        if (environment.production) {
            console.log(window.location.href);
            const URL = (window.location.href).split('/');
            const CUR_SERVER_LOC = URL[0] + '//' + URL[2];
            // console.log((window.location.href).split('/index.html')[0])
            console.log(CUR_SERVER_LOC);
            localStorage.setItem('serverLocation', JSON.stringify(CUR_SERVER_LOC));
            // PRODUCTION SERVER PATH
            const SERVER_LOC = JSON.parse(localStorage.getItem('serverLocation'));
            console.log(SERVER_LOC,"server");
            
            
            // return 'https://' + SERVER_LOC + '//'; Previously
            // return SERVER_LOC + '//';
            // return 'https://place10x.org/dspservapi/';
            // return SERVER_LOC + '/dspservapi/';
            //    return 'https://place10x.com/dspservapi/';
            // return 'http://place10x.com/dspservapi/';
                // return 'http://65.0.193.133:8081/dspservapi/';
            // return 'https://place10x.com:8081/dspservapi/';
            return 'https://place10x.com/dspservapi/';

          
        } else {
            // DEVELOPMENT SERVER PATH

            //    return 'http://localhost:8080/dspservapi/';
            return 'https://place10x.com/dspservapi/';
            // return 'https://place10x.com/dspservapi/';

            //    return 'https://place10x.com/dspservapi/';

          
        }   
    }
    // DEVELOPMENT IP
    public static get BASE_API_URL_WIFI(): string {
        if (environment.production) {
            console.log(window.location.href);
            const URL = (window.location.href).split('/');
            const CUR_SERVER_LOC = URL[0] + '//' + URL[2];
            // console.log((window.location.href).split('/index.html')[0])
            console.log(CUR_SERVER_LOC);
            localStorage.setItem('serverLocation', JSON.stringify(CUR_SERVER_LOC));
            // PRODUCTION SERVER PATH
            const SERVER_LOC = JSON.parse(localStorage.getItem('serverLocation'));
            // return 'https://' + SERVER_LOC + '//'; Previously
            // return SERVER_LOC + '//';
            // return 'https://place10x.silicontechlab.com/wifi10x/';
            return 'https://place10x.org/wifi10x/';
        } else {
            // DEVELOPMENT SERVER PATH
            // return 'https://shop10x.app/wifi10x/';  // Production
            // return 'https://203.193.144.93:8080/wifi10xapi/';
            // return 'https://place10x.com/wifi10xapi/' ;  // Production
            // return 'https://place10x.silicontechlab.com/wifi10x/';
            return 'https://place10x.org/wifi10x/';

        }
    }

    getServerLocation() {
        // const CUR_SERVER_LOC = (window.location.href).split('#')[0].split('/')[2];
        console.log(window.location.href);
        const URL = (window.location.href).split('/');
        const CUR_SERVER_LOC = URL[0] + '//' + URL[2];
        // console.log((window.location.href).split('/index.html')[0])
        console.log(CUR_SERVER_LOC);
        localStorage.setItem('serverLocation', JSON.stringify(CUR_SERVER_LOC));
    }
    public static LANGUAGES = [
        { displayText: "English", apiCode: "en", frontendCode: "en" },
        { displayText: "Greek", apiCode: "grk", frontendCode: "grk" },
      ];
}
