<div class="qr-scan-area" style="text-align: center;">
  <!-- This is the npm package in-charge of scanning the QR -->
  <zxing-scanner #scanner [autofocusEnabled]="true" (scanSuccess)="onCodeResult($event)" *ngIf="!qrResultString">
  </zxing-scanner>


  <section class="results" *ngIf="qrResultString ">
    <div *ngIf="!actv2" style=" font-size:15px; color: #008000;">
      <div class="bodyres">
        <div class="checkmark2 " >
          <!--<i class="checkmark ires1" style="color: rgb(104, 17, 0)">&#x274c;</i>-->
         <i class=" ires1  fa fa-times " aria-hidden="true" ></i>

         <!--  <span class="glyphicon ires1" aria-hidden="true"></span>-->
        </div>
        <h1 class="h1res" style="color:black">Oops !</h1>
        <p class="pres">Invalid QR Code.</p>
      </div>
    </div>

    <div *ngIf="actv2" style=" font-size:15px; color: #008000;">
      <div class="bodyres">
        <div class="checkmark1 ">
        <!-- <i class="checkmark ires" style="color: #88B04B;">✓</i>-->
      <i class="fa fa-check ires" aria-hidden="true"></i>
      
        </div>
        <h1 class="h1res" style="color: #1c1d1a;">Congrats !</h1>
        <p class="pres">Valid QR Code. </p>
      </div>
    </div>
</section></div>