import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-search-menu',
  templateUrl: './search-menu.component.html',
  styleUrls: ['./search-menu.component.scss']
})
export class SearchMenuComponent implements OnInit {

  public menuToggle = false;
  public date=new Date();
  public month = this.date.toLocaleString('default', { month: 'long' });
  public year = this.date.getFullYear();
  public showScan:boolean=false;

  public stores = [ 
    {
      storeId: 1,
      storeName:"Bawadi Mall"
    },
    {
      storeId: 2,
      storeName:"Jumeirah"
    }
  ]

  constructor( private router:Router, private location:Location) { }

  ngOnInit() {
  }

  async toggleMenu() {
    this.menuToggle = !this.menuToggle;
    if (this.menuToggle === true) {
      this.router.navigate(['/category']);
    } else {
      // this.router.navigate(['/category']);
      this.location.back();

    }
  }

  
  scan()
  {
    this.showScan=!this.showScan;
   
  }

}
