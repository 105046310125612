import { Component, OnInit, HostListener } from '@angular/core';
import { CouponServiceService } from 'src/app/services/coupon/coupon-service.service';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss']
})
export class LocationComponent implements OnInit {

  public headerFix = false;
  public difference = 280;
  public value = 250;
  public coupons = [
    {
      id: 4,
      productName: 'Tops',
      productDesc: 'Flat 25% off',
      productPrice: 3000,
      productOfferPrice: 2500,
      productWeight: 200,
      productWeightMeasure: 'gram',
      productManufacturer: 'AMBH',
      productSpecification1: 'Cotton',
      productSpecification2: 'Printed',
      productSpecification3: 'Printed',
      productSpecification4: 'Printed',
      productSpecification5: 'Printed',
      productOverview1: 'Red',
      productOverview2: 'Light',
      productOverview3: 'Light',
      productOverview4: 'Cotton',
      productOverview5: 'Light',
      subProducts: [
        {
          subProductName: 'Floral Tops',
          subProductDesc: 'A premium denim brand thats earned acclaim for its fine fits, fabrics and finishes.',
          subProductPrice: 2500,
          subProductOfferPrice: 2250,
          subProductWeight: 100,
          subProductWeightMeasure: 'gram',
          subProductManufacturer: 'AMBH',
          subProductSpecification1: 'Cotton',
          subProductOverview1: 'Floral',
          size: 'M',
          imgSrc: 'Video/Coupon/Product/abmh.png',
          extraPrice: 'Rs.250',
          button: '\'Earn ! AED 300\'',
          productId: 4
        },
        {
          subProductName: 'Formal Tops',
          subProductDesc: 'A premium denim brand thats earned acclaim for its fine fits, fabrics and finishes.',
          subProductPrice: 2500,
          subProductOfferPrice: 2000,
          subProductWeight: 100,
          subProductWeightMeasure: 'gram',
          subProductManufacturer: 'AMBH',
          subProductSpecification1: 'Cotton',
          subProductOverview1: 'Formal',
          size: 'XXL',
          imgSrc: 'Video/Coupon/Product/abmh.png',
          extraPrice: 'Rs.500',
          button: '\'Earn ! AED 300\'',
          productId: 4
        }
      ],
      imgSrc: 'Video/Coupon/Product/abmh.png',
      extraPrice: 'Rs.500',
      buttonName: 'Earn Extra! Up to AED 300',
      catId: 1,
      subcatId: 1,
      brandId: 4,
      discountId: 0
    },
    {
      id: 6,
      productName: 'Jimmy Choo Shoes',
      productDesc: '125 AED discount',
      productPrice: 2200,
      productOfferPrice: 2000,
      productWeight: 200,
      productWeightMeasure: 'gram',
      productManufacturer: 'clymb',
      productSpecification1: 'Canvas',
      productSpecification2: 'Synthetic',
      productSpecification3: 'comfort',
      productSpecification4: 'washable',
      productSpecification5: 'good',
      productOverview1: 'White Colour',
      productOverview2: 'Good',
      productOverview3: 'Good',
      productOverview4: 'Awesome',
      productOverview5: 'comfortable',
      subProducts: [],
      imgSrc: 'Video/Coupon/Product/jimmy.jpg',
      extraPrice: 'Rs.200',
      buttonName: 'Earn Extra! Up to AED 300',
      catId: 1,
      subcatId: 1,
      brandId: 2,
      discountId: 0
    },
    {
      id: 8,
      productName: 'Moncet',
      productDesc: '125 AED discount',
      productPrice: 2200,
      productOfferPrice: 2000,
      productWeight: 200,
      productWeightMeasure: 'gram',
      productManufacturer: 'moncet',
      productSpecification1: 'moncet',
      productSpecification2: 'moncet',
      productSpecification3: 'comfort',
      productSpecification4: 'moncet',
      productSpecification5: 'good',
      productOverview1: 'White Colour',
      productOverview2: 'Good',
      productOverview3: 'Good',
      productOverview4: 'Awesome',
      productOverview5: 'comfortable',
      subProducts: [],
      imgSrc: 'Video/Coupon/Product/moncet.jpg',
      extraPrice: 'Rs.200',
      buttonName: 'Earn Extra! Up to AED 300',
      catId: 1,
      subcatId: 1,
      brandId: 2,
      discountId: 0
    },
    {
      id: 13,
      productName: 'Black Cosmatics',
      productDesc: '125 AED discount',
      productPrice: 2200,
      productOfferPrice: 2000,
      productWeight: 200,
      productWeightMeasure: 'gram',
      productManufacturer: 'Black',
      productSpecification1: 'Black',
      productSpecification2: 'Black',
      productSpecification3: 'comfort',
      productSpecification4: 'Black',
      productSpecification5: 'good',
      productOverview1: 'White Colour',
      productOverview2: 'Good',
      productOverview3: 'Good',
      productOverview4: 'Awesome',
      productOverview5: 'comfortable',
      subProducts: [],
      imgSrc: 'Video/Coupon/Product/black.jpg',
      extraPrice: 'Rs.200',
      buttonName: 'Earn Extra! Up to AED 300',
      catId: 1,
      subcatId: 1,
      brandId: 2,
      discountId: 0
    },
    {
      id: 14,
      productName: 'Samsung Galaxy Z Flip',
      productDesc: '125 AED discount',
      productPrice: 2200,
      productOfferPrice: 2000,
      productWeight: 200,
      productWeightMeasure: 'gram',
      productManufacturer: 'Samsung',
      productSpecification1: 'Samsung',
      productSpecification2: 'Samsung',
      productSpecification3: 'comfort',
      productSpecification4: 'Samsung',
      productSpecification5: 'good',
      productOverview1: 'White Colour',
      productOverview2: 'Good',
      productOverview3: 'Good',
      productOverview4: 'Awesome',
      productOverview5: 'comfortable',
      subProducts: [],
      imgSrc: 'Video/Coupon/Product/galaxyZ.png',
      extraPrice: 'Rs.200',
      buttonName: 'Earn Extra! Up to AED 300',
      catId: 1,
      subcatId: 1,
      brandId: 2,
      discountId: 0
    },
    {
      id: 20,
      productName: 'jumeirah',
      productDesc: '125 AED discount',
      productPrice: 2200,
      productOfferPrice: 2000,
      productWeight: 200,
      productWeightMeasure: 'gram',
      productManufacturer: 'jumeirah',
      productSpecification1: 'jumeirah',
      productSpecification2: 'jumeirah',
      productSpecification3: 'jumeirah',
      productSpecification4: 'jumeirah',
      productSpecification5: 'good',
      productOverview1: 'White Colour',
      productOverview2: 'Good',
      productOverview3: 'Good',
      productOverview4: 'Awesome',
      productOverview5: 'comfortable',
      subProducts: [],
      imgSrc: 'Video/Coupon/Product/jumeirah.jpg',
      extraPrice: 'Rs.200',
      buttonName: 'Earn Extra! Up to AED 300',
      catId: 1,
      subcatId: 1,
      brandId: 2,
      discountId: 0
    },
    {
      id: 23,
      productName: 'legoLand',
      productDesc: '125 AED discount',
      productPrice: 2200,
      productOfferPrice: 2000,
      productWeight: 200,
      productWeightMeasure: 'gram',
      productManufacturer: 'legoLand',
      productSpecification1: 'legoLand',
      productSpecification2: 'legoLand',
      productSpecification3: 'legoLand',
      productSpecification4: 'legoLand',
      productSpecification5: 'good',
      productOverview1: 'White Colour',
      productOverview2: 'Good',
      productOverview3: 'Good',
      productOverview4: 'Awesome',
      productOverview5: 'comfortable',
      subProducts: [],
      imgSrc: 'Video/Coupon/Product/legoLand.jpg',
      extraPrice: 'Rs.200',
      buttonName: 'Earn Extra! Up to AED 300',
      catId: 1,
      subcatId: 1,
      brandId: 2,
      discountId: 0
    },
    {
      id: 29,
      productName: 'nivea',
      productDesc: '125 AED discount',
      productPrice: 2200,
      productOfferPrice: 2000,
      productWeight: 200,
      productWeightMeasure: 'gram',
      productManufacturer: 'nivea',
      productSpecification1: 'nivea',
      productSpecification2: 'nivea',
      productSpecification3: 'nivea',
      productSpecification4: 'nivea',
      productSpecification5: 'good',
      productOverview1: 'White Colour',
      productOverview2: 'Good',
      productOverview3: 'Good',
      productOverview4: 'Awesome',
      productOverview5: 'comfortable',
      subProducts: [],
      imgSrc: 'Video/Coupon/Product/nivea.jpg',
      extraPrice: 'Rs.200',
      buttonName: 'Earn Extra! Up to AED 300',
      catId: 1,
      subcatId: 1,
      brandId: 2,
      discountId: 0
    },
  ];

  constructor(
    private couponService: CouponServiceService
  ) { }

  ngOnInit() {
    this.getLocationWiseCoupon();
  }

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScrollEvent(event) {
    // console.log(event);
    const data = window.scrollY;
    // this.change.emit(data);
    // this.service.emitScrollEvent(data);
    // console.log(data);
    if (data > 49) {
      this.headerFix = true;
    } else {
      this.headerFix = false;
    }
  }
  getLocationWiseCoupon() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const longitude = '' + position.coords.longitude;
        const latitude = '' + position.coords.latitude;
        console.log(position);
        const data = {
          longitude,
          latitude
        };
        this.couponService.getLocationWiseCoupon(data).subscribe(
          res => {
            console.log(res);
          }, err => {
            console.log(err);
            alert('Some error Occurred');
          }
        );
      });
    } else {
      console.log('No support for geolocation');
    }
  }
}
