<div class="headers">
  <div class="header" [ngStyle]="{ 'background-color': showLogo ? '#ffffff' : '#000000' }">
    <div class="row fstLine m-0 " [ngStyle]="{ color: showLogo ? '#000000' : '#ffffff' }">
      <div class="col-4 p-0 fontStyle">
        <p>{{ "translations.earnYouHaveEarnedText" | translate }}</p>
        <!-- <p>You Have earned</p> -->
        <p>
          <b>{{ "translations.earnCurrencyText" | translate }} 1000</b>
        </p>
      </div>
      <div class="col-4 p-0 fontStyle" *ngIf="!showLogo">
        <p>{{month}}</p>
        <!-- <p>April</p> -->
        <p>{{year}}</p>
      </div>
      <div class="col-4 p-0 fontStyle" *ngIf="showLogo">
        <img class="logoImg" src="assets/img/wifi10xlogo.png" alt="" [routerLink]="['/category']" />
      </div>
      <div class="col-4 p-0 fontStyle">
        <p>{{ "translations.earnYouhaveClaimed" | translate }}</p>
        <!-- <p>You have claimed</p> -->
        <p>
          <b>{{ "translations.earnCurrencyText" | translate }} 700</b>
        </p>
      </div>
    </div>
    <!-- <div class="row secdLine">
      <div class="col-4 fontStyle">
        <p>AED 1000</p>
      </div>
      <div class="col-4 fontStyle">
        <p>2020</p>
      </div>
      <div class="col-4 fontStyle">
        <p>AED 400</p>
      </div>
    </div> -->
  </div>
</div>