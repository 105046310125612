import { Component, OnInit, Input, HostListener, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { EventEmitterService } from 'src/app/services/EventEmitter/event-emitter.service';
import { CouponServiceService } from 'src/app/services/coupon/coupon-service.service';
import { ToastrService } from 'ngx-toastr';
import { Logging } from 'src/app/data/logging.model';
import { UserActivity } from 'src/app/data/user-activity.enum';
import { LoggingService } from 'src/app/services/logging.service';

@Component({
  selector: 'app-tiles-template',
  templateUrl: './tiles-template.component.html',
  styleUrls: ['./tiles-template.component.scss']
})
export class TilesTemplateComponent implements OnInit {
  @Input() campaignData: any;
  @ViewChild('scrollLock') scrollLock: ElementRef
  public height = '30vh';
  public shopBtnText: string[] = [];
  public isShopBtnEnabled: boolean[] = [];
  public isSubProdDescToggleOn: boolean[] = [];
  private startTime= new Date();
  navigator = window.navigator as any;
  public couponCode= "WWWWWyyy";
  public claimCard:any;
  public claimModalOpen:boolean=false;
  public isClaimButton:boolean = true;
  public showCode:boolean= false;
  public shareModal :boolean = false;
  public showMoreText:boolean =true;
  
  public desc = "BY PROVIDING THIS MOBILE NUMBER YOU CONFIRM YOU HAVE THE RECIPIENTS APPROVAL TO SHARE THE SAME."
  

  constructor(public router: Router,
    private service: EventEmitterService,
    private couponService: CouponServiceService,
    private toastr: ToastrService,
    private loggingService: LoggingService) { }

  ngOnInit() {
    
    const data = window.scrollY;
    console.log("Scrollinggggggg",data);
    let startTime = new Date();
    this.setDefaultValuesForProductElem(this.campaignData.length);
    let endTime = new Date();
    let userActivity: Logging[] = [];
    this.campaignData.forEach(element => {
      userActivity.push({
        advId: '',
        longitute:localStorage.getItem("Longitude"),
        latitude:localStorage.getItem("Latitude"),
        campaignId: element.campainMgmtId,
        categoryId:element.catID,
        productId: element.productId,
        subProductId: '',
        userId: localStorage.getItem("userId"),
        userAction: UserActivity[UserActivity.FRONTPAGE_B_TILES_DISPLAY],
        startTime: startTime.valueOf(),
        endTime: endTime.valueOf(),
        duration: (endTime.valueOf() - startTime.valueOf())
      });
    });


    this.loggingService.saveUserActivity(userActivity).subscribe();

  }
  clBtnzClicked(claimCard) {
  
    this.isClaimButton= !this.isClaimButton;
    this.toastr.warning("Please click once more to reconfirm . Once reconfirmed the voucher will be considered used")
  }

  claimBTNClicked(card) {
    localStorage.setItem("cardDetails",JSON.stringify(card));
    localStorage.removeItem("subProdDetails");
    this.router.navigate(["/offer"],{ queryParams: { id: card.id }});
   
    this.claimModalOpen= true;
    console.log("temppppppp",card);
    this.claimCard=card;
  }
  claimsubproductBTNClicked(pcard) {
    localStorage.setItem("subProdDetails",JSON.stringify(pcard));
    localStorage.removeItem("cardDetails");
    this.router.navigate(["/offer"],{ queryParams: { id: pcard.id }});
  }
  clBtnzClickedAgain(card) {
    this.showCode = true;
    
  }

  claimModalDismiss () {
    this.showCode = false;
    this.isClaimButton = true;
  }

  walletBtnclicked () {
    this.toastr.success("added to wallet successfully");
  }
  @HostListener("window:scroll", [])
  onScroll(): void {
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
      // Load Your Data Here
      console.log("datatatatatatatatat");
    }
  }
  onScroll1(event) {
    console.log('scrolling');
  }
  getImage(path: string,basePath) {
    let pathName= path.split(/(\\|\/)/);
     var path1 = pathName[pathName.length-1];
    // console.log(pathName);
    // console.log('path11111',path1);
    return this.couponService.getImage(path1,basePath);
  }
  public renderImage( id: number ): string {
    return this.couponService.getImagee( id);
}
  private setDefaultValuesForProductElem(lengthOfProduct: Number) {
    for (var i = 0; i < lengthOfProduct; i++) {
      this.shopBtnText.push("Earn AED");
      this.isShopBtnEnabled.push(false);
      this.isSubProdDescToggleOn.push(false);
    }
  }

  public minusBtnClicked(i) {
    let temp = document.getElementById(i);
    console.log(temp);
    temp.click();
  }

  shopNow(productId, subProduct, index, card) {
    console.log("bhfbjebfuiehfiehfi",productId);
    let endTime = new Date();

    if (!subProduct.isShopBtnEnabled) {
      subProduct.isShopBtnEnabled = true;
    } else {
      // Shop button clicked
      this.shopBtnText[index] = "Earn EUR";
    }
      let userActivity: Logging[] = [];
      console.log(this.startTime);
      console.log(endTime.getTime())
      let campaignId = card.campainMgmtId;
      userActivity.push({
        advId: '',
        longitute:localStorage.getItem("Longitude"),
        latitude:localStorage.getItem("Latitude"),
        campaignId: JSON.stringify(card.campainMgmtId),
        categoryId:JSON.stringify(card.catId),
        productId: JSON.stringify(productId),
        subProductId: JSON.stringify(subProduct.id),
        userId: localStorage.getItem("userId"),
        userAction: UserActivity[UserActivity.USER_CLICKED_SHOP_NOW],
        startTime: this.startTime.getTime(),
        endTime:  endTime.getTime(),
        duration: endTime.getTime() - this.startTime.getTime()
      });
      console.log("UserActivity",userActivity);



      this.loggingService.saveUserActivity(userActivity).subscribe();

      subProduct.isShopBtnEnabled = false;
      var test2 = localStorage.getItem("mobileNo");
      if (localStorage.getItem("mobileNo") === null) {
        //user not registered
        this.router.navigate(["/login/login"]);
      } else {
        //user already registered
        localStorage.removeItem("subProdDetails");
        localStorage.removeItem("offer");
        localStorage.setItem("subProdDetails", JSON.stringify(subProduct));
        localStorage.removeItem("cardDetails");
        // localStorage.setItem("offer", JSON.stringify(productId));
        // localStorage.setItem("subprodId", subProduct.id);
        // localStorage.setItem("subprodPrice", subProduct.subProductPrice);
        this.router.navigate(["/offer"],{ queryParams: { id: subProduct.id }});
      // }
    }
  }
  clickMoreText() {
    this.desc = '';
    this.showMoreText = !this.showMoreText
    if(!this.showMoreText) {
      this.desc ="BY PROVIDING THIS MOBILE NUMBER YOU CONFIRM YOU HAVE THE RECIPIENTS APPROVAL TO SHARE THE SAME. BY ACCEPTING OUR TOS YOU AGREE TO HAVE READ AND UNDERSTAND HOW THE SERVICE IS PROVIDED."
    } else {
      this.desc = "BY PROVIDING THIS MOBILE NUMBER YOU CONFIRM YOU HAVE THE RECIPIENTS APPROVAL TO SHARE THE SAME."
    }
  }

  toggleAcc (card,event, index,tr:HTMLElement) {
  //  tr.scrollIntoView();
  //  tr.addEventListener('mouseOver')
    console.log("Sujit");
    // this.scrollLock.nativeElement.scrollTo(0, 0);
    let endTime = new Date();
    console.log("toggleAcc");
    let userActivity: Logging[] = [];
      console.log(this.startTime);
      console.log(endTime.getTime())
      let campaignId = card.campainMgmtId;
      userActivity.push({
        advId: '',
        longitute:localStorage.getItem("Longitude"),
        latitude:localStorage.getItem("Latitude"),
        campaignId: JSON.stringify(card.campainMgmtId),
        categoryId:JSON.stringify(card.catId),
        productId: card.id ? card.id: card.productId,
        subProductId: '',
        userId: localStorage.getItem("userId"),
        userAction: UserActivity[UserActivity.USER_EXPANDED_THE_TILE],
        startTime: this.startTime.getTime(),
        endTime:  endTime.getTime(),
        duration: endTime.getTime() - this.startTime.getTime()
      });
      console.log("UserActivity",userActivity);
      if (!card.isActive){
        this.loggingService.saveUserActivity(userActivity).subscribe();
      }
      
  }
  
 

  toggleAccordian(card,event, index) {
   
    var element = event.target;
    console.log('index---->', index);
    console.log("fsdfrxrfcghvhy", this.campaignData[index])
    this.campaignData.forEach((element, curIndex) => {
      if (index != curIndex) {
        element.isActive = false;
      } else {
        if (element.isActive) {
          element.isActive = false;
        } else {
          element.isActive = true;
        }

      }
    });

  }
  shareApi(card) {
    let endTime = new Date();
    
    // Shop button clicked
    // this.shopBtnText[index] = "Earn AED";
    let userActivity: Logging[] = [];
    let campaignId = card.campainMgmtId;
    userActivity.push({
      advId: '',
      longitute:localStorage.getItem("Longitude"),
      latitude:localStorage.getItem("Latitude"),
      campaignId: JSON.stringify(card.campainMgmtId),
      categoryId:JSON.stringify(card.catId),
      productId: JSON.stringify(card.productId),
      subProductId: '',
      userId: localStorage.getItem("userId"),
      userAction: UserActivity[UserActivity.USER_SHARED],
      startTime: this.startTime.getTime(),
      endTime:  endTime.getTime(),
      duration: endTime.getTime() - this.startTime.getTime()
    });
    console.log("UserActivity",userActivity);
    


    this.loggingService.saveUserActivity(userActivity).subscribe();

    if (this.navigator.share) {
      this.navigator
        .share({
          title: "place10x.com",
          url: this.router.url,
          text:
            "Hey i found this interesting offer.Visit this link to check the offer"
        })
        .then(() => {
          console.log("Thanks for sharing!");
        })
        .catch(console.error);
    } else {
      this.toastr.error("", "Something went wrong", { timeOut: 1600 });
    }
  }
  shareApi1(card,subproduct) {
    let endTime = new Date();
    //Shop button clicked
    //this.shopBtnText[index] = "Earn AED";
    let userActivity: Logging[] = [];
    let campaignId = card.campainMgmtId;
    userActivity.push({
      advId: '',
      longitute:localStorage.getItem("Longitude"),
      latitude:localStorage.getItem("Latitude"),
      campaignId: JSON.stringify(card.campainMgmtId),
      categoryId:JSON.stringify(card.catId),
      productId: JSON.stringify(card.productId),
      subProductId: JSON.stringify(subproduct.id),
      userId: localStorage.getItem("userId"),
      userAction: UserActivity[UserActivity.USER_SHARED],
      startTime: this.startTime.getTime(),
      endTime:  endTime.getTime(),
      duration: endTime.getTime() - this.startTime.getTime()
    });
    console.log("UserActivity",this.router.url);
    


    this.loggingService.saveUserActivity(userActivity).subscribe();
    let path1=this.router.url;
    let path= path1.split('/');
    let path3= "#/offer"
    console.log(path[0]);

    if (this.navigator.share) {
      this.navigator
        .share({
          title: "place10x.com",
          url: path3+"?id="+subproduct.id,
          text:
            "Hey i found this interesting offer.Visit this link to check the offer"
        })
        .then(() => {
          console.log("Thanks for sharing!");
        })
        .catch(console.error);
    } else {
      this.toastr.error("", "Something went wrong", { timeOut: 1600 });
    }
  }
  public shopNow2(productId,card) {

  }
  shopNow3(productId, subProduct, index, card) {
    let endTime = new Date();
      //Shop button clicked
      //this.shopBtnText[index] = "Earn AED";
      let userActivity: Logging[] = [];
      let campaignId = localStorage.getItem('campainMgmtId') ;
      userActivity.push({
        advId: '',
        longitute:localStorage.getItem("Longitude"),
        latitude:localStorage.getItem("Latitude"),
        campaignId: card.campainId,
        categoryId:JSON.stringify(card.catId),
        productId: JSON.stringify(productId) ,
        subProductId:JSON.stringify(subProduct.id) ,
        userId: localStorage.getItem("userId"),
        userAction: UserActivity[UserActivity.USER_CLICKED_SHOP_NOW],
        startTime: this.startTime.getTime(),
        endTime: endTime.getTime(),
        duration: this.startTime.getTime()-endTime.getTime()
      });
      console.log("UserActivity",userActivity);



      this.loggingService.saveUserActivity(userActivity).subscribe();
      localStorage.removeItem('subProdDetails');
      localStorage.removeItem('offer');

      // localStorage.clear();

      // localStorage.setItem('subProdDetails', JSON.stringify(subProduct));
      // localStorage.setItem('offer', JSON.stringify(this.campaignData.subcatId));
      this.router.navigate(["/offer"],{ queryParams: { id: subProduct.id}});
    }
    // $(".brandName").text(function(index, currentText) {
    //   return currentText.substr(0, 6);
    // });
  
}




