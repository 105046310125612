<div class="deskBack">
  <img class="backImg" src="../../../assets/img/backDubai2.jpg">
  <div class="headers">
    <!-- <app-header><//app-header> -->
    <!-- <div class="row divP">
              <p class="fontStyl1">New to</p> <p class="fontStyl2">Wifi10X</p><p class="fontStyl1">?</p>
      </div>
      <div class="row">
          <button class="newAccountBtn">Create an Account</button>
      </div>
      <div class="row divStyl2">
          <p class="fontStyl">Already have an Account</p>
      </div> -->
    <div class="headDiv pl pr">
      <div class="mb-0 pt-4 pb-3">
        <p class="pSign mb-0">New Users Please Register</p>
      </div>
      <!-- <div class="row"> -->
      <!-- <div class="loginBtnHead">Registration</div> -->
      <!-- </div> -->
      <form [formGroup]="registerForm" class="formStyl" *ngIf="registerPage">

        <div class="form-group">
          <label class="regn-label" for="fullName">Country</label>
          <select class="form-control " formControlName="fullName" id="name"
            [ngClass]="{ 'is-invalid': submitted && f.fullName.errors }">
            <!-- <option [value]=0>Select One</option> -->
            <option *ngFor="let data of countries" value={{data.countryId}}>
              {{data.countryName}}</option>
          </select>

        </div>





        <div class="form-group">
          <label class="regn-label" for="email">Email address:</label>
          <input type="email" formControlName="email" class="form-control" id="email"
            [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
          <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
            <div *ngIf="f.email.errors.required">Email is required</div>
            <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
          </div>
        </div>
        <div class="form-group">
          <label class="regn-label" for="Phone">Date of Birth</label>
          <input type=date   class="form-control" id="dob" formControlName="dob" placeholder="yyyy-mm-dd">
        </div>
        <div class="form-group">
          <label class="regn-label" for="pwd">Password:</label>
          <input type="password" formControlName="password" class="form-control" placeholder="Password@123"
            [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
          <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
            <div *ngIf="f.password.errors.required">Password is required</div>
            <div *ngIf="f.password.errors.minlength">Password must be at least 8 characters</div>
            <div *ngIf="f.password.errors.maxlength">Password must be at most 15 characters</div>
            <div *ngIf="f.password.errors.pattern">Password must have one uppercase one special character and 8 character Long </div>

          </div>
        </div>
        <div class="form-group">
          <label class="regn-label" for="conpwd">Confirm Password:</label>
          <input type="password" formControlName="confirmPassword" class="form-control" id="conpwd"
            [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }">
          <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
            <div *ngIf="f.confirmPassword.errors.required">Confirm Password is required</div>
            <div *ngIf="f.confirmPassword.errors.mustMatch">Passwords must match</div>
          </div>
        </div>

        <div class="form-group">
          <label class="regn-label" for="mobile">Mobile Number</label>
          <input type="mobile" formControlName="mobile" type="tel" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }">
          <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
            <div *ngIf="f.mobile.errors.required">Mobile No. is required</div>
            <div *ngIf="f.mobile.errors.mobile">Enter valid Mobile No.</div>
          </div>
        </div>

        <div class="form-group" *ngIf="submitOtp">
          <label class="regn-label" for="otp" class="text-center otpLine">OTP</label>
          <input type="text" formControlName="otp" class="form-control otpInput" maxLength="4" id="name">
        </div>

        <div class="checkbox" *ngIf="submitOtp">
          <label><input type="checkbox" formControlName="acceptTerms"
              [ngClass]="{ 'is-invalid': submitted && f.acceptTerms.errors }"> I Accept all terms and conditions</label>
          <div *ngIf="submitted && f.acceptTerms.errors" class="invalid-feedback">Accept Ts & Cs is required</div>
        </div>

      </form>

      <div class="row mbOtpDiv">
        <form [formGroup]="otpForm" class="formStyl" *ngIf="!registerPage">
          <div class="form-group mb-0">
            <label class="mt-1 regn-label lable1">Please tick to indicate that the customer cnsents to recive to
              marketing
              form</label>
          </div>
          <div class="checkbox">
            <label class="lable1 regn-label"><input type="checkbox" formControlName="acceptTerms1"
                [ngClass]="{ 'is-invalid': submitted && f.acceptTerms.errors }">&nbsp;Meras Corporate office</label>
            <div *ngIf="submitted && f.acceptTerms.errors" class="invalid-feedback"></div>
          </div>
          <div class="checkbox">
            <label class="lable1 regn-label"><input type="checkbox" formControlName="acceptTerms2"
                [ngClass]="{ 'is-invalid': submitted && f.acceptTerms.errors }">&nbsp;I have read and agreed to the
              <b>Terms & Conditions</b>. By accepting and providing my personal I am consenting to
              <b>Meras privacy Policy</b>.as well as the applicable of data protection laws.</label>
            <div *ngIf="submitted && f.acceptTerms.errors" class="invalid-feedback"></div>
          </div>
          <!-- <div class="card card1 checkbox">
                <label><input type="checkbox" formControlName="acceptTerms3" [ngClass]="{ 'is-invalid': submitted && f.acceptTerms.errors }" >&nbsp;I'm not a robot</label>
              </div> -->
        </form>

      </div>

      <div class="row m-0">

        <button class="loginBtn" type="button" (click)="register()" *ngIf="!registerPage">
          <p class="lockP">{{buttonName}} <i class="fa fa-lock lockIcon"></i></p>
        </button>

        <!-- <div class="divPQ" *ngIf="!registerPage">
          <p class="fontStyl1">Already Registered?</p>
          <p class="fontStyl2">&nbsp;Wifi10X</p>
                <p class="fontStyl1">&nbsp;?</p>
          <button class="newAccountBtn" [routerLink]="['/loginNew']">Login</button>
        </div> -->

        <button class="loginBtn" type="button" (click)="onSubmit()" *ngIf="registerPage">{{buttonName}}</button>
      </div>
      <p class="or" *ngIf="registerPage">Or</p>
      <!-- <div class="col-12 text-center mb-2" *ngIf="registerPage">
        <a href="#" class="text-center" [routerLink]="['/login']">Login here, If already have an account</a>
      </div> -->
      <div class="divP mt-4">
        <p class="fontStyl1">Already have an account?</p>
        <!-- <p class="fontStyl2">&nbsp;Wifi10X</p>
            <p class="fontStyl1">&nbsp;?</p> -->
        <button class="newAccountBtn" [routerLink]="['/login/login']">Login</button>
      </div>
    </div>
  </div>
</div>